import atob from 'atob';

import cookie from './universal-cookies';

export const CookieUtil = {
  getUserExp() {
    const decodedUser = CookieUtil.decodeUserCookie();

    if (!decodedUser) {
      return 0;
    }

    return decodedUser.exp;
  },
  isUserExpired() {
    const decodedUser = CookieUtil.decodeUserCookie();

    if (!decodedUser) {
      return true;
    }

    // The encoded expiration time is in seconds
    const nowSeconds = Date.now() / 1000;
    return !(decodedUser.exp - nowSeconds > 0);
  },
  decodeJWT(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(atob(base64)); // eslint-disable-line no-undef
    } catch (e) {
      return null;
    }
  },
  decodeUserCookie() {
    try {
      const { user } = cookie(document.cookie);
      return CookieUtil.decodeJWT(user);
    } catch (e) {
      return null;
    }
  },
};
