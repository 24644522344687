import GenericStore from '../classes/generic-store';
import { PositionsApi } from '../api/positions-api';

import { OpportunityPositionsStore } from './opportunity-positions-store';

class EditPositionTagsStoreClass extends GenericStore {
  save({ opportunityId, positionId, tags }) {
    return this.clearState(
      PositionsApi.putPositionTags(positionId, tags).then(() =>
        OpportunityPositionsStore.load(opportunityId)
      )
    );
  }
}

export const EditPositionTagsStore = new EditPositionTagsStoreClass();
