import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import { TagShape } from '../../models/tag';
import SearchInput from '../SearchInput/SearchInput';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';
import { formatDataTestId } from '../../utils/dataTest';

import { StaticItem } from './GroupSettingsModalStyles';
import {
  Root,
  maxHeightDivStyle,
  DynamicScrollingItem,
  TagLabel,
  TagList,
  TagListItem,
} from './TagManagementListStyles';

const INITIAL_STATE = {
  query: '',
  shouldConfirmSave: false,
  stagedForDeletion: null,
};

class TagManagementList extends Component {
  get tags() {
    const { tags } = this.props;
    const { query } = this.state;

    if (!query) return tags;

    return tags.filter(({ label }) =>
      label.toLowerCase().includes(query.toLowerCase())
    );
  }

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  handleDelete = tag => {
    this.setState({ shouldConfirmDelete: true, stagedForDeletion: tag });
  };

  handleConfirmDelete = () => {
    const tagId = this.state.stagedForDeletion.value;
    this.setState({ shouldConfirmDelete: false });
    this.props.onConfirmTagDeletion(tagId);
  };

  handleDismissConfirmation = () => {
    this.setState({ shouldConfirmDelete: false, stagedForDeletion: null });
  };

  handleQueryChange = e => this.setState({ query: e.target.value });

  render() {
    const {
      handleConfirmDelete,
      tags,
      props: { onSelectedChange, selected },
      state: { query, shouldConfirmDelete },
    } = this;

    return (
      <Root>
        <StaticItem>
          <SearchInput
            aria-label="Search Tags"
            data-test={formatDataTestId('TAG_MGM_SEARCH')}
            onChange={this.handleQueryChange}
            value={query}
          />

          <Button
            icon="plus"
            pattern="secondary"
            variation="minimal"
            onClick={() =>
              onSelectedChange({ id: 'CREATE', name: 'CREATE', type: 'CREATE' })
            }
          >
            Create New Tag
          </Button>
        </StaticItem>
        <DynamicScrollingItem style={maxHeightDivStyle}>
          <TagList data-test={formatDataTestId('tagList')} role="listbox">
            {tags.map(tag => (
              <TagListItem
                data-test={formatDataTestId('TAG_ITEM')}
                key={tag.value}
                onClick={() => onSelectedChange(tag)}
                selected={selected && selected.id === tag.value}
              >
                <TagLabel data-test={formatDataTestId('TAG_LABEL')}>
                  {tag.label}
                </TagLabel>
                <ContextMenu direction="left">
                  <ContextMenuItem onClick={() => this.handleDelete(tag)}>
                    Delete Tag
                  </ContextMenuItem>
                </ContextMenu>
              </TagListItem>
            ))}
          </TagList>
        </DynamicScrollingItem>
        {shouldConfirmDelete ? (
          <Modal size="confirm">
            <ModalHeader onClose={this.handleDismissConfirmation}>
              Delete Tag
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                You have selected to delete this tag. Doing so will remove it
                from all jobs, talent profiles and saved searches where this has
                been selected.
              </Paragraph>
            </ModalSection>
            <ModalFooter>
              <Button
                variation="outline"
                onClick={this.handleDismissConfirmation}
              >
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </Root>
    );
  }
}

TagManagementList.defaultProps = {
  selected: null,
  onSelectedChange: () => {},
  onConfirmTagDeletion: () => {},
};

TagManagementList.propTypes = {
  tags: PreloadedTagsStore.getDataShape().isRequired,
  selected: TagShape,
  onSelectedChange: PropTypes.func,
  onConfirmTagDeletion: PropTypes.func,
};

export default TagManagementList;
