import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const OTPoolWrapper = styled.div`
  border-top: 0.0625rem solid ${getThemeProp('textMuted')};
  border-bottom: 0.0625rem solid
    ${({ isOpen }) => (isOpen ? 'transparent' : getThemeProp('textMuted'))};
`;
export const OTPoolHeader = styled.button`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-bottom: ${({ isOpen }) => (isOpen ? '1rem' : '1.5rem')};
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTPoolHeaderLeft = styled.div`
  color: ${getThemeProp('darkBlue')};
  font-weight: bold;
`;
export const OTPoolHeaderRight = styled.div`
  flex: 0;
`;
export const OTPoolBody = styled.div``;
export const OTPoolTalentDrawer = styled.div`
  overflow: visible;
`;
