import { GenericDto } from './generic-dto';

export const CertificationDto = class CertificationDto extends GenericDto {
  constructor(body) {
    super(body, [
      'award',
      'candidateId',
      'institution',
      'stateExamScore',
      'year',
    ]);
  }
};
