import React from 'react';
import { CheckmarkCircleIcon, ExclamationMarkCircleIcon } from '@axiom/ui';
import { CandidateOpportunityUtils } from '@axiom/utils';
import { CandidateOpportunitiesConst } from '@axiom/const';

import { CandidateOpportunityShape } from '../../models/candidate-opportunity';

const { CandidateStatuses } = CandidateOpportunitiesConst;

export const CpqMarginIndicator = ({ candidateOpportunity }) => {
  if (!candidateOpportunity?.position?.id) {
    return <div />;
  }

  const { clientRequest } = candidateOpportunity;

  if (
    (candidateOpportunity.candidateStatus === CandidateStatuses.Rejected &&
      clientRequest?.candidateOpportunityStatus ===
        CandidateStatuses.Rejected) ||
    !CandidateOpportunityUtils.hasSubmittedRate(candidateOpportunity)
  ) {
    return <CheckmarkCircleIcon name="RATE_NOT_SET" />;
  }

  if (!CandidateOpportunityUtils.isWithinMargin(candidateOpportunity)) {
    return <ExclamationMarkCircleIcon name="RATE_OUTSIDE_GUIDELINES" />;
  }

  if (CandidateOpportunityUtils.isOverMargin(candidateOpportunity)) {
    return <ExclamationMarkCircleIcon name="MARGIN_OVER_GUIDELINES" />;
  }

  return <CheckmarkCircleIcon checked name="RATE_WITHIN_GUIDELINES" />;
};

CpqMarginIndicator.defaultProps = {
  candidateOpportunity: {},
};

CpqMarginIndicator.propTypes = {
  candidateOpportunity: CandidateOpportunityShape,
};
