import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const CertificationItemWrapper = styled.div`
  margin: 0.625rem 0;
`;

export const CICard = styled.div`
  border: 0.0625rem solid ${getThemeProp('colorPrimary')};
  background: ${getThemeProp('appBackground')};
  padding: 0.625rem;
  position: relative;
`;

export const CICardBody = styled.div``;
export const CICardRow = styled.div`
  &:not(:first-child) {
    margin: 1.125rem 0 0 0;
  }
  display: flex;
  justify-content: space-between;
`;

export const CICardRowLeft = styled.div`
  flex: 0 0 60%;
`;

export const CICardRowRight = styled.div`
  flex: 0 0 40%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;
export const CICardRowRightMenu = styled.div``;
