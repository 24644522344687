const ALPHAS = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const generateRandomInt = () => Math.round(Math.random() * 1e10) + 1e4;

export const generateRandomAlphaString = () => {
  const max = Math.ceil(Math.random() * 64);
  let data = '';

  for (let i = 0; i < max; i += 1) {
    data += ALPHAS[Math.floor(Math.random() * ALPHAS.length)];
  }

  return data;
};

export const generateRandomDateStamp = () =>
  new Date(Math.round(Math.random()) * 1e3);
