import { string, arrayOf, shape } from 'prop-types';

import { UserShape } from './user';

const AccountModel = shape({
  calculatedName: string,
  id: string,
  name: string,
  displayName: string,
  ownerUserId: string,
  businessTeam: string,
  cxPartnerId: string,
  parentAccountId: string,
  salesforceId: string,
  zoomIndustry: string,
  zoomRecordId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
});

export const ContactModel = shape({
  id: string,
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  email: string,
  mobileNumber: string,
  phoneNumber: string,
  salesforceid: string,
  createdAt: string,
  updatedAt: string,
  user: UserShape,
  accounts: arrayOf(AccountModel),
});
