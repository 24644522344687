import { CANDIDATE_STATUS_STYLES } from './candidate-statuses-colors';
import { EMPLOYMENT_STATUS_STYLES } from './employment-statuses-colors';

export const HEADER_HEIGHT = 90;
export const FOOTER_HEIGHT = 90;
export const PAGE_PADDING = 40;
export const GUTTER_WIDTH = 20;
export const TABLE_HEAD_HEIGHT = 34;
export const TABLE_ROW_HEIGHT = 57;
export const SIDEBAR_PADDING = 30;
export const SPINNER_LENGTH = 100;
export const SCROLLBAR_WIDTH = 17;
export const PANEL_WIDTH = 630;
export const SIDEBAR_WIDTH = 630;

export const BOX_SHADOW_RULE = 'box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.1)';

export const GENERAL_STYLES_MAP = {
  AXIOM: {
    color: '#fff',
    background: '#ec653e',
    border: '#000',
  },
  FONT: {
    primary: '#000000',
    secondary: '#2b2b2b',
    tertiary: '#707070',
  },
  DISABLED: {
    border: '#b4b4b8',
    color: '#000',
    background: '#CFCFD4',
  },
  DROPDOWN: {
    border: '#000',
    color: '#fff',
    background: '#001D4C',
    weight: 'bold',
  },
  FALLBACK: {
    border: '#000',
    color: '#000',
    background: '#fff',
  },
  ICON_BUTTON: {
    defaultColor: '#707070',
    defaultBackground: 'transparent',
    hoverColor: '#fff',
    hoverBackground: '#001e49',
    disabledColor: '#cfcfd4',
    disabledBackground: '#fff',
  },
  PANEL_HEADER: {
    color: '#fff',
    background: '#335c81',
    border: '#1d3545',
  },
  SKILLS: {
    border: '#000',
    color: '#fff',
    background: '#001D4C',
    weight: 'bold',
    secondaryBackground: '#f2f2f2',
  },
  ERROR: {
    border: '#D0021B',
    color: '#800000',
    background: '#ffd0d0',
    weight: 'bold',
  },
  INPUT: {
    border: '#707070',
  },
};

export const STATUS_STYLE_MAP = {
  EMPLOYMENT: EMPLOYMENT_STATUS_STYLES,
  CANDIDATE: CANDIDATE_STATUS_STYLES,
};

export const STATUS_STYLE_SHAPES = {
  EMPLOYMENT: Object.keys(EMPLOYMENT_STATUS_STYLES),
  CANDIDATE: Object.keys(CANDIDATE_STATUS_STYLES),
};
