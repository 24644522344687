import React from 'react';

import DefaultLayout from '../layouts/default';
import { CandidatesList } from '../components/CandidatesList/CandidatesList';
import { CandidateFilters } from '../components/CandidateFilters/CandidateFilters';
import { Page } from '../components/Page/Page';

export const TalentPage = () => (
  <Page>
    <DefaultLayout sidebarContent={<CandidateFilters />}>
      <CandidatesList />
    </DefaultLayout>
  </Page>
);
