import React from 'react';
import moment from 'moment';
import { z } from 'zod';
import { CandidateOpportunitiesConst } from '@axiom/const';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Form,
  Button,
  Gutter,
  Checkbox,
  Banner,
  Well,
  CondensedHeader,
  CondensedLabel,
  CondensedMedium,
  Grid,
  GridRow,
  GridColumn,
  DateUtil,
} from '@axiom/ui';
import {
  Experience,
  Opportunity,
  OpportunityPosition,
} from '@axiom/validation';

import { CandidateExperiencesApi } from '../../api/candidate-experiences';
import { CandidateIndustriesApi } from '../../api/candidate-industries';
import { ExperiencesApi } from '../../api/experiences';

const { CandidateStatuses } = CandidateOpportunitiesConst;

export const ConvertToAxiomExperienceConfirmation: React.FC<{
  onClose: () => void;
  experience: Experience;
  opportunity: Opportunity;
  positions: OpportunityPosition[];
}> = ({ onClose = () => {}, experience, opportunity, positions }) => {
  const earliestPositionStartDate = DateUtil.displayDate(
    (positions ?? [])
      .map(({ startDate }) => startDate)
      .filter(Boolean)
      .reduce(
        (acc, curr) =>
          acc === null || moment(curr).isBefore(moment(acc), 'day')
            ? curr
            : acc,
        null
      )
  );
  const hasOngoingPosition = (positions ?? []).some(
    ({ endDate }) => endDate === null
  );
  const mostFuturePositionEndDate = DateUtil.displayDate(
    (positions ?? [])
      .map(({ endDate }) => endDate)
      .filter(Boolean)
      .reduce(
        (acc, curr) =>
          acc === null || moment(curr).isAfter(moment(acc), 'day') ? curr : acc,
        null
      )
  );
  return (
    <Form
      schema={z.object({
        confirm: z
          .boolean()
          .refine(value => value === true, { message: 'Required' }),
      })}
      onSubmit={async () => {
        await ExperiencesApi.updateExperience(experience.id, {
          opportunityId: opportunity.id,
          client: opportunity.accountName ?? 'Axiom Client',
        });
        await Promise.all([
          CandidateExperiencesApi.refreshExperiences(experience.candidateId),
          CandidateIndustriesApi.refreshIndustries(experience.candidateId),
        ]);
        onClose();
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="convert-experience-confirmation">
            <ModalHeader onClose={onClose}>
              Convert to Axiom Engagement
            </ModalHeader>
            <ModalSection>
              <Gutter horizontal="8px" vertical="8px">
                <Banner>
                  Review and compare the opportunity information below and
                  confirm if this is the opportunity information you would like
                  use for this experience.
                </Banner>
                <Gutter bottom="24px" />
                <Well>
                  <CondensedHeader>Profile Experience Details</CondensedHeader>
                  <Gutter bottom="16px" />
                  <Grid>
                    {[
                      {
                        label: 'Company Name',
                        value: experience.client ?? '--',
                        testId: 'experience-company-name',
                      },
                      {
                        label: 'Start Date',
                        value: DateUtil.displayDate(experience.startDate),
                        testId: 'experience-start-date',
                      },
                      {
                        label: 'End Date',
                        value: DateUtil.displayDate(experience.endDate),
                        testId: 'experience-end-date',
                      },
                    ].map(({ label, value, testId }) => (
                      <GridRow key={label}>
                        <GridColumn width={4}>
                          <CondensedLabel>{label}</CondensedLabel>
                        </GridColumn>
                        <GridColumn width={8}>
                          <CondensedMedium name={testId}>
                            {value || '--'}
                          </CondensedMedium>
                        </GridColumn>
                      </GridRow>
                    ))}
                  </Grid>
                </Well>
                <Gutter bottom="24px" />
                <Well>
                  <CondensedHeader>Opportunity Details</CondensedHeader>
                  <Gutter bottom="16px" />
                  <Grid>
                    {[
                      {
                        label: 'Company Name',
                        value: opportunity.accountName,
                        testId: 'opportunity-company-name',
                      },
                      {
                        label: 'Opportunity Name',
                        value: opportunity.jobName,
                        testId: 'opportunity-opportunity-name',
                      },
                      {
                        label: 'Start Date',
                        value: earliestPositionStartDate,
                        testId: 'opportunity-start-date',
                      },
                      {
                        label: 'End Date',
                        value: hasOngoingPosition
                          ? null
                          : mostFuturePositionEndDate,
                        testId: 'opportunity-end-date',
                      },
                      {
                        label: 'Talent on Opportunity',
                        value: positions
                          .map(
                            position =>
                              position.candidates.find(candidate =>
                                [
                                  CandidateStatuses.Completed,
                                  CandidateStatuses.Engaged,
                                  CandidateStatuses.Selected,
                                ].includes(candidate.candidateStatus)
                              )?.calculatedDisplayName
                          )
                          .filter(Boolean)
                          .join(', '),
                        testId: 'opportunity-talent-on-position',
                      },
                    ].map(({ label, value, testId }) => (
                      <GridRow key={label}>
                        <GridColumn width={4}>
                          <CondensedLabel>{label}</CondensedLabel>
                        </GridColumn>
                        <GridColumn width={8}>
                          <CondensedMedium name={testId}>
                            {/* don't use ?? because one can be empty string */}
                            {value || '--'}
                          </CondensedMedium>
                        </GridColumn>
                      </GridRow>
                    ))}
                  </Grid>
                </Well>
                <Gutter bottom="24px" />
                <Checkbox
                  name="confirm"
                  displayValue="By converting this experience to an Axiom engagement, some data (such as company name) will be overwritten."
                />
              </Gutter>
            </ModalSection>
            <ModalFooter>
              <Button
                name="convert-experience-confirmation-cancel"
                onClick={onClose}
                variation="outline"
              >
                Cancel
              </Button>
              <Button
                name="convert-experience-confirmation-confirm"
                onClick={fireSubmit}
              >
                Convert
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
