import React from 'react';
import { shape, string, func, bool } from 'prop-types';

import { FFError } from '../FFError/FFError';
import { FlexDiv } from '../../themes/components';
import { CheckboxWithLabel } from '../CheckboxWithLabel/CheckboxWithLabel';

import { FFErrorWrapper, FFRadioLeftToRightWrapper } from './FFCheckboxStyles';

export const FFCheckbox = props => {
  const {
    input,
    meta,
    onValueChange,
    id,
    label,
    className,
    disabled,
    checkboxWidth,
    checkboxHeight,
  } = props;

  const handleChange = value => {
    input.onChange(value);
    onValueChange(value);
  };

  return (
    <FFRadioLeftToRightWrapper>
      <CheckboxWithLabel
        type="checkbox"
        onValueChange={handleChange}
        className={className}
        disabled={disabled}
        id={
          !id && label
            ? `ID_NEEDED_HTMLFOR_${Math.round(Math.random() * 1000)}`
            : id
        }
        label={label}
        checked={input.checked}
        name={input.name}
        checkboxWidth={checkboxWidth}
        checkboxHeight={checkboxHeight}
      />
      <FlexDiv>
        <FFErrorWrapper>
          <FFError finalFormElementMeta={meta} />
        </FFErrorWrapper>
      </FlexDiv>
    </FFRadioLeftToRightWrapper>
  );
};

FFCheckbox.defaultProps = {
  onValueChange: () => {},
  label: '',
  className: null,
  disabled: false,
  checkboxWidth: undefined,
  checkboxHeight: undefined,
};

FFCheckbox.propTypes = {
  id: string.isRequired,
  input: shape({
    checked: bool.isRequired,
    name: string.isRequired,
  }).isRequired,
  meta: shape({}).isRequired,
  label: string,
  onValueChange: func,
  className: string,
  disabled: bool,
  checkboxWidth: string,
  checkboxHeight: string,
};
