import {
  arrayOf,
  exact,
  string,
  oneOfType,
  number,
  oneOf,
  bool,
  shape,
} from 'prop-types';

export const DropdownSelectedValueShape = oneOfType([
  string,
  number,
  arrayOf(string),
  bool,
]);

export const OptionShape = exact({
  group: DropdownSelectedValueShape,
  groupLabel: DropdownSelectedValueShape,
  type: oneOf(['tag', 'skill', 'practicearea', 'specialty']),

  label: DropdownSelectedValueShape.isRequired,
  value: DropdownSelectedValueShape.isRequired,
});

export const DropdownOptionsShape = arrayOf(OptionShape);

export const DropdownTreeShape = arrayOf(
  shape({
    id: string.isRequired,
    bullhornId: string,
    name: string.isRequired,
    type: oneOf(['skill', 'practicearea', 'specialty', 'candidateStatus'])
      .isRequired,
    parentId: string,
  }).isRequired
);
