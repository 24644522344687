import {
  OPPORTUNITY_COLLABORATORS_LOAD,
  OPPORTUNITY_COLLABORATORS_LOADED,
  OPPORTUNITY_COLLABORATORS_LOAD_ERROR,
} from '../constants';

export const getOpportunityCollaborators = payload => ({
  type: OPPORTUNITY_COLLABORATORS_LOAD,
  payload,
});

export const opportunityCollaboratorsLoaded = payload => ({
  type: OPPORTUNITY_COLLABORATORS_LOADED,
  payload,
});

export const opportunityCollaboratorsError = payload => ({
  type: OPPORTUNITY_COLLABORATORS_LOAD_ERROR,
  error: true,
  payload,
});
