import get from 'lodash/get';

import GenericStore from '../classes/generic-store';
import { getLanguages } from '../api/languages';
import { DropdownOptionsShape } from '../models/dropdown-options';

class PreloadedLanguagesClass extends GenericStore {
  load() {
    return this.setState(
      getLanguages().then(response =>
        response.data.map(data => ({
          value: data.id,
          label: data.name,
        }))
      )
    );
  }

  getDataShape() {
    return DropdownOptionsShape;
  }

  selectData(state) {
    return get(this.select(state), 'data', []);
  }
}

export const PreloadedLanguagesStore = new PreloadedLanguagesClass();
