import { Account, Contact } from '@axiom/validation';
import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('contacts');

export const getContact = async (contactId: Contact['id']) => {
  const { data: contact } = await api.GET(`/contacts/${contactId}`);
  return contact;
};

export const getContacts = async (filterMeta: {
  currentPage: number;
  pageCount: number;
  resultCount: number;
  aggregations: null; // this is always NULL aka we don't use it
}) => {
  const { data, meta } = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/contacts`,
    filterMeta,
    true
  );
  return {
    results: data,
    meta,
  };
};

export const addAccountsToContact = async ({
  contactId,
  accountIds,
}: {
  contactId: Contact['id'];
  accountIds: Account['id'][];
}) => {
  const { data } = await api.POST(`/contacts/${contactId}/accounts`, {
    accountIds,
  });
  return data;
};

class ContactsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  updateContacts(contactId: Contact['id'], body: Contact = {}) {
    return super.write<unknown>({
      endpoint: `/contacts/${contactId}`,
      method: 'PATCH',
      body,
    });
  }
}
export const ContactsApi = new ContactsApiClass();
