import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const NotificationsIconBadge = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 3px;
  right: 3px;
  background: #ff5a2d;
  border-radius: 8px;
  ${MediaQueryUtil.smallScreenOnly(css`
    top: 8px;
  `)}
`;

export const NotificationsIconWrapper = styled.div`
  position: relative;
`;

export const Header = styled.div`
  background: #08164a;
  color: #fff;
  line-height: 3em;
  padding-left: 3em;
`;

export const MessagesWrapper = styled.div`
  background: #fff;
  max-height: 40em;
  width: 100%;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  margin: 1em 2em 0 3em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
`;

export const NotificationIndicator = styled.div`
  display: inline-block;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.dataVizOrange};

  &.read {
    background-color: transparent;
  }
`;
