import React from 'react';
import { connect } from 'react-redux';
import { shape } from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalSection,
  DataTest,
  Paragraph,
  Layout,
  Gutter,
  window,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { TalentWarningModalStore } from '../../stores/talent-warning-modal-store';
import { ObjectUtil } from '../../utils/object-util';
import { withRouter } from '../../hocs/with-router';
import { UserSettingsUtil } from '../../utils/user-settings-util';
import { WithRouterShape } from '../../models/router';
import { WindowUtil } from '../../utils/window-util';

import OpportunityTalentNote from './OpportunityTalentNote';
import OpportunityTalentPool from './OpportunityTalentPool';
import OpportunityTalentPosition from './OpportunityTalentPosition';
import { OpportunityTalentWrapper } from './OpportunityTalentStyles';
import { OpportunityTalentCandidateParent } from './OpportunityTalentCandidateParent';

const defaultState = {
  showReassignConfirmModal: false,
  confirmCandidateId: null,
  confirmPositionId: null,
  oldState: null,
};

class OpportunityTalent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  onDragUpdate = event => {
    if (event.destination) {
      EditOpportunityTalentTabStore.setState({
        hoveredPositionId: event.destination.droppableId,
      });
    } else {
      EditOpportunityTalentTabStore.setState({
        hoveredPositionId: null,
      });
    }
  };

  onDragEnd = event => {
    if (event.destination) {
      const {
        source: { droppableId: sourcePositionId } = {},
        destination: { droppableId: destinationPositionId } = {},
        draggableId: candidateId,
      } = event;

      const editTalent = this.props.editTalentsState.data;
      const { isMlRecommendation = false } =
        editTalent.talentPool.candidates.find(({ id }) => id === candidateId) ??
        {};
      const newState = this.moveCandidate(
        editTalent,
        candidateId,
        destinationPositionId
      );

      if (destinationPositionId === sourcePositionId) {
        EditOpportunityTalentTabStore.setState({
          hoveredPositionId: null,
        });
      } else if (
        sourcePositionId === EditOpportunityTalentTabStore.TALENT_POOL_ID
      ) {
        const args = {
          opportunityId: newState.opportunity.id,
          candidateId,
          positionId: destinationPositionId,
        };
        EditOpportunityTalentTabStore.setState(newState).then(() =>
          (isMlRecommendation
            ? EditOpportunityTalentTabStore.saveCandidateToPosition(args)
            : EditOpportunityTalentTabStore.saveOpportunityCandidate(args)
          )
            .then(() => {
              TalentWarningModalStore.onTalentOpportunityUpdate(candidateId);
              this.clearConfirmState();
            })
            .catch(e => {
              EditOpportunityTalentTabStore.revertStateWithError(editTalent, e);
            })
        );
      } else {
        this.handleShowReassignConfirmModal(
          candidateId,
          destinationPositionId,
          () => {
            EditOpportunityTalentTabStore.setState(newState);
          }
        );
      }
    }
  };

  handleShowReassignConfirmModal = (
    confirmCandidateId,
    confirmPositionId,
    callback = () => {}
  ) => {
    this.setState(
      {
        oldState: this.props.editTalentsState.data,
        confirmCandidateId,
        confirmPositionId,
        showReassignConfirmModal: true,
      },
      callback
    );
  };

  moveCandidate(editState, candidateId, newPositionId) {
    const candidate = editState.candidates.find(c => c.id === candidateId);
    const newState = ObjectUtil.clone(editState);

    newState.talentPool.candidates = newState.talentPool.candidates.filter(
      c => c.id !== candidateId
    );
    newState.positions = newState.positions.map(p => {
      p.candidates = p.candidates.filter(c => c.id !== candidateId);
      if (p.id === newPositionId) {
        p.candidates.push(candidate);
      }
      return p;
    });

    return newState;
  }

  clearConfirmState = () => {
    this.setState({
      ...defaultState,
    });
  };

  cancel = () => {
    EditOpportunityTalentTabStore.setState(this.state.oldState);
    this.clearConfirmState();
  };

  confirm = () => {
    const { oldState, confirmCandidateId, confirmPositionId } = this.state;
    EditOpportunityTalentTabStore.saveOpportunityCandidate({
      opportunityId: oldState.opportunity.id,
      candidateId: confirmCandidateId,
      positionId: confirmPositionId,
    })
      .then(() => {
        TalentWarningModalStore.onTalentOpportunityUpdate(confirmCandidateId);
        this.clearConfirmState();
      })
      .catch(e => {
        EditOpportunityTalentTabStore.revertStateWithError(oldState, e);
      });
    this.clearConfirmState();
  };

  renderConfirmModal = () => {
    const { confirm, cancel } = this;

    return (
      <Modal name="REASSIGNPOSITIONMODAL">
        <ModalHeader onClose={cancel}>Reassign Position</ModalHeader>
        <ModalSection>
          <Paragraph>
            Are you sure you would like to reassign this talent to another
            position? Doing so will impact their current rate, status and
            submission tracker in Axiom for Talent?
          </Paragraph>
        </ModalSection>
        <ModalFooter>
          <Button onClick={cancel} name="CANCELBUTTON" variation="outline">
            Cancel
          </Button>
          <Button onClick={confirm} name="SAVEBUTTON">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    const {
      props: {
        editTalentsState,
        user,
        router: { location, navigate },
      },
      state: { showReassignConfirmModal },
      onDragUpdate,
      onDragEnd,
      renderConfirmModal,
    } = this;

    if (
      UserSettingsUtil.shouldShowNewOpportunityTalentView(user.userSettings)
    ) {
      return <OpportunityTalentCandidateParent />;
    }

    return (
      <Gutter horizontal="16px">
        <StoreStateTreatment
          name="opp_talent_tab"
          storeState={editTalentsState}
          renderLoadedView={({ data: editTalent }) => (
            <OpportunityTalentWrapper>
              {showReassignConfirmModal && renderConfirmModal()}
              <Gutter top="16px" right="16px">
                <Layout position="right">
                  <Button
                    name="PENDO_CHECK_OUT_THE_NEW_TALENT_VIEW_BUTTON"
                    onClick={async () => {
                      await UserSettingsUtil.updateShowNewOpportunityTalentViewSetting(
                        true
                      );
                      if (location.pathname.includes('/opportunity-detail')) {
                        navigate(
                          WindowUtil.appendQueryParams(
                            `/opportunity-detail/${editTalent.opportunity.id}/talent/`
                          )
                        );
                      } else {
                        window.open(
                          `/opportunity-detail/${editTalent.opportunity.id}/talent/`
                        );

                        navigate(
                          WindowUtil.appendQueryParams(`/opportunities`)
                        );
                      }
                    }}
                  >
                    CHECK OUT THE NEW TALENT VIEW
                  </Button>
                </Layout>
              </Gutter>
              <OpportunityTalentNote
                opportunityPositions={editTalent.positions}
                opportunity={editTalent.opportunity}
              />
              <DragDropContext
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
              >
                <DataTest value="opportunityTalentPositions">
                  {editTalent.positions.map((position, i) => (
                    <OpportunityTalentPosition
                      data-test="candidate-card-position"
                      position={position}
                      editTalent={editTalent}
                      positionIndex={i}
                      isHovered={editTalent.hoveredPositionId === position.id}
                      key={position.id}
                      positions={editTalent.positions}
                      user={user}
                    />
                  ))}
                </DataTest>
                <OpportunityTalentPool
                  editTalent={editTalent}
                  user={user}
                  savedFilters={editTalent.savedFilters}
                />
              </DragDropContext>
            </OpportunityTalentWrapper>
          )}
        />
      </Gutter>
    );
  }
}

OpportunityTalent.propTypes = {
  editTalentsState: EditOpportunityTalentTabStore.getStateShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  router: shape(WithRouterShape).isRequired,
};

const mapStateToProps = state => ({
  editTalentsState: EditOpportunityTalentTabStore.select(state),
  user: PreloadedUserStore.select(state),
});

export default connect(mapStateToProps)(withRouter(OpportunityTalent));
