import {
  HOME_OFFICE_LOADED,
  HOME_OFFICE_LOAD_ERROR,
  HOME_OFFICES_LOADED,
  HOME_OFFICES_LOAD_ERROR,
} from '../constants';

export const homeOfficeLoaded = payload => ({
  type: HOME_OFFICE_LOADED,
  payload,
});

export const homeOfficeLoadedError = name => ({
  type: HOME_OFFICE_LOAD_ERROR,
  payload: { name },
});

export const homeOfficesLoaded = payload => ({
  type: HOME_OFFICES_LOADED,
  payload,
});

export const homeOfficesLoadedError = payload => ({
  type: HOME_OFFICES_LOAD_ERROR,
  payload,
});
