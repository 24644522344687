import { EventsConst } from '@axiom/const';

import { TalentActivitiesApi } from '../api/talentActivities';
import { eventShape } from '../models/event';
import { AjvUtil } from '../utils/ajv-util';
import { DateUtil } from '../utils/date-util';
import { addActivityById, updateActivityById } from '../api/activities';
import { getCollaboratorsByTalentId } from '../api/talentCollaborators';
import { getTalent } from '../api/talent';
import { getCollaboratorsByOpportunityId } from '../api/opportunityCollaborators';
import { getJob as getOpportunity } from '../api/jobs';
import { UserTalentApi } from '../api/user-talent-api';
import { selectifyObject } from '../utils/selectify';
import FormStore from '../classes/form-store';

import { EventsStore, EventSubjectConst } from './events-store';

export const eventTypeOptions = selectifyObject(
  Object.values(EventsConst.Types).sort()
);
export const eventPurposeOptions = selectifyObject(
  Object.values(EventsConst.Purposes).sort()
);

const EventValidation = AjvUtil.compileSchema({
  required: ['type', 'purpose'],
  properties: {
    type: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
    purpose: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
  },
});

class AppFormEventItemModalStoreClass extends FormStore {
  getDataShape() {
    return eventShape;
  }

  closeModal() {
    return this.clearState();
  }

  /**
   *
   * @param listEventSubject - EventSubjectConst.*
   * @param event - {}
   * @returns {Promise}
   */
  openModal(listEventSubject, event, ownerUserId = undefined) {
    EventsStore.ensureValidSubject(listEventSubject);

    if (!event || (!event.opportunityId && !event.candidateId)) {
      throw new Error('Must pass in a candidateId or an opportunityId or both');
    }
    const { candidateId, opportunityId } = event;
    const asyncData = [
      candidateId ? getCollaboratorsByTalentId(candidateId) : {},
      opportunityId ? getCollaboratorsByOpportunityId(opportunityId) : {},
      candidateId ? getTalent(candidateId) : {},
      opportunityId ? getOpportunity(opportunityId) : {},
    ];

    return this.setState(
      Promise.all(asyncData).then(
        ([candidateCollabs, oppCollabs, can, opp]) => {
          const ownerUserEmails = [can.data, opp.data].reduce((acc, data) => {
            if (data && data.ownerUser && data.ownerUser.email) {
              acc.push(data.ownerUser.email);
            }

            return acc;
          }, []);

          const collabEmails = [candidateCollabs.data, oppCollabs.data].reduce(
            (acc, data) => {
              if (data && data.length) {
                data.forEach(user => {
                  if (user && user.email) {
                    acc.push(user.email);
                  }
                });
              }

              return acc;
            },
            []
          );

          const collaborateEmails = [
            ...new Set(collabEmails.concat(ownerUserEmails)),
          ];

          return {
            id: event.id,
            listEventSubject,
            candidateId,
            opportunityId,
            description: event.description,
            purpose: event.purpose,
            type: event.type,
            attachmentKey: event.attachmentKey,
            attachmentName: event.attachmentName,
            emailAddresses: event.id
              ? event.emailAddresses || []
              : collaborateEmails,
            scheduledDate:
              event.scheduledDate || DateUtil.formatAsDate(new Date()),
            updatedAt: event.updatedAt,
            collaborateEmails,
            ownerUserId,
          };
        }
      )
    );
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(EventValidation, formData);
  }

  submit(changedFields, formData) {
    const eventSubject = formData.listEventSubject;
    const { listEventSubject, ...filteredFormData } = formData;
    return this.clearState(
      Promise.resolve()
        .then(() => {
          delete changedFields.collaborateEmails;

          if (filteredFormData.id) {
            /**
             * Issues with FormData which the updateActivityById => Multipatch is using;
             * It does NOT handle nulls. It will translate null => "null";
             * It's really really stupid. But we need it for the file uploading.....
             */
            if (changedFields.description === null) {
              changedFields.description = '';
            }

            return updateActivityById(filteredFormData.id, changedFields);
          }

          return addActivityById(filteredFormData);
        })
        .then(async () => {
          EventsStore.load(
            eventSubject,
            eventSubject === EventSubjectConst.Candidate
              ? filteredFormData.candidateId
              : filteredFormData.opportunityId
          );
          if (filteredFormData.candidateId) {
            await TalentActivitiesApi.refreshActivities(
              filteredFormData.candidateId
            );
            if (filteredFormData.ownerUserId) {
              await UserTalentApi.refreshUserTalent(
                filteredFormData.ownerUserId
              );
            }
          }

          return null;
        })
    );
  }
}

export const AppEventItemModalStore = new AppFormEventItemModalStoreClass();
