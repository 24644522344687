import { arrayOf, shape, string } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getPracticeAreas } from '../api/practice-area-api';

class PreloadedPracticeAreasStoreClass extends GenericStore {
  static validTypes() {
    return {
      PRACTICE_AREA: 'practicearea',
      SPECIALTY: 'specialty',
    };
  }

  load() {
    return this.setState(
      getPracticeAreas().then(response =>
        response.data
          .filter(data => data.type)
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
      )
    );
  }

  getDataShape() {
    return arrayOf(
      shape({
        id: string,
        bullhornId: string,
        name: string,
        parentId: string,
        type: string,
      })
    );
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const PreloadedPracticeAreasStore =
  new PreloadedPracticeAreasStoreClass();
