import { reducer as form } from 'redux-form';

import app from './app';
import bannerMessages from './bannerMessages';
import barredLocations from './barredLocations';
import groupManagementView from './groupManagementView';
import groups from './groups';
import homeOffices from './homeOffices';
import opportunityCandidates from './opportunityCandidates';
import opportunityCollaborators from './opportunityCollaborators';
import opportunityLanguages from './opportunityLanguages';
import opportunities from './opportunities';
import languages from './languages';
import searches from './searches';
import notifications from './notifications';
import talent from './talent';
import talentBarredLocations from './talentBarredLocations';
import talentCollaborators from './talentCollaborators';
import talentHomeOffices from './talentHomeOffices';
import talentLanguages from './talentLanguages';
import talentOpportunities from './talentOpportunities';
import talentTags from './talentTags';
import userManagementView from './userManagementView';
import userOpportunities from './userOpportunities';
import users from './users';
import userTalent from './userTalent';

export default {
  app,
  bannerMessages,
  barredLocations,
  form,
  groupManagementView,
  groups,
  homeOffices,
  languages,
  notifications,
  opportunities,
  opportunityCandidates,
  opportunityCollaborators,
  opportunityLanguages,
  searches,
  talent,
  talentBarredLocations,
  talentCollaborators,
  talentHomeOffices,
  talentLanguages,
  talentOpportunities,
  talentTags,
  userManagementView,
  userOpportunities,
  users,
  userTalent,
};
