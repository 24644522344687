import {
  any,
  arrayOf,
  number,
  shape,
  string,
  bool,
  oneOfType,
  object,
} from 'prop-types';
import { omit } from 'lodash';

import {
  TALENT_FIELD_ACTIVITY_LOG,
  TALENT_FIELD_ADDRESS_1,
  TALENT_FIELD_ADDRESS_2,
  TALENT_FIELD_ADDRESS_CITY,
  TALENT_FIELD_ADDRESS_COUNTRY,
  TALENT_FIELD_ADDRESS_COUNTRY_CODE,
  TALENT_FIELD_ADDRESS_DISPLAY_NAME,
  TALENT_FIELD_ADDRESS_STATE,
  TALENT_FIELD_ADDRESS_ZIP,
  TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE,
  TALENT_FIELD_AVAILABILITY,
  TALENT_FIELD_AVAILABILITY_NOTES,
  TALENT_FIELD_AXIOM_EMPLOYEE_TYPE,
  TALENT_FIELD_BARRED_LOCATIONS,
  TALENT_FIELD_BULLHORN_ID,
  TALENT_FIELD_CALCULATED_DESIRED_WEEKLY_HOURS,
  TALENT_FIELD_CANDIDACY_ENGAGEMENTS,
  TALENT_FIELD_CANDIDACY_LIST,
  TALENT_FIELD_COLLABORATORS,
  TALENT_FIELD_COLLABORATOR_ID,
  TALENT_FIELD_COMPENSATION,
  TALENT_FIELD_CREATED_AT,
  TALENT_FIELD_DAYS_DESIRED_REMOTE,
  TALENT_FIELD_DAYS_REQUIRED_REMOTE,
  TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE,
  TALENT_FIELD_DAYS_WILLING_TO_COMMUTE,
  TALENT_FIELD_EXPERIENCES,
  TALENT_FIELD_FIRST_NAME,
  TALENT_FIELD_FURTHEST_CANDIDACY_STATUS,
  TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT,
  TALENT_FIELD_HOME_OFFICE_ID,
  TALENT_FIELD_HOME_PHONE,
  TALENT_FIELD_HOURLY_COMPENSATION,
  TALENT_FIELD_ID,
  TALENT_FIELD_IS_OPEN_TO_ADHOC,
  TALENT_FIELD_IS_OPEN_TO_FULL_TIME,
  TALENT_FIELD_IS_OPEN_TO_PART_TIME,
  TALENT_FIELD_IS_OPEN_TO_REMOTE,
  TALENT_FIELD_IS_OPEN_TO_COMMUTE,
  TALENT_FIELD_LANGUAGE_CEFR,
  TALENT_FIELD_LANGUAGES,
  TALENT_FIELD_LAST_NAME,
  TALENT_FIELD_LAST_UPDATED_BY,
  TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE,
  TALENT_FIELD_MATTER_ID,
  TALENT_FIELD_MAX_MINUTES_COMMUTE_ONE_WAY,
  TALENT_FIELD_MIDDLE_NAME,
  TALENT_FIELD_MOBILE_PHONE,
  TALENT_FIELD_NICK_NAME,
  TALENT_FIELD_NOTICE_PERIOD,
  TALENT_FIELD_OCCUPATION_TYPE,
  TALENT_FIELD_OWNER_FULL_NAME,
  TALENT_FIELD_OWNER_LAST_NAME,
  TALENT_FIELD_OWNER_USER_ID,
  TALENT_FIELD_PART_TIME_HOURS_MONDAY,
  TALENT_FIELD_PART_TIME_HOURS_TUESDAY,
  TALENT_FIELD_PART_TIME_HOURS_WEDNESDAY,
  TALENT_FIELD_PART_TIME_HOURS_THURSDAY,
  TALENT_FIELD_PART_TIME_HOURS_FRIDAY,
  TALENT_FIELD_PERSONAL_EMAIL,
  TALENT_FIELD_PROFILE_STATUS,
  TALENT_FIELD_PROFILE_STATUS_UPDATED_AT,
  TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_TAGS,
  TALENT_FIELD_UPDATED_AT,
  TALENT_FIELD_WEEKLY_AVAILABILITY,
  TALENT_FIELD_WORK_EMAIL,
  TALENT_FIELD_YEARS_OF_EXPERIENCE,
} from './constants/talentFields';

export const talentFormInitialValues = talent => {
  const values = {
    ...omit(talent, [
      TALENT_FIELD_ACTIVITY_LOG,
      TALENT_FIELD_CANDIDACY_LIST,
      TALENT_FIELD_CREATED_AT,
      TALENT_FIELD_EXPERIENCES,
      TALENT_FIELD_UPDATED_AT,
      TALENT_FIELD_YEARS_OF_EXPERIENCE,
    ]),
  };

  values[TALENT_FIELD_COLLABORATORS] = values[TALENT_FIELD_COLLABORATORS].map(
    collaborator => collaborator.id
  );

  return values;
};

export const talentModel = {
  [TALENT_FIELD_ACTIVITY_LOG]: arrayOf(any),
  [TALENT_FIELD_ADDRESS_1]: string,
  [TALENT_FIELD_ADDRESS_2]: string,
  [TALENT_FIELD_ADDRESS_CITY]: string,
  [TALENT_FIELD_ADDRESS_COUNTRY_CODE]: string,
  [TALENT_FIELD_ADDRESS_COUNTRY]: string,
  [TALENT_FIELD_ADDRESS_DISPLAY_NAME]: string,
  [TALENT_FIELD_ADDRESS_STATE]: string,
  [TALENT_FIELD_ADDRESS_ZIP]: string,
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE]: string,
  [TALENT_FIELD_AVAILABILITY_NOTES]: string,
  [TALENT_FIELD_AVAILABILITY]: string,
  [TALENT_FIELD_AXIOM_EMPLOYEE_TYPE]: string,
  [TALENT_FIELD_BARRED_LOCATIONS]: oneOfType([
    arrayOf(string),
    arrayOf(object),
  ]),
  [TALENT_FIELD_BULLHORN_ID]: string,
  [TALENT_FIELD_CALCULATED_DESIRED_WEEKLY_HOURS]: number,
  [TALENT_FIELD_CANDIDACY_ENGAGEMENTS]: arrayOf(any),
  [TALENT_FIELD_CANDIDACY_LIST]: arrayOf(any),
  [TALENT_FIELD_COLLABORATOR_ID]: arrayOf(any),
  [TALENT_FIELD_COLLABORATORS]: arrayOf(any),
  [TALENT_FIELD_COMPENSATION]: string,
  [TALENT_FIELD_CREATED_AT]: string,
  [TALENT_FIELD_DAYS_DESIRED_REMOTE]: number,
  [TALENT_FIELD_DAYS_REQUIRED_REMOTE]: number,
  [TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE]: number,
  [TALENT_FIELD_DAYS_WILLING_TO_COMMUTE]: number,
  [TALENT_FIELD_EXPERIENCES]: arrayOf(any),
  [TALENT_FIELD_FIRST_NAME]: string,
  [TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT]: string,
  [TALENT_FIELD_FURTHEST_CANDIDACY_STATUS]: string,
  [TALENT_FIELD_HOME_OFFICE_ID]: string,
  [TALENT_FIELD_HOME_PHONE]: string,
  [TALENT_FIELD_HOURLY_COMPENSATION]: string,
  [TALENT_FIELD_ID]: string,
  [TALENT_FIELD_IS_OPEN_TO_ADHOC]: bool,
  [TALENT_FIELD_IS_OPEN_TO_FULL_TIME]: bool,
  [TALENT_FIELD_IS_OPEN_TO_PART_TIME]: bool,
  [TALENT_FIELD_IS_OPEN_TO_REMOTE]: bool,
  [TALENT_FIELD_IS_OPEN_TO_COMMUTE]: bool,
  [TALENT_FIELD_LANGUAGE_CEFR]: arrayOf(any),
  [TALENT_FIELD_LANGUAGES]: arrayOf(any),
  [TALENT_FIELD_LAST_NAME]: string,
  [TALENT_FIELD_LAST_UPDATED_BY]: string,
  [TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  [TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE]: string,
  [TALENT_FIELD_MATTER_ID]: string,
  [TALENT_FIELD_MAX_MINUTES_COMMUTE_ONE_WAY]: number,
  [TALENT_FIELD_MIDDLE_NAME]: string,
  [TALENT_FIELD_MOBILE_PHONE]: string,
  [TALENT_FIELD_NICK_NAME]: string,
  [TALENT_FIELD_NOTICE_PERIOD]: string,
  [TALENT_FIELD_OCCUPATION_TYPE]: string,
  [TALENT_FIELD_OWNER_FULL_NAME]: string,
  [TALENT_FIELD_OWNER_LAST_NAME]: string,
  [TALENT_FIELD_OWNER_USER_ID]: string,
  [TALENT_FIELD_PART_TIME_HOURS_MONDAY]: number,
  [TALENT_FIELD_PART_TIME_HOURS_TUESDAY]: number,
  [TALENT_FIELD_PART_TIME_HOURS_WEDNESDAY]: number,
  [TALENT_FIELD_PART_TIME_HOURS_THURSDAY]: number,
  [TALENT_FIELD_PART_TIME_HOURS_FRIDAY]: number,
  [TALENT_FIELD_PERSONAL_EMAIL]: string,
  practiceAreaId: string,
  practiceArea: shape({
    bullhornId: string,
    id: string,
    name: string,
  }),
  [TALENT_FIELD_PROFILE_STATUS_UPDATED_AT]: string,
  [TALENT_FIELD_PROFILE_STATUS]: string,
  [TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  soonestEngagementEndDate: string,
  [TALENT_FIELD_TAGS]: arrayOf(any),
  [TALENT_FIELD_UPDATED_AT]: string,
  [TALENT_FIELD_WEEKLY_AVAILABILITY]: number,
  [TALENT_FIELD_WORK_EMAIL]: string,
  [TALENT_FIELD_YEARS_OF_EXPERIENCE]: number,
  displayFirstName: string,
  displayLastName: string,
  availabilityPreferencesUpdatedAt: string,
  availabilityPreferencesUpdatedBy: string,
  openToLowerEsc: bool,
};

export const talentShape = shape(talentModel);
export const talentModelProperties = Object.keys(talentModel);
