import React from 'react';
import { node } from 'prop-types';
import { Gutter, Header4, Text } from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';
import { SIDEBAR_PADDING } from '../../themes/constants';

import { NoContentPlaceholderWrapper } from './NoContentPlaceholderStyles';

const NoContentPlaceholder = ({ image, text, title }) => (
  <NoContentPlaceholderWrapper
    data-test={formatDataTestId('no content placeholder')}
  >
    {image}
    <Gutter vertical="0.625rem">
      <Header4 name="title">{title}</Header4>
    </Gutter>
    <Gutter bottom={`${SIDEBAR_PADDING}`}>
      <Text name="text">{text}</Text>
    </Gutter>
  </NoContentPlaceholderWrapper>
);

NoContentPlaceholder.defaultProps = {
  image: null,
};

NoContentPlaceholder.propTypes = {
  image: node,
  text: node.isRequired,
  title: node.isRequired,
};

export default NoContentPlaceholder;
