import { USER_TALENT_LOADED, USER_TALENT_LOAD_ERROR } from '../constants';

export const talentByUserIdLoaded = payload => ({
  type: USER_TALENT_LOADED,
  payload,
});

export const talentByUserIdError = payload => ({
  type: USER_TALENT_LOAD_ERROR,
  payload,
  error: true,
});
