import {
  GROUPS_LOAD,
  GROUPS_LOAD_ERROR,
  GROUPS_LOADED,
  GROUP_CREATE,
  GROUP_CREATE_ERROR,
  GROUP_CREATED,
  GROUP_DELETE,
  GROUP_DELETE_ERROR,
  GROUP_DELETED,
  GROUP_UPDATE,
  GROUP_UPDATE_ERROR,
  GROUP_UPDATED,
  GROUP_USER_ADD,
  GROUP_USER_ADD_ERROR,
  GROUP_USER_ADDED,
  GROUP_USER_REMOVE,
  GROUP_USER_REMOVE_ERROR,
  GROUP_USER_REMOVED,
} from '../constants';

export const loadGroups = payload => ({
  type: GROUPS_LOAD,
  payload,
});

export const groupsLoaded = payload => ({
  type: GROUPS_LOADED,
  payload,
});

export const groupsLoadedError = payload => ({
  type: GROUPS_LOAD_ERROR,
  error: true,
  payload,
});

export const createGroup = payload => ({
  type: GROUP_CREATE,
  payload,
});

export const groupCreated = payload => ({
  type: GROUP_CREATED,
  payload,
});

export const groupCreatedError = payload => ({
  type: GROUP_CREATE_ERROR,
  error: true,
  payload,
});

export const deleteGroup = payload => ({
  type: GROUP_DELETE,
  payload,
});

export const groupDeleted = payload => ({
  type: GROUP_DELETED,
  payload,
});

export const groupDeletedError = payload => ({
  type: GROUP_DELETE_ERROR,
  error: true,
  payload,
});

export const updateGroup = payload => ({
  type: GROUP_UPDATE,
  payload,
});

export const groupUpdated = payload => ({
  type: GROUP_UPDATED,
  payload,
});

export const groupUpdatedError = payload => ({
  type: GROUP_UPDATE_ERROR,
  error: true,
  payload,
});

export const addGroupUser = payload => ({
  type: GROUP_USER_ADD,
  payload,
});

export const groupUserAdded = payload => ({
  type: GROUP_USER_ADDED,
  payload,
});

export const groupUserAddedError = payload => ({
  type: GROUP_USER_ADD_ERROR,
  error: true,
  payload,
});

export const removeGroupUser = payload => ({
  type: GROUP_USER_REMOVE,
  payload,
});

export const groupUserRemoved = payload => ({
  type: GROUP_USER_REMOVED,
  payload,
});

export const groupUserRemovedError = payload => ({
  type: GROUP_USER_REMOVE_ERROR,
  error: true,
  payload,
});
