import get from 'lodash/get';
import { bool, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { WindowUtil } from '../utils/window-util';

const FEATURE_FLAG_NAMES = {
  enablePendo: 'enable-envoy-pendo',
  enablePdfDownloading: 'enable-pdf-downloading',
  enableJiraIssueCollectors: 'enable-envoy-jira',
  enableDeletePosition: 'enable-delete-position',
  enableManageClients: 'enable-envoy-manage-clients',
  enableMinPayCheck: 'enable-min-pay-check',
};

export const DEFAULT_FLAGS = {
  [FEATURE_FLAG_NAMES.enablePendo]: true,
  [FEATURE_FLAG_NAMES.enablePdfDownloading]: true,
  [FEATURE_FLAG_NAMES.enableJiraIssueCollectors]: true,
  [FEATURE_FLAG_NAMES.enableDeletePosition]: true,
  [FEATURE_FLAG_NAMES.enableManageClients]: true,
  [FEATURE_FLAG_NAMES.enableMinPayCheck]: true,
};

class PreloadedFeatureFlagsStoreClass extends GenericStore {
  bootstrap(user) {
    this.load();

    WindowUtil.initializePendo(user);
  }

  getDataShape() {
    return shape({
      [FEATURE_FLAG_NAMES.enablePendo]: bool,
      [FEATURE_FLAG_NAMES.enablePdfDownloading]: bool,
      [FEATURE_FLAG_NAMES.enableJiraIssueCollectors]: bool,
      [FEATURE_FLAG_NAMES.enableDeletePosition]: bool,
      [FEATURE_FLAG_NAMES.enableManageClients]: bool,
      [FEATURE_FLAG_NAMES.enableMinPayCheck]: bool,
    });
  }

  /**
   *
   * @param flags - comes in as {"enable-envoy-pendo": value}
   */
  load(flags = {}) {
    return this.setState({
      ...DEFAULT_FLAGS,
      ...flags,
    });
  }

  select(state) {
    return super.select(state).data || { ...DEFAULT_FLAGS };
  }

  isPendoEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enablePendo);
  }

  isPdfDownloadingEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enablePdfDownloading);
  }

  isJiraIssueCollectorsEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enableJiraIssueCollectors);
  }

  isDeletePositionEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enableDeletePosition);
  }

  isManageClientsEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enableManageClients);
  }

  isMinPayCheckEnabled(flags) {
    return get(flags, FEATURE_FLAG_NAMES.enableMinPayCheck);
  }
}

export const PreloadedFeatureFlagsStore = new PreloadedFeatureFlagsStoreClass();
