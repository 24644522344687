import React, { useState } from 'react';
import moment from 'moment-timezone';
import { z } from 'zod';
import {
  CalendarEventType,
  Candidate,
  Opportunity,
  User,
  CalendarEventSchema,
} from '@axiom/validation';
import {
  Grid,
  GridColumn,
  GridRow,
  ImageCircle,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  SmallHeader,
  Calendar,
  CalendarPermissionsUtil,
  Button,
  LayoutItem,
  Gutter,
  Form,
  CalendarTimeZoneUtil,
  useApi,
  FormLabel,
  CondensedMedium,
  useBreakpoint,
  CandidateProfileUtil,
  CalendarEventsUtil,
  EventStates,
  Dropdown,
  Badge,
  Paragraph,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  PermissionImpersonationRoles,
  CandidatesConst,
} from '@axiom/const';
import { candidateOpportunityStatusUtils } from '@axiom/utils';

import { CalendarApi } from '../../api/calendar-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { useRefreshCandidate } from '../../hooks/useRefreshCandidate';

import { TalentInterviewConfirmModal } from './TalentInterviewConfirmModal';
import { LegendKey } from './TalentInterviewAvailabilityModalStyles';

const dayWeekMode = {
  slots: 2,
  increments: 30,
};

const NAME = 'CALENDAR_COMPONENT' as const;

const OptionsIds = {
  AVAILABILITY: 'availability',
  INTERVIEW: 'interview',
};
const ModeDropdownOptions = [
  {
    id: OptionsIds.AVAILABILITY,
    text: 'Edit Availability',
  },
  {
    id: OptionsIds.INTERVIEW,
    text: 'Schedule Interview',
  },
];
const optionsEnum = Object.values(OptionsIds) as NonEmptyArray<string>;
const CalendarSchema = z.object({
  [NAME]: CalendarEventSchema.array(),
  MODE_CHANGER: z.enum(optionsEnum),
});
type CalendarType = z.infer<typeof CalendarSchema>;

export type TalentInterviewAvailabilityModalType = {
  opportunity?: Opportunity;
  position?: Opportunity['positions'][number];
  candidate: Candidate;
  user: User;
  onClose: () => void;
};

export const TalentInterviewAvailabilityModal = ({
  opportunity,
  position,
  candidate,
  user,
  onClose,
}: TalentInterviewAvailabilityModalType) => {
  const guessIana = moment.tz.guess();
  const { isMobile } = useBreakpoint();
  const [confirmModalOpen, setConfirmModalOpen] = useState([]);
  const { refreshCandidate } = useRefreshCandidate(candidate.id);
  const { id: calendarId = null, timezone: talentIana } =
    candidate.calendar || {};
  const timezone = CalendarTimeZoneUtil.getTimeZone(talentIana || guessIana);
  const [{ data: calendarData } = { data: {} }] = useApi(
    calendarId && CalendarApi.readCalendar(calendarId)
  );

  const canChangeCandidateStatusToInterviewing: boolean =
    !!opportunity?.id &&
    opportunity?.isQualificationComplete &&
    (candidate.candidateStatus ===
      CandidatesConst.CandidateStatuses.Interviewing ||
      candidateOpportunityStatusUtils
        .getAllowedTransitions({
          currentCandidateStatus: candidate.candidateStatus,
          opportunity,
          position,
          role: user.roles[0],
        })
        .includes(CandidatesConst.CandidateStatuses.Interviewing));

  const {
    changePermissionsToEditStaticEvent: changeStaticPermissions,
    changePermissionsToEditEventBoundary: changeFreeTimePermissions,
  } = CalendarPermissionsUtil(user, PermissionImpersonationRoles.envoy);

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarId),
      CalendarApi.refreshCalendarEvents(calendarId),
      opportunity?.id ? OpportunityStore.load(opportunity.id) : null,
      refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = async (
    { [NAME]: formEvents, MODE_CHANGER }: CalendarType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const hasError = CalendarEventsUtil.getFormError(formEvents);
    if (hasError) {
      setFieldError(NAME, hasError);

      return;
    }

    const activePermissions =
      MODE_CHANGER === ModeDropdownOptions[0].id
        ? changeFreeTimePermissions()
        : changeStaticPermissions();

    const cleanEvents = CalendarEventsUtil.clearAndOrganiseEvents(
      activePermissions,
      calendarData.events,
      formEvents
    );

    const currentCalendarId =
      calendarId ||
      (
        await CalendarApi.createCalendar({
          candidateId: candidate.id,
          timezone: timezone.iana,
        })
      ).data.id;

    const promises = [];

    // These API calls are in specific order to prevent race-conditions ==================== START
    if (cleanEvents.deleteIds.length) {
      await CalendarApi.deleteCalendarEvents(
        currentCalendarId,
        cleanEvents.deleteIds
      );
    }

    if (cleanEvents.modifiedEvents.length) {
      const payload = cleanEvents.modifiedEvents.reduce((crnt, event) => {
        const { id, ...evnt } = event;
        crnt[id] = evnt;
        return crnt;
      }, {} as { [key: string]: CalendarEventType });

      promises.push(
        CalendarApi.updateCalendarEvents(currentCalendarId, payload)
      );
    }

    if (cleanEvents.newEvents.length) {
      promises.push(
        CalendarApi.createCalendarEvents(
          currentCalendarId,
          cleanEvents.newEvents
        )
      );
    }

    await Promise.all(promises);
    // These API calls are in specific order to prevent race-conditions ==================== END

    if (cleanEvents.staticEvents.length) {
      setConfirmModalOpen(cleanEvents.staticEvents);
    } else {
      refreshData();
      onClose();
    }
  };

  return (
    <>
      {confirmModalOpen.length === 0 ? (
        <Form
          name="INTERVIEW_AUTO_FORM"
          schema={CalendarSchema}
          initialValues={
            {
              [NAME]: calendarData?.events || [],
              MODE_CHANGER: OptionsIds.AVAILABILITY,
            } as CalendarType
          }
          onSubmit={handleUpdateOnClick}
        >
          {({ fireSubmit, values }) => {
            const isAvailabilityMode =
              values.MODE_CHANGER === ModeDropdownOptions[0].id;
            const activePermissions = isAvailabilityMode
              ? changeFreeTimePermissions()
              : changeStaticPermissions();

            return (
              <Modal size="large" name="INTERVIEW_AUTO_MODAL">
                <ModalHeader name="MODAL_HEADER" onClose={onClose}>
                  <Layout position="middle" wrap>
                    <LayoutItem rightGutter="16px">
                      <ImageCircle
                        imageName={candidate.calculatedDisplayName}
                        src={CandidateProfileUtil.getProfileImageUri(
                          candidate,
                          PossibleImageSizes.W_100
                        )}
                        size="small"
                        name="TALENT_IMAGE"
                      />
                    </LayoutItem>
                    <LayoutItem fluid>
                      <SmallHeader name="HEADER_TEXT">
                        {isAvailabilityMode
                          ? `Edit ${candidate.calculatedFirstName}'s interview availability`
                          : `Schedule an interview for ${candidate.calculatedFirstName}`}
                      </SmallHeader>
                    </LayoutItem>
                    {canChangeCandidateStatusToInterviewing && (
                      <LayoutItem name="BADGE_CONTAINER" rightGutter="16px">
                        {isAvailabilityMode ? (
                          <Badge name="EDIT_AVAILABILITY" background="green">
                            Edit availability
                          </Badge>
                        ) : (
                          <Badge name="SCHEDULE_INTERVIEW" background="red">
                            Schedule interview
                          </Badge>
                        )}
                      </LayoutItem>
                    )}
                  </Layout>
                </ModalHeader>
                <ModalSection>
                  <Grid>
                    {canChangeCandidateStatusToInterviewing && (
                      <GridRow gutterBottom="24px">
                        <GridColumn>
                          <Dropdown
                            name="MODE_CHANGER"
                            valueKey="id"
                            displayKey="text"
                            options={ModeDropdownOptions}
                            label="Would you like to edit the talents availability or schedule an interview?"
                          />
                        </GridColumn>
                      </GridRow>
                    )}
                    <GridRow>
                      <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                        {isAvailabilityMode ? (
                          <CondensedMedium name="TALENTS_AVAILABILITY_TEXT">
                            Choose as many time slots as possible to increase
                            the likelihood of being matched for an interview. We
                            suggest a minimum 4 hours over the next two weeks.
                          </CondensedMedium>
                        ) : (
                          <CondensedMedium name="SCHEDULE_TALENT_INTERVIEW_TEXT">
                            Hello! We've highlighted{' '}
                            {candidate.calculatedFirstName}
                            's availability in the calendar. Please select one
                            30-minute time slot for
                            {candidate.calculatedFirstName} to interview with{' '}
                            {opportunity.account.calculatedName}.
                          </CondensedMedium>
                        )}
                        <Gutter bottom="24px" />
                        <FormLabel name="pendo-timezone">Time zone</FormLabel>
                        {talentIana ? (
                          <>
                            <CondensedMedium name="TALENT_TIME_ZONE_TEXT">
                              Times are shown in the talent's local time zone.
                            </CondensedMedium>
                            <CondensedMedium name="TALENT_TIME_ZONE">
                              {CalendarTimeZoneUtil.getDisplayZone(timezone)}
                            </CondensedMedium>
                          </>
                        ) : (
                          <CondensedMedium name="NO_TALENT_TIME_ZONE">
                            A default time zone has not been set. Please reach
                            out to the talent to confirm and adjust their
                            preferred time zone.
                          </CondensedMedium>
                        )}
                        <Gutter bottom="16px" />
                      </GridColumn>
                      <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                        <Calendar
                          name={NAME}
                          permissions={activePermissions}
                          dayMode={dayWeekMode}
                          weekMode={dayWeekMode}
                          timezone={timezone.iana}
                        />
                        <Gutter bottom="8px" />
                        <Layout position="left middle" horizontalGutter="8px">
                          <LayoutItem>
                            <LegendKey type="availability" />
                          </LayoutItem>
                          <LayoutItem rightGutter="16px">
                            <Paragraph name="LEGEND_TEXT_AVAILABILITY">
                              {candidate.calculatedFirstName}'s availability
                            </Paragraph>
                          </LayoutItem>
                          <LayoutItem>
                            <LegendKey type="scheduled" />
                          </LayoutItem>
                          <LayoutItem>
                            <Paragraph name="LEGEND_TEXT">
                              Scheduled Interviews
                            </Paragraph>
                          </LayoutItem>
                        </Layout>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </ModalSection>
                <ModalFooter>
                  <Button
                    onClick={onClose}
                    pattern="primary"
                    variation="outline"
                    name="CANCEL_BUTTON"
                  >
                    Cancel
                  </Button>
                  {!isMobile &&
                    (isAvailabilityMode &&
                    !values[NAME]?.find(
                      event =>
                        !CalendarEventsUtil.isInThePast(
                          event.endTime,
                          activePermissions
                        ) &&
                        event.busy &&
                        event.state !== EventStates.SAVED
                    ) ? (
                      <Button onClick={fireSubmit} name="SAVE_BUTTON">
                        Save
                      </Button>
                    ) : (
                      <Button onClick={fireSubmit} name="NEXT_BUTTON">
                        Next
                      </Button>
                    ))}
                </ModalFooter>
              </Modal>
            );
          }}
        </Form>
      ) : (
        <TalentInterviewConfirmModal
          refreshData={refreshData}
          onBackHandler={async () => {
            setConfirmModalOpen([]);
          }}
          opportunity={opportunity}
          candidate={candidate}
          onClose={onClose}
          scheduledEvents={confirmModalOpen.sort((a, b) => {
            return a.startTime > b.startTime ? 1 : -1;
          })}
          timezone={timezone}
        />
      )}
    </>
  );
};
