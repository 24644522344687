import styled from 'styled-components';

export const RadioButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  input,
  label {
    cursor: pointer;
  }

  ${({ $labelSide }) =>
    $labelSide === 'right'
      ? 'input {order: 1; margin-right: 0.5em;} label {order: 2; margin: 0}'
      : 'input {order: 2; margin: 0;} label {order: 1; margin-right: 0.75em;}'}
`;

export const RadioInput = styled.input``;

export const RadioLabel = styled.label`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colorSecondary : 'inherit'};
`;
