import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';
import TypeaheadModal from '../TypeaheadModal/TypeaheadModal';
import { TalentWarningModalStore } from '../../stores/talent-warning-modal-store';

import {
  AFCFOMFlagIcon,
  AFCFOMFlagWrapper,
  AFCFOMItemContainer,
} from './AppFindCandidateForOppModalStyles';

const AppFindCandidateForOppModal = ({ findCandidateState }) => {
  const [confirmCandidateId, setConfirmCandidateId] = useState(null);

  const onSelect = (storeData, candidateId) => {
    if (
      !confirmCandidateId &&
      AppFindCandidateForOppModalStore.isCandidateInPosition(
        storeData.positions,
        candidateId
      )
    ) {
      setConfirmCandidateId(candidateId);
    } else {
      setConfirmCandidateId(null);
      AppFindCandidateForOppModalStore.submit(storeData, candidateId).then(
        () => {
          TalentWarningModalStore.onTalentOpportunityUpdate(candidateId);
        }
      );
    }
  };

  const onCancel = () => {
    setConfirmCandidateId(null);
    AppFindCandidateForOppModalStore.clearState();
  };

  return (
    <StoreStateTreatment
      isModalState
      showInitialLoader={false}
      showRefetchLoader={false}
      name="FIND_CANDIDATE_FOR_OPP_STATE"
      storeState={findCandidateState}
      renderLoadedView={({ data }) => (
        <>
          <TypeaheadModal
            storeState={findCandidateState}
            onSearch={(searchTerm, page) =>
              AppFindCandidateForOppModalStore.findCandidates(searchTerm, {
                ...data,
                page,
                results: page === 1 ? [] : data.results,
              })
            }
            onCancel={onCancel}
            totalResultsCount={data.totalResultsCount}
            itemRender={candidate => (
              <AFCFOMItemContainer
                key={data.id}
                onClick={() => {
                  onSelect(data, candidate.id);
                }}
              >
                <AFCFOMFlagWrapper>
                  <AFCFOMFlagIcon filled={!!candidate.isFlagged} />
                </AFCFOMFlagWrapper>
                <span>{candidate.calculatedDisplayName}</span>
              </AFCFOMItemContainer>
            )}
            title="Search Talent"
            searchTerm={data.searchTerm}
            results={data.results}
            page={data.page}
          />
          {confirmCandidateId && (
            <Modal name="reassignPositionModal">
              <ModalHeader onClose={onCancel}>Reassign Position</ModalHeader>
              <ModalSection>
                <Paragraph>
                  Are you sure you would like to reassign this talent to another
                  position? Doing so will impact their current rate, status and
                  submission tracker in Axiom for Talent?
                </Paragraph>
              </ModalSection>
              <ModalFooter>
                <Button
                  onClick={onCancel}
                  name="cancelButton"
                  variation="outline"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => onSelect(data, confirmCandidateId)}
                  name="saveButton"
                >
                  Save
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </>
      )}
    />
  );
};

AppFindCandidateForOppModal.propTypes = {
  findCandidateState:
    AppFindCandidateForOppModalStore.getStateShape().isRequired,
};

export default connect(state => ({
  findCandidateState: AppFindCandidateForOppModalStore.select(state),
  featureFlags: PreloadedFeatureFlagsStore.select(state),
}))(AppFindCandidateForOppModal);
