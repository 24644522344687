import { string, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { PositionsApi } from '../api/positions-api';

import { OpportunityStore } from './opportunity-store';

class DeletePositionStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      id: string,
      opportunityId: string,
    });
  }

  load({ id, opportunityId }) {
    return this.setState({
      id,
      opportunityId,
    });
  }

  delete({ id, opportunityId }) {
    return this.clearState(PositionsApi.deletePosition(id)).then(() => {
      OpportunityStore.load(opportunityId);
    });
  }
}

export const DeletePositionStore = new DeletePositionStoreClass();
