import { Api } from '@axiom/ui';
import { Experience, ExperienceEdit } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class ExperiencesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  updateExperience(experienceId: Experience['id'], body: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${experienceId}`,
      body,
      method: 'PATCH',
    });
  }

  deleteExperience(experienceId: Experience['id']) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${experienceId}`,
      method: 'DELETE',
    });
  }
}

export const ExperiencesApi = new ExperiencesApiClass();
