import {
  DECORATED_OPPORTUNITY_LOADED,
  OPPORTUNITY_CANDIDATES_LOAD,
  OPPORTUNITY_CANDIDATES_LOAD_SUCCESS,
  OPPORTUNITY_CANDIDATES_LOAD_ERROR,
} from '../constants';

export const INITIAL_STATE = {
  byOpportunityId: {},
  error: false,
  loading: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DECORATED_OPPORTUNITY_LOADED:
      return {
        ...state,
        byOpportunityId: {
          ...state.byOpportunityId,
          [payload.opportunityId]: payload.candidates.data,
        },
      };

    case OPPORTUNITY_CANDIDATES_LOAD:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case OPPORTUNITY_CANDIDATES_LOAD_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case OPPORTUNITY_CANDIDATES_LOAD_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        byOpportunityId: {
          ...state.byOpportunityId,
          [payload.opportunityId]: payload.candidates.data,
        },
      };

    default:
      return state;
  }
};

export default reducer;
