import { Api } from '@axiom/ui';
import { Candidate, Event } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('activity');

export const getActivitiesByTalentId = async (candidateID: Candidate['id']) => {
  const response = await api.GET(`/candidates/${candidateID}/events`);
  return { data: response.data };
};

class TalentActivitiesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readActivities(candidateId: Candidate['id']) {
    return super.read<{ data: Event[] }>({
      endpoint: `/candidates/${candidateId}/events`,
      method: 'GET',
    });
  }

  refreshActivities(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}/events`);
  }

  refreshAllActivities() {
    return super.invalidateAll();
  }
}

export const TalentActivitiesApi = new TalentActivitiesApiClass();
