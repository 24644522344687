import { createSelector } from 'reselect';
import fGet from 'lodash/fp/get';
import fMapValues from 'lodash/fp/mapValues';

import { sortUsers } from '../../utils/sort';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';

const rolesFilter = new Set(['EnvoyAdmin', 'EnvoyUser', 'EnvoySales']);

export const usersSelector = state => PreloadedUsersStore.select(state);

export const usersLastUpdatedSelector = state => state.lastUpdated;

export const usersSelectorSortedList = createSelector(usersSelector, users =>
  Array.isArray(users.data)
    ? users.data
        .map(user => ({ ...user }))
        .sort((a, b) => {
          if (a.isActive !== b.isActive) {
            return a.isActive ? -1 : 1;
          }
          return sortUsers(a, b);
        })
    : []
);

export const usersSelectorActiveEnvoyUsers = createSelector(
  usersSelectorSortedList,
  users =>
    (users || []).filter(u => u.roles.some(role => role.includes('Envoy')))
);

export const usersSelectorDropdownOptions = createSelector(
  usersSelectorSortedList,
  users => ({
    options: (users || []).map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
  })
);

export const fullNameByIdSelector = createSelector(
  state => PreloadedUsersStore.selectDataForById(state),
  fMapValues(({ firstName, lastName }) => `${firstName} ${lastName}`)
);

export const usersByIdSelector = createSelector(
  fGet('users.byId'),
  usersById => usersById
);

export const usersSelectorFilteredDropdownOptions = createSelector(
  usersSelectorSortedList,
  users => ({
    options: (users || [])
      .filter(user => user.roles.some(role => rolesFilter.has(role)))
      .map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
  })
);
