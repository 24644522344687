import { AjvUtil } from '../utils/ajv-util';
import FormStore from '../classes/form-store';
import { lawSchoolShape } from '../models/lawSchool';
import {
  updateLawSchool,
  getLawSchool,
  postLawSchool,
} from '../api/lawSchools';

import { LawSchoolsStore } from './law-schools-store';

const lawSchoolsValidation = AjvUtil.compileSchema({
  properties: {
    name: {
      type: 'string',
      isNotEmpty: true,
      minLength: 1,
    },
    tags: {
      type: 'string',
      format: 'uuid',
    },
  },
  required: ['name'],
});

class FormLawSchoolManagementStoreClass extends FormStore {
  load({ id: schoolId, tags: newSchoolTags }) {
    return this.setState(
      schoolId
        ? getLawSchool(schoolId).then(({ name, tags, id }) => ({
            name,
            tags: tags?.[0]?.id,
            id,
          }))
        : this.clearState().then(() => ({
            id: schoolId,
            name: undefined,
            tags: newSchoolTags,
          }))
    );
  }

  // using setTimeout per Brutus since elasticsearch takes a bit
  reload() {
    return new Promise(resolve => {
      setTimeout(() => {
        LawSchoolsStore.load({
          searchTerm: '',
        }).then(() => resolve());
      }, 1500);
    });
  }

  getDataShape() {
    return lawSchoolShape;
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(lawSchoolsValidation, formData);
  }

  formatRequest(payload, defaults) {
    const copy = { ...defaults, ...payload };
    if (copy.name) copy.name = copy.name.replace(/ +/g, ' ').trim();
    if (copy.tags) copy.tags = [copy.tags];
    return copy;
  }

  submit(changedFields, { id, tags }) {
    return (
      id
        ? updateLawSchool(id, this.formatRequest(changedFields))
        : postLawSchool(this.formatRequest(changedFields, { tags }))
    )
      .then(() => this.clearState())
      .then(() => this.reload())
      .catch(error => {
        if (error?.applicationError?.response?.status !== 409) {
          throw error;
        }
      });
  }
}

export const FormLawSchoolManagementStore =
  new FormLawSchoolManagementStoreClass();
