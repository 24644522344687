export const USER_FIELD_EMAIL = 'email';
export const USER_FIELD_FIRST_NAME = 'firstName';
export const USER_FIELD_HOME_OFFICE = 'homeOffice';
export const USER_FIELD_ID = 'id';
export const USER_FIELD_IS_ACTIVE = 'isActive';
export const USER_FIELD_LAST_NAME = 'lastName';
export const USER_FIELD_ROLES = 'roles';
export const USER_FIELD_TEAM = 'team';

export const userFieldLabels = {
  [USER_FIELD_EMAIL]: 'User Email',
  [USER_FIELD_FIRST_NAME]: 'First Name',
  [USER_FIELD_HOME_OFFICE]: 'Home Office',
  [USER_FIELD_IS_ACTIVE]: 'User Status',
  [USER_FIELD_LAST_NAME]: 'Last Name',
  [USER_FIELD_ROLES]: 'User Type',
};
