import { shape, arrayOf, string, oneOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getSkills } from '../api/tags';

class SkillsStoreClass extends GenericStore {
  getDataShape() {
    return arrayOf(
      shape({
        id: string,
        name: string,
        type: oneOf(['skill']),
        parentId: string,
      })
    );
  }

  load() {
    return this.setState(getSkills().then(response => response.data));
  }

  select(state) {
    return super.select(state)?.data || [];
  }
}

export const PreloadedSkillsStore = new SkillsStoreClass();
