const EnvVars = {
  ENVOY_API_URL: 'ENVOY_API_URL',
  CLIENT_URL: 'CLIENT_URL',
  CLIENT_API_BASE: 'CLIENT_API_BASE',
  PORTAL_URL: 'PORTAL_URL',
  LD_CLIENT_ID: 'LD_CLIENT_ID',
  LD_FAKE_USER_ID: 'LD_FAKE_USER_ID',
  COOKIE_DOMAIN: 'COOKIE_DOMAIN',
  LOGIN_URL: 'LOGIN_URL',
  SFDC_BASE_URL: 'SFDC_BASE_URL',
  SFDC_APEX_URL: 'SFDC_APEX_URL',
  SFDC_CONTACT_URL: 'SFDC_CONTACT_URL',
  GA_ACCOUNT_ID: 'GA_ACCOUNT_ID',
  GTM_ACCOUNT_ID: 'GTM_ACCOUNT_ID',
  SFDC_OPP_URL: 'SFDC_OPP_URL',
  PENDO_APP_ID: 'PENDO_APP_ID',
  GITLAB_MERGE_ID: 'GITLAB_MERGE_ID',
  GITLAB_PROJECT_ID: 'GITLAB_PROJECT_ID',
  CLIENT_BENCH_URL: 'CLIENT_BENCH_URL',
  TABLEAU_URL_COMPANY: 'TABLEAU_URL_COMPANY',
  TABLEAU_URL_LOCATION: 'TABLEAU_URL_LOCATION',
  TABLEAU_URL_LANGUAGE: 'TABLEAU_URL_LANGUAGE',
  TABLEAU_URL_SIMILAR_TALENT: 'TABLEAU_URL_SIMILAR_TALENT',
  LOGGLY_CLIENT_ID: 'LOGGLY_CLIENT_ID',
  ENVIRONMENT_NAME: 'ENVIRONMENT_NAME',
  ENABLE_LOGGING: 'ENABLE_LOGGING',
  S3_PUBLIC_FILE_BUCKET: 'S3_PUBLIC_FILE_BUCKET',
  S3_PUBLIC_BASE_URL: 'S3_PUBLIC_BASE_URL',
  GOOGLE_MAPS_API_URL: 'GOOGLE_MAPS_API_URL',
  HUBSPOT_TALENT_FINDER: 'HUBSPOT_TALENT_FINDER',
  MY_APPS_URL: 'MY_APPS_URL',
  PROPOSED_BURDENED_HELP_LINK: 'PROPOSED_BURDENED_HELP_LINK',
  ENABLE_SUGGESTIONS: 'ENABLE_SUGGESTIONS',
  ENABLE_CALENDAR_EVENTS_TODAY: 'ENABLE_CALENDAR_EVENTS_TODAY',
  AXIOM_SEISMIC_SCREEN_RESOLUTION_INFO_URL:
    'AXIOM_SEISMIC_SCREEN_RESOLUTION_INFO_URL',
  CONTACT_SERVICEDESK_URL: 'CONTACT_SERVICEDESK_URL',
  REPORT_AN_ISSUE_URL: 'REPORT_AN_ISSUE_URL',
};

let envs = {};

const EnvUtil = {
  initialize(env) {
    envs = this.pluckAllowedFrom(env);
  },

  getAllowedFrontendProcessVars() {
    return Object.values(EnvVars);
  },

  pluckAllowedFrom(obj) {
    const allowedProps = {};
    this.getAllowedFrontendProcessVars().forEach(propName => {
      allowedProps[propName] = obj[propName] || null;
    });

    return allowedProps;
  },

  get loginUrl() {
    return envs[EnvVars.LOGIN_URL];
  },

  get clientUrl() {
    return envs[EnvVars.CLIENT_URL];
  },

  get portalUrl() {
    return envs[EnvVars.PORTAL_URL];
  },

  get apiEnvoyUrl() {
    return envs[EnvVars.ENVOY_API_URL];
  },

  get cookieDomain() {
    return envs[EnvVars.COOKIE_DOMAIN];
  },

  get sfdcBaseUrl() {
    return envs[EnvVars.SFDC_BASE_URL];
  },

  get sfdcContactUrl() {
    return envs[EnvVars.SFDC_CONTACT_URL];
  },

  get sfdcOppUrl() {
    return envs[EnvVars.SFDC_OPP_URL];
  },

  get gaAccountId() {
    return envs[EnvVars.GA_ACCOUNT_ID];
  },

  get gtmAccountId() {
    return envs[EnvVars.GTM_ACCOUNT_ID];
  },

  get pendoAppId() {
    return envs[EnvVars.PENDO_APP_ID];
  },

  get gitLabMergeId() {
    return envs[EnvVars.GITLAB_MERGE_ID];
  },

  get gitLabProjectId() {
    return envs[EnvVars.GITLAB_PROJECT_ID];
  },

  get clientApiBase() {
    return envs[EnvVars.CLIENT_API_BASE] || '/api';
  },

  get clientBenchUrl() {
    return envs[EnvVars.CLIENT_BENCH_URL];
  },

  get tableauCompanyUrl() {
    return envs[EnvVars.TABLEAU_URL_COMPANY];
  },

  get tableauLocationUrl() {
    return envs[EnvVars.TABLEAU_URL_LOCATION];
  },

  get tableauLanguageUrl() {
    return envs[EnvVars.TABLEAU_URL_LANGUAGE];
  },

  get tableauSimilarTalentUrl() {
    return envs[EnvVars.TABLEAU_URL_SIMILAR_TALENT];
  },

  get logglyClientId() {
    return envs[EnvVars.LOGGLY_CLIENT_ID];
  },

  get environmentName() {
    return envs[EnvVars.ENVIRONMENT_NAME];
  },

  get enableLogging() {
    return (
      envs[EnvVars.ENABLE_LOGGING] === true ||
      envs[EnvVars.ENABLE_LOGGING] === 'true'
    );
  },

  get publicS3Bucket() {
    return envs[EnvVars.S3_PUBLIC_FILE_BUCKET];
  },

  get publicBaseUrlS3() {
    return envs[EnvVars.S3_PUBLIC_BASE_URL];
  },

  get axiomSeismicScreenResolutionInfoUrl() {
    return envs[EnvVars.AXIOM_SEISMIC_SCREEN_RESOLUTION_INFO_URL];
  },

  get contactServicesdeskUrl() {
    return envs[EnvVars.CONTACT_SERVICEDESK_URL];
  },

  get reportAnIssueUrl() {
    return envs[EnvVars.REPORT_AN_ISSUE_URL];
  },

  get googleMapsApiUrl() {
    // something (yaml parser?) encodes these characters
    return (envs[EnvVars.GOOGLE_MAPS_API_URL] ?? '')
      .replaceAll('&#x3D;', '=')
      .replaceAll('&amp;', '&');
  },

  get hubspotTalentFinder() {
    return envs[EnvVars.HUBSPOT_TALENT_FINDER];
  },

  get myAppsUrl() {
    return envs[EnvVars.MY_APPS_URL];
  },

  get proposedBurdenedHelpLink() {
    return envs[EnvVars.PROPOSED_BURDENED_HELP_LINK];
  },

  get enableSuggestions() {
    return (
      envs[EnvVars.ENABLE_SUGGESTIONS] === true ||
      envs[EnvVars.ENABLE_SUGGESTIONS] === 'true'
    );
  },

  get enableCalendarEventsToday() {
    return (
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === true ||
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === 'true'
    ).toString();
  },
};

try {
  EnvUtil.initialize(window?.ENV || {});
  // eslint-disable-next-line no-empty
} catch (e) {}

module.exports = { EnvUtil };
