import { CandidateDto, CandidateFiltersRequestDto, Api } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

class TalentApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readCandidate(candidateId: Candidate['id']) {
    return super.read<{ data: Candidate }>({
      endpoint: `/candidates/${candidateId}`,
      method: 'GET',
    });
  }

  updateCandidate(candidateId: Candidate['id'], candidate: Candidate) {
    return super.write<{ data: Candidate }>({
      endpoint: `/candidates/${candidateId}`,
      method: 'PATCH',
      body: candidate,
    });
  }

  refreshCandidate(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}`);
  }
}

export const LegacyTalentApi = new TalentApiClass();

/**
 * This is displayed to user so needs to be talent, not candidate
 */
const api = new APIHelper('talent');

export const getTalents = async (filterMeta = { page: 1 }) => {
  const requestBody = new CandidateFiltersRequestDto(filterMeta);
  const { data, meta } = await api.POST(
    `${EnvUtil.apiEnvoyUrl}/candidates/search`,
    requestBody.export()
  );
  return { data, meta };
};

export const getTalent = async (candidateID: Candidate['id']) => {
  const { data } = await api.GET(`/candidates/${candidateID}`);
  return { data };
};

export const patchTalent = async (
  candidateID: Candidate['id'],
  body: Candidate
) => {
  const requestBody = new CandidateDto(body);
  const { data } = await api.PATCH(
    `/candidates/${candidateID}`,
    requestBody.export()
  );
  return { data };
};

export const downloadTalentList = async (body: {
  request: Record<string, unknown>;
}) => {
  const response = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/downloads/candidates`,
    body,
    true
  );
  return response;
};
