import React from 'react';
import { func } from 'prop-types';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Tabs,
  Tab,
} from '@axiom/ui';

import ManageAccountsView from '../ManageAccountsView/ManageAccountsView';
import ManageContactsView from '../ManageContactsView/ManageContactsView';

const ManageClientsModal = ({ onClose }) => {
  return (
    <Modal title="Manage Clients" size="large" name="ManageClientsModal">
      <ModalHeader onClose={onClose}>Manage Clients</ModalHeader>
      <ModalSection>
        <Tabs>
          <Tab label="Accounts" name="Accounts">
            <ManageAccountsView />
          </Tab>
          <Tab label="Users" name="Users">
            <ManageContactsView />
          </Tab>
        </Tabs>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

ManageClientsModal.propTypes = {
  onClose: func.isRequired,
};

export default ManageClientsModal;
