import styled from 'styled-components';

// eslint-disable-next-line boundaries/element-types
import { DynamicScrollingItem as UMLDynamicScrollingItem } from '../UserManagementList/UserManagementListStyles';

export const ManageContactListDynamicScrollingItem = styled(
  UMLDynamicScrollingItem
)`
  max-height: 32em;
  overflow: hidden;
`;

export {
  Root as ManageContactListRoot,
  StaticItem as ManageContactListStaticItem,
  UserList as MCList,
  UserListItem as ManageContactListItem,
} from '../UserManagementList/UserManagementListStyles';
