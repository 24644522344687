import { UserConst } from '@axiom/const';
import { Api, UserDto } from '@axiom/ui';
import { User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('user');

export const getLoggedUser = async () => {
  const data = await api.GET('/user/me', {
    expand: [UserConst.Expansions.settingsInfo],
  });

  return data ? data.data : null;
};

export const getUsers = async (body: { roles?: Array<string> } = {}) => {
  body.roles = [
    UserConst.UserRoles.EnvoyAdmin,
    UserConst.UserRoles.EnvoyUser,
    UserConst.UserRoles.EnvoySales,
  ];

  const { data } = await api.GET('/users', body);
  return { data };
};

export const updateUser = async (user: User) => {
  const requestBody = new UserDto(user);
  const { data } = await api.PATCH(`/users/${user.id}`, requestBody.export());
  return { data };
};

export const getUserGroups = async (user: User) => {
  const { data } = await api.GET(`/users/${user.id}/groups`);
  return { data };
};

export const setUserGroups = async (user: User, groups: Array<string>) => {
  const { data } = await api.PUT(`/users/${user.id}/groups`, groups);
  return { data };
};

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  getUserById(id: User['id']) {
    return super.read<{ data: User }>({
      endpoint: `/users/${id}/`,
      method: 'GET',
    });
  }

  readUsers(query: { roles?: Array<string> } = {}) {
    return super.read<{ data: Array<User> }>({
      endpoint: '/users',
      method: 'GET',
      encodeQuery: false,
      query: {
        roles: [
          UserConst.UserRoles.EnvoyAdmin,
          UserConst.UserRoles.EnvoyUser,
          UserConst.UserRoles.EnvoySales,
        ],
        ...query,
      },
    });
  }
}

export const UsersApi = new UsersApiClass();

export default {
  getUsers,
  updateUser,
  setUserGroups,
};
