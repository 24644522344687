import React, { useState } from 'react';
import { func } from 'prop-types';
import {
  CandidateStatusLabelsMap,
  CurrentEngagementCandidateStatuses,
  InConsiderationCandidateStatuses,
  NoLongerInConsiderationCandidateStatuses,
  PreviousEngagementCandidateStatuses,
} from '@axiom/const';

import DropdownTree from '../DropdownTree/DropdownTree';
import { TalentOpportunityCardTypeNames } from '../TalentOpportunityCard/TalentOpportunityCard';

const optionConfig = {
  inConsideration: 'InConsiderationSection',
  currentSectionId: 'CurrentSection',
  previousSectionId: 'PreviousSection',
  noLongerInConsideration: 'NoLongerInConsideration',
  optionType: 'candidateStatus',
};

const candidateStatusFilterOptionMapper = (statuses, parentId) => {
  return statuses.map(status => ({
    id: status,
    name: CandidateStatusLabelsMap.get(status),
    type: optionConfig.optionType,
    parentId,
  }));
};

const talentOpportunityOptions = [
  {
    id: optionConfig.inConsideration,
    name: TalentOpportunityCardTypeNames.inConsideration,
    type: optionConfig.optionType,
    parentId: null,
  },
  ...candidateStatusFilterOptionMapper(
    InConsiderationCandidateStatuses,
    optionConfig.inConsideration
  ),
  {
    id: optionConfig.currentSectionId,
    name: TalentOpportunityCardTypeNames.currentEngagements,
    type: optionConfig.optionType,
    parentId: null,
  },
  ...candidateStatusFilterOptionMapper(
    CurrentEngagementCandidateStatuses,
    optionConfig.currentSectionId
  ),
  {
    id: optionConfig.previousSectionId,
    name: TalentOpportunityCardTypeNames.previousEngagements,
    type: optionConfig.optionType,
    parentId: null,
  },
  ...candidateStatusFilterOptionMapper(
    PreviousEngagementCandidateStatuses,
    optionConfig.previousSectionId
  ),
  {
    id: optionConfig.noLongerInConsideration,
    name: TalentOpportunityCardTypeNames.noLongerInConsideration,
    type: optionConfig.optionType,
    parentId: null,
  },
  ...candidateStatusFilterOptionMapper(
    NoLongerInConsiderationCandidateStatuses,
    optionConfig.noLongerInConsideration
  ),
];

export const TalentOpportunityTabFilter = ({ onFilterChange }) => {
  const [values, setValues] = useState([]);

  const onSelectionChanged = selectedOptions => {
    const selected = selectedOptions.map(op => op.value);
    setValues(selected);

    onFilterChange(selected);
  };

  return (
    <DropdownTree
      label="Filter By:"
      placeholder="Status"
      options={talentOpportunityOptions}
      selectedValues={values}
      onChangeAction={onSelectionChanged}
      treeLayers={2}
    />
  );
};

TalentOpportunityTabFilter.defaultProps = {
  onFilterChange: () => {},
};

TalentOpportunityTabFilter.propTypes = {
  onFilterChange: func,
};
