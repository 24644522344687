import { string, shape, arrayOf, bool } from 'prop-types';

import { UserShape } from './users';

const ContactModel = shape({
  id: string,
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  email: string,
  mobileNumber: string,
  phoneNumber: string,
  salesforceid: string,
  createdAt: string,
  updatedAt: string,
  user: UserShape,
});

export const AccountModel = shape({
  calculatedName: string,
  id: string,
  name: string,
  ownerUserId: string,
  businessTeam: string,
  cxPartnerId: string,
  parentAccountId: string,
  salesforceId: string,
  zoomIndustry: string,
  zoomRecordId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
  displayName: string,
  calculatedConfidentialName: string,
  confidentialAccountName: string,
  isAccountConfidential: bool,
  contacts: arrayOf(ContactModel),
});
