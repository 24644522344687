import React from 'react';
import { func } from 'prop-types';
import {
  CondensedLabel,
  CondensedMedium,
  ContextMenuItem,
  DateUtil,
  Gutter,
  Layout,
  LayoutItem,
} from '@axiom/ui';
import { Link } from 'react-router-dom';

import messageShape from '../../models/notifications';

import { NotificationIndicator } from './NotificationsStyles';

const NotificationsMessage = ({ notification, markAllNotificationsRead }) => {
  const buildBody = data => {
    /**
     * There's a mutation happening with the body. For now, doing a check for a
     * json string start of '{', but we need to fix properly when we scrub
     * this component and its parent.
     */
    if (typeof data === 'string' && !data.startsWith('{')) {
      return <div>{data}</div>;
    }

    return (
      <>
        <Link to={data.link} className="gtm-notification-click">
          Details
        </Link>
      </>
    );
  };

  const handleClick = () => {
    if (!notification.isRead) {
      markAllNotificationsRead(notification.id);
    }
  };

  return (
    <ContextMenuItem onClick={handleClick} name="notificationItem">
      <Layout position="middle">
        <LayoutItem>
          <NotificationIndicator
            className={notification.isRead && 'read'}
            data-test="NOTIFICATIONITEMISREADMARKER"
          />
        </LayoutItem>
        <LayoutItem fluid>
          <CondensedLabel name="notificationItemTitle">
            {notification.subject}
          </CondensedLabel>
        </LayoutItem>
      </Layout>
      <Gutter left="16px" top="8px">
        <CondensedMedium name="notificationItemContent">
          {notification.body && buildBody(notification.body)}
        </CondensedMedium>
      </Gutter>
      <Gutter left="16px" top="8px">
        <CondensedMedium name="notificationItemDate">
          {DateUtil.displayShortDate(notification.createdAt)}
        </CondensedMedium>
      </Gutter>
    </ContextMenuItem>
  );
};

NotificationsMessage.propTypes = {
  notification: messageShape.isRequired,
  markAllNotificationsRead: func.isRequired,
};

export default NotificationsMessage;
