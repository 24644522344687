import React from 'react';

import { CellRenderProps, SortType } from './data-grid-props';

export type DataGridHeaderProps = {
  name: string;
  cellRender?: (props: CellRenderProps) => React.ReactNode;
  displayName?: string | (() => JSX.Element);
  FiltersRender?: () => JSX.Element;
  pinned?: 'left' | 'right';
  lockPinned?: boolean;
  sortingOrder?: Array<SortType>;
  initialSort?: SortType;
  width?: number;
  stretched?: boolean;
  onCellClicked?: (data: Record<string, unknown>) => void;
};

export const DataGridHeader = ({ name, sortingOrder }: DataGridHeaderProps) => {
  return (
    <div data-test={name} data-sorting-order={JSON.stringify(sortingOrder)}>
      <div className="header-text" />
    </div>
  );
};
