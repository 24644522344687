import { toNumber, isNumber, isNaN } from 'lodash';

export const NumberUtil = {
  formatNumberAsDecimal(value, decimalPlaces = 2) {
    const number = toNumber(value);

    if (!isNumber(number)) {
      return null;
    }

    return number.toFixed(decimalPlaces);
  },
  formatCurrency(value, currencyType, prependToNumber = '') {
    const number = toNumber(value);

    if (!isNumber(number)) {
      return null;
    }

    return currencyType
      ? `${currencyType} ${prependToNumber}${number.toFixed(2)}`
      : `${prependToNumber}${number.toFixed(2)}`;
  },
  formatAsHours(hours) {
    if (typeof hours !== 'string' && typeof hours !== 'number') {
      return null;
    }

    if (hours === null || hours === undefined) {
      return null;
    }

    hours = toNumber(hours);
    if (isNaN(hours)) {
      return null;
    }

    hours = Math.floor(hours);

    return hours === 1 ? '1 hr' : `${hours} hrs`;
  },
};
