import { string } from 'prop-types';

export const SavedFilterDefault = (filterDefaults = {}) => ({
  id: null,
  name: null,
  filters: filterDefaults,
});

export const SavedFilterShapeStructure = (filterShape = {}) => ({
  id: string,
  name: string,
  filters: filterShape,
});
