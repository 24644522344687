import PropTypes from 'prop-types';

export const USER_TYPE_USER = 'EnvoyUser';
export const USER_TYPE_ADMIN = 'EnvoyAdmin';
export const USER_TYPE_SALES = 'EnvoySales';

export const USER_TYPES_MAP = {
  [USER_TYPE_SALES]: 'Sales Member',
  [USER_TYPE_USER]: 'Team Member',
  [USER_TYPE_ADMIN]: 'Administrator',
};

export const userTypeOptions = Object.keys(USER_TYPES_MAP).map(value => ({
  value,
  label: USER_TYPES_MAP[value],
}));

export const USER_STATUS_ACTIVE = 'Active';
export const USER_STATUS_INACTIVE = 'Inactive';

export const USER_STATUSES_MAP = {
  [USER_STATUS_ACTIVE]: true,
  [USER_STATUS_INACTIVE]: false,
};

export const userStatusOptions = [
  {
    label: USER_STATUS_ACTIVE,
    value: USER_STATUSES_MAP[USER_STATUS_ACTIVE],
  },
  {
    label: USER_STATUS_INACTIVE,
    value: USER_STATUSES_MAP[USER_STATUS_INACTIVE],
  },
];

export const UserModel = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isSSO: PropTypes.bool,
  lastName: PropTypes.string,
  roles: PropTypes.array,
  team: PropTypes.arrayOf(PropTypes.shape({})),
};

export const USER_MODEL_EDITABLE_PROPERTIES = ['isActive', 'roles', 'team'];

export const UserShape = PropTypes.shape(UserModel);
