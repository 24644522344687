import styled from 'styled-components';
import { DropDownList as KendoDropdownList } from '@progress/kendo-react-dropdowns';
import { loadLightPalette } from '@axiom/ui';

const PALETTE = loadLightPalette();

export const DropdownListWrapper = styled.div`
  ${props =>
    props.disabled === true &&
    `
    background-color: #bfc6cd !important;
  `};

  &:not([data-value]) {
    .k-dropdown-wrap .k-input {
      color: ${PALETTE.neutrals.malignant40};
    }
  }
`;

export const KendoDropdownListWrapper = styled(KendoDropdownList)`
  width: 100%;

  & span.k-dropdown-wrap {
    border-radius: 0;
    border: none;
    background: transparent !important;
    box-shadow: none;
  }

  & span.k-input {
    padding-left: 0;
  }

  & span.k-select {
    background-color: transparent !important;
  }
`;

export const LabelWrapper = styled.div`
  padding: 0.5em 0;
`;
