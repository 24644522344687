import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const StyledTalentResume = styled.div`
  max-height: calc(100vh - 400px);
  width: 100%;
  color: ${getThemeProp('colorSecondary')};
  overflow-y: auto;
`;
