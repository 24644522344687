import { CandidateOpportunitiesConst } from '@axiom/const';

const { CandidateStatuses } = CandidateOpportunitiesConst;

export const COMPLETED = CandidateStatuses.Completed;
export const COOLED = CandidateStatuses.Cooled;
export const ENGAGED = CandidateStatuses.Engaged;
export const INTERESTED = CandidateStatuses.Interested;
export const INTERESTED_REJECTED = CandidateStatuses.InterestedRejected;
export const INTERVIEWING = CandidateStatuses.Interviewing;
export const REJECTED = CandidateStatuses.Rejected;
export const REMOVED = CandidateStatuses.Removed;
export const REP_BIO_SHARE = CandidateStatuses.RepBioShare;
export const SELECTED = CandidateStatuses.Selected;
export const SHORT_LIST = CandidateStatuses.ShortList;
export const SUBMITTED = CandidateStatuses.Submitted;
export const SUGGESTED = CandidateStatuses.Suggested;
export const TALENT_OPT_OUT = CandidateStatuses.TalentOptOut;
export const WARMED = CandidateStatuses.Warmed;
export const WARMED_YES = CandidateStatuses.WarmedYes;
export const WARMED_UNSURE = CandidateStatuses.WarmedUnsure;

export const candidateStatusEnums = Object.keys(CandidateStatuses);
