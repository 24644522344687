import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { CandidatesConst } from '@axiom/const';
import { Button, FluidButtonLayout, Gutter } from '@axiom/ui';

import {
  TPHEditContainer,
  TPHEditDropdownWrapper,
} from './TalentProfileHeaderStyles';
import { TPSDropdownWrapper } from './TalentProfileStatusStyles';
import { TalentProfilePendingAlumModal } from './TalentProfilePendingAlumModal';

const {
  ProfileStatuses: { PendingAlum, PendingAlumDNR },
} = CandidatesConst;

export const TalentProfileStatusEdit = ({
  submit,
  cancel,
  nextAvailableStatuses,
  formData,
}: {
  submit: () => void;
  cancel: () => void;
  nextAvailableStatuses: { label: string; value: string }[];
  formData: { profileStatus: string };
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <TPHEditContainer>
        <TPHEditDropdownWrapper>
          <Field
            name="profileStatus"
            component={TPSDropdownWrapper}
            label="Profile Status"
            options={nextAvailableStatuses}
            afterSave={() => setShowModal(false)}
          />
        </TPHEditDropdownWrapper>
      </TPHEditContainer>
      <Gutter bottom="16px" />
      <FluidButtonLayout>
        <Button
          onClick={cancel}
          name="SUBMIT_PROFILE_STATUS_CANCEL"
          variation="outline"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (
              formData.profileStatus === PendingAlum ||
              formData.profileStatus === PendingAlumDNR
            ) {
              setShowModal(true);
            } else {
              submit();
            }
          }}
          name="SUBMIT_PROFILE_STATUS_CHANGE"
        >
          Submit
        </Button>
      </FluidButtonLayout>
      <hr />
      {showModal && (
        <TalentProfilePendingAlumModal
          profileStatus={formData.profileStatus}
          onCancel={() => setShowModal(false)}
          onSave={submit}
        />
      )}
    </>
  );
};
