import styled from 'styled-components';

export const AftIconWrapper = styled.span`
  color: #151a48;
  display: inline-block;
  height: 1.125rem;
  width: 1.125rem;

  :hover {
    color: #3443af;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;
