import { Api } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateIndustriesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readIndustries(candidateId: Candidate['id']) {
    return super.read<{ data: Candidate['industries'] }>({
      endpoint: `/candidates/${candidateId}/industries`,
      method: 'GET',
    });
  }

  refreshIndustries(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}/industries`);
  }
}

export const CandidateIndustriesApi = new CandidateIndustriesApiClass();
