import FormStore from '../classes/form-store';
import { certificationShape } from '../models/certification';
import { AjvUtil } from '../utils/ajv-util';
import { DefaultLocation } from '../components/LocationTypeahead/LocationTypeahead';
import { CandidateExperiencesApi } from '../api/candidate-experiences';
import { CandidateIndustriesApi } from '../api/candidate-industries';
import { ExperiencesApi } from '../api/experiences';

const getSchema = specificSchema =>
  AjvUtil.compileSchema({
    required: ['startDate', ...specificSchema.required],
    properties: {
      isAxiom: {
        type: 'boolean',
      },
      isConfidential: {
        type: ['boolean', 'null'],
      },
      startDate: {
        type: ['string', 'null'],
        format: 'date',
        minLength: 1,
        cannotBeInFuture: true,
      },
      description: {
        type: ['string', 'null'],
      },
      tags: {
        type: 'array',
      },
      industryValue: {
        type: ['string', 'null'],
      },
      ...specificSchema.properties,
    },
  });

class FormTalentExperienceClass extends FormStore {
  getDataShape() {
    return certificationShape;
  }

  load(candidateId, experience) {
    return this.setState({
      candidateId,
      ...DefaultLocation,
      ...experience,
      description: experience.description || '',
      tags: (experience?.tags || []).map(t => t.id),
    });
  }

  validate(formData) {
    const baseValidation = {
      isAxiom: formData.isAxiom,
      isConfidential: formData.isConfidential,
      startDate: formData.startDate,
      endDate: formData.endDate,
      description: formData.description,
      tags: formData.tags,
    };

    if (formData.isAxiom) {
      const isAxiomSchema = {
        required: [],
        properties: {
          endDate: {
            type: 'string',
            format: 'date',
            minLength: 1,
          },
          industryIsOverridden: {
            type: 'boolean',
          },
        },
      };

      return AjvUtil.formatValidationForFinalForm(getSchema(isAxiomSchema), {
        ...baseValidation,
      });
    }

    let toValidate = {
      ...baseValidation,
      client: formData.client,
      externalOpportunityName: formData.externalOpportunityName,
      locationAddressComponents: formData.locationAddressComponents,
      locationLatitude: formData.locationLatitude,
      locationLongitude: formData.locationLongitude,
      locationName: formData.locationName,
      locationPlaceId: formData.locationPlaceId,
      industryValue: formData.industryValue,
    };

    if (formData.location) {
      toValidate = {
        ...toValidate,
        ...formData.location,
      };
    }

    const isOtherSchema = {
      required: ['client', 'industryValue'],
      properties: {
        externalOpportunityName: {
          type: ['string', 'null'],
        },
        client: {
          type: 'string',
          minLength: 1,
        },
        locationAddressComponents: {
          type: ['object', 'null'],
        },
        locationLatitude: {
          type: ['number', 'null'],
        },
        locationLongitude: {
          type: ['number', 'null'],
        },
        locationName: {
          type: ['string', 'null'],
        },
        locationPlaceId: {
          type: ['string', 'null'],
        },
        industryValue: {
          type: 'string',
        },
      },
    };

    /**
     * NOTEs:
     * endDate === undefined > new
     * endDate === '' > set to current work here
     * endDate === null > previously set to current work here
     * endDate === '<date>' > value
     * */

    if (formData.endDate !== null) {
      if (formData.endDate !== '') {
        if (!isOtherSchema.required.includes('endDate')) {
          isOtherSchema.required.push('endDate');
        }

        isOtherSchema.properties.endDate = {
          type: 'string',
          format: 'date',
          minLength: 1,
          dateIsAfter: {
            beforeDate: 'startDate',
            errorMessage: 'End date must be later than start date',
          },
        };
      } else if (formData.endDate === '') {
        isOtherSchema.required.filter(r => r === 'endDate');
        isOtherSchema.properties.endDate = {
          type: 'string',
        };
      }
    }

    return AjvUtil.formatValidationForFinalForm(
      getSchema(isOtherSchema),
      toValidate
    );
  }

  submit(changedFields, formData) {
    const payload = { ...changedFields };

    if (payload.externalOpportunityName === null) {
      payload.externalOpportunityName = '';
    }

    if (payload.description === null) {
      payload.description = '';
    }

    if (payload.location) {
      payload.locationAddressComponents =
        payload.location.locationAddressComponents;
      payload.locationLatitude = payload.location.locationLatitude;
      payload.locationLongitude = payload.location.locationLongitude;
      payload.locationName = payload.location.locationName;
      payload.locationPlaceId = payload.location.locationPlaceId;
      payload.location = undefined;
    }

    if (formData.isAxiom && payload.industryValue) {
      payload.industryIsOverridden = true;
    }

    const promise = formData.id
      ? ExperiencesApi.updateExperience(formData.id, payload)
      : CandidateExperiencesApi.createExperience(formData.candidateId, payload);

    return this.clearState(promise).then(() => {
      CandidateExperiencesApi.refreshExperiences(formData.candidateId);
      CandidateIndustriesApi.refreshIndustries(formData.candidateId);
    });
  }
}

export const FormTalentExperienceStore = new FormTalentExperienceClass();
