import { shape, string, bool, date, arrayOf, oneOfType } from 'prop-types';

const body = oneOfType([
  string,
  shape({
    link: string,
    date: string,
  }),
]);

const message = {
  subject: string,
  body,
  id: string,
  userId: string,
  isRead: bool,
  createdAt: date,
  updatedAt: date,
  type: string,
  email: string,
};

export const notifications = {
  hasUnread: bool,
  list: arrayOf(shape(message)),
};

export default shape(message);
