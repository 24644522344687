import isPlainObject from 'lodash/isPlainObject';

export const LANGUAGE_SKILL_ORDER = ['speaking', 'reading', 'writing', ''];
export const LANGUAGE_PROFICIENCY_ORDER = ['fluent', 'proficient', 'basic', ''];

const sortLanguages = (a, b) => {
  if (!isPlainObject(a) || !isPlainObject(b)) return 0;

  if (a.name !== b.name) return a.name.localeCompare(b.name);

  const skillProp =
    'requiredLanguageSkill' in a || 'requiredLanguageSkill' in b
      ? 'requiredLanguageSkill'
      : 'languageSkill';

  if (a[skillProp] !== b[skillProp]) {
    return LANGUAGE_SKILL_ORDER.indexOf(a[skillProp] || '') <
      LANGUAGE_SKILL_ORDER.indexOf(b[skillProp] || '')
      ? -1
      : 1;
  }

  const proficiencyProp =
    'requiredLanguageProficiency' in a || 'requiredLanguageProficiency' in b
      ? 'requiredLanguageProficiency'
      : 'languageProficiency';

  return LANGUAGE_PROFICIENCY_ORDER.indexOf(a[proficiencyProp] || '') <
    LANGUAGE_PROFICIENCY_ORDER.indexOf(b[proficiencyProp] || '')
    ? -1
    : 1;
};

export default sortLanguages;
