import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { BOX_SHADOW_RULE } from '../../themes/constants';
import { appErrors as appErrorsStackingContext } from '../../themes/stacking-context';

export const AppErrorsWrapper = styled.div`
  width: 640px;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -320px;
  z-index: ${appErrorsStackingContext};
`;

export const AppErrorsCard = styled.div`
  border: 1px solid black;
  margin: 0.625rem 0;
  background: ${getThemeProp('contentBackground')};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.75);
  ${BOX_SHADOW_RULE};
  flex: 0 0 auto;
  font-weight: bold;
  position: relative;
  word-break: break-word;
  display: flex;
  & > *:first-child,
  & > *:last-child {
    height: auto;
    width: auto;
    svg {
      width: auto;
    }
    margin: 0 1.25rem;
    align-self: center;
  }
  & > *:first-child svg {
    width: 1.56rem;
  }
`;

export const AppErrorsMessage = styled.div`
  color: black;
  font-weight: normal;
  margin: 1.25rem 0;
  flex-grow: 1;
  overflow: auto;
  max-height: 75vh;
`;
