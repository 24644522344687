import styled from 'styled-components';

import { FlexWrapper } from '../InputCharRemaining/InputCharRemaining';
import { getThemeProp } from '../../themes';

export const StyledFFTextArea = styled.textarea`
  background: white;
  display: block;
  width: 100%;
  height: 10rem;
  min-height: 13rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem;
  overflow: hidden;
  font-size: 1rem;
  border: 0.0625rem solid #707070;
  resize: none;
  overflow-y: scroll;
  line-height: 1.4rem;
  color: ${getThemeProp('colorPrimary')};

  ${FlexWrapper} {
    background-color: ${getThemeProp('appBackground')};
  }
`;
