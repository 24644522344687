import React from 'react';
import { shape, func, string } from 'prop-types';
import { Label } from '@axiom/ui';

import SkillsDropdown from '../SkillsDropdown/SkillsDropdown';
import { FFError } from '../FFError/FFError';

export const FFSkillsDropdown = props => {
  const { label, input, meta, onValueChange } = props;

  const handleOnChange = data => {
    const value = data.map(d => d.value);

    input.onChange(value);
    onValueChange(value);
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <SkillsDropdown
        selectedValues={input.value}
        onChangeAction={handleOnChange}
      />
      <FFError finalFormElementMeta={meta} />
    </>
  );
};

FFSkillsDropdown.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  label: string,
  onValueChange: func,
};

FFSkillsDropdown.defaultProps = {
  label: null,
  onValueChange: x => x,
};
