import React from 'react';
import { func } from 'prop-types';

import UnorderedList from '../UnorderedList/UnorderedList';
import { opportunityShape } from '../../models/opportunities';
import { talentShape } from '../../models/talent';
import { formatDataTestId } from '../../utils/dataTest';

import {
  OECard,
  OECardHeader,
  OECardBody,
  OECardFirstRow,
  OECardLastRow,
  OECardFirstColumn,
} from './OpportunityEngagementsStyles';

const OpportunityEngagementItem = props => {
  const handleClick = event => {
    event.preventDefault();

    const { opportunity } = props;
    props.onClick(opportunity.id);
  };

  const { candidate, opportunity } = props;

  return (
    <OECard
      onClick={handleClick}
      data-test={formatDataTestId('engagementsCard')}
    >
      <OECardHeader>
        <UnorderedList
          title="Opportunity Name"
          values={opportunity.jobName}
          dataTest={formatDataTestId('opportunityName')}
        />
      </OECardHeader>
      <OECardBody>
        <OECardFirstRow>
          <OECardFirstColumn>
            <UnorderedList
              showAftIcon
              title="Account Name"
              values={opportunity.accountName}
              dataTest={formatDataTestId('accountName')}
            />
          </OECardFirstColumn>
        </OECardFirstRow>
        <OECardLastRow>
          <OECardFirstColumn>
            <UnorderedList
              title="Talent Name"
              values={`${candidate.calculatedDisplayName} - ${candidate.candidateStatus}`}
            />
          </OECardFirstColumn>
          <UnorderedList
            title="Roll Off Date Status"
            values={candidate.position.endDateStatus}
            dataTest={formatDataTestId('rollOffDateStatus')}
          />
        </OECardLastRow>
      </OECardBody>
    </OECard>
  );
};

OpportunityEngagementItem.defaultProps = {
  onClick: () => {},
};

OpportunityEngagementItem.propTypes = {
  candidate: talentShape.isRequired,
  opportunity: opportunityShape.isRequired,
  onClick: func,
};

export default OpportunityEngagementItem;
