import { call, put, takeEvery } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import { CANDIDATE_COLLABORATORS_LOAD } from '../constants';
import { getCollaboratorsByTalentId } from '../../api/talentCollaborators';
import {
  talentCollaboratorsLoaded,
  talentCollaboratorsError,
} from '../actions/talentCollaborators';

export function* getTalentCollaboratorsSaga({ payload }) {
  try {
    const collaboratorsByOpportunity = yield call(
      getCollaboratorsByTalentId,
      payload.id
    );
    yield put(talentCollaboratorsLoaded(collaboratorsByOpportunity));
  } catch (e) {
    yield put(addApiError(e.applicationError));
    yield put(talentCollaboratorsError(e));
  }
}

export default function* userOpportunitySaga() {
  yield takeEvery(CANDIDATE_COLLABORATORS_LOAD, getTalentCollaboratorsSaga);
}
