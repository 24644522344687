import React from 'react';

import { OpportunitiesContextMenu } from '../OpportunitiesContextMenu/OpportunitiesContextMenu';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { opportunityShape } from '../../models/opportunities';

import {
  OTNoteWrapper,
  OTNoteBody,
  OTNoteBodyLeft,
  OTNoteBodyLeftToAddTalentText,
  OTNoteBold,
} from './OpportunityTalentNoteStyles';

const OpportunityTalentNote = ({ opportunity, opportunityPositions }) => (
  <OTNoteWrapper>
    <OTNoteBody>
      <OTNoteBodyLeft>
        <OTNoteBodyLeftToAddTalentText>
          <OTNoteBold>Note:</OTNoteBold> Add talent to the Talent Pool if you
          are not prepared to assign a
        </OTNoteBodyLeftToAddTalentText>
        <OTNoteBodyLeftToAddTalentText>
          specific position.
        </OTNoteBodyLeftToAddTalentText>
      </OTNoteBodyLeft>
      <OpportunitiesContextMenu
        opportunity={opportunity}
        opportunityPositions={opportunityPositions}
      />
    </OTNoteBody>
  </OTNoteWrapper>
);

OpportunityTalentNote.propTypes = {
  opportunityPositions: OpportunityPositionsStore.getDataShape().isRequired,
  opportunity: opportunityShape.isRequired,
};

export default OpportunityTalentNote;
