import {
  DELETE_EDUCATION,
  DELETE_EDUCATION_ERROR,
  DELETE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION,
} from '../constants';

export const deleteEducation = educationId => ({
  type: DELETE_EDUCATION,
  payload: { educationId },
});

export const deleteEducationFailed = educationId => ({
  type: DELETE_EDUCATION_ERROR,
  payload: { educationId },
});

export const deleteEducationSucceeded = educationId => ({
  type: DELETE_EDUCATION_SUCCESS,
  payload: { educationId },
});

export const updateEducation = payload => ({
  type: UPDATE_EDUCATION,
  payload,
});
