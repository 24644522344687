import {
  any,
  arrayOf,
  bool,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { omit } from 'lodash';

import {
  CLOSED_WON,
  CLOSED_LOST,
  VALIDATE,
  DEVELOP,
  CONTRACT,
  CRAFT,
  COMPETE,
} from '../api/constants';

import {
  OPPORTUNITY_FIELD_ACCOUNT_NAME,
  OPPORTUNITY_FIELD_ACCOUNT_SALESFORCE_ID,
  OPPORTUNITY_FIELD_AMOUNT,
  OPPORTUNITY_FIELD_BILLING_CURRENCY,
  OPPORTUNITY_FIELD_BILLING_ESTIMATED_UNITS,
  OPPORTUNITY_FIELD_BILLING_NAME,
  OPPORTUNITY_FIELD_BUSINESS_TEAM,
  OPPORTUNITY_FIELD_CLOSED_LOST_NOTES,
  OPPORTUNITY_FIELD_COLLABORATOR_ID,
  OPPORTUNITY_FIELD_COMPETITOR_TYPE,
  OPPORTUNITY_FIELD_COMPETITOR_VIEW,
  OPPORTUNITY_FIELD_CREATED_AT,
  OPPORTUNITY_FIELD_CULTURE_SKILLS,
  OPPORTUNITY_FIELD_CURRENCY,
  OPPORTUNITY_FIELD_DESCRIPTION,
  OPPORTUNITY_FIELD_ENGAGEMENT_TYPE,
  OPPORTUNITY_FIELD_FIRST_SUBMITTED_CANDIDATE_AT,
  OPPORTUNITY_FIELD_FORECAST_CATEGORY,
  OPPORTUNITY_FIELD_ID,
  OPPORTUNITY_FIELD_INADEQUATE_SUPPLY_CATEGORY,
  OPPORTUNITY_FIELD_IS_REMOTE,
  OPPORTUNITY_FIELD_LANGUAGES,
  OPPORTUNITY_FIELD_LAST_UPDATED_BY,
  OPPORTUNITY_FIELD_MATTER_ID,
  OPPORTUNITY_FIELD_NEXT_STEP,
  OPPORTUNITY_FIELD_OFFERING,
  OPPORTUNITY_FIELD_OPPORTUNITY_OWNER_SALESFORCE_ID,
  OPPORTUNITY_FIELD_OWNER_USER_ID,
  OPPORTUNITY_FIELD_PRACTICE_AREA_ID,
  OPPORTUNITY_FIELD_QUALIFIED_AT,
  OPPORTUNITY_FIELD_REASON_LOST,
  OPPORTUNITY_FIELD_REGION,
  OPPORTUNITY_FIELD_REQUIRED_NUM_RESOURCES,
  OPPORTUNITY_FIELD_SALES_CX_LEAD_ID,
  OPPORTUNITY_FIELD_SALES_LEAD_ID,
  OPPORTUNITY_FIELD_SALES_NOTE,
  OPPORTUNITY_FIELD_SALESFORCE_ACCOUNT_ID,
  OPPORTUNITY_FIELD_SALESFORCE_ID,
  OPPORTUNITY_FIELD_STAGE,
  OPPORTUNITY_FIELD_STATUS_UPDATED_AT,
  OPPORTUNITY_FIELD_UPDATED_AT,
  OPPORTUNITY_FIELD_USE_CASE,
} from './constants/opportunityFields';
import { TagShape } from './tag';

export const BENCHED = 'benched';
export const ENGAGED = 'engaged';
export const READY = 'ready';

export const DEVELOPING = 'In Development';
export const UNQUALIFIED = 'Unqualified';
export const QUALIFIED = 'Qualified';
export const INTERVIEWING = 'Interviewing';
export const CONTRACTING = 'Contracting';

export const stageToStatusGroupMap = {
  [CRAFT]: QUALIFIED,
  [COMPETE]: INTERVIEWING,
  [CONTRACT]: CONTRACTING,
  [CLOSED_WON]: CLOSED_WON,
  [CLOSED_LOST]: CLOSED_LOST,
  [DEVELOP]: DEVELOPING,
  [VALIDATE]: UNQUALIFIED,
};

export const opportunityFormInitialValues = opportunity => ({
  ...omit(opportunity, [
    'activityLog',
    'candidates',
    'createdAt',
    'salesforceId',
    'updatedAt',
    'statusGroup',
    'hasBeenAIMatched',
    'requiredBillingHoursPerWeek',
  ]),
});

/*
 * Engagement Types
 */

export const OPPORTUNITY_TYPE_ADDITIONAL =
  'Existing Engagement Additional Attorney';
export const OPPORTUNITY_TYPE_ADVISORY = 'Advisory';
export const OPPORTUNITY_TYPE_BACKFILL =
  'Existing Engagement Backfill Attorney';
export const OPPORTUNITY_TYPE_CONVERSION = 'Conversion';
export const OPPORTUNITY_TYPE_NEW_DEAL = 'New Deal';

export const opportunityTypesInfo = {
  [OPPORTUNITY_TYPE_ADDITIONAL]: { hasParent: true, hasChildren: false },
  [OPPORTUNITY_TYPE_ADVISORY]: { hasParent: false, hasChildren: false },
  [OPPORTUNITY_TYPE_BACKFILL]: { hasParent: true, hasChildren: false },
  [OPPORTUNITY_TYPE_CONVERSION]: { hasParent: false, hasChildren: false },
  [OPPORTUNITY_TYPE_NEW_DEAL]: { hasParent: false, hasChildren: true },
};

export const OPPORTUNITY_TYPES_WITH_CHILDREN = Object.keys(
  opportunityTypesInfo
).reduce((t, k) => {
  if (opportunityTypesInfo[k].hasChildren) t[k] = true;
  return t;
}, {});

export const OPPORTUNITY_TYPES_WITH_PARENT = Object.keys(
  opportunityTypesInfo
).reduce((t, k) => {
  if (opportunityTypesInfo[k].hasParent) t[k] = true;
  return t;
}, {});

export const OPPORTUNITY_TYPES_OPTIONS = [
  { value: OPPORTUNITY_TYPE_NEW_DEAL, label: OPPORTUNITY_TYPE_NEW_DEAL },
  { value: OPPORTUNITY_TYPE_BACKFILL, label: OPPORTUNITY_TYPE_BACKFILL },
  { value: OPPORTUNITY_TYPE_ADDITIONAL, label: OPPORTUNITY_TYPE_ADDITIONAL },
  { value: OPPORTUNITY_TYPE_ADVISORY, label: OPPORTUNITY_TYPE_ADVISORY },
  { value: OPPORTUNITY_TYPE_CONVERSION, label: OPPORTUNITY_TYPE_CONVERSION },
];

/*
 * Offering Types
 */

export const OPPORTUNITY_OFFERING_TYPES = [
  'Contracts Negotiation',
  'FS Reg Benchmark Reform',
  'FS Reg Brexit',
  'FS Reg Initial Margin',
  'FS Reg Resolution Stay',
  'Privacy Brazil Privacy Law',
  'Privacy California Privacy Law',
  'Privacy DPIA',
  'Privacy EU ePrivacy',
  'Privacy Swiss Privacy Law',
];

export const OPPORTUNITY_OFFERING_TYPES_OPTIONS =
  OPPORTUNITY_OFFERING_TYPES.map(type => ({
    label: type,
    value: type,
  }));

/*
 * Solution Types
 */

export const OPPORTUNITY_SOLUTION_TYPE_SINGLE = 'Single Secondee';
export const OPPORTUNITY_SOLUTION_TYPE_TEAM = 'Team Solution';
export const OPPORTUNITY_SOLUTION_TYPE_OFFERING = 'Offering';

export const opportunitySolutionTypeInfo = {
  [OPPORTUNITY_SOLUTION_TYPE_SINGLE]: { isTeam: false },
  [OPPORTUNITY_SOLUTION_TYPE_TEAM]: { isTeam: true },
  [OPPORTUNITY_SOLUTION_TYPE_OFFERING]: { isTeam: true },
};

export const OPPORTUNITY_SOLUTION_TYPES_OPTIONS = [
  {
    value: OPPORTUNITY_SOLUTION_TYPE_SINGLE,
    label: OPPORTUNITY_SOLUTION_TYPE_SINGLE,
  },
  {
    value: OPPORTUNITY_SOLUTION_TYPE_TEAM,
    label: OPPORTUNITY_SOLUTION_TYPE_TEAM,
  },
  {
    value: OPPORTUNITY_SOLUTION_TYPE_OFFERING,
    label: OPPORTUNITY_SOLUTION_TYPE_OFFERING,
  },
];

/*
 * PropTypes
 */

const opportunityModel = {
  [OPPORTUNITY_FIELD_ACCOUNT_NAME]: string,
  [OPPORTUNITY_FIELD_ACCOUNT_SALESFORCE_ID]: string,
  [OPPORTUNITY_FIELD_AMOUNT]: number,
  [OPPORTUNITY_FIELD_BILLING_CURRENCY]: string,
  [OPPORTUNITY_FIELD_BILLING_ESTIMATED_UNITS]: string,
  [OPPORTUNITY_FIELD_BILLING_NAME]: string,
  [OPPORTUNITY_FIELD_BUSINESS_TEAM]: string,
  [OPPORTUNITY_FIELD_CLOSED_LOST_NOTES]: string,
  collaborators: oneOfType([arrayOf(string), arrayOf(TagShape)]),
  [OPPORTUNITY_FIELD_COLLABORATOR_ID]: arrayOf(any),
  [OPPORTUNITY_FIELD_COMPETITOR_TYPE]: string,
  [OPPORTUNITY_FIELD_COMPETITOR_VIEW]: string,
  [OPPORTUNITY_FIELD_CREATED_AT]: string,
  [OPPORTUNITY_FIELD_CULTURE_SKILLS]: string,
  [OPPORTUNITY_FIELD_CURRENCY]: string,
  [OPPORTUNITY_FIELD_DESCRIPTION]: string,
  contractedEndDate: string,
  [OPPORTUNITY_FIELD_ENGAGEMENT_TYPE]: string,
  [OPPORTUNITY_FIELD_FIRST_SUBMITTED_CANDIDATE_AT]: string,
  [OPPORTUNITY_FIELD_FORECAST_CATEGORY]: string,
  [OPPORTUNITY_FIELD_INADEQUATE_SUPPLY_CATEGORY]: string,
  [OPPORTUNITY_FIELD_ID]: string,
  [OPPORTUNITY_FIELD_IS_REMOTE]: bool,
  [OPPORTUNITY_FIELD_LANGUAGES]: arrayOf(any),
  [OPPORTUNITY_FIELD_LAST_UPDATED_BY]: string,
  [OPPORTUNITY_FIELD_MATTER_ID]: string,
  [OPPORTUNITY_FIELD_NEXT_STEP]: string,
  [OPPORTUNITY_FIELD_OFFERING]: string,
  [OPPORTUNITY_FIELD_OPPORTUNITY_OWNER_SALESFORCE_ID]: string,
  [OPPORTUNITY_FIELD_OWNER_USER_ID]: string,
  [OPPORTUNITY_FIELD_PRACTICE_AREA_ID]: string,
  [OPPORTUNITY_FIELD_QUALIFIED_AT]: string,
  [OPPORTUNITY_FIELD_REASON_LOST]: string,
  [OPPORTUNITY_FIELD_REGION]: string,
  [OPPORTUNITY_FIELD_REQUIRED_NUM_RESOURCES]: number,
  salesCloseDate: oneOfType([object, string]),
  [OPPORTUNITY_FIELD_SALES_CX_LEAD_ID]: string,
  [OPPORTUNITY_FIELD_SALES_LEAD_ID]: string,
  [OPPORTUNITY_FIELD_SALES_NOTE]: string,
  [OPPORTUNITY_FIELD_SALESFORCE_ACCOUNT_ID]: string,
  [OPPORTUNITY_FIELD_SALESFORCE_ID]: string,
  [OPPORTUNITY_FIELD_STAGE]: oneOf(Object.keys(stageToStatusGroupMap)),
  startDate: string,
  statusGroup: oneOf(Object.values(stageToStatusGroupMap)),
  [OPPORTUNITY_FIELD_STATUS_UPDATED_AT]: string,
  tags: oneOfType([arrayOf(string), arrayOf(TagShape)]),
  [OPPORTUNITY_FIELD_UPDATED_AT]: string,
  [OPPORTUNITY_FIELD_USE_CASE]: string,
};

opportunityModel.parent = shape(opportunityModel);
opportunityModel.related = arrayOf(shape(opportunityModel));

export const opportunityShape = shape(opportunityModel);
export const opportunityModelProperties = Object.keys(opportunityModel);

export default opportunityModel;
