import { all } from 'redux-saga/effects';

import bannerMessagesSaga from './bannerMessages';
import candidateCollaborators from './candidateCollaborators';
import decoratedOpportunitySaga from './decoratedOpportunity';
import decoratedTalentSaga from './decoratedTalent';
import educationSaga from './education';
import formsSaga from './forms';
import groupsSaga from './groups';
import homeOfficesSaga from './homeOffices';
import initialDataSaga from './initialApplicationData';
import opportunityCandidates from './opportunityCandidates';
import opportunityCollaborators from './opportunityCollaborators';
import opportunitiesSaga from './opportunities';
import searchesSaga from './searches';
import notificationsSaga from './notifications';
import talentSaga from './talent';
import userOpportunities from './userOpportunities';
import usersSaga from './users';
import userTalent from './userTalent';

export default function* rootSaga() {
  yield all([
    bannerMessagesSaga(),
    candidateCollaborators(),
    decoratedOpportunitySaga(),
    decoratedTalentSaga(),
    educationSaga(),
    formsSaga(),
    groupsSaga(),
    homeOfficesSaga(),
    initialDataSaga(),
    opportunityCandidates(),
    opportunityCollaborators(),
    opportunitiesSaga(),
    searchesSaga(),
    notificationsSaga(),
    talentSaga(),
    userOpportunities(),
    usersSaga(),
    userTalent(),
  ]);
}
