import { AccountsStore } from './accounts-store';
import { AccountStore } from './account-store';
import { ActivityLogsSearchStore } from './activity-logs-search-store';
import { AdminFunctionsModalStore } from './admin-functions-modal-store';
import { AppEventItemModalStore } from './app-event-item-modal-store';
import { AppFindAccountForContactModalStore } from './app-find-account-for-contact-modal-store';
import { AppFindCandidateForOppModalStore } from './app-find-candidate-for-opp-modal-store';
import { AppFindContactForAccountModalStore } from './app-find-contact-for-account-modal-store';
import { AppFindOppForCandidateModalStore } from './app-find-opp-for-candidate-modal-store';
import { BenchStore } from './bench-store';
import { CandidateAvailabilityModalStore } from './candidate-availability-modal-store';
import { CandidateCertificationsStore } from './candidate-certifications-store';
import { CandidateCollaboratorsStore } from './candidate-collaborators-store';
import { CandidateOpportunitiesStore } from './candidate-opportunities-store';
import { CandidateStore } from './candidate-store';
import { ContactsStore } from './contacts-store';
import { ContactStore } from './contact-store';
import { DeletePositionStore } from './delete-position-store';
import { EditAutoWarmingModalStore } from './edit-auto-warming-modal-store';
import { EditOpportunityFulfillmentStore } from './edit-opportunity-fulfillment-store';
import { EditOpportunityStore } from './edit-opportunity-store';
import { EditOpportunityTalentTabStore } from './edit-opportunity-talent-tab-store';
import { EditPositionStore } from './edit-position-store';
import { EditPositionTagsStore } from './edit-position-tags-store';
import { EditSavedCandidateFilterStore } from './edit-saved-candidate-filter-store';
import { EditSavedOpportunityFilterStore } from './edit-saved-opportunity-filter-store';
import { EditSubmissionRateStore } from './edit-submission-rate-store';
import { EditSubmissionStore } from './edit-submission-store';
import { EventsStore } from './events-store';
import { FormAccountConfidentialStore } from './form-account-confidential-store';
import { FormAutoCoolingModalStore } from './form-auto-cooling-modal-store';
import { FormCertificationItemStore } from './form-certification-item-store';
import { FormEducationItemStore } from './form-education-item-store';
import { FormLawSchoolManagementStore } from './form-law-school-management-store';
import { FormOpportunityExcludeFromWorkFeedStore } from './form-opportunity-exclude-from-work-feed-store';
import { FormOpportunityProfileDetailStore } from './form-opportunity-profile-detail-store';
import { FormOpportunityProfileNextStepsDescriptionStore } from './form-opportunity-profile-next-steps-description-store';
import { FormTagsManagementStore } from './form-tag-management-store';
import { FormTalentAvailabilityNotesStore } from './form-talent-availability-notes';
import { FormTalentExperienceStore } from './form-talent-experience-store';
import { FormTalentProfileEditStore } from './form-talent-profile-edit-store';
import { FormTalentProfileAvailabilityEditStore } from './form-talent-profile-availability-edit-store';
import { FormTalentProfileStatusStore } from './form-talent-profile-status-store';
import { FormTeamsStore } from './form-teams-store';
import { FormUserManagementStore } from './form-user-management-store';
import { LawSchoolsStore } from './law-schools-store';
import { OpportunitiesStore } from './opportunities-store';
import { OpportunityCandidatesStore } from './opportunity-candidates-store';
import { OpportunityEngagementsStore } from './opportunity-engagements-store';
import { OpportunityPositionsStore } from './opportunity-positions-store';
import { OpportunityStore } from './opportunity-store';
import { PreloadedAppErrorsStore } from './preloaded-app-errors-store';
import { PreloadedBarredLocationsStore } from './preloaded-barred-locations-store';
import { PreloadedFeatureFlagsStore } from './preloaded-feature-flags-store';
import { PreloadedGroupsStore } from './preloaded-groups-store';
import { PreloadedLanguagesStore } from './preloaded-languages-store';
import { PreloadedLawSchoolRanksStore } from './preloaded-law-school-ranks-store';
import { PreloadedPracticeAreasStore } from './preloaded-practice-areas-store';
import { PreloadedSkillsStore } from './preloaded-skills-store';
import { PreloadedTagsStore } from './preloaded-tags-store';
import { PreloadedUsersStore } from './preloaded-users-store';
import { PreloadedUserStore } from './preloaded-user-store';
import { TalentWarningModalStore } from './talent-warning-modal-store';
import { TalentOpportunityTabStore } from './talent-opportunity-tab-store';
import { UserCandidateFiltersStore } from './user-candidate-filters-store';
import { UserOpportunitiesStore } from './user-opportunities-store';
import { UserOpportunityFiltersStore } from './user-opportunity-filters-store';
import { PreloadedCandidateListFilters } from './preloaded-candidate-list-filters';

export const stores = {
  ACCOUNT: AccountStore,
  ACCOUNTS: AccountsStore,
  ACTIVITY_LOGS_SEARCHABLES: ActivityLogsSearchStore,
  ADMIN_FUNCTIONS_MODAL: AdminFunctionsModalStore,
  APP_ERRORS: PreloadedAppErrorsStore,
  APP_FIND_ACCOUNT_FOR_CONTACT: AppFindAccountForContactModalStore,
  APP_FIND_CANDIDATE_FOR_OPP: AppFindCandidateForOppModalStore,
  APP_FIND_CONTACT_FOR_ACCOUNT: AppFindContactForAccountModalStore,
  APP_FIND_OPP_FOR_CANDIDATE: AppFindOppForCandidateModalStore,
  APP_FORM_EVENT_ITEM_STORE: AppEventItemModalStore,
  BARRED_LOCATIONS: PreloadedBarredLocationsStore,
  BENCH: BenchStore,
  CANDIDATE_AVAILABILITY_MODAL: CandidateAvailabilityModalStore,
  CANDIDATE_CERTIFICATIONS: CandidateCertificationsStore,
  CANDIDATE_COLLABORATORS: CandidateCollaboratorsStore,
  CANDIDATE_OPPORTUNITIES: CandidateOpportunitiesStore,
  CANDIDATE_LIST_FILTERS: PreloadedCandidateListFilters,
  CANDIDATE: CandidateStore,
  CONTACT: ContactStore,
  CONTACTS: ContactsStore,
  DELETE_POSITION: DeletePositionStore,
  EDIT_AUTO_COOLING_MODAL: FormAutoCoolingModalStore,
  EDIT_AUTO_WARMING_MODAL: EditAutoWarmingModalStore,
  EDIT_OPPORTUNITY_FULFILLMENT: EditOpportunityFulfillmentStore,
  EDIT_OPPORTUNITY_TALENT_TAB: EditOpportunityTalentTabStore,
  EDIT_OPPORTUNITY: EditOpportunityStore,
  EDIT_POSITION_TAGS: EditPositionTagsStore,
  EDIT_POSITION: EditPositionStore,
  EDIT_SAVED_CANDIDATE_FILTERS: EditSavedCandidateFilterStore,
  EDIT_SAVED_OPPORTUNITY_FILTERS: EditSavedOpportunityFilterStore,
  EDIT_SUBMISSION_RATE: EditSubmissionRateStore,
  EDIT_SUBMISSION: EditSubmissionStore,
  EVENTS: EventsStore,
  FEATURE_FLAGS: PreloadedFeatureFlagsStore,
  FORM_ACCOUNT_CONFIDENTIAL: FormAccountConfidentialStore,
  FORM_CERTIFICATION: FormCertificationItemStore,
  FORM_EDUCATION: FormEducationItemStore,
  FORM_LAW_SCHOOL_MANAGEMENT: FormLawSchoolManagementStore,
  FORM_OPP_NEXT_STEP_DESCRIPTION:
    FormOpportunityProfileNextStepsDescriptionStore,
  FORM_OPP_EXCLUDE_FROM_WORK_FEED: FormOpportunityExcludeFromWorkFeedStore,
  FORM_OPP_PROFILE_DETAIL: FormOpportunityProfileDetailStore,
  FORM_TAGS_MANAGEMENT: FormTagsManagementStore,
  FORM_TALENT_AVAILABILITY_NOTES: FormTalentAvailabilityNotesStore,
  FORM_TALENT_EXPERIENCE: FormTalentExperienceStore,
  FORM_TALENT_PROFILE_EDIT: FormTalentProfileEditStore,
  FORM_TALENT_PROFILE_AVAILABILITY_EDIT: FormTalentProfileAvailabilityEditStore,
  FORM_TALENT_PROFILE_STATUS: FormTalentProfileStatusStore,
  FORM_TEAMS_STORE: FormTeamsStore,
  FORM_USER_MANAGEMENT: FormUserManagementStore,
  GROUPS: PreloadedGroupsStore,
  LANGUAGES: PreloadedLanguagesStore,
  LAW_SCHOOL_RANKS: PreloadedLawSchoolRanksStore,
  LAW_SCHOOLS: LawSchoolsStore,
  OPPORTUNITIES: OpportunitiesStore,
  OPPORTUNITY_CANDIDATES: OpportunityCandidatesStore,
  OPPORTUNITY_ENGAGEMENTS: OpportunityEngagementsStore,
  OPPORTUNITY_POSITIONS: OpportunityPositionsStore,
  OPPORTUNITY: OpportunityStore,
  PRACTICE_AREAS: PreloadedPracticeAreasStore,
  SKILLS: PreloadedSkillsStore,
  TAGS: PreloadedTagsStore,
  TALENT_WARNING_MODAL: TalentWarningModalStore,
  TALENT_OPPORTUNITY_TAB: TalentOpportunityTabStore,
  USER_CANDIDATE_FILTERS: UserCandidateFiltersStore,
  USER_OPPORTUNITIES: UserOpportunitiesStore,
  USER_OPPORTUNITY_FILTERS: UserOpportunityFiltersStore,
  USER: PreloadedUserStore,
  USERS: PreloadedUsersStore,
};
