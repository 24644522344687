import FormStore from '../classes/form-store';
import { getOpportunitiesByTalentId } from '../api/talentOpportunities';

class TalentOpportunityTabStoreClass extends FormStore {
  // The form entry data is from a dropdown, so nothing to validate here
  validate() {
    return true;
  }

  load(candidateId, queryParams = {}) {
    return this.setState(
      getOpportunitiesByTalentId(candidateId, queryParams).then(results => {
        return {
          candidacyList: results.data,
        };
      })
    );
  }
}

export const TalentOpportunityTabStore = new TalentOpportunityTabStoreClass();
