import {
  Card,
  CardHeader,
  CardSection,
  Layout,
  LayoutItem,
  Tile,
  useApi,
  Flashy,
  Icon,
  Grid,
  GridRow,
  GridColumn,
  Paragraph,
  Tiles,
  SmallHeader,
  CondensedHeader,
  CondensedLarge,
} from '@axiom/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SuccessPersonGraphic from '../../public/svg/success-party-person.svg';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { TimeToSubmissionCopyUtil } from '../../utils/timeToSubmissionCopyUtil';
import { UserOpportunityApi } from '../../api/user-opportunities-api';

export const WorkspaceTopTasks = () => {
  const navigate = useNavigate();
  const user = useSelector(state => PreloadedUserStore.select(state));
  const [{ data: opportunities }] = useApi(
    UserOpportunityApi.readTimeSensitiveUserOpportunities(user.id)
  );
  const filteredOpps = opportunities.filter(
    opp =>
      !opp.firstCandidateSelectedOrFurtherAt &&
      !opp.timeToSubmission.isSubmitted &&
      opp.timeToSubmission.hours >= 24 &&
      opp.isFulfillmentActive &&
      Number(opp.stageCode) > 0
  );
  filteredOpps.splice(5, filteredOpps.length - 5);

  const renderNoResults = () => {
    return (
      <Layout name="NO_TOP_TASKS" direction="vertical" verticalGutter="16px">
        <LayoutItem position="center">
          <SuccessPersonGraphic width="100%" height="auto" />
        </LayoutItem>
        <LayoutItem>
          <SmallHeader name="noTopTasksHeader">
            You're all caught up!
          </SmallHeader>
        </LayoutItem>
        <LayoutItem>
          <Paragraph name="noTopTasksParagraph">
            Nice work! There are no tasks for you to action in this category at
            the moment.
          </Paragraph>
        </LayoutItem>
      </Layout>
    );
  };

  return (
    <Grid stretched>
      <GridRow gutterTop="16px">
        <GridColumn>
          <Card stretched>
            <CardHeader>
              <SmallHeader>Top tasks</SmallHeader>
            </CardHeader>
            <CardSection>
              <Layout direction="vertical">
                {filteredOpps.length < 1 ? (
                  renderNoResults()
                ) : (
                  <Tiles columnWidth={1}>
                    {filteredOpps.map(opp => (
                      <Tile
                        key={opp.id}
                        name="TOP_TASK_TILE"
                        onClick={() =>
                          navigate(`/opportunity-detail/${opp.id}/talent`)
                        }
                      >
                        <Layout>
                          <LayoutItem
                            rightGutter="8px"
                            position="center"
                            verticalGutter="16px"
                          >
                            <Flashy
                              name="TOP_TASK_BADGE"
                              color={
                                opp.timeToSubmission.hours > 47
                                  ? 'dataVizRed'
                                  : 'dataVizYellow'
                              }
                            >
                              <Icon name="alert-filled" />
                            </Flashy>
                          </LayoutItem>
                          <LayoutItem topGutter="4px">
                            <CondensedHeader as="h6" maxLines={2}>
                              {`Submission Needed - ${TimeToSubmissionCopyUtil(
                                opp
                              ).toLowerCase()}`}
                            </CondensedHeader>
                            <CondensedLarge>{opp.jobName}</CondensedLarge>
                          </LayoutItem>
                        </Layout>
                      </Tile>
                    ))}
                  </Tiles>
                )}
              </Layout>
            </CardSection>
          </Card>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
