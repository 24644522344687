import APIHelper from './APIHelper';

const api = new APIHelper('barred location');

export const getBarredLocationsByTalentId = async talentId => {
  const { data, _meta } = await api.GET(
    `/candidates/${talentId}/barredLocations`
  );
  return {
    data,
    meta: _meta,
  };
};
