import styled from 'styled-components';
import { Label } from '@axiom/ui';

import AxiomForTalentIndicator from '../AxiomForTalentIndicator/AxiomForTalentIndicator';
import { getEmploymentStylesByStatus } from '../../utils/color';
import { getThemeProp } from '../../themes';

export const UnorderedListWrapper = styled.div`
  display: inline-block;
  word-break: break-word;
`;

export const ULTitle = styled(Label)`
  display: flex;
  align-items: center;
  color: ${({ candidateStatus }) =>
    getEmploymentStylesByStatus(candidateStatus).color ||
    getThemeProp('primaryText')};
`;

export const ULAftIcon = styled(AxiomForTalentIndicator)`
  margin-left: 0.25rem;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  & > li {
    list-style-type: ${props => (props.showBullets ? 'disc' : 'none')};
    list-style-position: inside;
  }
`;
