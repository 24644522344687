import React, { FC } from 'react';
import { Gutter, Layout, ParagraphHeader, Well } from '@axiom/ui';
import {
  Candidate,
  CandidateOpportunity,
  Opportunity,
  OpportunityCandidate,
  Taxonomy,
} from '@axiom/validation';

import { OpportunityTalentMatchChecklistItem } from './OpportunityTalentMatchChecklistItem';
import { MatchChecklistUtil, MatchChecklistItem } from './match-checklist-util';

interface OpportunityTalentMatchChecklistProps {
  candidate: Candidate;
  opportunity: Opportunity;
  opportunityCandidate: OpportunityCandidate;
  candidateOpportunities: Array<CandidateOpportunity>;
  taxonomy: Taxonomy;
}

export const OpportunityTalentMatchChecklist: FC<
  OpportunityTalentMatchChecklistProps
> = ({
  candidate,
  opportunity,
  opportunityCandidate,
  candidateOpportunities,
  taxonomy,
}) => {
  const checklistItems: Array<MatchChecklistItem> = [
    MatchChecklistUtil.getAvailabilityHoursChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    MatchChecklistUtil.getOpenToLowerEscItem(opportunityCandidate),
    MatchChecklistUtil.getCrossFulfillmentChecklistItem(
      opportunity,
      candidateOpportunities
    ),
    MatchChecklistUtil.getEngagedHoursChecklistItem(
      candidate,
      opportunity,
      candidateOpportunities
    ),
    MatchChecklistUtil.getCountryChecklistItem(candidate, opportunity),
    MatchChecklistUtil.getBarredToWorkOnsiteChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    MatchChecklistUtil.getBarredToWorkRemotelyChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    MatchChecklistUtil.getStateChecklistItem(candidate, opportunity),
    MatchChecklistUtil.getPracticeAreaChecklistItem(candidate, opportunity),
    MatchChecklistUtil.getIndustryChecklistItem(
      candidate,
      opportunity,
      taxonomy.industries
    ),
  ]
    .filter(i => i)
    .sort((a, b) => +a.isMatched - +b.isMatched);

  return (
    <Well>
      <Layout name="checklist" direction="vertical" verticalGutter="4px">
        <Gutter bottom="8px">
          <ParagraphHeader>Match Checklist</ParagraphHeader>
        </Gutter>

        {checklistItems.map(({ id, isMatched, children }) => (
          <OpportunityTalentMatchChecklistItem
            key={id}
            id={id}
            isMatched={isMatched}
          >
            {children}
          </OpportunityTalentMatchChecklistItem>
        ))}
      </Layout>
    </Well>
  );
};
