import keyBy from 'lodash/keyBy';

import {
  SET_USER_GROUPS_SUCCESS,
  UPDATE_USER_SUCCESS,
  USER_GROUPS_LOADED,
  USERS_LOADED,
} from '../constants';

const INITIAL_STATE = {
  data: [],
  byId: {},
  lastUpdated: 0,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USERS_LOADED: {
      const payloadData =
        payload && payload.data
          ? payload.data.map(u => {
              u.roles = u.roles || [];
              return u;
            })
          : [];
      return {
        ...state,
        data: [...state.data, ...payloadData],
        byId: keyBy(payloadData, 'id'),
        lastUpdated: Date.now(),
      };
    }
    case UPDATE_USER_SUCCESS: {
      const newUser = payload.user;
      const data = state.data.map(user =>
        user.id === newUser.id ? { team: user.team, ...newUser } : user
      );
      return {
        ...state,
        data,
        byId: keyBy(data, 'id'),
      };
    }

    case USER_GROUPS_LOADED: {
      const {
        teams,
        user: { id: userId },
      } = payload;
      const teamIds = teams.map(team => team.id);
      const data = state.data.map(user =>
        user.id === userId
          ? {
              ...user,
              team: teamIds,
            }
          : user
      );
      return {
        ...state,
        data,
        byId: keyBy(data, 'id'),
      };
    }

    case SET_USER_GROUPS_SUCCESS: {
      const {
        teams,
        user: { id: userId },
      } = payload;
      const teamIds = teams.map(team => team.id);
      const data = state.data.map(user =>
        user.id === userId
          ? {
              ...user,
              team: teamIds,
            }
          : user
      );
      return {
        ...state,
        data,
        byId: keyBy(data, 'id'),
      };
    }

    default:
      return state;
  }
};

export default reducer;
