import React from 'react';
import { OpportunitiesConst } from '@axiom/const';
import { string } from 'prop-types';
import {
  Modal,
  ModalSection,
  ModalFooter,
  ModalHeader,
  Button,
  Paragraph,
} from '@axiom/ui';

import { EditOpportunityFulfillmentStore } from '../../stores/edit-opportunity-fulfillment-store';

const FulfillmentConfirmationModal = ({ opportunityId }) => {
  const handleClose = () => EditOpportunityFulfillmentStore.clearState();

  return (
    <Modal name="FULFILLMENTMODAL">
      <ModalHeader onClose={handleClose}>Complete Fulfillment</ModalHeader>
      <ModalSection>
        <Paragraph>
          Are you sure you would like to complete fulfillment and reassign this
          entire opportunity to sales for final contracting?
        </Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button onClick={handleClose} variation="outline">
          Cancel
        </Button>
        <Button
          onClick={() =>
            EditOpportunityFulfillmentStore.save(opportunityId, {
              fulfillmentStatus:
                OpportunitiesConst.FulfillmentStatuses.FulfillmentComplete.v,
            })
          }
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FulfillmentConfirmationModal.propTypes = {
  opportunityId: string.isRequired,
};

export default FulfillmentConfirmationModal;
