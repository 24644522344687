/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { END } from 'redux-saga';

function hoc(config) {
  return BaseComponent => {
    class WrappedComponent extends Component {
      static async getInitialProps(props) {
        const {
          ctx: { store },
        } = props;

        let pageProps = {};
        if (BaseComponent.getInitialProps) {
          pageProps = await BaseComponent.getInitialProps(props);
        }

        // Keep saga running on the client (async mode)
        // Next.js automatically recognizes 'typeof window' and does dead code elimination,
        // so we want to do this check directly in the if statement
        // See: https://github.com/vercel/next.js/issues/5354
        if (config.async && typeof window !== 'undefined') {
          return pageProps;
        }

        // Force saga to end in all other cases
        store.dispatch(END);

        await store.sagaTask.toPromise();

        // Restart saga on the client (sync mode)
        if (typeof window !== 'undefined') {
          store.runSagaTask();
        }

        return pageProps;
      }

      // eslint-disable-next-line react/static-property-placement
      static displayName = `withReduxSaga(${
        BaseComponent.displayName || BaseComponent.name || 'BaseComponent'
      })`;

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return WrappedComponent;
  };
}

function withReduxSaga(arg) {
  const defaultConfig = { async: false };

  if (typeof arg === 'function') {
    return hoc(defaultConfig)(arg);
  }

  return hoc({ ...defaultConfig, ...arg });
}

export default withReduxSaga;
