import {
  JOB_PRACTICE_AREAS as OPPORTUNITY_PRACTICE_AREAS,
  LanguageConst,
} from '@axiom/const';

import { selectifyObject } from '../../utils/selectify';
import { OpportunityUtils } from '../../utils/opportunity-utils';

const WORK_TYPES = OpportunityUtils.getWorkTypes();

const selectify = options =>
  Object.keys(options).map(key => ({
    label: options[key],
    value: key,
  }));

export const COLLABORATORS = 'collaboratorId';

export const FORECAST_OPTIONS = {
  Omitted: 'Omitted',
  Pipeline: 'Pipeline',
  Upside: 'Upside',
  Commit: 'Commit',
  Closed: 'Closed',
};

export const OPPORTUNITY_OWNER_STUB = {
  'John Doe': 'John Doe',
  'Jane Doe': 'Jane Doe',
  'Foo Bar': 'Foo Bar',
};
export const SALES_LEAD_STUB = {
  'John Doe': 'John Doe',
  'Jane Doe': 'Jane Doe',
  'Foo Bar': 'Foo Bar',
};
export const SENIORITIES = {
  '0-4 yrs': '0-4 yrs',
  '5-9 yrs': '5-9 yrs',
  '10+ yrs': '10+ yrs',
  'N/A': 'N/A',
};
export const RESOURCE_TYPES = {
  Legal: 'Legal',
  'Non-Legal': 'Non Legal',
};
export const OPPORTUNITY_COLLABORATOR_STUB = {
  'John Doe': 'John Doe',
  'Jane Doe': 'Jane Doe',
  'Foo Bar': 'Foo Bar',
};
export const USE_CASES = {
  [OPPORTUNITY_PRACTICE_AREAS[100000]]: {
    'Flexible Funds Counsel': 'Flexible Funds Counsel',
    'Fund Advisory': 'Fund Advisory',
    'Hedge & Alternative Fund Formation': 'Hedge & Alternative Fund Formation',
    'Mutual Fund Formation': 'Mutual Fund Formation',
    'Transactional Counsel': 'Transactional Counsel',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100005]]: {
    'Capital Markets Transaction Advisory & Execution':
      'Capital Markets Transaction Advisory & Execution',
    'Financing Advisory': 'Financing Advisory',
    'Flexible Commercial Banking Counsel':
      'Flexible Commercial Banking Counsel',
    'Securities Clearing Counsel': 'Securities Clearing Counsel',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100010]]: {
    'Client Response': 'Client Response',
    'Credit Card Services': 'Credit Card Services',
    'Flexible Retail Banking Counsel': 'Flexible Retail Banking Counsel',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100015]]: {
    'Business or Product Expansion': 'Business or Product Expansion',
    'CMS Preparation': 'CMS Preparation',
    'Contract Information Management': 'Contract Information Management',
    'Contracts Creation & Execution: Complex Agreements':
      'Contracts Creation & Execution: Complex Agreements',
    'Contracts Creation & Execution: Sales Agreements':
      'Contracts Creation & Execution: Sales Agreements',
    'Contracts Creation & Execution: Simple Agreements':
      'Contracts Creation & Execution: Simple Agreements',
    'Contracts Creation & Execution: Vendor Agreements':
      'Contracts Creation & Execution: Vendor Agreements',
    'Flexible Commercial Counsel': 'Flexible Commercial Counsel',
    'Government Contracts': 'Government Contracts',
    'Templates & Playbooks Enablement': 'Templates & Playbooks Enablement',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100020]]: {
    'Corporate Entity Management': 'Corporate Entity Management',
    'Corporate Finance Counsel': 'Corporate Finance Counsel',
    'Corporate Governance Process Improvement':
      'Corporate Governance Process Improvement',
    'Corporate Restructuring': 'Corporate Restructuring',
    'Legal Department Leadership': 'Legal Department Leadership',
    'Securities/Corporate Governance SME':
      'Securities/Corporate Governance SME',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100025]]: {
    'Collateral Management & Netting Agreements':
      'Collateral Management & Netting Agreements',
    'ISDA Documentation': 'ISDA Documentation',
    'Prime Brokerage Agreeements': 'Prime Brokerage Agreeements',
    'SIFMA Documentation': 'SIFMA Documentation',
    'Structured Products': 'Structured Products',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100030]]: {
    'Flexible IP Counsel': 'Flexible IP Counsel',
    'IP Portfolio Audit': 'IP Portfolio Audit',
    'IP Portfolio Management': 'IP Portfolio Management',
    'IP Prep & Pros': 'IP Prep & Pros',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100035]]: {
    'Employment Agreements': 'Employment Agreements',
    'Employment Compliance Assessment': 'Employment Compliance Assessment',
    'Employment Counseling & Investigations':
      'Employment Counseling & Investigations',
    'ERISA SME': 'ERISA SME',
    'Immigration Advisory': 'Immigration Advisory',
    'Labor/Collective Bargaining Agreements':
      'Labor/Collective Bargaining Agreements',
    'Policies & Procedures': 'Policies & Procedures',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100040]]: {
    'Clinical Trial/R&D': 'Clinical Trial/R&D',
    'Flexible LS Counsel': 'Flexible LS Counsel',
    'Insurance/Government Reimbursement': 'Insurance/Government Reimbursement',
    'Life Sciences SME': 'Life Sciences SME',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100045]]: {
    'Discovery Management': 'Discovery Management',
    'Flexible Litigation Counsel': 'Flexible Litigation Counsel',
    'IP Litigation SME': 'IP Litigation SME',
    'Litigation Case Management': 'Litigation Case Management',
    'Litigation Industry SME': 'Litigation Industry SME',
    'Litigation Inquiries/Subpeonas': 'Litigation Inquiries/Subpeonas',
    'Litigation Process Optimization': 'Litigation Process Optimization',
    'Pre-suit Investigations & Research': 'Pre-suit Investigations & Research',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100050]]: {
    'Flexible M&A Counsel': 'Flexible M&A Counsel',
    'M&A Deal Quarterback': 'M&A Deal Quarterback',
    'M&A Process Development': 'M&A Process Development',
    'M&A Supplemental Documentation': 'M&A Supplemental Documentation',
    'New Business Policy Framework Development':
      'New Business Policy Framework Development',
    'Post-Deal Contract Review & Remediation':
      'Post-Deal Contract Review & Remediation',
    'Post-Deal Contract Risk Assessment': 'Post-Deal Contract Risk Assessment',
    'Post-Deal Integration Counsel': 'Post-Deal Integration Counsel',
    'Pre-Deal Contracts Diligence': 'Pre-Deal Contracts Diligence',
    'Pre-Deal Diligence SME': 'Pre-Deal Diligence SME',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100055]]: {
    'Contracts Creation & Execution: Privacy':
      'Contracts Creation & Execution: Privacy',
    'Data Mapping': 'Data Mapping',
    'Data Privacy Gap Assessment': 'Data Privacy Gap Assessment',
    'Data Privacy Office Support': 'Data Privacy Office Support',
    'Policies & Procedure': 'Policies & Procedure',
    'Privacy by Design': 'Privacy by Design',
    'Privacy Impact Assessments': 'Privacy Impact Assessments',
    'Privacy Regulatory SME': 'Privacy Regulatory SME',
    'Record Keeping': 'Record Keeping',
    'Subject Access Request Support': 'Subject Access Request Support',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100060]]: {
    'Construction or Environment SME': 'Construction or Environment SME',
    'Flexible RE Counsel': 'Flexible RE Counsel',
    'RE Portfolio Management': 'RE Portfolio Management',
    'RE Portfolio Review': 'RE Portfolio Review',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100065]]: {
    'AML/KYC': 'AML/KYC',
    'Business or Product Expansion': 'Business or Product Expansion',
    'Compliance Monitoring': 'Compliance Monitoring',
    'Compliance Testing & Audit': 'Compliance Testing & Audit',
    'Flexible Compliance Counsel': 'Flexible Compliance Counsel',
    'Flexible Regulatory Counsel': 'Flexible Regulatory Counsel',
    'FS Regulatory Filings': 'FS Regulatory Filings',
    'Policies & Procedures': 'Policies & Procedures',
    'Regulatory Monitoring & Interpretation':
      'Regulatory Monitoring & Interpretation',
    'Regulatory Response (Talent Platform)':
      'Regulatory Response (Talent Platform)',
    'Sales & Marketing Review': 'Sales & Marketing Review',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100070]]: {
    'Business or Product Expansion': 'Business or Product Expansion',
    'Compliance Monitoring': 'Compliance Monitoring',
    'Compliance Program Development & Management':
      'Compliance Program Development & Management',
    'Flexible Compliance Counsel': 'Flexible Compliance Counsel',
    'Flexible Regulatory Counsel': 'Flexible Regulatory Counsel',
    'Sales & Marketing Review': 'Sales & Marketing Review',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100075]]: {
    'Compliance Monitoring & Testing': 'Compliance Monitoring & Testing',
    'Compliance Program Development': 'Compliance Program Development',
    'Corporate Compliance Counsel': 'Corporate Compliance Counsel',
    'Sales & Marketing Review': 'Sales & Marketing Review',
    'Trade Compliance SME': 'Trade Compliance SME',
  },
  [OPPORTUNITY_PRACTICE_AREAS[100080]]: {
    'Business or Product Expansion': 'Business or Product Expansion',
    'Flexible Technology Counsel': 'Flexible Technology Counsel',
  },
};

export const BILLING_HOURS_PER_WEEK = {
  10: '10',
  15: '15',
  20: '20',
  25: '25',
  30: '30',
  40: '40',
  50: '50',
  Other: 'Other',
};

export const BILLING_DAYS_PER_WEEK = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  Other: 'Other',
};

export const BILLING_ESTIMATED_UNITS = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  19: '19',
  20: '20',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: '30',
  31: '31',
  32: '32',
  33: '33',
  34: '34',
  35: '35',
  36: '36',
  37: '37',
  38: '38',
  39: '39',
  40: '40',
  41: '41',
  42: '42',
  43: '43',
  44: '44',
  45: '45',
  46: '46',
  47: '47',
  48: '48',
  49: '49',
  50: '50',
};

export const RESERVED_HOURLY_BILLING_UNIT_VALUE = 'Reserved Hourly';
export const RESERVED_DAILY_BILLING_UNIT_VALUE = 'Reserved Daily';
export const VARIABLE_BILLING_UNIT_VALUE = 'Variable';

export const BILLING_UNIT_MEASUREMENT = {
  [RESERVED_HOURLY_BILLING_UNIT_VALUE]: 'Reserved Hourly',
  [RESERVED_DAILY_BILLING_UNIT_VALUE]: 'Reserved Daily',
  [VARIABLE_BILLING_UNIT_VALUE]: 'Variable',
};

export const CURRENCIES = {
  GBP: 'British Pound',
  CAD: 'Canadian Dollar',
  EUR: 'Euro',
  HKD: 'Hong Kong Dollar',
  INR: 'Indian Rupee',
  PLN: 'Polish Zloty',
  SGD: 'Singapore Dollar',
  CHF: 'Swiss Franc',
  USD: 'USD',
};

export const languageSkillItems = selectifyObject(
  LanguageConst.LANGUAGE_SKILLS
);
export const languageProficiencyItems = selectifyObject(
  LanguageConst.LANGUAGE_PROFICIENCIES
);
export const opportunityOwnerItems = selectify(OPPORTUNITY_OWNER_STUB);
export const salesLeadItems = selectify(SALES_LEAD_STUB);
export const seniorityItems = selectify(SENIORITIES);
export const resourceTypeItems = selectify(RESOURCE_TYPES);
export const workTypeItems = selectify(WORK_TYPES);
export const opportunityCollaboratorItems = selectify(
  OPPORTUNITY_COLLABORATOR_STUB
);
export const practiceAreaItems = selectify(OPPORTUNITY_PRACTICE_AREAS);
export const getCaseItemsDropdownValues = practiceArea =>
  practiceArea && USE_CASES[practiceArea]
    ? selectify(USE_CASES[practiceArea])
    : [];
export const billingUnitsPerWeekDropdownValues = isHourly =>
  selectify(isHourly ? BILLING_HOURS_PER_WEEK : BILLING_DAYS_PER_WEEK);
export const billingHoursPerWeekItems = selectify(BILLING_HOURS_PER_WEEK);
export const billingDaysPerWeekItems = selectify(BILLING_DAYS_PER_WEEK);
export const billingEstimatedUnitsDropdownValues = selectify(
  BILLING_ESTIMATED_UNITS
);
export const billingUnitDropdownValues = selectify(BILLING_UNIT_MEASUREMENT);
export const forecastItems = selectify(FORECAST_OPTIONS);
export const currencyItems = selectify(CURRENCIES);
