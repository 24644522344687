/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { CheckboxIcon } from '@axiom/ui';

import { CheckboxWrapper, InputWrapper } from './CheckboxStyles';

export default class Checkbox extends React.PureComponent {
  onClick = () => {
    if (this.input.current) this.input.current.click();
  };

  input = React.createRef();

  render() {
    const { checked, onValueChange, inputProps, width, height, ...props } =
      this.props;

    return (
      <CheckboxWrapper onClick={this.onClick} {...props}>
        <InputWrapper
          type="checkbox"
          checked={checked}
          onChange={onValueChange}
          {...inputProps}
          ref={this.input}
        />
        <CheckboxIcon
          checked={checked}
          width={width}
          height={height}
          disabled={inputProps?.disabled}
        />
      </CheckboxWrapper>
    );
  }
}

Checkbox.defaultProps = {
  checked: false,
  onValueChange: () => {},
  inputProps: {
    disabled: false,
  },
  width: '1.0625rem',
  height: '1.0625rem',
};

Checkbox.propTypes = {
  checked: bool,
  onValueChange: func,
  inputProps: shape({
    disabled: bool,
  }),
  width: string,
  height: string,
};
