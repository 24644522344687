import React from 'react';
import { Badge, Button, Flashy, Icon, Tooltip } from '@axiom/ui';
import { Opportunity } from '@axiom/validation';

import { TimeToSubmissionCopyUtil } from '../../utils/timeToSubmissionCopyUtil';

import { WorkspaceViewTimeToSubmissionTooltipBody } from './WorkspaceViewTimeToSubmissionTooltipBody';

export const WorkspaceViewTimeToSubmission = ({
  opportunity,
}: {
  opportunity: Opportunity;
}) => {
  let badgeBackground: 'red' | 'yellow' | 'green' | 'light blue';
  switch (true) {
    case opportunity.timeToSubmission.isSubmitted:
      badgeBackground = 'light blue';
      break;
    case opportunity.timeToSubmission.hours < 24:
      badgeBackground = 'green';
      break;
    case opportunity.timeToSubmission.hours >= 24 &&
      opportunity.timeToSubmission.hours < 48:
      badgeBackground = 'yellow';
      break;
    default:
      badgeBackground = 'red';
      break;
  }

  return (
    <>
      <Tooltip
        position="right"
        name="timeToSubmissionTooltip"
        anchor={
          <Button variation="minimal">
            <Badge background={badgeBackground} name="timeToSubmissionBadge">
              {TimeToSubmissionCopyUtil(opportunity)}
            </Badge>
            {(opportunity.timeToSubmission.isSubmitted ||
              opportunity.firstCandidateSelectedOrFurtherAt) && (
              <Flashy color="textDefault">
                <Icon name="checkmark" />
              </Flashy>
            )}
          </Button>
        }
      >
        <WorkspaceViewTimeToSubmissionTooltipBody opportunity={opportunity} />
      </Tooltip>
    </>
  );
};
