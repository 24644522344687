import React from 'react';
import {
  ModalHeader,
  ModalSection,
  Paragraph,
  ModalFooter,
  Modal,
  Button,
} from '@axiom/ui';

interface SubmissionModalUnsavedChangesProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const SubmissionModalUnsavedChanges = ({
  onCancel,
  onConfirm,
}: SubmissionModalUnsavedChangesProps) => {
  return (
    <Modal size="confirm" name="confirm_close_modal">
      <ModalHeader name="confirm_close_header" onClose={onCancel}>
        Unsaved Changes
      </ModalHeader>
      <ModalSection>
        <Paragraph>
          Just a heads up! It looks like you have some unsaved changes, that
          will be lost if you exit now. How would you like to continue?
        </Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button
          name="discardChangesButton"
          variation="outline"
          onClick={() => onConfirm()}
        >
          DISCARD CHANGES
        </Button>
        <Button name="closeButton" onClick={() => onCancel()}>
          KEEP EDITING
        </Button>
      </ModalFooter>
    </Modal>
  );
};
