export const defaultPick =
  (p: Record<string, unknown>) => (o: Record<string, unknown>) =>
    Object.keys(p).reduce(
      (a, k) => ({
        ...a,
        ...(typeof p[k] !== 'undefined' || o[k] ? { [k]: o[k] || p[k] } : {}),
      }),
      {}
    );

export const arrayToConstMap: (l: string[]) => Record<string, string> = l =>
  Object.freeze(l.reduce((a, e) => ({ ...a, [e]: e }), {}));

export const cleanText = (text: string) => {
  // eslint-disable-next-line no-misleading-character-class
  const DISALLOWED_UNICODE_REGEX = /[\u200B\u200C\u200D\uFEFF\uFFFD]/gm;
  return text?.replace(DISALLOWED_UNICODE_REGEX, '');
};

export const applyTransforms = (
  tfs: Array<(acc: unknown, opts: unknown) => unknown>,
  result: unknown,
  opts: unknown
) => tfs.reduce((acc, tf) => tf(acc, opts), result);

export const dataOrFunc: (df: unknown) => (args: unknown[]) => unknown =
  df =>
  (...args) =>
    typeof df === 'function' ? df(...args) : df;

export const loggerStringify = (obj: Record<string, unknown> = {}) =>
  Object.keys(obj).reduce(
    (acc, s) =>
      `${acc}${acc ? ', ' : ''}${s}: ${
        typeof obj[s] === 'string' ? obj[s] : JSON.stringify(obj[s])
      }`,
    ''
  );

type ObjectKey = string;
type CallbackArgs = unknown[];
type CallbackReturn = { handlerArgs: unknown[] } & Record<string, unknown>;
type Callback = (...args: CallbackArgs) => CallbackReturn;
type HandlerReturn = unknown;
export type Handler = (...a: CallbackReturn['handlerArgs']) => HandlerReturn;
type Opts = { handler: Handler } & Record<string, unknown>;
export type Generator = (
  cb: Callback,
  opts: Opts
) => (...args: CallbackArgs) => HandlerReturn;

export const createHandlers = (
  o: Record<ObjectKey, Callback>,
  {
    generator = (cb, { handler }) =>
      (...args: CallbackArgs) =>
        handler(...(cb?.(...args)?.handlerArgs ?? [])),
    ...opts
  }: { generator?: Generator } & Opts
): Record<ObjectKey, Handler> =>
  Object.keys(o).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: generator(o[curr], opts),
    }),
    {}
  );
