import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  oneOfType,
  string,
  node,
  func,
  shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { Gutter } from '@axiom/ui';

import { Header } from '../components/Header/Header';
import BannerContainer from '../components/Banner/Banner';
import Sidebar from '../components/Sidebar/Sidebar';
import AppErrors from '../components/AppErrors/AppErrors';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { hasInitialDataSelector } from '../redux/selectors/app';
import { SideDrawer } from '../components/SideDrawer/SideDrawer';

import { ErrorBoundaryLayout } from './ErrorBoundaryLayout/ErrorBoundaryLayout';
import { AppWrapper, InnerPage, StyledSection, WorkArea } from './styles';

const DefaultComponent = ({
  apiErrors,
  children,
  sidebarContent,
  user,
  hasInitialData,
  showSideDrawer,
  sideBarActive,
  sideDrawerActive,
  simple,
}) => {
  const [expandedSideDrawer, setExpandedSideDrawer] = useState(false);
  const rightSideGutter = showSideDrawer || sidebarContent ? '72px' : '16px';
  const closeSideDrawer = () => {
    setExpandedSideDrawer(false);
  };

  if (hasInitialData && user) {
    return (
      <AppWrapper>
        <AppErrors />
        <Header closeSideDrawer={closeSideDrawer} />
        <BannerContainer />
        {simple ? (
          children
        ) : (
          <InnerPage>
            <WorkArea>
              <StyledSection>{children}</StyledSection>
            </WorkArea>

            <Gutter right={rightSideGutter} />
            {sidebarContent ? (
              <Sidebar
                sideBarActive={sideBarActive}
                sideDrawerActive={sideDrawerActive}
              >
                {sidebarContent}
              </Sidebar>
            ) : null}
            {showSideDrawer && (
              <SideDrawer
                expanded={expandedSideDrawer}
                setExpanded={setExpandedSideDrawer}
              />
            )}
          </InnerPage>
        )}
      </AppWrapper>
    );
  }

  return apiErrors.filter(error => !!error).length > 0 ? (
    <ErrorBoundaryLayout />
  ) : (
    <LoadingTreatment maxLoadMilliseconds={30000} />
  );
};
/* eslint-enable no-nested-ternary */

DefaultComponent.defaultProps = {
  apiErrors: [],
  hasInitialData: false,
  sidebarContent: null,
  children: null,
  showSideDrawer: true,
  sideBarActive: true,
  sideDrawerActive: false,
  user: null,
  simple: false,
};

DefaultComponent.propTypes = {
  apiErrors: arrayOf(shape({})),
  children: oneOfType([string, node, func]),
  sidebarContent: node,
  user: shape({
    activeDirectorySecurityGroup: string,
  }),
  hasInitialData: bool,
  showSideDrawer: bool,
  sideBarActive: bool,
  sideDrawerActive: bool,
  simple: bool,
};
// HMR does not work well with composition function, need to split these
// into their own variables so they can be replaced by updates.
const ConnectHOC = connect(hasInitialDataSelector);
export default ConnectHOC(DefaultComponent);
