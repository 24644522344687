import APIHelper from './APIHelper';

/**
 * This is displayed to user so needs to be talent, not candidate
 */
const api = new APIHelper('talent tag');

export const getTagsByTalentId = async talentId => {
  const response = await api.GET(`/candidates/${talentId}/tags`);
  return { data: response.data, talentId };
};

export const postTagsByTalentId = async (talentId, tagIds) => {
  const { data } = await api.PUT(`/candidates/${talentId}/tags`, [...tagIds]);
  return { data };
};
