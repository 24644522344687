import { Api } from '@axiom/ui';
import { Opportunity } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class OpportunityApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  publishOpportunity(opportunityId: Opportunity['id']) {
    return super.write<{ data: Opportunity }>({
      endpoint: `/opportunities/${opportunityId}/publishSubmission`,
      method: 'PATCH',
    });
  }
}

export const OpportunityApi = new OpportunityApiClass();
