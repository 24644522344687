import styled from 'styled-components';

// eslint-disable-next-line boundaries/element-types
import {
  List,
  ULTitle,
  UnorderedListWrapper,
} from '../UnorderedList/UnorderedListStyles';
// eslint-disable-next-line boundaries/element-types
import {
  RadioButtonWrapper,
  RadioInput,
} from '../RadioButton/RadioButtonStyles';
import {
  FlexLeftWrapper,
  FlexRightWrapper,
} from '../InputCharRemaining/InputCharRemaining';

export const DivSubmissionDetailsHeaderWrapper = styled.div``;
export const DivSubmissionDetailsBodyWrapper = styled.div`
  flex-grow: 1;
  margin: 1rem 0;
  display: flex;
`;

export const DivTalentListWrapper = styled.div`
  flex: 0 0 40%;
`;
export const DivSelectedTalentWrapper = styled.div`
  border: 0.0625rem solid lightgrey;
  border-left: none;
  box-shadow: -0.1875rem 0.09375rem 0.1875rem rgba(0, 0, 0, 0.16);
  flex: 0 0 60%;
`;

export const SubmissionModalUrlBox = styled.div`
  border: 0.0625rem solid #979797;
  padding: 0.6875rem 0.625rem;
  width: 90%;
  height: 3rem;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const SubmissionModalLastUpdatedText = styled.div`
  margin-top: 0.5rem;
  font-style: italic;
  color: #a1a1a1;
`;

export const SubmissionModalTalentListItem = styled.div`
  cursor: pointer;
  padding: 18px 20px;

  ${props => props.selected && `background-color: #001E49;`};
`;

export const SubmissionModalDataTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SubmissionModalDataLabel = styled.div`
  & input {
    margin-top: -0.6rem;
  }
`;

export const SubmissionModalRateInputWrapper = styled.div`
  margin-right: 6px;
  height: 100%;

  & > div {
    width: 100%;
  }

  ${UnorderedListWrapper} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  ${ULTitle} {
    display: block;
    align-items: flex-start;
    min-height: 3rem;
    width: 100%;
  }
  ${List} {
    margin-top: 0.5rem;
  }

  ${RadioButtonWrapper} {
    align-items: flex-start;
  }
  ${RadioInput} {
    margin-top: 3px;
    margin-right: 10px;
  }

  ${FlexLeftWrapper},
  ${FlexRightWrapper} {
    padding-top: 0;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CPQWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: -0.5rem 0 0.5rem 0;
  width: 100%;
`;

export const CPQCol = styled.div`
  flex: 1 0 33%;
  max-width: 33%;
`;

export const CPQLabel = styled.span`
  font-weight: bold;
`;
