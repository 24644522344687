import React, { useState, useRef } from 'react';
import crypto from 'crypto-js/sha256';
import { Header1, Button, Gutter } from '@axiom/ui';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';

import { ConvertUserIdsTextArea } from './ConvertUserIdsStyles';

const extractUuids = (() => {
  const uuidRegex = /\w{8}(?:-\w{4}){3}-\w{12}/g;

  return (str = '') => str.match(uuidRegex) || [];
})();

const ConvertUserIds = () => {
  const [userIds, setUserIds] = useState();
  const [rawUserIds, setRawUserIds] = useState();
  const exporter = useRef();

  return (
    <section>
      <Header1>UserId =&gt; Pendo UserId Converter</Header1>
      <form
        onSubmit={event => {
          event.preventDefault();

          const tableData = extractUuids(rawUserIds).reduce((accum, userId) => {
            accum.push({
              userId,
              pendoUserId: crypto(userId).toString(),
            });

            return accum;
          }, []);

          setUserIds(tableData.length > 0 ? tableData : null);
        }}
      >
        <ConvertUserIdsTextArea
          placeholder="Input a list of user ids and hit Convert to generate a table of user ids sent to pendo."
          onChange={event => {
            setRawUserIds(event.target.value);
          }}
        />
        <Gutter top="1rem" bottom="1rem">
          <Button type="submit">Convert</Button>
        </Gutter>
      </form>

      {userIds && (
        <ExcelExport data={userIds} ref={exporter}>
          <Grid data={userIds} style={{ height: '420px' }}>
            <GridToolbar>
              <Button
                name="Export Excel"
                onClick={() => {
                  exporter.current.save();
                }}
              >
                Export to Excel
              </Button>
            </GridToolbar>
            <GridColumn field="userId" title="user id" />
            <GridColumn field="pendoUserId" title="pendo user id" />
          </Grid>
        </ExcelExport>
      )}
    </section>
  );
};

export default ConvertUserIds;
