import { arrayOf } from 'prop-types';
import get from 'lodash/get';

import GenericStore from '../classes/generic-store';
import groupShape from '../models/group';
import { getGroups } from '../api/groups';

class GroupsStoreClass extends GenericStore {
  getDataShape() {
    return arrayOf(groupShape);
  }

  load() {
    return this.setState(getGroups().then(response => response.data));
  }

  selectData(state) {
    return get(super.select(state), 'data', []);
  }
}

export const PreloadedGroupsStore = new GroupsStoreClass();
