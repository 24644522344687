import React from 'react';

import { Layout } from '../Layout/Layout';
import { LayoutItem } from '../Layout/LayoutItem';
import { Flashy } from '../../element/Flashy/Flashy';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import NoResults from '../../../public/static/svg/no-results-binoculars.svg';

import { DataGridNoResultsProps } from './data-grid-props';

export const DataGridNoResults = ({
  noRowsMessageFunc,
}: DataGridNoResultsProps) => {
  return (
    <>
      <Layout position="center middle" horizontalGutter="8px">
        <LayoutItem>
          <NoResults width={75} height={75} />
        </LayoutItem>
        <LayoutItem>
          <Flashy bold>
            <ParagraphHeader>{noRowsMessageFunc()}</ParagraphHeader>
          </Flashy>
        </LayoutItem>
      </Layout>
    </>
  );
};
