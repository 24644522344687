import PropTypes from 'prop-types';

export const groupModel = {
  id: PropTypes.string,
  name: PropTypes.string,
  parentId: PropTypes.string,
};

export const GROUP_MODEL_PROPERTIES = Object.keys(groupModel);

export default PropTypes.shape(groupModel);
