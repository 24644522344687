import styled from 'styled-components';

export const DivLocationTypeaheadWrapper = styled.div`
  position: relative;

  .k-dropdown-wrap {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
  }

  .k-dropdown-wrap .k-select {
    border-width: 0px;
    background-color: white !important;
    color: black !important;
    background-image: none;
  }

  .k-combobox {
    width: 100%;
  }

  .k-dropdown .k-input {
    min-height: 2.2rem;
  }

  .k-multiselect-wrap .k-button {
    background-color: #212529;
  }
`;

export const DivInputDropdownWrapper = styled.div`
  position: relative;
  z-index: ${props => (props.isOpen ? 1 : 0)};
  width: 100%;
  margin-bottom: ${props => (props.isOpen ? '2rem' : 0)};
`;

export const DivGoogleMapWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  padding-top: ${props => (props.isOpen ? '40%' : 0)};
`;
