import { shape, bool } from 'prop-types';

import GenericStore from '../classes/generic-store';

class AdminFunctionsModalClass extends GenericStore {
  load(data) {
    return this.setState(data);
  }

  getDataShape() {
    return shape({ modalIsOpen: bool });
  }

  closeModal() {
    this.clearState();
  }
}

export const AdminFunctionsModalStore = new AdminFunctionsModalClass();
