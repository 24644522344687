import styled, { DefaultTheme } from 'styled-components';

export const LegendKey = styled.div`
  background-color: ${({
    type,
    theme,
  }: {
    type: string;
    theme: DefaultTheme;
  }) =>
    type === 'scheduled'
      ? theme.colors.dataVizLightBlue
      : theme.colors.dataVizBlue};
  width: 18px;
  height: 18px;
`;
