import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SearchInput from '../SearchInput/SearchInput';
import { AccountsStore } from '../../stores/accounts-store';
import { AccountStore } from '../../stores/account-store';

import {
  ManageAccountsListRoot,
  ManageAccountsListStaticItem,
  ManageAccountsListDynamicScrollingItem,
  MAList,
  ManageAccountsListItem,
} from './ManageAccountsListStyles';

const ManageAccountsListComponent = ({ accountsState }) => {
  const [searchTerm, setSearchTerm] = useState(accountsState.searchTerm);
  const handleQueryChange = e => {
    const inputValue = e.target.value;
    AccountsStore.load({ searchTerm: inputValue });
    setSearchTerm(inputValue);
  };

  const [selected, setSelected] = useState(null);

  const onSelectedChange = account => {
    AccountStore.load(account.id);
    setSelected(account);
  };

  useEffect(() => {
    AccountsStore.load();
  }, []);

  return (
    <ManageAccountsListRoot data-test="SEARCH_LIST">
      <ManageAccountsListStaticItem>
        <SearchInput
          data-test="SEARCH_INPUT"
          onChange={handleQueryChange}
          placeholder="Search Accounts"
          value={searchTerm}
        />
      </ManageAccountsListStaticItem>
      <ManageAccountsListDynamicScrollingItem>
        <StoreStateTreatment
          storeState={accountsState}
          name="SEARCH_LIST"
          renderLoadedView={({
            data: { results, totalResultsCount, page },
          }) => {
            return (
              <InfiniteScroll
                dataLength={results.length}
                next={() =>
                  AccountsStore.load({
                    results,
                    page: page + 1,
                    searchTerm,
                  })
                }
                hasMore={totalResultsCount > results.length}
                height="32em"
              >
                <MAList data-test="RESULTS_LIST" role="listbox">
                  {results.map(account => {
                    const { name, id } = account;

                    return (
                      <ManageAccountsListItem
                        key={id}
                        isSelected={selected && selected.id === id}
                        onClick={() => onSelectedChange(account)}
                        data-test="RESULT_ITEM"
                      >
                        {name}
                      </ManageAccountsListItem>
                    );
                  })}
                </MAList>
              </InfiniteScroll>
            );
          }}
        />
      </ManageAccountsListDynamicScrollingItem>
    </ManageAccountsListRoot>
  );
};

ManageAccountsListComponent.propTypes = {
  accountsState: AccountsStore.getDataShape().isRequired,
};

export default connect(
  state => ({
    accountsState: AccountsStore.select(state),
  }),
  {}
)(ManageAccountsListComponent);
