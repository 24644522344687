import React from 'react';
import { oneOfType, func, node } from 'prop-types';

import { DisabledDisplayWrapper } from './DisabledDisplayStyles';

const DisabledDisplay = ({ children }) => (
  <DisabledDisplayWrapper>{children}</DisabledDisplayWrapper>
);

DisabledDisplay.propTypes = {
  children: oneOfType([func, node]).isRequired,
};

export default DisabledDisplay;
