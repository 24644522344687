import styled from 'styled-components';

export const LoadingGradientWrapper = styled.div`
  .block-placeholder {
    height: 16px;

    background: linear-gradient(
      -45deg,
      transparent,
      #b0b0b0,
      transparent,
      #929292
    );
    background-size: 400% 400%;
    animation: Gradient 3s ease infinite;

    @keyframes Gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    &.header {
      height: 20px;
      width: 160px;
      margin-bottom: 20px;
    }

    &.header-lg {
      height: 38px;
      width: 200px;
      margin-bottom: 20px;
    }

    &.placeholder-dark {
      opacity: 0.3;
    }
  }
`;
