import {
  positionDraggableStatuses,
  poolNotDraggableStatuses,
} from './OpportunityTalentConstants';

export function isCandidateDraggable({
  candidate,
  positions = [],
  opportunity,
}) {
  if (!opportunity.isFulfillmentActive) return false;
  if (poolNotDraggableStatuses.includes(candidate.candidateStatus)) {
    return false;
  }
  if (candidate.position && candidate.position.id) {
    if (positions.length < 2) return false;
    if (!positionDraggableStatuses.includes(candidate.candidateStatus)) {
      return false;
    }
  } else if (!positions.length) return false;
  return true;
}
