import APIHelper from './APIHelper';

const api = new APIHelper('group');

export const getGroups = async () => {
  const { data } = await api.GET('/groups');
  return { data };
};

export const createGroup = async body => {
  const { data } = await api.POST('/groups', body);
  return { data };
};

export const deleteGroup = async groupId => {
  const { data } = await api.DELETE(`/groups/${groupId}`);
  return { data };
};

export const updateGroup = async (groupId, body) => {
  const { data } = await api.PATCH(`/groups/${groupId}`, body);
  return { data };
};

export const addGroupUser = async (groupId, userId) => {
  const { data } = await api.POST(`/groups/${groupId}/users`, { userId });
  return { data };
};

export const removeGroupUser = async (groupId, userId) => {
  const { data } = await api.DELETE(`/groups/${groupId}/users/${userId}`);
  return { data };
};
