import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const InputWrapper = styled.div`
  position: relative;
  margin: 25px 0;

  & svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    background: transparent;
    border: 0;
    border-bottom: 3px solid ${getThemeProp('colorSecondary')};
    color: ${getThemeProp('primaryText')};
    font-size: 1rem;
    font-weight: bold;
    height: 2.25rem;
    padding: 0 40px 0 0;
    width: 100%;

    &::placeholder {
      /* matches Kendo input */
      color: rgba(101, 101, 101, 0.5);
      font-weight: normal;
      opacity: 1;
    }

    &:focus {
      border-color: ${GENERAL_STYLES_MAP.AXIOM.background};
      /* transparent outline, not none, for Windows High Contrast users */
      outline: 2px solid transparent;
    }
  }
`;
