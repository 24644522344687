import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import get from 'lodash/get';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
} from '@axiom/ui';

import { FormCell, FormRow } from '../../styled/form';
import { educationDegreeOptions } from '../../models/education';
import { DateUtil } from '../../utils/date-util';
import { toDropdownOptions } from '../../utils/to-dropdown-options';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormEducationItemStore } from '../../stores/form-education-item-store';
import { FFInput } from '../FFInput/FFInput';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import FFLawSchoolsAutoComplete from '../FFLawSchoolsAutoComplete/FFLawSchoolsAutoComplete';
import { CandidateStore } from '../../stores/candidate-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';

const year = new Date().getFullYear();
const educationYears = toDropdownOptions(
  DateUtil.getYearOptionsByRange(year + 10, 1950)
);

const EducationForm = ({ candidateState }) => {
  const hasJdEducation = candidate =>
    !!get(candidate, 'data.degrees', []).find(obj => obj.degree === 'JD');

  /**
   * Only filter out JD option if the current form doesn't contain it
   * and if another degree already has it.
   */
  const getDegreeOptions = (degree, candidate) => {
    const options =
      hasJdEducation(candidate) && degree !== 'JD'
        ? educationDegreeOptions.filter(option => option.value !== 'JD')
        : educationDegreeOptions;

    return toDropdownOptions(options);
  };

  return (
    <FormStateTreatment
      isModalState
      name="Edit_Education_Modal"
      formStore={FormEducationItemStore}
      renderLoadedView={({ formData, fireSubmit, fireCancel }) => {
        const { educationId, degree, institution } = formData;
        return (
          <StoreStateTreatment
            storeState={candidateState}
            renderLoadedView={candidate => (
              <Modal name="EDUCATIONMODAL">
                <ModalHeader onClose={fireCancel}>
                  {educationId ? 'Edit' : 'Add'} Education
                </ModalHeader>
                <ModalSection>
                  <FormRow>
                    <FormCell width="full">
                      <Field
                        name="lawSchoolId"
                        component={FFLawSchoolsAutoComplete}
                        label="Institution"
                        placeholder={institution}
                      />
                    </FormCell>
                  </FormRow>

                  <FormRow>
                    <FormCell width="double">
                      <Field
                        name="degree"
                        label="Degree"
                        component={FFDropdown}
                        options={getDegreeOptions(degree, candidate)}
                      />
                    </FormCell>

                    <FormCell width="double">
                      <Field
                        name="yearAwarded"
                        label="Date"
                        component={FFDropdown}
                        options={educationYears}
                      />
                    </FormCell>
                  </FormRow>

                  {degree === 'State Exam' && (
                    <FormRow>
                      <FormCell width="double">
                        <Field
                          name="stateExamScore"
                          label="Score"
                          component={FFInput}
                          allowNull
                        />
                      </FormCell>
                    </FormRow>
                  )}
                </ModalSection>
                <ModalFooter>
                  <Button
                    name="CANCELEDUCATIONBUTTON"
                    onClick={fireCancel}
                    variation="outline"
                  >
                    Cancel
                  </Button>
                  <Button name="SAVEEDUCATIONBUTTON" onClick={fireSubmit}>
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          />
        );
      }}
    />
  );
};

EducationForm.propTypes = {
  candidateState: CandidateStore.getDataShape().isRequired,
};

const mapStateToProps = state => ({
  candidateState: CandidateStore.select(state),
});

export default connect(mapStateToProps)(EducationForm);
