import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SearchInput from '../SearchInput/SearchInput';
import { ContactsStore } from '../../stores/contacts-store';
import { ContactStore } from '../../stores/contact-store';

import {
  ManageContactListRoot,
  ManageContactListStaticItem,
  ManageContactListDynamicScrollingItem,
  MCList,
  ManageContactListItem,
} from './ManageContactsListStyles';

const ManageContactsListComponent = ({ contactsState }) => {
  const [searchTerm, setSearchTerm] = useState(contactsState.searchTerm);

  const handleQueryChange = e => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    ContactsStore.load({ searchTerm: inputValue });
  };

  const [selected, setSelected] = useState(null);

  const onSelectedChange = contact => {
    ContactStore.load(contact.id);
    setSelected(contact);
  };

  useEffect(() => {
    ContactsStore.load();
  }, []);

  return (
    <ManageContactListRoot>
      <ManageContactListStaticItem>
        <SearchInput
          data-test="SEARCH_INPUT"
          onChange={handleQueryChange}
          placeholder="Search Users"
          value={searchTerm}
        />
      </ManageContactListStaticItem>
      <ManageContactListDynamicScrollingItem>
        <StoreStateTreatment
          storeState={contactsState}
          name="SEARCH_LIST"
          renderLoadedView={({
            data: { results, totalResultsCount, page },
          }) => {
            return (
              <InfiniteScroll
                dataLength={results.length}
                next={() =>
                  ContactsStore.load({ results, page: page + 1, searchTerm })
                }
                hasMore={totalResultsCount > results.length}
                height="32em"
              >
                <MCList data-test="RESULTS_LIST" role="listbox">
                  {results.map(contact => {
                    const { firstName, lastName, id } = contact;

                    return (
                      <ManageContactListItem
                        key={id}
                        isSelected={selected && selected.id === id}
                        onClick={() => onSelectedChange(contact)}
                        data-test="RESULT_ITEM"
                      >
                        {`${firstName} ${lastName}`}
                      </ManageContactListItem>
                    );
                  })}
                </MCList>
              </InfiniteScroll>
            );
          }}
        />
      </ManageContactListDynamicScrollingItem>
    </ManageContactListRoot>
  );
};

ManageContactsListComponent.propTypes = {
  contactsState: ContactsStore.getDataShape().isRequired,
};

export default connect(
  state => ({
    contactsState: ContactsStore.select(state),
  }),
  {}
)(ManageContactsListComponent);
