import styled from 'styled-components';

export const LogContainer = styled.div`
  margin: 2rem 1rem 0 1rem;

  &:last-child {
    padding-bottom: 4rem;
  }
`;
export const LogHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LogHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;
export const LogHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
export const LogItems = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;
