import { arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getOpportunityPositions } from '../api/opportunities-legacy-api';
import { positionShape } from '../models/position';
import { PositionUtils } from '../utils/position-utils';

class OpportunityPositionsStoreClass extends GenericStore {
  load(opportunityId) {
    return this.setState(
      // name isn't initially filled in
      getOpportunityPositions(opportunityId).then(PositionUtils.sortPositions)
    );
  }

  getDataShape() {
    return arrayOf(positionShape);
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const OpportunityPositionsStore = new OpportunityPositionsStoreClass();
