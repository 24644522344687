import { string, number, bool, arrayOf, shape } from 'prop-types';

export const UserShape = {
  bullhornId: string,
  candidateId: string,
  created_at: string,
  email: string,
  firstLoginAt: string,
  firstName: string.isRequired,
  fullName: string,
  hasAcceptedTermsAndConditions: bool.isRequired,
  homeOffice: string,
  id: string.isRequired,
  isActive: bool.isRequired,
  lastLoginAt: string,
  lastName: string.isRequired,
  locked: string,
  organizationId: string,
  retryAttempts: number,
  roles: arrayOf(string),
  salesforceId: string,
  termsAndConditionsAcceptedAt: string,
  updated_at: string,
};

export const UsersShape = arrayOf(shape(UserShape));
