import APIHelper from './APIHelper';

const api = new APIHelper('talent');

export const getCandidateWhiteboard = async id => {
  const { data } = await api.GET(`/candidates/${id}/whiteboard`);
  return { data };
};

export const putCandidateWhiteboard = async (id, payload = { body: '' }) => {
  const { data } = await api.PUT(`/candidates/${id}/whiteboard`, payload);
  return { data };
};
