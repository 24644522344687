import React from 'react';
import { Candidate, SubmissionCandidate } from '@axiom/validation';

import { Gutter } from '../../layout/Gutter/Gutter';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Paragraph } from '../../content/Paragraph/Paragraph';

export type BioPdfFooterTypes = {
  candidate: Candidate | SubmissionCandidate;
  clientUrl: string;
};

export const BioPdfFooter = ({ clientUrl }: BioPdfFooterTypes) => {
  return (
    <Gutter top="32px" bottom="24px">
      <Grid name="BIO_PDF_FOOTER">
        <GridRow>
          <GridColumn>
            <Paragraph name="INFO">
              To view the most up-to-date matches and project information, visit
              <a href={clientUrl} data-test="LINK">
                {' '}
                client.apps.axiomlaw.com
              </a>{' '}
              and sign in. This talent may not have been contacted regarding
              this opportunity. If chosen, we'll verify availability and
              interest directly with them.
            </Paragraph>
          </GridColumn>
        </GridRow>
      </Grid>
    </Gutter>
  );
};
