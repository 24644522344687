import { CandidateOpportunitiesConst } from '@axiom/const';

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;

export const poolContextMenuStatusLabels = {
  [CandidateStatuses.ShortList]: 'Short List',
  [CandidateStatuses.Suggested]: 'Suggest',
  [CandidateStatuses.RepBioShare]: 'Share Rep Bio',
  [CandidateStatuses.Removed]: 'Remove',
  [CandidateStatuses.InterestedRejected]: 'Interested - Reject',
  [OppCandMlRecStatuses.AiRemoved]: 'AI Match - Remove',
};

export const poolNotDraggableStatuses = [OppCandMlRecStatuses.AiRemoved];

export const positionContextMenuStatusLabels = {
  [CandidateStatuses.Cooled]: 'Cool',
  [CandidateStatuses.Interviewing]: 'Interview',
  [CandidateStatuses.Rejected]: 'Not Selected',
  [CandidateStatuses.Removed]: 'Remove',
  [CandidateStatuses.RepBioShare]: 'Share Rep Bio',
  [CandidateStatuses.Selected]: 'Select',
  [CandidateStatuses.ShortList]: 'Short List',
  [CandidateStatuses.Suggested]: 'Suggest',
  [CandidateStatuses.TalentOptOut]: 'Talent Opt Out',
  [CandidateStatuses.Warmed]: 'Warm',
  [CandidateStatuses.WarmedYes]: 'Opt Talent In',
  [CandidateStatuses.WarmedUnsure]: 'Warmed Unsure',
  [CandidateStatuses.InterestedRejected]: 'Interested - Reject',
};

export const positionDraggableStatuses = [
  CandidateStatuses.Suggested,
  CandidateStatuses.ShortList,
  CandidateStatuses.RepBioShare,
  CandidateStatuses.Warmed,
  CandidateStatuses.WarmedUnsure,
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Submitted,
  CandidateStatuses.Interviewing,
  CandidateStatuses.Rejected,
];
