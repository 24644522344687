import React, { useEffect, useState } from 'react';
import { exact, number, string } from 'prop-types';

import { formatDataTestId } from '../../utils/dataTest';

// Flip to true to see the value render in the UI and console.log
const DEBUG = false;

export const MultiLoadConfirm = ({ data, name }) => {
  const [done, setDone] = useState(false);
  let timeout = null;

  useEffect(() => {
    if (data.called === data.received) {
      /**
       * Using 'setTimeout' to push this action to the bottom of the JS stack
       * There are cases where the grid finishes loading
       * then makes one last round of requests
       * */
      timeout = setTimeout(() => {
        setDone(true);
      });
    } else if (done) {
      // No need to setDone if it's already false
      setDone(false);
    }

    return () => clearTimeout(timeout);
  }, [data.called, data.received, timeout]);

  // This is expected to log per the DEBUG option
  // eslint-disable-next-line no-console
  if (DEBUG) console.log(done);

  return (
    <div
      data-test={formatDataTestId(
        `multi_loader_confirmation${name ? '_' : ''}${name}`
      )}
      data-ready={done}
    >
      {DEBUG && JSON.stringify(done)}
    </div>
  );
};

MultiLoadConfirm.defaultProps = {
  name: null,
};

MultiLoadConfirm.propTypes = {
  data: exact({
    called: number.isRequired,
    received: number.isRequired,
  }).isRequired,
  name: string,
};
