import { arrayOf, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getCandidateSearchesByUserId } from '../api/searches';

class UserCandidateFiltersStoreClass extends GenericStore {
  load(userId) {
    return this.setState(
      getCandidateSearchesByUserId(userId, this.getType()).then(userFilters =>
        userFilters.map(item => ({
          id: item.id,
          name: item.name,
          filters: item.search,
        }))
      )
    );
  }

  getType() {
    return 'candidate';
  }

  getDataShape() {
    return arrayOf(shape({}));
  }
}

export const UserCandidateFiltersStore = new UserCandidateFiltersStoreClass();
