import styled from 'styled-components';
import { CheckmarkCircleIcon } from '@axiom/ui';

import { getThemeProp } from '../../themes';

export const OTPositionWrapper = styled.div`
  border-top: 0.0625rem solid ${getThemeProp('textMuted')};
`;
export const OTPositionHeader = styled.button`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 1rem 1rem 1rem 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTPositionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const OTPositionHeaderLeftCheckmark = styled(CheckmarkCircleIcon)`
  width: 1.875rem;
  height: 1.9375rem;
  margin-right: 1rem;
`;
export const OTPositionHeaderRight = styled.div`
  flex: 0;
`;
export const OTPositionBody = styled.div``;
export const OTPositionTalentDrawer = styled.div`
  overflow: visible;
`;
