import get from 'lodash/get';
import memoize from 'lodash/memoize';

import GenericStore from '../classes/generic-store';
import { UsersShape } from '../models/users';
import { getUsers } from '../api/users-api';

class PreloadedUsersClass extends GenericStore {
  load() {
    return this.setState(getUsers().then(response => response.data));
  }

  getDataShape() {
    return UsersShape;
  }

  selectData(state) {
    return get(this.select(state), 'data', []);
  }

  organizeUsersById = memoize(users =>
    users.reduce((data, item) => {
      if (!data[item.id]) {
        data[item.id] = item;
      }
      return data;
    }, {})
  );

  // TODO: deprecate, I put it in so I don't have to run down this rabbit hole.
  // used by envoy/src/redux/selectors/users.ts
  selectDataForById(state) {
    // We use a memoized reduce (organizeUsersById) because this is being used
    // as a selector. That means that we need to preserve reference equality as long
    // as the data is constant, or we'll get huge amounts of unnecessary re-renders
    // that slow things down.
    // The true solution, per the above comment, is to remove this entirely. This
    // is intended as a temporary fix.
    return this.organizeUsersById(this.selectData(state));
  }
}

export const PreloadedUsersStore = new PreloadedUsersClass();
