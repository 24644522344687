import styled from 'styled-components';

export const SessionExpirySignIn = styled.span`
  color: ${({ theme }) => theme.colors.brandAxiomOrange};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
