import React from 'react';
import { Field } from 'react-final-form';
import {
  Label,
  ExclamationMarkCircleIcon,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Button,
  Layout,
  CondensedLarge,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
  ReadonlyTextarea,
  CondensedSmall,
} from '@axiom/ui';

import { FFIndustryDropdown } from '../FFIndustryDropdown/FFIndustryDropdown';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { CheckboxWithLabel } from '../CheckboxWithLabel/CheckboxWithLabel';
import FFDateMonthYearPicker from '../FFDateMonthYearPicker/FFDateMonthYearPicker';
import FFDatePicker from '../FFDatePicker/FFDatePicker';
import { DateUtil } from '../../utils/date-util';
import { FormTalentExperienceStore } from '../../stores/form-talent-experience-store';
import { FFInput } from '../FFInput/FFInput';
import { FFCheckbox } from '../FFCheckbox/FFCheckbox';
import { FFLocationTypeahead } from '../FFLocationTypeahead/FFLocationTypeahead';
import { FFSkillsDropdown } from '../FFSkillsDropdown/FFSkillsDropdown';

import { ExperienceFormLocationWrapper } from './CandidateExperienceFormStyles';

const year = new Date().getFullYear();
const yearOptions = DateUtil.getYearOptionsByRange(year + 10, 1970);

export const CandidateExperienceForm = () => {
  return (
    <FormStateTreatment
      isModalState
      name="Edit_Candidate_Experience_Modal"
      formStore={FormTalentExperienceStore}
      renderLoadedView={({
        form,
        formData: {
          id,
          isAxiom,
          endDate,
          locationAddressComponents,
          locationLatitude,
          locationLongitude,
          locationName,
          locationPlaceId,
          description,
        },
        fireSubmit,
        fireCancel,
      }) => {
        return (
          <Modal size="large" name={id ? 'EDITEXPERIENCE' : 'ADDEXPERIENCE'}>
            <ModalHeader onClose={fireCancel}>
              {isAxiom
                ? 'Edit Axiom Experience'
                : `${id ? 'Edit' : 'Add'} Other Experience`}
            </ModalHeader>
            <ModalSection>
              <Grid>
                {isAxiom && (
                  <GridRow gutterBottom="16px">
                    <GridColumn width="12">
                      <Layout position="middle" horizontalGutter="8px">
                        <ExclamationMarkCircleIcon />
                        <CondensedLarge>
                          Some Axiom experience fields must be updated directly
                          in Salesforce.
                        </CondensedLarge>
                      </Layout>
                    </GridColumn>
                  </GridRow>
                )}
                <GridRow gutterBottom="16px">
                  <GridColumn width="6">
                    {isAxiom ? (
                      <Field
                        name="opportunity.jobName"
                        label="Opportunity"
                        component={FFInput}
                        disabled
                        allowNull
                      />
                    ) : (
                      <Field
                        name="externalOpportunityName"
                        label="Job Title"
                        component={FFInput}
                        allowNull
                      />
                    )}
                  </GridColumn>

                  <GridColumn width="6">
                    <Field
                      name="client"
                      label="Company"
                      component={FFInput}
                      disabled={isAxiom}
                    />
                    <Gutter bottom="8px" />
                    <Field
                      name="isConfidential"
                      id="isConfidential"
                      label="Confidential Company"
                      component={FFCheckbox}
                      type="checkbox" // only here because Field requires it
                    />
                  </GridColumn>
                </GridRow>

                {isAxiom ? (
                  <>
                    <GridRow gutterBottom="16px">
                      <GridColumn width="6">
                        <Field
                          name="startDate"
                          label="Start Date"
                          id="startDate"
                          disableParentScrolling={false}
                          component={FFDatePicker}
                        />
                      </GridColumn>
                      <GridColumn width="6">
                        <Field
                          name="endDate"
                          label="End Date"
                          id="endDate"
                          component={FFDatePicker}
                          allowNull
                          disableParentScrolling={false}
                          anchorDirection="right"
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow gutterBottom="16px">
                      <GridColumn width={4}>
                        <Field
                          name="opportunity.locationCityStateCountry"
                          label="Location"
                          component={FFInput}
                          disabled
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <Field
                          name="opportunity.position.worksite"
                          label="Site Preference"
                          component={FFInput}
                          disabled
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <Field
                          name="industryValue"
                          component={FFIndustryDropdown}
                        />
                      </GridColumn>
                    </GridRow>
                  </>
                ) : (
                  <>
                    <GridRow gutterBottom="16px">
                      <GridColumn width={6}>
                        <Field
                          name="startDate"
                          label="Start"
                          component={FFDateMonthYearPicker}
                          yearOptions={yearOptions}
                        />
                      </GridColumn>
                      <GridColumn width={6}>
                        <Field
                          name="endDate"
                          label="End"
                          component={FFDateMonthYearPicker}
                          yearOptions={yearOptions}
                          allowNull
                          disabled={endDate === null}
                        />
                        <Gutter bottom="8px">
                          <CheckboxWithLabel
                            name="currentlyWorkHere"
                            id="currentlyWorkHere"
                            label="I currently work here"
                            checked={endDate === null}
                            onValueChange={value => {
                              // eslint-disable-next-line no-unused-expressions
                              value
                                ? form.change('endDate', null)
                                : form.change(
                                    'endDate',
                                    DateUtil.formatAsDate(new Date())
                                  );
                            }}
                          />
                        </Gutter>
                      </GridColumn>
                    </GridRow>
                    <GridRow gutterBottom="16px">
                      <GridColumn width={12}>
                        <Field
                          name="industryValue"
                          component={FFIndustryDropdown}
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow gutterBottom="16px">
                      <GridColumn width={12}>
                        <Label>Location</Label>
                        <br />
                        <i>
                          Enter the name of the relevant office location. If the
                          exact address isn't found, enter the city and state.
                        </i>
                        <ExperienceFormLocationWrapper>
                          <Field
                            name="location"
                            component={FFLocationTypeahead}
                            defaultLocation={{
                              locationAddressComponents,
                              locationLatitude,
                              locationLongitude,
                              locationName,
                              locationPlaceId,
                            }}
                            allowNull
                          />
                        </ExperienceFormLocationWrapper>
                      </GridColumn>
                    </GridRow>
                  </>
                )}
                <GridRow gutterBottom="16px">
                  <GridColumn width={12}>
                    <Field
                      name="tags"
                      label="Skills"
                      component={FFSkillsDropdown}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={12}>
                    <Label htmlFor="description">Description</Label>
                    <Gutter bottom="8px" />
                    <ReadonlyTextarea value={description} />
                    <Gutter bottom="8px" />
                    <CondensedSmall>
                      Please update the description in Axiom for Talent
                    </CondensedSmall>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCELEXPERIENCEBUTTON"
                onClick={fireCancel}
                variation="outline"
              >
                Cancel
              </Button>
              <Button name="CANCELEXPERIENCEBUTTON" onClick={fireSubmit}>
                Save Experience
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};
