import styled from 'styled-components';
import { FlagIcon } from '@axiom/ui';

export const AFCFOMFlagIcon = styled(FlagIcon)`
  height: 1rem;
  visibility: ${props => (props.full ? 'visible' : 'hidden')};
  width: 0.75rem;
`;
export const AFCFOMFlagWrapper = styled.div`
  align-self: center;
  margin-right: 0.5rem;
`;

export const AFCFOMItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
