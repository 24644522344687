import keyBy from 'lodash/keyBy';
import { actionTypes } from 'redux-form';

import {
  DECORATED_OPPORTUNITY_LOADED,
  OPPORTUNITIES_SAVE_ERROR,
  OPPORTUNITIES_SAVE_SUCCESS,
  OPPORTUNITIES_SAVE,
  RELATED_OPPORTUNITIES_ERROR,
  RELATED_OPPORTUNITIES_LOAD,
  RELATED_OPPORTUNITIES_LOADED,
  USER_OPPORTUNITIES_LOADED,
} from '../constants';
import { JOBS_FORM as OPPORTUNITIES_FORM } from '../../api/constants';
import {
  OPPORTUNITY_FIELD_ENGAGEMENT_TYPE,
  OPPORTUNITY_FIELD_OWNER_USER_ID,
  OPPORTUNITY_FIELD_SALES_CX_LEAD_ID,
  OPPORTUNITY_FIELD_SALES_LEAD_ID,
  OPPORTUNITY_FIELD_STAGE,
} from '../../models/constants/opportunityFields';
import { stageToStatusGroupMap } from '../../models/opportunities';

const INITIAL_STATE = {
  loading: false,
  lastLocalUpdate: 0,
  error: undefined,
  currentPage: 1,
  totalPages: undefined,
  totalResults: undefined,
  saving: {},
  data: [],
  byId: {},
  related: {},
  loadingRelated: false,
};

const idFromObject = obj => (obj && typeof obj === 'object' ? obj.id : obj);

export const normalizeOpportunityData = opportunity => {
  /**
  This function should be applied to all incoming opportunities. It:

  1. Normalizes `engagementType` strings, because the database was
     never migrated, and contains several variations of each. Examples:
     * Bad: "Existing Engagement – Additional Attorney" (en dash)
     * Bad: "Existing Engagement - Additional Attorney" (regular dash)
     * Good: "Existing Engagement Additional Attorney" (no dash)

  2. Adds a `statusGroup` property, because doing this once in the
     reducer is more efficient and consistent than doing it repeatedly
     in every component or selector that needs it.

  3. Replaces nested user objects returned by some APIs with user ID strings.
  */
  if (
    !opportunity ||
    typeof opportunity !== 'object' ||
    Array.isArray(opportunity)
  ) {
    return {};
  }

  const type = opportunity[OPPORTUNITY_FIELD_ENGAGEMENT_TYPE];
  const stage = opportunity[OPPORTUNITY_FIELD_STAGE];

  return {
    ...opportunity,
    statusGroup: (stage && stageToStatusGroupMap[stage]) || null,
    [OPPORTUNITY_FIELD_ENGAGEMENT_TYPE]:
      typeof type === 'string'
        ? type
            .replace(/[^a-z ]/gi, '')
            .replace(/\s+/g, ' ')
            .trim()
        : null,
    [OPPORTUNITY_FIELD_OWNER_USER_ID]: idFromObject(
      opportunity[OPPORTUNITY_FIELD_OWNER_USER_ID]
    ),
    [OPPORTUNITY_FIELD_SALES_CX_LEAD_ID]: idFromObject(
      opportunity[OPPORTUNITY_FIELD_SALES_CX_LEAD_ID]
    ),
    [OPPORTUNITY_FIELD_SALES_LEAD_ID]: idFromObject(
      opportunity[OPPORTUNITY_FIELD_SALES_LEAD_ID]
    ),
    // TODO: temp till api change from opportunity to opportunity.
    opportunityName: opportunity.opportunityName || opportunity.jobName,
  };
};

const reducer = (state = INITIAL_STATE, { type, payload, meta }) => {
  switch (type) {
    case actionTypes.START_SUBMIT:
      return meta.form === OPPORTUNITIES_FORM
        ? { ...state, loading: true }
        : state;

    case actionTypes.STOP_SUBMIT:
      return meta.form === OPPORTUNITIES_FORM
        ? { ...state, loading: false }
        : state;

    case OPPORTUNITIES_SAVE:
      return {
        ...state,
        saving: { ...state.saving, [payload.id]: true },
      };

    case OPPORTUNITIES_SAVE_SUCCESS:
      return {
        ...state,
        saving: { ...state.saving, [payload.id]: false },
        byId: {
          ...state.byId,
          [payload.id]: normalizeOpportunityData(payload),
        },
      };

    case OPPORTUNITIES_SAVE_ERROR:
      return {
        ...state,
        saving: { ...state.saving, [payload.id]: false },
      };

    case DECORATED_OPPORTUNITY_LOADED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.opportunityId]: normalizeOpportunityData(
            payload.opportunity.data
          ),
        },
      };

    case USER_OPPORTUNITIES_LOADED: {
      const normalizedData = payload.data.map(normalizeOpportunityData);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(normalizedData, 'id'),
        },
      };
    }

    case RELATED_OPPORTUNITIES_LOAD:
      return {
        ...state,
        loadingRelated: true,
      };

    case RELATED_OPPORTUNITIES_ERROR:
      return {
        ...state,
        loadingRelated: false,
        error: payload,
      };

    case RELATED_OPPORTUNITIES_LOADED: {
      const { parent, related } = payload.data;

      return {
        ...state,
        loadingRelated: false,
        related: {
          ...state.related,
          [parent.id]: related.map(opportunity => opportunity.id),
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
