import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Badge,
  Banner,
  Button,
  Dropdown,
  Flashy,
  Form,
  Grid,
  GridRow,
  GridColumn,
  Gutter,
  Layout,
  LayoutItem,
} from '@axiom/ui';
import { Opportunity } from '@axiom/validation';
import { OpportunitiesConst } from '@axiom/const';

import { OpportunityStore } from '../../stores/opportunity-store';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { DateUtil } from '../../utils/date-util';
import { fullNameByIdSelector } from '../../redux/selectors/users';

const { TransactionSubType } = OpportunitiesConst;

const userOverrideValues = {
  hide: 'hide',
  show: 'show',
  auto: 'auto',
} as const;
const eoiValues = {
  no: 'no',
  yes: 'yes',
};
const formSchema = z.object({
  excludedFromFeedUserOverride: z.enum(
    Object.values(userOverrideValues) as NonEmptyArray<UserOverrideValue>
  ),
  allowInterest: z.enum(Object.values(eoiValues) as NonEmptyArray<EoiValue>),
});

type UserOverrideValue =
  (typeof userOverrideValues)[keyof typeof userOverrideValues];
type EoiValue = (typeof eoiValues)[keyof typeof eoiValues];

const effoTranslateForUi = (value: boolean) => {
  if (value === true) return userOverrideValues.hide;
  if (value === false) return userOverrideValues.show;
  return userOverrideValues.auto;
};
const effoTranslateForApi = (value: UserOverrideValue) => {
  if (value === userOverrideValues.hide) return true;
  if (value === userOverrideValues.show) return false;
  return null;
};

const eoiTranslateForApi = (value: EoiValue) => {
  if (value === eoiValues.yes) return true;
  if (value === eoiValues.no) return false;
  return null;
};

const getAutoVisibilityLabel = (opportunity: Opportunity) => {
  if (opportunity.confidential) {
    return 'Auto-selected: Hidden (this is a confidential opportunity)';
  }
  if (opportunity.transactionSubType === TransactionSubType.Backfill) {
    return 'Auto-selected: Hidden (this is a backfill opportunity)';
  }
  return 'Auto-selected: Shown (default option)';
};

export const OpportunityFeedVisibilityForm = ({
  opportunity,
}: {
  opportunity: Opportunity;
}) => {
  const [, setSearchParams] = useSearchParams();
  const names = useSelector(fullNameByIdSelector);
  const { positions } = opportunity;

  const emptyPosDesc = !positions?.[0]?.description?.trim();

  const openPositionDescription = (positionId: string) => {
    setSearchParams(`editPositionDescription=${positionId}`);
  };

  return (
    <Gutter top="36px">
      <Form
        name="OPPORTUNITY_FEED_VISIBILITY_FORM"
        schema={formSchema}
        submitOnChange
        initialValues={{
          excludedFromFeedUserOverride: effoTranslateForUi(
            opportunity.excludedFromFeedUserOverride
          ),
          allowInterest: opportunity.allowInterest
            ? eoiValues.yes
            : eoiValues.no,
        }}
        onSubmit={async formData => {
          const allowInterest = eoiTranslateForApi(formData.allowInterest);

          await OpportunityLegacyApi.updateOpportunity(opportunity.id, {
            excludedFromFeedUserOverride: effoTranslateForApi(
              formData.excludedFromFeedUserOverride
            ),
            ...(allowInterest !== null ? { allowInterest } : {}),
          });
          await OpportunityStore.load(opportunity.id);
          return true;
        }}
      >
        {() => {
          const isHiddenFromFeed =
            opportunity.excludedFromFeedUserOverride ??
            opportunity.transactionSubType === TransactionSubType.Backfill;
          return (
            <>
              {!isHiddenFromFeed &&
                positions?.length > 0 &&
                !positions[0].description?.trim() && (
                  <Gutter bottom="8px">
                    <Banner
                      impact="low"
                      type="critical"
                      name="ADD_POSITION_BANNER"
                    >
                      <Layout position="middle">
                        <LayoutItem hug>
                          Please add a position description in order to accept
                          expressions of interest.
                        </LayoutItem>
                        <LayoutItem>
                          <Button
                            name="SCROLL_TO_POS_DESC_BUTTON"
                            onClick={() =>
                              openPositionDescription(positions[0].id)
                            }
                          >
                            Add Now
                          </Button>
                        </LayoutItem>
                      </Layout>
                    </Banner>
                  </Gutter>
                )}
              <Layout horizontalGutter="8px">
                {opportunity.confidential && (
                  <LayoutItem bottomGutter="8px">
                    <Badge background="sky" name="CONFIDENTIAL_BADGE">
                      Confidential
                    </Badge>
                  </LayoutItem>
                )}
                {opportunity.transactionSubType ===
                  TransactionSubType.Backfill && (
                  <LayoutItem bottomGutter="8px">
                    <Badge background="sky" name="BACKFILL_BADGE">
                      Backfill
                    </Badge>
                  </LayoutItem>
                )}
              </Layout>
              <Grid columns={2}>
                <GridRow>
                  <GridColumn>
                    <Dropdown
                      name="excludedFromFeedUserOverride"
                      label="Show on Opportunity Feed?"
                      options={[
                        { label: 'Hide', value: userOverrideValues.hide },
                        { label: 'Show', value: userOverrideValues.show },
                        ...((opportunity.excludedFromFeedUserOverride ??
                          null) === null
                          ? [
                              {
                                label: getAutoVisibilityLabel(opportunity),
                                value: userOverrideValues.auto,
                              },
                            ]
                          : []),
                      ]}
                      displayKey="label"
                      valueKey="value"
                    />
                  </GridColumn>
                  <GridColumn>
                    <Dropdown
                      name="allowInterest"
                      label="Accept EOIs?"
                      options={[
                        { label: 'No', value: eoiValues.no },
                        { label: 'Yes', value: eoiValues.yes },
                      ]}
                      displayKey="label"
                      valueKey="value"
                      disabled={emptyPosDesc}
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
              {names[opportunity.excludedFromFeedUpdatedBy] &&
                opportunity.excludedFromFeedUpdatedAt && (
                  <>
                    <Gutter bottom="8px" />
                    <Flashy
                      name="EXCLUDED_FROM_FEED_USER_OVERRIDE_LAST_UPDATED"
                      color="textSubtle"
                      italic
                    >
                      Last updated{' '}
                      {DateUtil.displayDate(
                        opportunity.excludedFromFeedUpdatedAt
                      )}{' '}
                      by {names[opportunity.excludedFromFeedUpdatedBy]}
                    </Flashy>
                  </>
                )}
            </>
          );
        }}
      </Form>
    </Gutter>
  );
};
