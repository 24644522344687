import React from 'react';
import { Position, PracticeArea, Submission } from '@axiom/validation';

import { PracticeAreaUtil } from '../../../utils/practice-area-util';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { CondensedHeader } from '../../content/CondensedHeader/CondensedHeader';
import { CondensedLarge } from '../../content/CondensedLarge/CondensedLarge';
import { Visible } from '../../layout/Visible/Visible';
import { Gutter } from '../../layout/Gutter/Gutter';

const { getPracticeAreaAndSpecialty } = PracticeAreaUtil;

const durations = {
  zeroToSixMonths: '0-6 months',
  sixToTwelveMonths: '6-12 months',
  twelvePlusMonths: '12+ months',
  notSure: `I'm not sure`,
} as const;

type DurationValue = (typeof durations)[keyof typeof durations];

const getDurationGroup = (duration?: number): DurationValue => {
  let data: DurationValue = durations.notSure;

  if (duration) {
    if (duration < 6) {
      data = durations.zeroToSixMonths;
    } else if (duration < 12) {
      data = durations.sixToTwelveMonths;
    } else if (duration >= 12) {
      data = durations.twelvePlusMonths;
    }
  }

  return data;
};

const getBillingHoursPerWeek = (hours?: number) => {
  if (hours !== null) {
    return hours >= 40 ? 'Full-time' : hours;
  }

  return null;
};

export type ClientEngagementsDetailsContentTypes = {
  submission: Submission;
  practiceAreas: PracticeArea[];
};

export const ClientEngagementsDetailsContent = ({
  submission,
  practiceAreas,
}: ClientEngagementsDetailsContentTypes) => {
  const position: Position | null = submission?.positions[0] ?? null;
  const duration: DurationValue = getDurationGroup(
    position?.estimatedEngagementTotalMonths
  );
  const billingHoursPerWeek = getBillingHoursPerWeek(
    position?.billingHoursPerWeek
  );
  const { practiceArea, specialty } = getPracticeAreaAndSpecialty(
    practiceAreas,
    submission?.practiceArea?.id
  );
  const placeholder = '--';

  return (
    <>
      <Grid stackable name="ENGAGEMENT_DETAILS_CONTENT">
        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Engagement Length</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={2}>
            <CondensedLarge name="DURATION">{duration}</CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="8px" />
            </Visible>
          </GridColumn>
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Area of law</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4}>
            <CondensedLarge name="PRACTICE_AREA">
              {practiceArea?.name ?? placeholder}
            </CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="8px" />
            </Visible>
          </GridColumn>
        </GridRow>
        <GridRow columns={4} gutterBottom="4px">
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Hours per week</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={2}>
            <CondensedLarge name="HOURS_PER_WEEK">
              {billingHoursPerWeek ?? placeholder}
            </CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="12px" />
            </Visible>
          </GridColumn>
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Area of focus</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4}>
            <CondensedLarge name="SPECIALTY">
              {specialty?.name ?? placeholder}
            </CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="8px" />
            </Visible>
          </GridColumn>
        </GridRow>
        <GridRow columns={4}>
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Location</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={2}>
            <CondensedLarge name="WORKSITE">
              {position?.worksite?.replace('/', ' / ') ?? placeholder}
            </CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="12px" />
            </Visible>
          </GridColumn>
          <GridColumn largeScreenWidth={3}>
            <CondensedHeader>Years of experience</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4}>
            <CondensedLarge name="YOE">
              {position?.talentExperienceYears ?? placeholder}
            </CondensedLarge>
            <Visible only="mobile">
              <Gutter bottom="8px" />
            </Visible>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="16px" />
    </>
  );
};
