/**
 *
 * LoadingTreatment
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AxiomLogo } from '@axiom/ui';

import ProgressBar from '../ProgressBar/ProgressBar';

import { DEFAULT_MAX_LOAD_TIME } from './LoadingTreatmentConstants';
import { FrameBlocker, ContentWrapper } from './LoadingTreatmentStyles';

const INITIAL_STATE = {
  status: 0,
};

class LoadingTreatment extends Component {
  constructor(config) {
    super(config);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    // TODO: Scroll blocking starts here
    const intervalRate = this.props.maxLoadMilliseconds / 300;

    this.interval = setInterval(() => {
      const was = this.state.status;
      const willBe = was < 100 ? was + 1 : 0;
      this.setState({ status: willBe });
    }, intervalRate);
  }

  componentWillUnmount() {
    // TODO: Stop scroll blocking stops here
    clearInterval(this.interval);
  }

  render() {
    return (
      <FrameBlocker>
        <ContentWrapper>
          <AxiomLogo />
          <ProgressBar status={this.state.status} />
        </ContentWrapper>
      </FrameBlocker>
    );
  }
}

LoadingTreatment.defaultProps = {
  maxLoadMilliseconds: DEFAULT_MAX_LOAD_TIME,
};

LoadingTreatment.propTypes = {
  maxLoadMilliseconds: PropTypes.number,
};

export default LoadingTreatment;
