export const numberParse = val =>
  val || val === 0 ? parseInt(val.replace(/\D/g, ''), 10) : '';

export const percentageWithDecimals = val => {
  const stringVal = typeof val === 'number' ? String(val) : val;
  if (typeof stringVal !== 'string') return null;

  // Remove non decimals/numbers
  const onlyNumbersAndDecimal = stringVal.replace(/[^0-9.]/g, '');
  if (!onlyNumbersAndDecimal) return null;

  const hasDecimal = onlyNumbersAndDecimal.includes('.');
  if (!hasDecimal) return onlyNumbersAndDecimal.substring(0, 2);

  // Create an array with sections split by .
  const sections = onlyNumbersAndDecimal.split('.');
  const numbersAfterDecimal = (sections[1] || '').substring(0, 2);

  return `${sections[0].substring(0, 2)}.${numbersAfterDecimal}`;
};

export const formatPercentage = val => {
  const validInput = typeof val === 'number' && val !== 0;
  return validInput ? `${val}%` : null;
};

export const formatCurrency = (amount, symbol) => {
  const str = typeof amount === 'number' ? String(amount) : amount;
  if (typeof str !== 'string') return '';

  const cleaned = str.replace(/[^\d.]/g, '');
  if (cleaned.length === 0) return '';

  const parts = cleaned.split('.');
  // regex from https://stackoverflow.com/a/2901298
  const dollars = (parts[0] || '0').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const cents = `${parts[1] || '0'}00`.substr(0, 2);

  return symbol && typeof symbol === 'string'
    ? `${symbol} ${dollars}.${cents}`
    : `${dollars}.${cents}`;
};

export const formatCurrencyInput = amount => {
  const str = typeof amount === 'number' ? String(amount) : amount;
  if (typeof str !== 'string') return '';

  const cleaned = str.replace(/[^\d.]/g, '');
  if (cleaned.length === 0) return '';

  const parts = cleaned.split('.');
  const dollars = parts[0];
  if (parts.length < 2) return dollars;

  const cents = parts[1].substr(0, 2);
  return `${dollars}.${cents}`;
};
