/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string } from 'prop-types';

import { StyledHorizontalRule } from './HorizontalRuleStyles';

const HorizontalRule = props => (
  <StyledHorizontalRule aria-hidden="true" role="presentation" {...props} />
);

HorizontalRule.defaultProps = {
  marginBottom: '30px',
  marginTop: '30px',
};

HorizontalRule.propTypes = {
  marginBottom: string,
  marginTop: string,
};

export default HorizontalRule;
