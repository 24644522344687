import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import {
  Header3,
  Header5,
  DataTest,
  Button,
  FluidButtonLayout,
  Gutter,
} from '@axiom/ui';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import { FFInput } from '../FFInput/FFInput';
import { FormLawSchoolManagementStore } from '../../stores/form-law-school-management-store';
import { PreloadedLawSchoolRanksStore } from '../../stores/preloaded-law-school-ranks-store';

import {
  LawSchoolManagementFormRow,
  LawSchoolManagementFormColumn,
  LawSchoolManagementFormFormHeader,
} from './LawSchoolManagementFormStyles';

export const LawSchoolManagementFormComponent = ({ lawSchoolRanks }) => {
  return (
    <FormStateTreatment
      name="LAW_SCHOOL_MANAGEMENT"
      formStore={FormLawSchoolManagementStore}
      renderLoadedView={({ formData, fireSubmit, fireReset }) => {
        return (
          <DataTest value="LAW_SCHOOL_MANAGEMENT_FORM">
            <LawSchoolManagementFormFormHeader>
              <Header3>{formData.id ? 'Edit School' : 'Add School'}</Header3>
            </LawSchoolManagementFormFormHeader>
            <LawSchoolManagementFormRow divider>
              <LawSchoolManagementFormColumn>
                <Header5>School Name</Header5>
              </LawSchoolManagementFormColumn>
              <LawSchoolManagementFormColumn>
                <Field name="name" component={FFInput} />
              </LawSchoolManagementFormColumn>
            </LawSchoolManagementFormRow>
            <LawSchoolManagementFormRow divider>
              <LawSchoolManagementFormColumn>
                <Header5>School Rank</Header5>
              </LawSchoolManagementFormColumn>
              <LawSchoolManagementFormColumn>
                <Field
                  name="tags"
                  component={FFDropdown}
                  options={lawSchoolRanks}
                />
              </LawSchoolManagementFormColumn>
            </LawSchoolManagementFormRow>
            <Gutter top="16px" horizontal="32px">
              <FluidButtonLayout>
                <Button name="CANCEL" onClick={fireReset} variation="outline">
                  Cancel
                </Button>
                <Button name="SUBMIT" onClick={fireSubmit}>
                  Save
                </Button>
              </FluidButtonLayout>
            </Gutter>
          </DataTest>
        );
      }}
    />
  );
};

LawSchoolManagementFormComponent.propTypes = {
  lawSchoolRanks: PreloadedLawSchoolRanksStore.getDataShape().isRequired,
};

LawSchoolManagementFormComponent.propTypes = {
  lawSchoolRanks: PreloadedLawSchoolRanksStore.getDataShape().isRequired,
};

export default connect(
  state => ({
    lawSchoolRanks: PreloadedLawSchoolRanksStore.selectAsOptions(state),
  }),
  {}
)(LawSchoolManagementFormComponent);
