import styled from 'styled-components';

import { getEmploymentStylesByStatus } from '../../utils/color';

export const JPHBanner = styled.div`
  background-color: ${({ statusName }) =>
    getEmploymentStylesByStatus(statusName).background};
  border: 2px solid
    ${({ statusName }) => getEmploymentStylesByStatus(statusName).border};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const JPHBannerLeft = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
export const JPHBannerStageIcon = styled.div`
  padding-top: 0.25rem;
  background: ${({ statusName }) =>
    getEmploymentStylesByStatus(statusName).color};
  color: ${({ statusName }) =>
    getEmploymentStylesByStatus(statusName).background};
  min-height: 2.75rem;
  min-width: 2.75rem;
  margin: 0.1875rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const JPHBannerStageIconLabel = styled.div`
  font-size: 0.6875rem;
  font-weight: bold;
`;
export const JPHBannerStageIconValue = styled.div`
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding-bottom: 5px;
`;
export const JPHBannerLabelWrapper = styled.div`
  padding: 0rem 0.5rem;
  display: inline-block;
  color: #fff;
`;
