import React, { useState } from 'react';
import { Field } from 'react-final-form';
import {
  Button,
  FluidButtonLayout,
  Gutter,
  Header3,
  Header5,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import { FFInput } from '../FFInput/FFInput';
import { formatDataTestId } from '../../utils/dataTest';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormTagsManagementStore } from '../../stores/form-tag-management-store';

import { TMFWrapper, FormHeader } from './TagManagementFormStyles';

const TagManagementForm = () => {
  const [shouldConfirmSave, setShouldConfirmSave] = useState(false);

  const handleSave = (formData, fireSubmit) => {
    if (formData && formData.id) {
      setShouldConfirmSave(true);
    } else {
      fireSubmit();
    }
  };
  const handleConfirmSave = fireSubmit => {
    setShouldConfirmSave(false);
    fireSubmit();
  };

  const handleDismissConfirmation = () => {
    setShouldConfirmSave(false);
  };

  return (
    <FormStateTreatment
      name="TAG_FORM_TREATMENT"
      formStore={FormTagsManagementStore}
      renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
        <TMFWrapper data-test={formatDataTestId('TagManagementForm')}>
          <FormHeader>
            <Header3>{formData.id ? 'Edit Tag' : 'Create New Tag'}</Header3>
          </FormHeader>
          <Gutter horizontal="24px" vertical="24px">
            <Layout horizontalGutter="8px">
              <LayoutItem>
                <Header5>{formData.id ? 'Tag' : 'New Tag'}</Header5>
              </LayoutItem>
              <LayoutItem fluid>
                <Field name="name" component={FFInput} />
              </LayoutItem>
            </Layout>

            <Gutter bottom="16px" />

            <FluidButtonLayout>
              {formData.id && (
                <Button
                  onClick={() => {
                    fireCancel();
                  }}
                  variation="outline"
                >
                  Cancel
                </Button>
              )}
              <Button onClick={() => handleSave(formData, fireSubmit)}>
                Save
              </Button>
            </FluidButtonLayout>
          </Gutter>

          {shouldConfirmSave && (
            <Modal size="confirm">
              <ModalHeader onClose={handleDismissConfirmation}>
                Edit Tag
              </ModalHeader>
              <ModalSection>
                <Paragraph>
                  You have selected to edit this tag. Doing so will edit it on
                  all opportunities, talent profiles and saved searches where
                  this has been selected.
                </Paragraph>
              </ModalSection>
              <ModalFooter>
                <Button variation="outline" onClick={handleDismissConfirmation}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleConfirmSave(fireSubmit);
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </TMFWrapper>
      )}
    />
  );
};

TagManagementForm.defaultProps = {};

TagManagementForm.propTypes = {};

export default TagManagementForm;
