import FormStore from '../classes/form-store';
import { opportunityShape } from '../models/opportunities';
import { AjvUtil } from '../utils/ajv-util';
import { patchOpportunity } from '../api/opportunities-legacy-api';

import { OpportunityStore } from './opportunity-store';

const FormOpportunityProfileNextStepsDescriptionStoreValidation =
  AjvUtil.compileSchema({
    properties: {
      nextStep: {
        type: 'string',
        maxLength: 255,
      },
      description: {
        type: 'string',
      },
    },
  });

class FormOpportunityProfileNextStepsDescriptionStoreClass extends FormStore {
  getDataShape() {
    return opportunityShape;
  }

  load(opportunity = {}) {
    return this.setState({
      id: opportunity.id || null,
      nextStep: opportunity.nextStep || '',
      description: opportunity.description || '',
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormOpportunityProfileNextStepsDescriptionStoreValidation,
      {
        ...formData,
      }
    );
  }

  submit(changedField, formData) {
    return this.clearState(
      patchOpportunity(formData.id, changedField).then(() =>
        OpportunityStore.load(formData.id)
      )
    );
  }
}

// eslint-disable-next-line max-len
export const FormOpportunityProfileNextStepsDescriptionStore =
  new FormOpportunityProfileNextStepsDescriptionStoreClass();
