import React from 'react';
import {
  arrayOf,
  bool,
  oneOfType,
  string,
  node,
  func,
  shape,
} from 'prop-types';
import { connect } from 'react-redux';

import BannerContainer from '../components/Banner/Banner';
import { Header } from '../components/Header/Header';
import AppErrors from '../components/AppErrors/AppErrors';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { hasInitialDataSelector } from '../redux/selectors/app';

import { ErrorBoundaryLayout } from './ErrorBoundaryLayout/ErrorBoundaryLayout';
import { AppDetailWrapper, DetailWrapper, DetailArea } from './styles';

/* eslint-disable no-nested-ternary */
const DetailComponent = ({
  wide,
  apiErrors,
  children,
  hasInitialData,
  showGreyBackground,
}) => (
  <AppDetailWrapper>
    {hasInitialData ? (
      <>
        <AppErrors />
        <Header />
        <BannerContainer />
        <DetailWrapper data-test="DETAIL_WRAPPER" wide={wide}>
          <DetailArea
            data-test="DETAIL_AREA"
            showGreyBackground={showGreyBackground}
          >
            {children}
          </DetailArea>
        </DetailWrapper>
      </>
    ) : apiErrors.filter(error => !!error).length > 0 ? (
      <ErrorBoundaryLayout />
    ) : (
      <LoadingTreatment maxLoadMilliseconds={30000} />
    )}
  </AppDetailWrapper>
);
/* eslint-enable no-nested-ternary */

DetailComponent.defaultProps = {
  wide: false,
  apiErrors: [],
  hasInitialData: false,
  children: null,
  showGreyBackground: false,
};

DetailComponent.propTypes = {
  wide: bool,
  apiErrors: arrayOf(shape({})),
  children: oneOfType([string, node, func]),
  hasInitialData: bool,
  showGreyBackground: bool,
};
// HMR does not work well with composition function, need to split these
// into their own variables so they can be replaced by updates.
const ConnectHOC = connect(hasInitialDataSelector);
export default ConnectHOC(DetailComponent);
