import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Gutter,
  Modal,
  ModalSection,
  ModalFooter,
  ModalHeader,
  Button,
  CondensedLabel,
} from '@axiom/ui';

import { FFRichTextEditor } from '../FFRichTextEditor/FFRichTextEditor';
import { EditAutoWarmingModalStore } from '../../stores/edit-auto-warming-modal-store';

const AutoWarmingModal = ({ form }) => {
  const fireClose = () => EditAutoWarmingModalStore.close();

  const fireCancel = () => EditAutoWarmingModalStore.cancel();

  const fireSubmit = data => EditAutoWarmingModalStore.save(data);

  const fireValidate = data => EditAutoWarmingModalStore.validate(data);

  return (
    <Form
      onSubmit={fireSubmit}
      validate={fireValidate}
      initialValues={form.data}
      render={({ handleSubmit }) => (
        <Modal>
          <ModalHeader onClose={fireClose}>Warming Talent</ModalHeader>

          <ModalSection>
            <div data-test="AUTO_WARMING_FORM">
              <Gutter bottom="16px">
                <CondensedLabel>
                  We'll send the following message to this individual to let
                  them know they've been warmed. To customize the message, edit
                  the text below before you click Send. Message text cannot be
                  blank.
                </CondensedLabel>
              </Gutter>
              <Field
                component={FFRichTextEditor}
                name="candidateStatusMessage"
                showResetButton
                maxLength={1000}
              />
            </div>
          </ModalSection>
          <ModalFooter>
            <Button
              className="warming-talent-action"
              onClick={fireCancel}
              variation="outline"
            >
              Cancel
            </Button>
            <Button className="warming-talent-action" onClick={handleSubmit}>
              Send
            </Button>
          </ModalFooter>
        </Modal>
      )}
    />
  );
};

AutoWarmingModal.propTypes = {
  form: EditAutoWarmingModalStore.getDataShape().isRequired,
};

export default AutoWarmingModal;
