import React from 'react';
import { SubmissionCandidate } from '@axiom/validation';

import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { Gutter } from '../../layout/Gutter/Gutter';

export type TalentEducationTypes = {
  candidate: SubmissionCandidate;
};

export const TalentEducation = ({ candidate }: TalentEducationTypes) => (
  <>
    {candidate?.degrees?.length === 0 && (
      <Paragraph name="NO_EDUCATION">--</Paragraph>
    )}
    {candidate?.degrees?.map(
      ({ institution, degree, yearAwarded, areaOfStudy }, index) => (
        <div
          data-test="EDUCATION_DEGREE"
          key={`${yearAwarded}_${institution}_${degree}_${areaOfStudy?.name}`}
        >
          <ParagraphHeader name="DEGREE_INSTITUTION">
            {institution}
          </ParagraphHeader>

          <Paragraph name="EDUCATION">
            {[degree, areaOfStudy?.name, yearAwarded]
              .filter(Boolean)
              .join(', ')}
          </Paragraph>
          {index < candidate.degrees.length - 1 && <Gutter bottom="16px" />}
        </div>
      )
    )}
  </>
);
