/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { connect } from 'react-redux';

import MultiSelect from '../MultiSelect/MultiSelect';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';

const rolesFilter = new Set(['EnvoyAdmin', 'EnvoyUser', 'EnvoySales']);
const UserMultiSelect = props => <MultiSelect {...props} />;

/**
 * Only certain user types should be selectable in Envoy. This is here so we can't select
 * LPUsers
 */
export default connect(state => ({
  options: PreloadedUsersStore.selectData(state)
    .filter(user => user.roles.some(role => rolesFilter.has(role)))
    .map(user => ({
      label: user.fullName,
      value: user.id,
    })),
}))(UserMultiSelect);
