import { arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { deleteTag, getTags } from '../api/tags';
import { TagShape } from '../models/tag';

class TagsStoreClass extends GenericStore {
  getDataShape() {
    return arrayOf(TagShape);
  }

  load() {
    return this.setState(
      getTags().then(response =>
        response.data.map(d => ({
          value: d.id,
          label: d.name,
          type: d.type,
        }))
      )
    );
  }

  delete(id) {
    return this.watchState(deleteTag(id).then(() => this.load()));
  }

  selectData(state) {
    return super.select(state).data || [];
  }

  selectById(state, id) {
    return this.select(state).find(tag => tag.value === id);
  }

  selectOptions(state) {
    return {
      options: this.selectData(state),
      tagType: 'tag',
    };
  }
}

export const PreloadedTagsStore = new TagsStoreClass();
