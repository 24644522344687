import React from 'react';
import styled from 'styled-components';
import { shape, bool, func } from 'prop-types';

import { FFError, isErroring } from '../FFError/FFError';
import LocationTypeahead, {
  defaultLocationShape,
} from '../LocationTypeahead/LocationTypeahead';

const FlowDivWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const FFLocationTypeahead = ({
  input,
  meta,
  defaultLocation,
  onValueChange,
  remainOpen,
}) => (
  <>
    <LocationTypeahead
      invalid={isErroring(meta)}
      onChange={loc => {
        input.onChange(loc);
        onValueChange(loc);
      }}
      defaultLocation={defaultLocation}
      useReactFinalForm
      remainOpen={remainOpen}
    />
    <FlowDivWrapper>
      <FFError finalFormElementMeta={meta} />
    </FlowDivWrapper>
  </>
);

FFLocationTypeahead.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  defaultLocation: defaultLocationShape,
  onValueChange: func,
  remainOpen: bool,
};

FFLocationTypeahead.defaultProps = {
  defaultLocation: undefined,
  onValueChange: () => false,
  remainOpen: false,
};
