import styled from 'styled-components';

import { getThemeProp } from '../themes';

const percentWidth = {
  double: '50%',
  full: '100%',
  normal: '25%',
  third: '33.33333334%',
  triple: '75%',
};

export const ErrorMessage = styled.div`
  color: ${getThemeProp('negativeColor')};
  margin: 10px 0 0;
`;

export const FormRow = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  margin-left: -40px;

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    flex: 0 0 ${percentWidth.normal};
    margin-bottom: 0;
    padding-left: 40px;
    width: ${percentWidth.normal};
  }

  & .DateInput,
  & .DatePicker,
  & .SingleDatePickerInput {
    display: block;
    width: 100%;
  }

  label + div > div:first-child > .DatePicker:first-child {
    margin-top: 5px;
  }
`;

export const FormCell = styled.div`
  ${FormRow} > && {
    flex: 0 0
      ${({ width }) => (width && percentWidth[width]) || percentWidth.normal};
    padding-left: 40px;
    width: ${({ width }) =>
      (width && percentWidth[width]) || percentWidth.normal};
  }

  & > * {
    width: 100% !important;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

export const Fieldset = styled.fieldset`
  border: 0;
  display: block;
  margin: 40px 0;
  padding: 0;
  position: relative;

  &:first-child {
    margin-top: 0;
  }
`;
