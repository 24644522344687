import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';
import { getThemeProp } from '../../themes';

export const TypeaheadModalItem = styled.div`
  color: ${getThemeProp('darkBlue')};
  cursor: pointer;

  border-bottom: 1px solid ${getThemeProp('colorSecondary')};
  padding: 16px 8px;

  &:hover,
  &:focus-within {
    color: white;
    background-color: ${GENERAL_STYLES_MAP.DROPDOWN.background};
    border-bottom: 1px solid inherit;
  }
`;
