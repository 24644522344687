import { bool, number, shape, string, exact } from 'prop-types';
import { getPreferredWeeklyHoursSliderMaxValue } from '@axiom/utils';

import FormStore from '../classes/form-store';
import { patchTalent } from '../api/talent';
import { getDecoratedTalent } from '../redux/actions/talent';

class FormTalentProfileAvailabilityEditClass extends FormStore {
  getDataShape() {
    return shape({
      minMaxWeeklyHours: exact({
        min: exact({
          value: number,
          label: string,
        }),
        max: exact({
          value: number,
          label: string,
        }),
      }).isRequired,
      id: string,
      preferredWeeklyMaxHours: number.isRequired,
      isOpenToOtherWork: bool.isRequired,
      isOpenToMultipleClients: bool.isRequired,
      daysSinceStatusChange: number.isRequired,
      // counselWorkPreference: string.isRequired,
      noticePeriod: string.isRequired,
      profileStatus: string.isRequired,
      availabilityPreferencesUpdatedByType: string.isRequired,
    });
  }

  load(candidate = {}) {
    const { desiredWeeklyMaxHours, maxHours: maxHoursFromWorkday } = candidate;
    return this.setState({
      minMaxWeeklyHours: {
        min: {
          value: 0,
        },
        max: {
          value: getPreferredWeeklyHoursSliderMaxValue(candidate),
        },
      },
      maxHoursFromWorkday,
      preferredWeeklyMaxHours: desiredWeeklyMaxHours ?? maxHoursFromWorkday,
      id: candidate.id || null,
      isOpenToOtherWork: candidate.isOpenToOtherWork,
      isOpenToMultipleClients: candidate.isOpenToMultipleClients,
      daysSinceStatusChange: candidate.daysSinceStatusChange,
      // counselWorkPreference: candidate.counselWorkPreference,
      noticePeriod: candidate.noticePeriod,
      profileStatus: candidate.profileStatus,
      availabilityPreferencesUpdatedAt:
        candidate.availabilityPreferencesUpdatedAt,
      availabilityPreferencesUpdatedByType:
        candidate.availabilityPreferencesUpdatedByType,
      calculatedDisplayName: candidate.calculatedDisplayName,
      calculatedFirstName: candidate.calculatedFirstName,
    });
  }

  validate() {
    return true;
  }

  submit(changedFields, formData) {
    const submitData = {};

    submitData.desiredWeeklyMaxHours = changedFields?.preferredWeeklyMaxHours;
    submitData.isOpenToOtherWork = changedFields.isOpenToOtherWork;
    submitData.isOpenToMultipleClients = changedFields.isOpenToMultipleClients;
    // submitData.counselWorkPreference = changedFields.counselWorkPreference;

    return this.watchState(patchTalent(formData.id, submitData)).then(() =>
      this.dispatch(getDecoratedTalent(formData.id))
    );
  }
}

export const FormTalentProfileAvailabilityEditStore =
  new FormTalentProfileAvailabilityEditClass();
