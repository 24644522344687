import React from 'react';
import { connect } from 'react-redux';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import TypeaheadModal from '../TypeaheadModal/TypeaheadModal';
import { AppFindOppForCandidateModalStore } from '../../stores/app-find-opp-for-candidate-modal-store';

const AppFindOppForCandidateModal = ({ findOppState }) => {
  const onSelect = (storeData, oppId) => {
    AppFindOppForCandidateModalStore.submit(storeData, oppId);
  };

  const onCancel = () => {
    AppFindOppForCandidateModalStore.clearState();
  };

  return (
    <StoreStateTreatment
      isModalState
      showInitialLoader={false}
      showRefetchLoader={false}
      name="FIND_OPP_FOR_CANDIDATE_STATE"
      storeState={findOppState}
      renderLoadedView={({ data }) => (
        <TypeaheadModal
          storeState={findOppState}
          onSearch={(searchTerm, page) =>
            AppFindOppForCandidateModalStore.findCandidates(searchTerm, {
              ...data,
              page,
              results: page === 1 ? [] : data.results,
            })
          }
          onCancel={onCancel}
          totalResultsCount={data.totalResultsCount}
          itemRender={opp => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              key={opp.id}
              onClick={() => {
                onSelect(data, opp.id);
              }}
            >
              {opp.jobName}
            </div>
          )}
          title="Search Opportunities"
          searchTerm={data.searchTerm}
          results={data.results}
          page={data.page}
        />
      )}
    />
  );
};

AppFindOppForCandidateModal.propTypes = {
  findOppState: AppFindOppForCandidateModalStore.getStateShape().isRequired,
};

export default connect(state => ({
  findOppState: AppFindOppForCandidateModalStore.select(state),
}))(AppFindOppForCandidateModal);
