import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

import OpportunityDetails from '../OpportunityDetails/OpportunityDetails';
import { getDecoratedTalent } from '../../redux/actions/talent';
import { getDecoratedOpportunity } from '../../redux/actions/opportunities';
import { TalentProfile } from '../TalentProfile/TalentProfile';
import { formatDataTestId } from '../../utils/dataTest';

export const SideDrawerContentsComponent = ({
  openDrawer,
  closeDrawer,
  expanded,
  getDecoratedTalent: getDecoratedTalentAction,
  getDecoratedOpportunity: getDecoratedOpportunityAction,
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const isTalentView =
    pathname.startsWith('/talent') || pathname.startsWith('/bench');
  const isOpportunityView =
    pathname.startsWith('/opp') || pathname.startsWith('/workspace/opp');

  useEffect(() => {
    openDrawer();
    if (isTalentView) {
      getDecoratedTalentAction(id);
    } else if (isOpportunityView) {
      getDecoratedOpportunityAction(id);
    }
  }, [id]);

  return (
    <>
      {isTalentView && (
        <TalentProfile
          expanded={expanded}
          onCloseSidedrawer={closeDrawer}
          candidateId={id}
        />
      )}
      {isOpportunityView && (
        <OpportunityDetails
          expanded={expanded}
          onCloseSidedrawer={closeDrawer}
        />
      )}
      {!id && (
        <p data-teat={formatDataTestId('no side drawer content')}>
          Please select a record from the list.
        </p>
      )}
    </>
  );
};

SideDrawerContentsComponent.propTypes = {
  expanded: bool.isRequired,
  openDrawer: func.isRequired,
  closeDrawer: func.isRequired,
  getDecoratedOpportunity: func.isRequired,
  getDecoratedTalent: func.isRequired,
};

export const SideDrawerContents = connect(null, {
  getDecoratedTalent,
  getDecoratedOpportunity,
})(SideDrawerContentsComponent);
