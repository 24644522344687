import styled, { css } from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const ManageAccountsFormWrapper = styled.div``;

export const ManageAccountsFormHeader = styled.div`
  background: ${getThemeProp('appBackground')};
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  svg {
    display: block;
  }
`;

export const ManageAccountsRow = styled.div`
  display: flex;
  margin: 0 32px;

  ${props =>
    props.divider &&
    css`
      border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
    `};

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const ManageAccountsColumn = styled.div`
  min-width: 40%;
  padding: 24px 24px 24px 0;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-child(2) {
    min-width: 60%;
    padding-right: 0;
  }
`;

export const ManageAccountsEmpty = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
`;

export const ManageAccountsAssociatedUsersSearchRow = styled(ManageAccountsRow)`
  align-items: center;
`;

export const ManageAccountsContactName = styled.p`
  margin-top: 0;
`;

export const ManageAccountsAccountDetails = styled.dl`
  width: 100%;
  flex-basis: 100%;
  margin: 0;

  dt,
  dd {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  dt {
    margin-top: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid ${({ theme }) => theme.neutrals.darkest};
  }

  dd {
    padding-left: 0.5rem;
    font-weight: bold;
  }
`;

export const ManageAccountsEmailColumn = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ManageAccountsEmail = styled.p`
  margin: 0;
  overflow-wrap: anywhere;
  padding-right: 1rem;
`;
