import styled from 'styled-components';

import { UnstyledButton } from '../../styled';

export const StyledListButton = styled(UnstyledButton)`
  display: block;
  text-align: left;
  width: 100%;

  &:hover {
    color: white !important;
  }

  .search-result {
    margin-bottom: 0;
    line-height: 1.6rem;
  }
`;
