import React from 'react';
import { string, number, func, bool, oneOfType } from 'prop-types';
import get from 'lodash/get';
import { DataTest, Label } from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';
import {
  DropdownOptionsShape,
  DropdownSelectedValueShape,
} from '../../models/dropdown-options';

import {
  DropdownListWrapper,
  KendoDropdownListWrapper,
  LabelWrapper,
} from './DropdownListStyles';

const DropdownList = props => {
  const OptionComponent = (li, itemProps) => {
    const itemChildren = (
      <LabelWrapper data-value={itemProps.dataItem.value}>
        {li.props.children}
      </LabelWrapper>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleOnChange = event => {
    props.onChange(event.target.value.value);
    props.onChangeAction(event.target.value.value);
  };

  const handleOnBlur = event => {
    props.onBlur(get(event.target.value, 'value', null));
  };

  const getValueFromOptions = options =>
    options.find(option => option.value === props.value) || null;

  const { options } = props;
  const val = getValueFromOptions(options);

  return (
    <DataTest value={props.id || props.name}>
      {props.label && (
        <Label invalid={!props.valid} htmlFor={props.id || props.name}>
          {props.label}
        </Label>
      )}
      <DropdownListWrapper
        disabled={props.disabled}
        invalid
        data-test={formatDataTestId('dropdownlist')}
        data-value={val?.value}
        id={props.id}
      >
        <KendoDropdownListWrapper
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          valid={props.valid}
          className={props.className}
          data={options}
          dataItemKey={props.dataItemKey}
          defaultItem={{ label: props.placeholder }}
          disabled={props.disabled}
          itemRender={OptionComponent}
          onFocus={props.onFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          textField={props.textField}
          value={val}
          popupSettings={{ animate: false }}
        />
      </DropdownListWrapper>
    </DataTest>
  );
};

DropdownList.defaultProps = {
  className: null,
  options: [],
  dataItemKey: 'value',
  disabled: false,
  id: null,
  label: null,
  name: undefined,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  onChangeAction: () => {},
  placeholder: '',
  textField: 'label',
  valid: true,
  value: undefined,
  type: undefined,
};

DropdownList.propTypes = {
  className: string,
  options: DropdownOptionsShape,
  dataItemKey: string,
  disabled: bool,
  id: string,
  label: string,
  name: string,
  placeholder: oneOfType([string, number]),
  onFocus: func,
  onBlur: func,
  onChange: func,
  onChangeAction: func,
  textField: string,
  valid: bool,
  value: DropdownSelectedValueShape,
  type: string,
};

export default DropdownList;
