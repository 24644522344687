import styled from 'styled-components';

export const FFMSWrapper = styled.div`
  color: ${props => (props.disabled ? '#d3d3d3' : 'inherit')};
  margin-bottom: 25px;
  width: 100%;
`;

export const FFMSErrorWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;
