import React from 'react';
import { CondensedLarge, Gutter } from '@axiom/ui';
import { PostMlRecommendedResponseCandidate } from '@axiom/validation';

export const AiDuplicatesMessage: React.FC<{
  duplicates: PostMlRecommendedResponseCandidate[];
}> = ({ duplicates }) => (
  <div data-test="ai-duplicates-message">
    <CondensedLarge>
      <Gutter bottom="24px">
        The AI has also identified the following talent as a good match:
      </Gutter>
      {duplicates.map(({ id, calculatedDisplayName }) => (
        <div key={id}>
          <a href={`/talent-detail/${id}`} target="_blank" rel="noreferrer">
            {calculatedDisplayName}
          </a>
        </div>
      ))}
    </CondensedLarge>
  </div>
);
