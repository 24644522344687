import styled from 'styled-components';

import { getThemeProp } from '../../themes';
// eslint-disable-next-line boundaries/element-types
import { MultiSelectWrapper } from '../MultiSelect/MultiSelectStyles';

export const AttachFileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  #file-upload {
    display: none;
  }
`;

export const RichTextEditorWrapper = styled.div`
  margin-top: ${({ isEnabled }) => (isEnabled ? '1rem' : '0.25rem')};

  .buttonContainer {
    background-color: ${getThemeProp('appBackground')};
    border-bottom: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
    padding-top: 2px;
    margin-top: -4px;
    margin-bottom: 1rem;
  }
  .editors {
    background-color: ${({ isEnabled }) =>
      isEnabled ? '#fff' : getThemeProp('appBackground')};
    border: solid 1px black;
    line-height: 1;
    height: ${({ height }) => height || 'calc(100vh - 400px)'};
    width: 100%;
    resize: none;
    display: inline-block;
    overflow-y: auto;
    padding: 10px;

    li {
      list-style: disc;
    }

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      height: 100%;
    }
  }
`;

export const UpperContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
`;

export const LowerContentWrapper = styled.div`
  .activity-description {
    height: 20rem;
  }
  ${MultiSelectWrapper} {
    margin-bottom: 1rem;
    margin-top: 1rem;
    & > * {
      padding: 15px 15px 10px 15px;
      border: 1px solid #000000;
    },
  }
`;

export const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #e08d1f;
  border-radius: 6px;
  padding: 3px;
  margin-bottom: 1.5em;
`;

export const Label = styled.label`
  position: relative;
  margin-right: 10px;
  font-style: italic;
  color: #ffffff;
  :before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    display: inline-block;
    margin-right: 10px;
    top: 2px;
    left: 2px;
    position: relative;
  }
`;

export const Input = styled.input`
  margin: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
`;

export const StyledSvgCheck = styled.svg`
  fill: ${getThemeProp('primaryText')};
  width: 20px;
  position: absolute;
  top: 0px;
  left: 4px;
`;

export const StyledSvgDash = styled.svg`
  fill: ${getThemeProp('primaryText')};
  width: 20px;
  position: absolute;
  top: 11px;
  left: 8px;
  .cls-1 {
    fill: #0d0d0d;
  }
`;
