import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CondensedLarge,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  ContextMenuItem,
  FluidButtonLayout,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  SectionHeader,
} from '@axiom/ui';

import { notifications } from '../../models/notifications';
import {
  getNotificationsPoll,
  putNotificationRead,
  putAllNotificationRead,
} from '../../redux/actions/notifications';

import {
  NotificationsIconWrapper,
  NotificationsIconBadge,
} from './NotificationsStyles';
import NotificationsMessage from './NotificationsMessage';
import formatNotificationBody from './NotificationsUtils';

const NOTIFICATIONS = 'NOTIFICATIONS';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: false,
      gettingMore: false,
      paginationIndex: 1,
      done: false,
    };

    this.interval = null;
    this.scollContainerRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldUpdate =
      !props.list ||
      !state.list ||
      props.hasUnread !== state.hasUnread ||
      props.list.length !== state.list.length;

    if (!shouldUpdate) {
      let pCounter = 0;
      let sCounter = 0;

      props.list.forEach(item => {
        pCounter += item.isRead ? 1 : 2;
      });
      state.list.forEach(item => {
        sCounter += item.isRead ? 1 : 2;
      });

      shouldUpdate = pCounter !== sCounter;
    }

    if (shouldUpdate) {
      const obj = state.userAction ? state : props;
      const done = state.userAction ? state.done : false;

      return {
        ...state,
        hasUnread: obj.hasUnread,
        list: obj.list ? obj.list.map(item => ({ ...item })) : [],
        done,
      };
    }

    return null;
  }

  componentDidMount() {
    this.toggleInterval(false);
    window.addEventListener('click', this.clickOff);
  }

  componentWillUnmount() {
    this.toggleInterval(true);
    this.setState({ showList: false });
    window.removeEventListener('click', this.clickOff);
  }

  handleNotificationClick = () => {
    this.setState(
      prev => ({
        ...prev,
        showList: !prev.showList,
        paginationIndex: 1,
        gettingMore: false,
        scrollableAmount: 0,
        userAction: true,
      }),
      this.toggleInterval
    );
  };

  handleMarkReadClick = id => {
    const list = this.state.list.map(item => ({
      ...item,
      isRead: item.id === id ? true : item.isRead,
    }));
    const hasUnread = !!list.find(item => !item.isRead);

    this.setState(
      prev => ({
        ...prev,
        hasUnread,
        list,
        userAction: true,
      }),
      () => this.props.dispatch(putNotificationRead(id))
    );
  };

  handleMarkAllReadClick = () => {
    this.setState(prev => ({
      ...prev,
      hasUnread: false,
      list: prev.list.map(item => ({
        ...item,
        isRead: true,
        userAction: true,
      })),
    }));

    this.props.dispatch(putAllNotificationRead(this.props.userId));
  };

  handleMessageScroll = () => {
    if (!this.state.gettingMore) {
      const { userId } = this.props;
      const { paginationIndex } = this.state;
      const nextIndex = paginationIndex + 1;

      this.setState({ paginationIndex: nextIndex, gettingMore: true }, () => {
        fetch(`/api/users/${userId}/notifications/?page=${nextIndex}`)
          .then(response => response.json())
          .then(response => {
            const {
              _meta: { resultCount, maxPerPage, totalUnreadCount },
              data,
            } = response;

            this.setState(prev => ({
              ...prev,
              list: [...prev.list, ...formatNotificationBody(data)],
              done: !!(resultCount < maxPerPage),
              userAction: true,
              hasUnread: !!totalUnreadCount,
            }));
          })
          .finally(this.setState({ gettingMore: false }));
      });
    }
  };

  toggleInterval = overRide => {
    if (overRide || this.state.showList) {
      clearInterval(this.interval);
      this.interval = null;
    } else {
      const { dispatch, userId } = this.props;

      this.interval = setInterval(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ userAction: false }, () => {
          dispatch(
            getNotificationsPoll({
              userId,
              page: 1,
            })
          );
        });
      }, 6e4);
    }
  };

  clickOff = e => {
    if (this.state.showList) {
      let isNotifications = false;
      let currentDom = e.target;

      for (let i = 0; i < 10; i += 1) {
        if (!currentDom || currentDom.tagName === 'BODY') break;
        if (currentDom.dataset.container === NOTIFICATIONS) {
          isNotifications = true;
          break;
        }

        currentDom = currentDom.parentNode;
      }

      if (!isNotifications) {
        this.handleNotificationClick();
      }
    }
  };

  render() {
    const { hasUnread, list } = this.state;

    return (
      <>
        <ContextMenu
          direction="left"
          fullscreenOn="smallScreen"
          anchor={
            <NotificationsIconWrapper>
              <IconButton
                name="PENDO-NOTIFICATIONS-LARGE-SCREEN"
                icon="bell"
                variation="minimal"
                pattern="secondary"
                onClick={this.handleNotificationClick}
              />

              {hasUnread > 0 && <NotificationsIconBadge />}
            </NotificationsIconWrapper>
          }
        >
          <ContextMenuHeader>
            <Gutter horizontal="4px" top="8px">
              <Layout position="middle" horizontalGutter="8px">
                <LayoutItem fluid>
                  <SectionHeader>Notifications</SectionHeader>
                </LayoutItem>
                {list?.length > 0 && (
                  <Button
                    onClick={this.handleMarkAllReadClick}
                    variation="minimal"
                    pattern="secondary"
                    name="notificationsMarkAllAsReadButton"
                  >
                    Read All
                  </Button>
                )}
              </Layout>
            </Gutter>
          </ContextMenuHeader>
          <ContextMenuDivider />
          {list && list.length > 0 ? (
            list.map(message => (
              <NotificationsMessage
                notification={message}
                markAllNotificationsRead={this.handleMarkReadClick}
                key={message.id}
              />
            ))
          ) : (
            <Gutter horizontal="16px" bottom="24px">
              <Layout position="center">
                <CondensedLarge>No Notifications</CondensedLarge>
              </Layout>
            </Gutter>
          )}
          {list?.length > 0 && (
            <>
              <ContextMenuDivider />
              <ContextMenuItem>
                <FluidButtonLayout>
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.handleMessageScroll();
                      return false;
                    }}
                  >
                    Load More
                  </Button>
                </FluidButtonLayout>
              </ContextMenuItem>
            </>
          )}
        </ContextMenu>
      </>
    );
  }
}

Notifications.propTypes = notifications;

Notifications.defaultProps = {
  list: [],
};

const mapStateToProps = state => state.notifications;

export default connect(mapStateToProps)(Notifications);
