export const isEnvoyAdmin = user => user.roles?.includes('EnvoyAdmin');
export const isEnvoyUser = user => user.roles?.includes('EnvoyUser');
export const isSalesUser = user => user.roles?.includes('EnvoySales');
export const isLPUser = user => user.roles?.includes('LPUser');
export const isClientUser = user => user.roles?.includes('ClientUser');

export const isAHQUser = user =>
  isEnvoyAdmin(user) || isEnvoyUser(user) || isSalesUser(user);

export const isAFTUser = user =>
  isEnvoyAdmin(user) || isEnvoyUser(user) || isLPUser(user);

export const isAFCUser = user =>
  isEnvoyAdmin(user) ||
  isEnvoyUser(user) ||
  isSalesUser(user) ||
  isClientUser(user);

export const shouldRouteUserToWorkspace = user => isSalesUser(user);

export const isTalentSuccessUser = user =>
  isEnvoyAdmin(user) || isEnvoyUser(user);
