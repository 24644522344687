import { Api } from '@axiom/ui';
import { Account } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AccountsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  updateAccount(accountId: Account['id'], body: Account) {
    return super.write<{ data: Account }>({
      endpoint: `/accounts/${accountId}`,
      body,
      method: 'PATCH',
    });
  }
}

export const AccountsApi = new AccountsApiClass();
