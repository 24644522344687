import fGet from 'lodash/fp/get';
import { createSelector } from 'reselect';

import { PreloadedUserStore } from '../../stores/preloaded-user-store';

export const loadingSelector = createSelector(
  fGet('app.appLoading'),
  isLoading => ({ isLoading })
);

export const hasInitialDataSelector = createSelector(
  fGet('app.apiErrors'),
  fGet('app.hasInitialData'),
  fGet('app.sideBarActive'),
  fGet('app.sideDrawerActive'),
  state => PreloadedUserStore.select(state),
  (apiErrors, hasInitialData, sideBarActive, sideDrawerActive, user) => ({
    apiErrors,
    hasInitialData,
    sideBarActive,
    sideDrawerActive,
    user,
  })
);

export const apiErrorsSelector = createSelector(
  fGet('app.apiErrors'),
  apiErrors => ({ apiErrors })
);

export const sideBarSelector = createSelector(
  fGet('app.sideDrawerExpanded'),
  fGet('app.sideBarExpanded'),
  (sideDrawerIsExpanded, sideBarIsExpanded) => ({
    sideDrawerIsExpanded,
    sideBarIsExpanded,
  })
);
