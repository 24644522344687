import APIHelper from './APIHelper';

/**
 * This is displayed to user so needs to be talent, not candidate
 */
const api = new APIHelper('opportunity talent');

export const getCandidatesByJobId = async opportunityId => {
  const response = await api.GET(`/opportunities/${opportunityId}/candidates`);
  return { data: response.data };
};

export const postTalentsByOpportunityID = async ({
  opportunityId,
  candidateIds,
}) => {
  const { data } = await api.POST(
    `/opportunities/${opportunityId}/candidates`,
    {
      candidateIds: [...candidateIds],
    }
  );
  return { data };
};

export const updateOpportunityCandidate = async (opportunityId, candidate) => {
  const { data } = await api.PATCH(
    `/opportunities/${opportunityId}/candidates/${candidate.id}`,
    candidate
  );
  return { data };
};

export const updateTalentStatusByOpportunity = async ({
  opportunityId,
  candidateId,
  candidateStatus,
  contractedEndDate,
  candidateStatusMessage,
}) => {
  const payload = {
    candidateStatus,
    contractedEndDate,
    candidateStatusMessage,
  };
  const { data } = await api.PATCH(
    `/opportunities/${opportunityId}/candidates/${candidateId}`,
    payload
  );
  return { data };
};
