/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import { bool, func, number, oneOfType, string, node } from 'prop-types';

import { ErrorMessage } from '../../styled/form';
import InputCharRemaining from '../InputCharRemaining/InputCharRemaining';
import { formatDataTestId } from '../../utils/dataTest';
import { FlexDiv } from '../../themes/components';

import {
  StyledWrapper,
  StyledInput,
  StyledLabel,
  StyledAftIcon,
} from './InputStyles';

const Input = ({
  className,
  disabled,
  domID,
  error,
  label,
  name,
  normalize,
  placeholder,
  submitFailed,
  value,
  showAftIcon,
  showCharsRemainingLabel,
  maxLength,
  errorMessageNode,
  invalid,
  onChange,
  type,
  skipNormalize,
  defaultValue,
  ...rest
}) => {
  /*
  Use of localValue is necessary because at least one component,
  OpportunityProfilePosition, does not track the value and pass it in as a prop.
  This component relies on the native form state tracking.  This does
  not work if we are also presenting character limits in the DOM in a unified
  approach.  Therefore keeping a local state for this component is necessary.
  */
  const [localValue, setLocalValue] = useState(defaultValue);
  const handleChange = event => {
    if (!skipNormalize) {
      event.target.value = normalize(event.target.value);
    }
    if (onChange) {
      onChange(event);
    } else {
      setLocalValue(event.target.value);
    }
  };

  const id = domID || name;
  const isInvalid = (error && submitFailed) || (invalid && submitFailed);
  const errorID = `${id}-error`;

  const currentValue =
    value === undefined || value === '' || value === null ? localValue : value;

  return (
    <StyledWrapper
      className={className}
      isInvalid={isInvalid}
      isDisabled={disabled}
    >
      {!rest.hidden && (
        <FlexDiv>
          {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
          {label && showAftIcon && <StyledAftIcon />}
        </FlexDiv>
      )}
      <StyledInput
        {...rest}
        type={type}
        aria-invalid={isInvalid}
        aria-describedby={isInvalid ? errorID : null}
        disabled={disabled}
        invalid={isInvalid}
        id={id}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={currentValue}
        data-test={formatDataTestId(id || 'input')}
        maxLength={maxLength}
      />
      {!rest.hidden && !disabled && (
        <InputCharRemaining
          showCharsRemainingLabel={showCharsRemainingLabel}
          maxLength={maxLength}
          value={currentValue}
          type={type}
          errorMessageNode={
            errorMessageNode ||
            (isInvalid && <ErrorMessage id={errorID}>{error}</ErrorMessage>)
          }
        />
      )}
    </StyledWrapper>
  );
};

Input.defaultProps = {
  className: null,
  disabled: false,
  domID: null,
  error: undefined,
  label: null,
  name: null,
  normalize: value => value,
  onChange: null,
  placeholder: null,
  submitFailed: false,
  value: '',
  defaultValue: '',
  showAftIcon: false,
  showCharsRemainingLabel:
    InputCharRemaining.defaultProps.showCharsRemainingLabel,
  maxLength: InputCharRemaining.defaultProps.maxLength,
  errorMessageNode: null,
  invalid: false,
  type: null,
  skipNormalize: false,
};

Input.propTypes = {
  className: string,
  disabled: bool,
  domID: string,
  error: string,
  label: string,
  name: string,
  normalize: func,
  onChange: func,
  placeholder: string,
  submitFailed: bool,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  showAftIcon: bool,
  showCharsRemainingLabel: bool,
  maxLength: number,
  errorMessageNode: node,
  invalid: bool,
  type: string,
  skipNormalize: bool,
};

export default Input;
