import {
  DECORATED_OPPORTUNITY_LOADED,
  OPPORTUNITY_COLLABORATORS_LOADED,
} from '../constants';

export const INITIAL_STATE = {
  byOpportunityId: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPPORTUNITY_COLLABORATORS_LOADED:
      return {
        ...state,
        byOpportunityId: {
          ...state.byOpportunityId,
          [payload.id]: payload.data,
        },
      };
    case DECORATED_OPPORTUNITY_LOADED:
      return {
        ...state,
        byOpportunityId: {
          ...state.byOpportunityId,
          [payload.opportunityId]: payload.collaborators.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
