import React, { useState } from 'react';
import { useApi } from '@axiom/ui';
import { CandidatesConst } from '@axiom/const';

import { UserBenchFilterType } from '../../api/user-talent-api';
import { UsersApi } from '../../api/users-api';

import { BenchViewGrid } from './BenchViewGrid';

export const BenchView = () => {
  const [{ data: user }] = useApi(UsersApi.getSessionUser());
  const [filters, setFilters] = useState<UserBenchFilterType>({
    type: 'owner',
    profileStatus: [
      CandidatesConst.ProfileStatuses.Active,
      CandidatesConst.ProfileStatuses.Beach,
      CandidatesConst.ProfileStatuses.PendingActive,
      CandidatesConst.ProfileStatuses.PendingBeach,
      CandidatesConst.ProfileStatuses.Waitlist,
    ],
  });

  return (
    <BenchViewGrid
      filters={filters}
      onFilterChanges={newFilters => {
        setFilters(f => ({
          ...f,
          ...newFilters,
        }));
      }}
      user={user}
    />
  );
};
