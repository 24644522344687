import React from 'react';
import {
  DateUtil,
  Header4,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  useApi,
} from '@axiom/ui';
import { Candidate, User } from '@axiom/validation';

import HorizontalRule from '../HorizontalRule/HorizontalRule';
import { CalendarApi } from '../../api/calendar-api';
import { CandidateCalendarMenu } from '../CandidateCalendarMenu/CandidateCalendarMenu';
import { isSalesUser } from '../../utils/roles';

export const TalentInterviewAvailability = ({
  candidate,
  user,
}: {
  candidate: Candidate;
  user: User;
}) => {
  const [{ data: calendarData } = { data: {} }] = useApi(
    candidate?.calendar?.id && CalendarApi.readCalendar(candidate.calendar.id)
  );

  return (
    <>
      {!isSalesUser(user) && (
        <>
          <Layout name="INTERVIEW_AVAILABILITY_CONTAINER">
            <LayoutItem fluid>
              <Header4 name="INTERVIEW_AVAILABILITY_HEADER">
                Interview Availability
              </Header4>
              {calendarData.freeTimeUpdatedAt && (
                <Paragraph name="LAST_UPDATED">
                  Last Updated{' '}
                  {DateUtil.displayDatestamp(calendarData.freeTimeUpdatedAt)}
                </Paragraph>
              )}
            </LayoutItem>
            <LayoutItem position="right">
              <CandidateCalendarMenu
                user={user}
                candidate={candidate}
                icon={
                  <IconButton
                    icon="calendar"
                    variation="minimal"
                    pattern="secondary"
                  />
                }
              />
            </LayoutItem>
          </Layout>
          <HorizontalRule />
        </>
      )}
    </>
  );
};
