import React from 'react';
import { connect } from 'react-redux';
import { Banner, Gutter, Tab, Tabs } from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { OpportunitiesContextMenu } from '../OpportunitiesContextMenu/OpportunitiesContextMenu';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { OpportunityStore } from '../../stores/opportunity-store';
import { OpportunityProfilePositionDeleteModal } from '../OpportunityProfilePositionDeleteModal/OpportunityProfilePositionDeleteModal';
import { DeletePositionStore } from '../../stores/delete-position-store';

import { OpportunityProfilePositionsTabsWrapper } from './OpportunityProfilePositionsStyled';
import OpportunityProfilePosition from './OpportunityProfilePosition';

const OpportunityProfilePositions = ({
  opportunityPositionsState,
  opportunityState,
  deletePositionState,
}) => (
  <OpportunityProfilePositionsTabsWrapper>
    <StoreStateTreatment
      name="OpportunityProfilePositions"
      storeState={[opportunityState, opportunityPositionsState]}
      renderLoadedView={({ data: [opportunity, positions] }) => (
        <Tabs
          startTab="Positions"
          fluid
          appendToRight={
            <span>
              {positions.length > 0 && (
                <OpportunitiesContextMenu
                  opportunity={opportunity}
                  opportunityPositions={positions}
                />
              )}
            </span>
          }
        >
          <Tab label={`Positions (${positions.length})`} name="Positions">
            <Gutter top="8px" />
            {positions.length ? (
              positions.map((position, i) => (
                <OpportunityProfilePosition
                  displayIndex={i + 1}
                  position={position}
                  currency={opportunity.currency}
                  key={position.id}
                  data-test="POSITION_CARD"
                  isLast={i === positions.length - 1}
                  isFulfillmentActive={opportunity.isFulfillmentActive}
                />
              ))
            ) : (
              <Banner type="info" name="NO_POSITIONS">
                Currently no available positions
              </Banner>
            )}
          </Tab>
        </Tabs>
      )}
    />
    <StoreStateTreatment
      isModalState
      storeState={deletePositionState}
      renderLoadedView={({ data }) => (
        <OpportunityProfilePositionDeleteModal
          closeModal={() => DeletePositionStore.clearState()}
          submit={() => DeletePositionStore.delete(data)}
        />
      )}
    />
  </OpportunityProfilePositionsTabsWrapper>
);

OpportunityProfilePositions.propTypes = {
  opportunityState: OpportunityStore.getStateShape().isRequired,
  opportunityPositionsState:
    OpportunityPositionsStore.getStateShape().isRequired,
  deletePositionState: DeletePositionStore.getStateShape().isRequired,
};

const mapStateToProps = state => ({
  opportunityState: OpportunityStore.select(state),
  opportunityPositionsState: OpportunityPositionsStore.select(state),
  deletePositionState: DeletePositionStore.select(state),
});

export default connect(mapStateToProps)(OpportunityProfilePositions);
