import React from 'react';
import { useNavigate } from 'react-router';
import { ContextMenu, ContextMenuItem } from '@axiom/ui';
import { OpportunityCandidate } from '@axiom/validation';

import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EventSubjectConst } from '../../stores/events-store';

export const TalentOpportunityCardContextMenu = ({
  opportunityCandidate,
}: {
  opportunityCandidate: OpportunityCandidate;
}) => {
  const navigate = useNavigate();
  const { candidateId } = opportunityCandidate;
  const { opportunityId } = opportunityCandidate;
  return (
    <ContextMenu
      name={`${opportunityId}-TALENT_OPP_CARD_MENU`}
      direction="left"
    >
      <ContextMenuItem
        name="OPEN_OPPORTUNITY"
        onClick={() => {
          navigate(`/opportunity-detail/${opportunityId}`);
        }}
      >
        Open Opportunity
      </ContextMenuItem>
      <ContextMenuItem
        name="ADD_ACTIVITY"
        onClick={() => {
          AppEventItemModalStore.openModal(EventSubjectConst.Candidate, {
            opportunityId,
            candidateId,
          });
        }}
      >
        Add Activity
      </ContextMenuItem>
    </ContextMenu>
  );
};
