import { Api } from '@axiom/ui';
import { Candidate, Experience, ExperienceEdit } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateExperiencesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readExperiences(candidateId: Candidate['id']) {
    return super.read<{ data: Candidate['experiences'] }>({
      endpoint: `/candidates/${candidateId}/experiences`,
      method: 'GET',
    });
  }

  refreshExperiences(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}/experiences`);
  }

  createExperience(candidateId: Candidate['id'], body: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/candidates/${candidateId}/experiences`,
      body,
      method: 'POST',
    });
  }
}

export const CandidateExperiencesApi = new CandidateExperiencesApiClass();
