import styled from 'styled-components';
import { FlagIcon, Label, Text } from '@axiom/ui';

import { GENERAL_STYLES_MAP, SIDEBAR_PADDING } from '../../themes/constants';

export const DetailContainer = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const DetailColumn = styled.div`
  border-left: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  flex: 0 1 50%;
  margin: ${SIDEBAR_PADDING}px 0 0 0;
  max-width: 50%;
  padding: 0 ${SIDEBAR_PADDING}px 2rem ${SIDEBAR_PADDING}px;

  &:first-child {
    border-left: 0;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const DrawerContainer = styled.div`
  margin-top: ${SIDEBAR_PADDING}px;
`;

export const FlagIconWrapper = styled(FlagIcon)`
  vertical-align: middle;
  margin-right: 0.5rem;
  height: 1.0625rem;
`;

export const SpanTalentFullName = styled.span`
  vertical-align: middle;
`;

export const TPDFRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${props => (props.noCells ? 0 : '-1.25rem')};

  &.padding {
    padding-top: 10px;
  }

  &.margin {
    margin-top: 3rem;
  }

  &.multi-item-spacing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 0.75rem;
  }
`;

export const TPDCell = styled.div`
  flex: 0 0 auto;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem;
  width: ${({ isDetail }) => (isDetail ? '50%' : '33%')};

  &.last-avail-update-container {
    h3 {
      margin-left: -35px;
    }
    .bottom-row {
      display: flex;
    }
    .single-row {
      display: flex;
      margin-top: 4px;
    }
  }

  &.radio-slide-cell {
    padding-right: 3rem;
  }
`;

export const BreakCell = styled.div`
  flex: 0 0 100%;
`;

export const WideCell = styled.div`
  flex: 1 1 auto;
  min-width: ${({ isDetail }) => (isDetail ? '50%' : '33%')};
  margin-bottom: 1.25rem;
  padding-left: 1.25rem;
`;

export const TPDFTitle = styled(Label)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.no-margin {
    margin-bottom: 0;
  }
`;

export const TPDAvailabilityForm = styled.div`
  .radio-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer-row {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 12px;
      padding: 0.35rem;
    }
  }

  .preferred-weekly-hours-container {
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export const TPDFPreferredWeeklyHours = styled.strong`
  color: ${({ theme }) => theme.colors.axiomOrange};
`;

export const TPDFFlexGrowContainer = styled.div`
  flex: 1;
  margin-left: 4.25rem;
`;

export const TPDFAvailUpdateText = styled(Text)`
  line-height: 1rem;
`;

export const EmailButtonWrapper = styled.div`
  display: flex;

  .mail-icon {
    border: 1px solid ${GENERAL_STYLES_MAP.AXIOM.border};
    border-radius: 0.25rem;
    padding: 0.125rem;
    margin-right: 1rem;
  }
`;
