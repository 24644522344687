import { LanguageConst } from '@axiom/const';

import { selectifyObject } from '../../utils/selectify';

export const languageSkillItems = selectifyObject(
  LanguageConst.LANGUAGE_SKILLS
);
export const languageProficiencyItems = selectifyObject(
  LanguageConst.LANGUAGE_PROFICIENCIES
);
export const languageCEFRItems = selectifyObject(
  LanguageConst.LANGUAGE_CEFR_LEVELS
);

export const LANGUAGE_CEFR_DOM_ID = 'language_cefr';
export const LANGUAGE_DOM_ID = 'language';
export const LANGUAGE_PROFICIENCY_DOM_ID = 'language_procifiency';
export const LANGUAGE_SKILL_DOM_ID = 'language_skill';
