import React, { useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import OpportunityDetails from '../components/OpportunityDetails/OpportunityDetails';
import DetailLayout from '../layouts/detail';
import { getDecoratedOpportunity } from '../redux/actions/opportunities';
import { makeOpportunitiesViewSelector } from '../redux/selectors/opportunitiesProfileComponentSelector';
import { Page } from '../components/Page/Page';
import { UserSettingsUtil } from '../utils/user-settings-util';

export const OpportunityDetailPageComponent = ({
  getDecoratedOpportunity: getDecoratedOpportunityAction,
  user,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getDecoratedOpportunityAction(id);
  }, [id]);

  return (
    <Page>
      <DetailLayout
        user={user}
        wide
        showGreyBackground={UserSettingsUtil.shouldShowNewOpportunityTalentView(
          user.userSettings
        )}
      >
        <OpportunityDetails expanded isDetail onCloseSidedrawer={() => {}} />
      </DetailLayout>
    </Page>
  );
};

OpportunityDetailPageComponent.defaultProps = {
  user: null,
};

OpportunityDetailPageComponent.propTypes = {
  getDecoratedOpportunity: func.isRequired,
  user: shape({
    activeDirectorySecurityGroup: string,
  }),
};

const mapDispatchToProps = {
  getDecoratedOpportunity,
};

export const OpportunityDetailPage = connect(
  makeOpportunitiesViewSelector,
  mapDispatchToProps
)(OpportunityDetailPageComponent);
