/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { number, string, bool, func } from 'prop-types';
import { Draggable as DNDDraggable } from 'react-beautiful-dnd';

export default function Draggable({
  render,
  id,
  i,
  isDragDisabled,
  className,
  ...props
}) {
  return (
    <DNDDraggable draggableId={id} index={i} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={className}
          ref={provided.innerRef}
        >
          {React.createElement(render, {
            ...props,
            placeholder: provided.placeholder,
            provided,
            snapshot,
          })}
        </div>
      )}
    </DNDDraggable>
  );
}

Draggable.defaultProps = {
  isDragDisabled: false,
  className: null,
};
Draggable.propTypes = {
  render: func.isRequired,
  isDragDisabled: bool,
  className: string,
  id: string.isRequired,
  i: number.isRequired,
};
