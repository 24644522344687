import {
  Brief,
  CondensedLabel,
  CondensedSmall,
  DateUtil,
  Flashy,
  Gutter,
  Paragraph,
} from '@axiom/ui';
import { CandidateWhiteboard } from '@axiom/validation';
import React from 'react';

export const TalentAvailabilityNotesDisplay = ({
  note,
}: {
  note: CandidateWhiteboard;
}) => {
  return (
    <>
      <Gutter vertical="8px">
        {!note.body && !note.updatedByUser?.fullName && !note.updatedAt ? (
          <CondensedLabel name="placeholder">
            Enhance this profile with a helpful note about the talent
          </CondensedLabel>
        ) : (
          <Brief briefMaxHeight="140px" pattern="secondary">
            <Paragraph name="note">{note.body}</Paragraph>
          </Brief>
        )}
      </Gutter>

      {(note.updatedByUser?.fullName || note.updatedAt) && (
        <Gutter vertical="8px">
          <CondensedSmall name="lastEdited">
            <Flashy bold>Last Edited:</Flashy>
            {` ${
              note.updatedByUser?.fullName
                ? `${note.updatedByUser?.fullName}, `
                : ''
            } ${DateUtil.displayTimestamp(note.updatedAt || '')}`}
          </CondensedSmall>
        </Gutter>
      )}
    </>
  );
};
