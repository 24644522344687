import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

const getBorderColor = (invalid, readOnly) => {
  if (invalid) return `0.0625rem solid ${GENERAL_STYLES_MAP.ERROR.border}`;
  if (readOnly) return 'none';
  return '0.0625rem solid black';
};

export const DivRichTextEditorWrapper = styled.div``;
export const DivEditorWrapper = styled.div`
  border: ${({ readOnly, invalid }) => getBorderColor(invalid, readOnly)};
  padding: ${({ readOnly }) => (readOnly ? '0' : '0.5rem 1rem')};
  font-size: 1rem;
  line-height: 1.5rem;

  ol,
  ul {
    list-style: initial;
  }

  .editors,
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: ${({ readOnly }) => (readOnly ? 'auto' : '100%')};
    min-height: ${({ readOnly }) => (readOnly ? 'auto' : '10rem')};
  }

  ${({ hidePlaceHolder }) =>
    hidePlaceHolder
      ? '.public-DraftEditorPlaceholder-root {display: none;}'
      : null}
`;

export const DivButtonsWrapper = styled.div`
  background-color: ${getThemeProp('appBackground')};
  border-right: 0.0625rem solid black;
  border-bottom: 0.0625rem solid black;
  border-left: 0.0625rem solid black;
`;

export const ButtonUlToggleWrapper = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none !important;
`;

export const ResetButton = styled.button`
  float: right;
  height: 47px;
  border: none;
  background: transparent;
  padding: 1px 7px 2px;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
    margin-bottom: -2px;
  }
`;
