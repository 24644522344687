import React from 'react';
import {
  CaretIcon,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  ContextMenuItem,
  DataTest,
  IconButton,
} from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { shape } from 'prop-types';
import { candidateOpportunityStatusUtils } from '@axiom/utils';

import Droppable from '../Droppable/Droppable';
import { window } from '../../utils/global';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';
import { formatDataTestId } from '../../utils/dataTest';
import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EventSubjectConst } from '../../stores/events-store';
import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { RejectionModal } from '../RejectionModal/RejectionModal';

import { OpportunityTalentAiMatchControl } from './OpportunityTalentAiMatchControl';
import { isCandidateDraggable } from './OpportunityTalentUtils';
import { poolContextMenuStatusLabels } from './OpportunityTalentConstants';
import OpportunityTalentCard from './OpportunityTalentCard';
import {
  OTDraggableCardWrapper,
  OTDropzonePlaceholder,
  OTDropzonePlaceholderButton,
} from './OpportunityTalentStyles';
import {
  OTPoolWrapper,
  OTPoolHeader,
  OTPoolHeaderLeft,
  OTPoolHeaderRight,
  OTPoolBody,
  OTPoolTalentDrawer,
} from './OpportunityTalentPoolStyles';

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;

class OpportunityTalentPool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: true,
      isRejectionModalOpen: false,
      rejectCandidateId: null,
      rejectOpportunityId: null,
      rejectCandidateStatusOption: null,
    };
  }

  toggleIsExpanded = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  toggleRejectionModal = (
    isRejectionModalOpen,
    rejectCandidateId = null,
    rejectOpportunityId = null,
    rejectCandidateStatusOption = null
  ) => {
    this.setState({
      isRejectionModalOpen,
      rejectCandidateId,
      rejectOpportunityId,
      rejectCandidateStatusOption,
    });
  };

  renderTalentCard = ({
    candidate,
    opportunity,
    candidates,
    roleFitDescriptions,
    user,
  }) => {
    const allowedTransitions =
      candidateOpportunityStatusUtils.getAllowedTransitions({
        currentCandidateStatus: candidate.candidateStatus,
        opportunity,
      });

    // Removed to Cooled is not a valid transition in the UI
    const candidateStatusOptions = opportunity.isClosed
      ? allowedTransitions.filter(
          statusOption =>
            !(opportunity.isClosed && statusOption === CandidateStatuses.Cooled)
        )
      : allowedTransitions;
    return (
      <OpportunityTalentCard
        user={user}
        candidate={candidate}
        opportunity={opportunity}
        opportunityCandidates={candidates}
        roleFitDescriptions={roleFitDescriptions}
        renderContextMenu={() =>
          candidateStatusOptions.length > 0 && (
            <ContextMenu
              direction="left"
              anchor={
                <IconButton
                  icon="vertical-ellipsis"
                  inverse
                  variation="minimal"
                  pattern="secondary"
                />
              }
            >
              {candidateStatusOptions.length > 0 && (
                <>
                  <ContextMenuHeader>Update Status</ContextMenuHeader>
                  {candidateStatusOptions.map(statusOption => (
                    <ContextMenuItem
                      onClick={() => {
                        const rejectedStatuses = [
                          CandidateStatuses.InterestedRejected,
                          OppCandMlRecStatuses.AiRemoved,
                        ];
                        if (rejectedStatuses.includes(statusOption)) {
                          this.toggleRejectionModal(
                            true,
                            candidate.id,
                            this.props.editTalent.opportunity.id,
                            statusOption
                          );
                        } else if (candidate.isMlRecommendation) {
                          EditOpportunityTalentTabStore.saveCandidateToOpportunity(
                            {
                              candidateId: candidate.id,
                              opportunityId: opportunity.id,
                            }
                          ).then(() => {
                            if (statusOption === CandidateStatuses.ShortList) {
                              EditOpportunityTalentTabStore.saveOpportunityCandidate(
                                {
                                  candidateStatus: statusOption,
                                  candidateId: candidate.id,
                                  opportunityId: opportunity.id,
                                }
                              );
                            }
                          });
                        } else {
                          EditOpportunityTalentTabStore.saveOpportunityCandidate(
                            {
                              candidateStatus: statusOption,
                              candidateId: candidate.id,
                              opportunityId: opportunity.id,
                            }
                          );
                        }
                      }}
                      key={`update-status${statusOption}`}
                    >
                      {poolContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  ))}
                  <ContextMenuDivider />
                </>
              )}

              {!candidate.isMlRecommendation && (
                <ContextMenuItem
                  onClick={() => {
                    AppEventItemModalStore.openModal(
                      EventSubjectConst.Opportunity,
                      {
                        candidateId: candidate.id,
                        opportunityId: opportunity.id,
                      }
                    );
                  }}
                >
                  Add Activity
                </ContextMenuItem>
              )}
              <ContextMenuItem
                onClick={() => {
                  window
                    .open(`/talent-detail/${candidate.id}`, '_blank')
                    .focus();
                }}
              >
                Go to Talent
              </ContextMenuItem>
            </ContextMenu>
          )
        }
      />
    );
  };

  renderTalentPool = ({ placeholder }) => {
    const {
      props: {
        editTalent: {
          opportunity,
          positions,
          candidates,
          talentPool,
          roleFitDescriptions,
        },
        user,
        savedFilters,
      },
      state: { isExpanded },
      toggleIsExpanded,
    } = this;

    return (
      <OTPoolWrapper isExpanded={isExpanded}>
        {PreloadedUserStore.isAiMatchControlEnabled(user) && (
          <OpportunityTalentAiMatchControl
            opportunity={opportunity}
            savedFilters={savedFilters}
          />
        )}
        <OTPoolHeader isExpanded={isExpanded} onClick={toggleIsExpanded}>
          <OTPoolHeaderLeft>
            Talent Pool{' '}
            <DataTest value="pool count" inline>
              ({talentPool.candidates.length})
            </DataTest>
          </OTPoolHeaderLeft>
          <OTPoolHeaderRight>
            <CaretIcon direction={isExpanded ? 'down' : 'right'} />
          </OTPoolHeaderRight>
        </OTPoolHeader>
        <OTPoolBody data-test={formatDataTestId('OpportunityTalentPool')}>
          {placeholder}
          {isExpanded && (
            <OTPoolTalentDrawer>
              {talentPool.candidates.map((candidate, i) => (
                <OTDraggableCardWrapper
                  data-test="candidate-card-talent-pool"
                  key={candidate.id}
                  id={candidate.id}
                  i={i}
                  isDragDisabled={
                    !isCandidateDraggable({
                      candidate,
                      positions,
                      opportunity,
                    })
                  }
                  candidate={candidate}
                  render={() =>
                    this.renderTalentCard({
                      candidate,
                      opportunity,
                      candidates,
                      roleFitDescriptions,
                      user,
                    })
                  }
                />
              ))}
              <OTDropzonePlaceholder>
                <OTDropzonePlaceholderButton
                  onClick={() =>
                    AppFindCandidateForOppModalStore.beginAddingToOpportunity(
                      [opportunity.id],
                      opportunity.id
                    )
                  }
                  disabled={!opportunity.isFulfillmentActive}
                  title={
                    !opportunity.isFulfillmentActive
                      ? `This opportunity's fulfillment is not active.`
                      : null
                  }
                  data-test={formatDataTestId('dropzone')}
                >
                  <span className="gtm-candidate-log-add-candidate">
                    Click To Add
                  </span>
                </OTDropzonePlaceholderButton>
              </OTDropzonePlaceholder>
            </OTPoolTalentDrawer>
          )}
        </OTPoolBody>
        {this.state.isRejectionModalOpen && (
          <RejectionModal
            candidateId={this.state.rejectCandidateId}
            opportunityId={this.state.rejectOpportunityId}
            candidateStatus={this.state.rejectCandidateStatusOption}
            onClose={() => this.toggleRejectionModal(false)}
          />
        )}
      </OTPoolWrapper>
    );
  };

  render() {
    return (
      <Droppable
        id={this.props.editTalent.talentPool.id}
        isDropDisabled
        render={this.renderTalentPool}
      />
    );
  }
}

OpportunityTalentPool.propTypes = {
  editTalent: EditOpportunityTalentTabStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  savedFilters: shape({}).isRequired,
};

export default OpportunityTalentPool;
