import React, { useEffect } from 'react';

import { AccountStore } from '../../stores/account-store';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormAccountConfidentialStore } from '../../stores/form-account-confidential-store';

import { ManageAccountConfidential } from './ManageAccountConfidential';

export const ManageAccountsConfidential = ({ account }) => {
  useEffect(() => {
    FormAccountConfidentialStore.load({
      accountId: account?.id,
      isAccountConfidential: account?.isAccountConfidential,
      confidentialAccountName: account?.confidentialAccountName,
    });
  }, [account]);

  return (
    <FormStateTreatment
      name="FORM_CONFIDENTIAL_ACCOUNT"
      formStore={FormAccountConfidentialStore}
      renderLoadedView={({
        formData: { isAccountConfidential },
        fireSubmit,
        form,
      }) => {
        return (
          <ManageAccountConfidential
            fireSubmit={fireSubmit}
            isInputDisabled={!isAccountConfidential}
            onValueChange={confidentialFlag => {
              if (!confidentialFlag) {
                form.change('confidentialAccountName', null);
              }
            }}
          />
        );
      }}
    />
  );
};

ManageAccountsConfidential.propTypes = {
  account: AccountStore.getDataShape().isRequired,
};
