import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_PROGRESS_AMOUNT } from './ProgressBarConstants';
import { ProgressMeter, ProgressIndicator } from './ProgressBarStyles';

function ProgressBar(props) {
  return (
    <ProgressMeter>
      <ProgressIndicator
        status={props.status}
        style={{ width: `${props.status}%` }}
      >
        &nbsp;
      </ProgressIndicator>
    </ProgressMeter>
  );
}

ProgressBar.defaultProps = {
  status: DEFAULT_PROGRESS_AMOUNT,
};

ProgressBar.propTypes = {
  status: PropTypes.number,
};

export default ProgressBar;
