import { string, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { PositionsApi } from '../api/positions-api';

import { OpportunityStore } from './opportunity-store';

class EditPositionStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      id: string,
      opportunityId: string,
      description: string,
    });
  }

  load(position) {
    return this.setState({
      id: position.id,
      opportunityId: position.opportunityId,
      description: position.description,
    });
  }

  save({ id, opportunityId, ...rest }) {
    return this.clearState(PositionsApi.patchPosition(id, rest)).then(() => {
      OpportunityStore.load(opportunityId);
    });
  }
}

export const EditPositionStore = new EditPositionStoreClass();
