import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Layout,
  LayoutItem,
  ParagraphHeader,
  Well,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { CandidateLegacyApi } from '../../api/candidate-legacy-api';

import { TalentAvailabilityNotesEdit } from './TalentAvailabilityNotesEdit';
import { TalentAvailabilityNotesDisplay } from './TalentAvailabilityNotesDisplay';

export const TalentAvailabilityNotes = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  const { id } = candidate;
  const [editingNotes, setEditingNotes] = useState(false);
  const [{ data: note }] = useApi(CandidateLegacyApi.readCandidateNotes(id));

  useEffect(() => {
    setEditingNotes(false);
  }, [candidate]);

  return (
    <Well>
      <Layout>
        <LayoutItem position="left">
          <ParagraphHeader>Internal Note</ParagraphHeader>
        </LayoutItem>
        {!editingNotes && (
          <LayoutItem position="right" fluid>
            <IconButton
              pattern="secondary"
              variation="minimal"
              icon="pencil"
              onClick={() => {
                setEditingNotes(true);
              }}
              name="EDIT_NOTES"
            />
          </LayoutItem>
        )}
      </Layout>

      {editingNotes ? (
        <TalentAvailabilityNotesEdit
          candidate={candidate}
          note={note}
          setEditingNotes={setEditingNotes}
        />
      ) : (
        <TalentAvailabilityNotesDisplay note={note} />
      )}
    </Well>
  );
};
