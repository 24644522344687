import React, { useState } from 'react';
import {
  Button,
  DateUtil,
  Flashy,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Radio,
} from '@axiom/ui';
import { Opportunity, OpportunitySchema } from '@axiom/validation';
import { UserConst } from '@axiom/const';

import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { isClientUser } from '../../utils/roles';

const formSchema = OpportunitySchema.pick({ confidential: true });

const roleMap = {
  [UserConst.UserRoles.EnvoyUser]: 'Talent Success',
  [UserConst.UserRoles.EnvoySales]: 'Sales',
  [UserConst.UserRoles.EnvoyAdmin]: 'Admin',
  [UserConst.UserRoles.ClientUser]: 'Client',
};

export const OpportunityConfidential = ({
  opportunity,
}: {
  opportunity: Opportunity;
}) => {
  const [showOverrideConfirm, setShowOverrideConfirm] = useState(false);
  const [formAcions, setFormAcions] = useState(null);
  const [formValue, setFormValue] = useState(null);
  const updatedByUser = opportunity.confidentialLastUpdatedByUser;
  const updatedByRole = updatedByUser ? roleMap[updatedByUser.roles[0]] : null;

  const saveChange = async (confidential: boolean) => {
    await OpportunityLegacyApi.updateOpportunity(opportunity.id, {
      jobName: opportunity.jobName,
      confidential,
    });

    await Promise.all([
      OpportunityLegacyApi.refreshOpportunity(opportunity.id),
      OpportunityStore.load(opportunity.id),
    ]);
  };

  const updateOpportunityConfidential = async (
    { confidential = true },
    actions: { resetForm: () => void }
  ) => {
    if (
      Array.isArray(opportunity.confidentialLastUpdatedByUser?.roles) &&
      isClientUser(opportunity.confidentialLastUpdatedByUser)
    ) {
      setShowOverrideConfirm(!showOverrideConfirm);
      setFormAcions(actions);
      setFormValue(confidential);

      return Promise.resolve();
    }

    return saveChange(confidential);
  };

  const handleCloseConfirmModal = () => {
    formAcions.resetForm();
    setShowOverrideConfirm(!showOverrideConfirm);
  };

  const handleSaveConfirmModal = () => {
    setShowOverrideConfirm(!showOverrideConfirm);
    return saveChange(formValue);
  };

  return (
    <>
      <Grid>
        <GridRow>
          <GridColumn>
            <Flashy name="PENDO_OPP_CONFIDENTIAL">
              AFC Project Visibility
            </Flashy>
            <Gutter bottom="8px" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Form
              name="OPP_CONFIDENTIAL_FORM"
              schema={formSchema}
              initialValues={{
                ...opportunity,
                confidential: !!opportunity.confidential,
              }}
              submitOnChange
              onSubmit={updateOpportunityConfidential}
            >
              {() => {
                return (
                  <FormGroup
                    description={`Specify who at ${opportunity.accountName} can see this submission. Client can also change this setting in AFC`}
                  >
                    <Gutter bottom="16px" />
                    <Radio
                      name="confidential"
                      displayValue={`Everyone at ${opportunity.accountName} with an Axiom account`}
                      option={false}
                    />
                    <Gutter bottom="8px" />
                    <Radio
                      name="confidential"
                      displayValue="Confidential - only colleagues the client invites or contacts listed above"
                      option
                    />
                  </FormGroup>
                );
              }}
            </Form>
          </GridColumn>
        </GridRow>
        {opportunity.confidentialUpdatedAt && (
          <GridRow>
            <GridColumn>
              <Gutter bottom="16px" />
              <Flashy italic color="textSubtle" name="LAST_UPDATED">
                Last updated{' '}
                {DateUtil.displayDateFullYearShortMonth(
                  opportunity.confidentialUpdatedAt
                )}{' '}
                by {updatedByUser.fullName} ({updatedByRole})
              </Flashy>
            </GridColumn>
          </GridRow>
        )}
      </Grid>
      {showOverrideConfirm && (
        <Modal size="confirm" name="CLIENT_OVERRIDE_CONFIRM">
          <ModalHeader onClose={handleCloseConfirmModal} name="CONFIRM_HEADER">
            Override client’s selection?
          </ModalHeader>
          <ModalSection>
            <Paragraph name="CONFIRM_BODY_COPY">
              The last user to update this setting was {updatedByUser.fullName}{' '}
              ({updatedByRole}). By changing this setting, you will be
              overriding the client's selection.
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button
              name="CANCEL"
              pattern="secondary"
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </Button>
            <Button name="CONTINUE" onClick={handleSaveConfirmModal}>
              Continue
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
