import styled from 'styled-components';

export const ErrorBlurbWrapper = styled.div`
  margin: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.2rem;
  }
`;
