import styled from 'styled-components';
import { Label } from '@axiom/ui';

import { getThemeProp } from '../../themes';

export const CandidateExperienceItemWrapper = styled.div`
  margin: 0.625rem 0;
`;

export const CEICard = styled.div`
  border: 0.0625rem solid ${getThemeProp('colorPrimary')};
  background: ${getThemeProp('appBackground')};
  padding: 0.625rem;
  position: relative;
`;

export const CEICardBody = styled.div``;
export const CEICardRow = styled.div`
  &:not(:first-child) {
    margin: 1.125rem 0 0 0;
  }
  display: flex;
  justify-content: space-between;
`;

export const CEICardRowLeft = styled.div`
  flex: 0 0 60%;
`;

export const CEICardRowRight = styled.div`
  flex: 0 0 40%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const CEIStartEndDates = styled.div``;
export const CEICardRowRightMenu = styled.div``;
export const CEIDateTime = styled.time`
  display: inline;
`;

export const CEICardFooter = styled.div``;
export const CEICardDrawer = styled.div`
  border-top: 0.0625rem solid ${getThemeProp('buttonBorder')};
  margin-top: 0.9375rem;
  padding-top: 0.9375rem;
`;

export const CEILabel = styled(Label)`
  color: ${getThemeProp('primaryText')};
`;
