import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const OTNoteWrapper = styled.div`
  padding: 1rem 1rem 1rem 1.2rem;
`;
export const OTNoteBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTNoteBodyLeft = styled.div`
  flex: 1;
  color: ${getThemeProp('darkBlue')};
  font-style: italic;
`;
export const OTNoteBold = styled.span`
  color: ${getThemeProp('darkBlue')};
  font-style: normal;
  font-weight: bold;
`;
export const OTNoteBodyLeftToAddTalentText = styled.span`
  display: block;
  line-height: 1.2rem;
`;
