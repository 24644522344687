import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const OEWrapper = styled.div`
  flex: ${({ isDetail }) => (isDetail ? null : '1 1 auto')};
  padding: 1.875rem 0;
`;

export const OECard = styled.div`
  margin-bottom: 0.8rem;
`;

export const OECardHeader = styled.div`
  background-color: ${GENERAL_STYLES_MAP.PANEL_HEADER.background};
  color: ${GENERAL_STYLES_MAP.PANEL_HEADER.color};
  border: 2px solid ${GENERAL_STYLES_MAP.PANEL_HEADER.border};
  padding: 0.5rem 0.75rem;
`;

export const OECardBody = styled.div`
  border: 1px solid #000;
  border-top: none;
  padding: 0.95rem 0.85rem 0.85rem 0.85rem;
`;

export const OECardFirstRow = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const OECardLastRow = styled.div`
  display: flex;
`;

export const OECardFirstColumn = styled.div`
  flex: 0 0 70%;
`;
