import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import styled from 'styled-components';
import { Label } from '@axiom/ui';

import AxiomForTalentIndicator from '../AxiomForTalentIndicator/AxiomForTalentIndicator';
import { FFError, isErroring } from '../FFError/FFError';
import { formatDataTestId } from '../../utils/dataTest';
import DropdownList from '../DropdownList/DropdownList';
import { DropdownOptionsShape } from '../../models/dropdown-options';

const FFDropdownWrapper = styled.div`
  color: ${props => (props.isDisabled ? '#d3d3d3' : 'inherit')};
  margin-bottom: 25px;
  width: 100%;
`;
const FFDLabelErrorWrapper = styled.div`
  display: flex;
`;
const FFDErrorWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;
const FFDAftIcon = styled(AxiomForTalentIndicator)`
  margin-left: 0.25rem;
`;

export const FFDropdown = ({
  input,
  isDisabled,
  label,
  meta,
  showAftIcon,
  className,
  options,
  onValueChange,
}) => {
  const handleChange = value => {
    // Force null value to be undefined as null values will be coerced by
    // validation to be 'truthy' values.
    value = value ?? undefined;
    input.onChange(value);

    /**
     * react-final-form does not override or chain passed in onChange
     * must use custom onChange namespace
     * This is the recommended solution.
     * onValueChange
     */
    onValueChange(value);
  };

  const handleBlur = handleChange;

  return (
    <FFDropdownWrapper
      className={className}
      isDisabled={isDisabled}
      data-test={formatDataTestId(input.name || label || 'dropdown_wrapper')}
    >
      <FFDLabelErrorWrapper>
        {label && <Label htmlFor={input.name}>{label}</Label>}
        {label && showAftIcon && <FFDAftIcon />}
      </FFDLabelErrorWrapper>

      <DropdownList
        name={input.name}
        value={input.value}
        onChange={handleChange}
        onBlur={handleBlur}
        valid={!isErroring(meta)}
        options={options}
        disabled={isDisabled}
        type={input.type}
      />
      <FFDLabelErrorWrapper>
        <FFDErrorWrapper>
          <FFError finalFormElementMeta={meta} />
        </FFDErrorWrapper>
      </FFDLabelErrorWrapper>
    </FFDropdownWrapper>
  );
};

FFDropdown.propTypes = {
  input: shape({}).isRequired,
  onValueChange: func,
  isDisabled: bool,
  label: string,
  meta: shape({}).isRequired,
  showAftIcon: bool,
  className: string,
  options: DropdownOptionsShape,
};

FFDropdown.defaultProps = {
  isDisabled: false,
  label: null,
  showAftIcon: false,
  className: null,
  options: [],
  onValueChange: () => {},
};
