import {
  USER_MGMT_RESET_SELECTED,
  USER_MGMT_CHANGE_SELECTED,
} from '../constants';

const INITIAL_STATE = {
  selected: null,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USER_MGMT_RESET_SELECTED:
      return {
        ...state,
        selected: INITIAL_STATE.selected,
      };
    case USER_MGMT_CHANGE_SELECTED:
      return {
        ...state,
        selected: payload.selected,
      };
    default:
      return state;
  }
};

export default reducer;
