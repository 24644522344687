import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const EducationItemWrapper = styled.div`
  margin: 0.625rem 0;
`;

export const EICard = styled.div`
  border: 0.0625rem solid ${getThemeProp('colorPrimary')};
  background: ${getThemeProp('appBackground')};
  padding: 0.625rem;
  position: relative;
`;

export const EICardBody = styled.div``;
export const EICardRow = styled.div`
  &:not(:first-child) {
    margin: 1.125rem 0 0 0;
  }
  display: flex;
  justify-content: space-between;
`;

export const EICardRowLeft = styled.div`
  flex: 0 0 60%;
`;

export const EICardRowRight = styled.div`
  flex: 0 0 40%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;
export const EICardRowRightMenu = styled.div``;
