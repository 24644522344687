import { CandidateOccupationType, CountryCodesConst } from '@axiom/const';
import { User } from '@axiom/validation';

const { CountryCodes } = CountryCodesConst;

export const OccupationTypeUtil = {
  userShouldSeeConsultantLabel(user: User) {
    return (
      user?.contact?.country &&
      (user.contact.country === CountryCodes.HK ||
        user.contact.country === CountryCodes.SG)
    );
  },

  professionFromOccupationType(
    occupationType: CandidateOccupationType,
    user: User = {},
    defaultResponse: string = null
  ) {
    switch (occupationType) {
      case 'Lawyer':
      case 'Lawyer-Flex':
      case 'Lawyer-Client Referred':
        return this.userShouldSeeConsultantLabel(user)
          ? 'Consultant'
          : 'Lawyer';
      case 'Legal Support':
      case 'Legal Support-Flex':
        return 'Paralegal';
      default:
        return defaultResponse;
    }
  },
};
