import React from 'react';
import { GroupedExperiences, PracticeArea } from '@axiom/validation';

import { TalentExperiencesGroup } from './TalentExperiencesGroup';

export const TalentExperiences = ({
  groupedExperiences,
  practiceAreas,
}: {
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
}) => {
  return (
    <>
      {groupedExperiences.map(group => (
        <TalentExperiencesGroup
          key={`experience-group-${group.experiences[0].id}`}
          experienceGroup={group}
          practiceAreas={practiceAreas}
        />
      ))}
    </>
  );
};
