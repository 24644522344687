import { Opportunity } from '@axiom/const';
import { Badge, Gutter, Layout, LayoutItem, useApi } from '@axiom/ui';
import React from 'react';
import { string, bool } from 'prop-types';

import { DateUtil } from '../../utils/date-util';
import { OpportunityStore } from '../../stores/opportunity-store';
import { TaxonomyApi } from '../../api/taxonomy';

import OpportunityProfileHeaderBanner from './OpportunityProfileHeaderBanner';
import { OPHFieldRow, OPHCell } from './OpportunityProfileHeaderStyles';

function OpportunityProfileHeader({ opportunity, searchTerm, isDetail }) {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());

  if (!opportunity) return null;

  const isClosedWon = opportunity.stage === Opportunity.CLOSED_WON;

  return (
    <Layout background="panelLight" direction="vertical">
      <LayoutItem fluid name="OPPORTUNITYPROFILEHEADER" bottomGutter="8px">
        <OpportunityProfileHeaderBanner
          opportunity={opportunity}
          searchTerm={searchTerm}
          isDetail={isDetail}
        />
        <OPHFieldRow>
          <OPHCell
            showAftIcon
            title="Account Name"
            values={[opportunity.accountName]}
          />
          <OPHCell
            title="Industry"
            values={
              taxonomy.industries[opportunity?.account?.industryValue]
                ?.displayValue
            }
          />
          <OPHCell
            title="Close Date"
            values={DateUtil.displayDate(opportunity.salesCloseDate)}
          />
          {isClosedWon && (
            <OPHCell title="Matter ID" values={opportunity.integrationId} />
          )}
        </OPHFieldRow>
      </LayoutItem>
      <LayoutItem position="right">
        {opportunity.directEligible && (
          <Gutter left="24px" right="24px">
            <Badge name="direct-to-client-badge" background="light orange">
              Direct-To-Client
            </Badge>
          </Gutter>
        )}
      </LayoutItem>
      <Gutter bottom="24px" />
    </Layout>
  );
}

OpportunityProfileHeader.defaultProps = {
  searchTerm: undefined,
  opportunity: null,
  isDetail: false,
};

OpportunityProfileHeader.propTypes = {
  opportunity: OpportunityStore.getDataShape(),
  searchTerm: string,
  isDetail: bool,
};

export default OpportunityProfileHeader;
