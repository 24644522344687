import React, { useState } from 'react';
import { Text, useApi } from '@axiom/ui';
import { AreaOfStudy } from '@axiom/validation';

import { AreasOfStudyApi } from '../../api/areas-of-study';

import { AreasOfStudyManagementList } from './AreasOfStudyManagementList';
import { AreasOfStudyManagementForm } from './AreasOfStudyManagementForm';
import { Root, List, Form, Empty } from './GroupSettingsModalStyles';

export const AreasOfStudyManagementView = () => {
  const [{ data: areasOfStudy }] = useApi(AreasOfStudyApi.readAreasOfStudy());
  const [selected, setSelected] = useState<AreaOfStudy>(null);

  return (
    <Root data-test="MANAGE_AREAS_OF_STUDY">
      <List>
        <AreasOfStudyManagementList
          areasOfStudy={areasOfStudy}
          selected={selected}
          onSelect={setSelected}
        />
      </List>
      <Form>
        {selected ? (
          <AreasOfStudyManagementForm
            areasOfStudy={areasOfStudy}
            selected={selected}
            onClose={() => setSelected(null)}
          />
        ) : (
          <Empty>
            <Text name="NO_AREA_OF_STUDY_SELECTED">
              Select an area of study.
            </Text>
          </Empty>
        )}
      </Form>
    </Root>
  );
};
