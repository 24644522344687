import styled from 'styled-components';

export const Scroller = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

export const ScrollerWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: white;
`;
