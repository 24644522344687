import GenericStore from '../classes/generic-store';
import { getCollaboratorsByTalentId } from '../api/talentCollaborators';
import { CandidateCollaborators } from '../models/candidate-collaborators';

class CandidateCollaboratorsClass extends GenericStore {
  load(candidate) {
    return this.setState(
      getCollaboratorsByTalentId(candidate.id).then(response => response.data)
    );
  }

  getDataShape() {
    return CandidateCollaborators;
  }

  selectData(state) {
    return this.select(state).data || [];
  }
}
export const CandidateCollaboratorsStore = new CandidateCollaboratorsClass();
