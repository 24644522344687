import {
  HeaderBar,
  HeaderBarLink,
  HeaderBarLinkSection,
  HeaderBarUserSection,
  Button,
  ContextMenu,
  ContextMenuItem,
  ModalHeader,
  Modal,
  ModalFooter,
} from '@axiom/ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { EnvUtil } from '../../utils/env-util';
import { isTalentSuccessUser } from '../../utils/roles';
import Notifications from '../Notifications/Notifications';
import { UserAccountModal } from '../UserAccountModal/UserAccountModal';
import { AdminFunctionsModalStore } from '../../stores/admin-functions-modal-store';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { PreloadedGroupsStore } from '../../stores/preloaded-groups-store';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';
import ManageClientsModal from '../ManageClientsModal/ManageClientsModal';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AdminFunctionsModal } from '../AdminFunctionsModal/AdminFunctionsModal';

export const Header = ({
  closeSideDrawer,
}: {
  closeSideDrawer: () => void;
}) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showManageClientModal, setShowManageClientModal] = useState(false);
  const location = useLocation().pathname;
  const usersStore = useSelector(state => PreloadedUsersStore.select(state));
  const user = useSelector(state => PreloadedUserStore.select(state));
  const teamsStore = useSelector(state => PreloadedGroupsStore.select(state));
  const tagsStore = useSelector(state => PreloadedTagsStore.select(state));
  const adminFunctionsModalStore = useSelector(state =>
    AdminFunctionsModalStore.select(state)
  );
  const userName = `${user?.firstName || ''} ${user?.lastName || ''}`;

  return (
    <>
      <HeaderBar>
        <HeaderBarLinkSection name="NavigationBar">
          <HeaderBarLink
            selected={location.startsWith('/workspace')}
            name="Workspace"
          >
            <Link
              data-test="WORKSPACE_LINK"
              to="/workspace"
              onClick={() => {
                closeSideDrawer();
              }}
            >
              My Workspace
            </Link>
          </HeaderBarLink>
          {isTalentSuccessUser(user) && (
            <HeaderBarLink
              selected={location.startsWith('/bench')}
              name="MyBench"
            >
              <Link
                to="/bench"
                data-test="BENCH_LINK"
                onClick={() => {
                  closeSideDrawer();
                }}
              >
                My Bench
              </Link>
            </HeaderBarLink>
          )}
          <HeaderBarLink
            selected={location.startsWith('/opportunities')}
            name="Opportunities"
          >
            <Link
              data-test="OPPORTUNITIES_LINK"
              to="/opportunities"
              onClick={() => {
                closeSideDrawer();
              }}
            >
              Opportunities
            </Link>
          </HeaderBarLink>
          <HeaderBarLink
            selected={location.startsWith('/talent')}
            name="Talent"
          >
            <Link
              data-test="TALENT_LINK"
              to="/talent"
              onClick={() => {
                closeSideDrawer();
              }}
            >
              Talent
            </Link>
          </HeaderBarLink>
        </HeaderBarLinkSection>
        <HeaderBarUserSection>
          <Notifications />
          <ContextMenu
            direction="left"
            anchor={
              <Button
                name="UserMenuTrigger"
                icon="arrow-down"
                pattern="secondary"
                variation="minimal"
              >
                Welcome {user?.firstName}
              </Button>
            }
          >
            <ContextMenuItem
              name="MyAccountOption"
              onClick={() => {
                setShowUserModal(true);
              }}
            >
              My Account
            </ContextMenuItem>
            {user.roles.includes('EnvoyAdmin') && (
              <ContextMenuItem
                name="AdminFunctionsOption"
                onClick={() =>
                  AdminFunctionsModalStore.load({ modalIsOpen: true })
                }
              >
                Admin Functions
              </ContextMenuItem>
            )}

            <ContextMenuItem
              name="ManageClientsOption"
              onClick={() => {
                setShowManageClientModal(true);
              }}
            >
              Manage Clients
            </ContextMenuItem>
            <ContextMenuItem
              name="ReportIssueOption"
              onClick={() => window.open(EnvUtil.reportAnIssueUrl)}
            >
              Report an Issue
            </ContextMenuItem>
          </ContextMenu>
        </HeaderBarUserSection>
        {showUserModal && (
          <UserAccountModal
            onClose={() => {
              setShowUserModal(false);
            }}
            user={user}
            userName={userName}
          />
        )}
        {showManageClientModal && (
          <ManageClientsModal
            onClose={() => {
              setShowManageClientModal(false);
            }}
          />
        )}
        <StoreStateTreatment
          name="Admin_Functions_Modal_Treatment"
          isModalState
          storeState={[
            adminFunctionsModalStore,
            usersStore,
            teamsStore,
            tagsStore,
          ]}
          renderLoadedView={() => (
            <Modal size="large" name="AdminFunctionsModal">
              <ModalHeader
                onClose={() => {
                  AdminFunctionsModalStore.closeModal();
                }}
              >
                Admin Functions
              </ModalHeader>
              <AdminFunctionsModal />
              <ModalFooter>
                <Button
                  onClick={() => {
                    AdminFunctionsModalStore.closeModal();
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}
        />
      </HeaderBar>
    </>
  );
};
