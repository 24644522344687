/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape, func } from 'prop-types';

import DateMonthYearPicker from '../DateMonthYearPicker/DateMonthYearPicker';
import { FFError } from '../FFError/FFError';

const FFDateMonthYearPicker = ({ input, onValueChange, meta, ...rest }) => (
  <div>
    <DateMonthYearPicker
      date={input.value || null}
      id={input.id}
      onDateChange={value => {
        input.onChange(value);
        onValueChange(value);
      }}
      invalid={meta.error && meta.submitFailed}
      {...rest}
    />
    <FFError finalFormElementMeta={meta} />
  </div>
);

FFDateMonthYearPicker.defaultProps = {
  onValueChange: x => x,
};

FFDateMonthYearPicker.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  onValueChange: func,
};

export default FFDateMonthYearPicker;
