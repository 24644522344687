import React from 'react';
import { bool, func, oneOf, oneOfType, number, string, node } from 'prop-types';

import Checkbox from '../Checkbox/Checkbox';
import { formatDataTestId } from '../../utils/dataTest';

import { CheckboxWithLabelWrapper, CWLLabel } from './CheckboxWithLabelStyles';

export const CheckboxWithLabel = ({
  checked,
  disabled,
  id,
  label,
  name,
  type,
  value,
  className,
  onValueChange,
  checkboxWidth,
  checkboxHeight,
  ...rest
}) => {
  const handleChange = e => {
    onValueChange(e.target.checked);
  };

  return (
    <CheckboxWithLabelWrapper
      disabled={disabled}
      data-test={formatDataTestId(name || id || label || 'CHECKBOX_CONTAINER')}
      className={className}
    >
      <Checkbox
        checked={checked}
        onValueChange={handleChange}
        data-test={formatDataTestId('CHECKBOX')}
        data-value={checked ? 'checked' : 'unchecked'}
        inputProps={{
          ...rest,
          id,
          name: name || id,
          type,
          value,
          disabled,
        }}
        width={checkboxWidth}
        height={checkboxHeight}
      />
      {label && (
        <CWLLabel data-test={formatDataTestId('LABEL_TEXT')} htmlFor={id}>
          {label}
        </CWLLabel>
      )}
    </CheckboxWithLabelWrapper>
  );
};

CheckboxWithLabel.defaultProps = {
  disabled: false,
  checked: false,
  className: undefined,
  name: undefined,
  onValueChange: () => {},
  type: 'checkbox',
  value: undefined,
  checkboxWidth: undefined,
  checkboxHeight: undefined,
};

CheckboxWithLabel.propTypes = {
  className: string,
  disabled: bool,
  checked: bool,
  id: string.isRequired,
  label: oneOfType([string, node]).isRequired,
  name: string,
  onValueChange: func,
  type: oneOf(['radio', 'checkbox']),
  value: oneOfType([bool, number, string]),
  checkboxWidth: string,
  checkboxHeight: string,
};
