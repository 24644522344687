import { Candidate } from '@axiom/validation';
import { useDispatch } from 'react-redux';

import { CandidateApi } from '../api/candidate/candidate-api';
import { getDecoratedTalent } from '../redux/actions/talent';
import { LegacyTalentApi } from '../api/talent';

export const useRefreshCandidate = (candidateId?: Candidate['id']) => {
  const dispatch = useDispatch();

  const refreshCandidate = async (id = candidateId) => {
    dispatch(getDecoratedTalent(id)); // refresh redux
    await LegacyTalentApi.invalidate(`/candidates/${id}`);
    return CandidateApi.refreshCandidate(id); // refresh api cache
  };

  return {
    refreshCandidate,
  };
};
