import {
  CANDIDATE_COLLABORATORS_LOAD,
  CANDIDATE_COLLABORATORS_LOADED,
  CANDIDATE_COLLABORATORS_LOAD_ERROR,
} from '../constants';

export const getTalentCollaborators = payload => ({
  type: CANDIDATE_COLLABORATORS_LOAD,
  payload,
});

export const talentCollaboratorsLoaded = payload => ({
  type: CANDIDATE_COLLABORATORS_LOADED,
  payload,
});

export const talentCollaboratorsError = payload => ({
  type: CANDIDATE_COLLABORATORS_LOAD_ERROR,
  error: true,
  payload,
});
