import omit from 'lodash/omit';

import FormStore from '../classes/form-store';
import { opportunityShape } from '../models/opportunities';
import { AjvUtil } from '../utils/ajv-util';
import { patchOpportunity } from '../api/opportunities-legacy-api';
import { putCollaboratorsByOpportunityId } from '../api/opportunityCollaborators';
import { putLanguagesByOpportunityId } from '../api/opportunityLanguages';
import { getDecoratedOpportunity } from '../redux/actions/opportunities';

import { OpportunityStore } from './opportunity-store';

const OpportunityProfileDetailValidation = AjvUtil.compileSchema({
  required: ['id'],
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    opportunityName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    accountName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    clientName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    stage: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    offering: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    salesCloseDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    ownerUserId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    salesLeadId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    salesforceCxLeadId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    currency: {
      type: ['string', 'null'],
    },
    forecastCategory: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    practiceAreaId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    useCase: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    collaboratorId: {
      type: ['array', 'null'],
      maxLength: 255,
    },
    languages: {
      type: ['array', 'null'],
    },
    proficiency: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    skill: {
      type: ['string', 'null'],
      maxLength: 255,
    },
  },
});

class FormOpportunityProfileDetailStoreClass extends FormStore {
  getDataShape() {
    return opportunityShape;
  }

  load(opportunity = {}) {
    return this.setState({
      id: opportunity.id,
      opportunityName: opportunity.jobName,
      accountName: opportunity.accountName,
      clientName: opportunity.clientName,
      stage: opportunity.stage,
      offering: opportunity.offering,
      salesCloseDate: opportunity.salesCloseDate,
      ownerUserId: opportunity.ownerUserId,
      salesLeadId: opportunity.salesLeadId,
      salesforceCxLeadId: opportunity.salesforceCxLeadId,
      currency: opportunity.currency,
      forecastCategory: opportunity.forecastCategory,
      practiceAreaId: opportunity.practiceAreaId,
      useCase: opportunity.useCase,
      collaboratorId: opportunity.collaboratorId,
      languages: opportunity.languages,
      proficiency: opportunity.proficiency,
      skill: opportunity.skill,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      OpportunityProfileDetailValidation,
      formData
    );
  }

  isOpportunityChanged(changeField) {
    const opportunityChanges = omit(changeField, [
      'collaboratorId',
      'languages',
    ]);
    return Object.keys(opportunityChanges).length > 0;
  }

  submit(changedField, formData) {
    const isOpportunityChanged = this.isOpportunityChanged(changedField);
    if (changedField.location) {
      changedField = {
        ...changedField,
        ...changedField.location,
      };
      delete changedField.location;
    }
    return this.clearState(
      Promise.all([
        isOpportunityChanged
          ? patchOpportunity(formData.id, changedField)
          : null,
        changedField.collaboratorId
          ? putCollaboratorsByOpportunityId(
              formData.id,
              formData.collaboratorId
            )
          : null,
        changedField.languages
          ? putLanguagesByOpportunityId(formData.id, changedField.languages)
          : null,
      ]).then(([opportunity, collaboratorId, languages]) => {
        if (collaboratorId || languages) {
          this.dispatch(getDecoratedOpportunity(formData.id));
        }
        if (opportunity) {
          OpportunityStore.load(formData.id);
        }
      })
    );
  }
}

export const FormOpportunityProfileDetailStore =
  new FormOpportunityProfileDetailStoreClass();
