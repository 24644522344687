import React from 'react';
import { Candidate } from '@axiom/validation';

import { Well } from '../../layout/Well/Well';
import { Gutter } from '../../layout/Gutter/Gutter';
import { SectionHeader } from '../../content/SectionHeader/SectionHeader';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ReadonlyTextarea } from '../../form/Textarea/ReadonlyTextarea';

export type CandidateDescriptionTypes = {
  description: string;
  calculatedFirstName: Candidate['calculatedFirstName'];
};

export const CandidateDescription = ({
  description,
  calculatedFirstName,
}: CandidateDescriptionTypes) => {
  return (
    <Well name="CANDIDATE_DESCRIPTION">
      <Gutter bottom="8px">
        <SectionHeader name="FIRST_NAME">
          About {calculatedFirstName}
        </SectionHeader>
      </Gutter>
      <Paragraph name="DESCRIPTION">
        <ReadonlyTextarea value={description} />
      </Paragraph>
    </Well>
  );
};
