import React, { Component } from 'react';
import { func, bool, node } from 'prop-types';
import { connect } from 'react-redux';
import { SearchIcon } from '@axiom/ui';

import {
  closeSideBar,
  openSideBar,
  deactivateSideBar,
  activateSideBar,
  activateSideDrawer,
  deactivateSideDrawer,
} from '../../redux/actions/app';
import { sideBarSelector } from '../../redux/selectors/app';
import { formatDataTestId } from '../../utils/dataTest';

import { BarTab, StyledWrapper } from './SidebarStyles';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { transitionOpened: false };
  }

  toggleSideBar = () => {
    if (this.props.sideBarIsExpanded) {
      this.props.closeSideBar();
      this.props.deactivateSideBar();
    } else {
      this.props.openSideBar();
      this.props.deactivateSideDrawer();
      this.props.activateSideBar();
    }
  };

  render() {
    const {
      toggleSideBar,
      props: { sideBarIsExpanded, sideBarActive, children },
      state: { transitionOpened },
    } = this;

    return (
      <StyledWrapper>
        <BarTab
          data-test={formatDataTestId('sidebarTab')}
          aria-controls="sidebar"
          aria-expanded={sideBarIsExpanded}
          expanded={sideBarIsExpanded}
          onClick={toggleSideBar}
          type="button"
          active={sideBarActive}
          data-opened={sideBarIsExpanded}
        >
          <SearchIcon />
        </BarTab>
        <section
          id="sidebar"
          data-test={formatDataTestId('sidebar')}
          data-ready={transitionOpened}
          data-expanded={sideBarIsExpanded}
          onTransitionEnd={evt => {
            if (evt.target.tagName === 'SECTION') {
              this.setState({ transitionOpened: !transitionOpened });
            }
          }}
        >
          <div className="content" hidden={!sideBarIsExpanded}>
            {children}
          </div>
        </section>
      </StyledWrapper>
    );
  }
}

Sidebar.defaultProps = {
  children: null,
  sideBarActive: false,
  sideBarIsExpanded: false,
};

Sidebar.propTypes = {
  activateSideBar: func.isRequired,
  children: node,
  closeSideBar: func.isRequired,
  deactivateSideBar: func.isRequired,
  deactivateSideDrawer: func.isRequired,
  openSideBar: func.isRequired,
  sideBarActive: bool,
  sideBarIsExpanded: bool,
};

export default connect(sideBarSelector, {
  closeSideBar,
  openSideBar,
  activateSideBar,
  deactivateSideBar,
  activateSideDrawer,
  deactivateSideDrawer,
})(Sidebar);
