import styled from 'styled-components';

export const DisabledDisplayWrapper = styled.div`
  opacity: 0.6;

  label {
    color: inherit;
  }
  input {
    border: none;
    background-color: transparent !important;
    color: inherit;
    padding-left: 0;
  }
`;
