import React from 'react';
import { func } from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Button,
  Paragraph,
} from '@axiom/ui';

export const OpportunityProfilePositionDeleteModal = ({
  closeModal,
  submit,
}) => (
  <Modal>
    <ModalHeader onClose={closeModal}>Delete Position</ModalHeader>
    <ModalSection>
      <Paragraph>
        Move all talent on the position to the talent pool with a status of
        Short Listed.
      </Paragraph>
      <br />
      <Paragraph>
        This removes the opportunity from their active submissions and will not
        send a notification to the talent.
      </Paragraph>
      <br />
      <Paragraph>
        The associated quote line in Salesforce will be deleted and the
        opportunity amount recalculated.
      </Paragraph>
    </ModalSection>
    <ModalFooter>
      <Button onClick={closeModal} variation="outline">
        Cancel
      </Button>
      <Button onClick={submit}>Delete</Button>
    </ModalFooter>
  </Modal>
);

OpportunityProfilePositionDeleteModal.propTypes = {
  closeModal: func,
  submit: func,
};

OpportunityProfilePositionDeleteModal.defaultProps = {
  closeModal: () => {},
  submit: () => {},
};
