import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const MultiSelectWrapper = styled.div`
  .k-multiselect-wrap {
    height: auto;
    background: transparent !important;
    box-shadow: none;
    border-bottom: none;
  }

  .k-multiselect .k-multiselect-wrap,
  .k-dropdowntree .k-multiselect-wrap {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .k-multiselect {
    border-bottom: 2px solid #afafaf;
  }

  .k-multiselect.k-state-focused {
    border-bottom: 2px solid ${GENERAL_STYLES_MAP.AXIOM.background};
  }

  .k-input {
    padding-left: 0;
  }

  .k-multiselect-wrap.k-floatwrap {
    margin-top: 0.25rem;
  }

  ${({ invalid }) => {
    if (invalid) {
      return `
        .k-widget {
          border: none !important;
        }  
        .k-multiselect-wrap {
          height: 1.8rem;
        }
      `;
    }
    return null;
  }};

  li.k-button,
  .k-searchbar > .k-input {
    margin: 0;
    padding: 0;
  }

  .k-searchbar > .k-input {
    height: 2em;
  }

  .k-select > .k-icon.k-i-close {
    background-color: white;
    color: ${GENERAL_STYLES_MAP.AXIOM.background};
    border-radius: 50%;
    margin-left: 5px;
    height: 14px;
    width: 14px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const KendoErrorWrapper = styled.div`
  ${({ invalid }) => {
    if (invalid) {
      return `
        .form-control.is-invalid {
          border: solid 1px #c93333 !important;
          padding-bottom: 2rem !important;      
        }
      `;
    }
    return null;
  }};
`;

export const LabelWrapper = styled.div`
  padding: 0.5em 0;
`;
