import { shape, number, string, arrayOf } from 'prop-types';

import { ContactModel } from '../models/contact';
import GenericStore from '../classes/generic-store';
import { getContacts } from '../api/contacts';

class ContactsStoreClass extends GenericStore {
  load({ searchTerm, page = 1, results = [] } = {}) {
    const payload = { search: searchTerm || undefined, page };

    return this.setState(
      getContacts(payload).then(data => ({
        page: data.meta.currentPage,
        results: [...results, ...(data.results || [])],
        totalResultsCount: data.meta.resultCount,
        searchTerm,
      }))
    );
  }

  getDataShape() {
    return shape({
      results: arrayOf(ContactModel),
      page: number,
      totalResultsCount: number,
      searchTerm: string,
    });
  }
}

export const ContactsStore = new ContactsStoreClass();
