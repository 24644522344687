import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('submission');

export const getSubmission = async submissionId => {
  const { data } = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/submissions/${submissionId}`
  );
  return { data };
};

export const patchSubmission = async (submissionId, body) => {
  const { data } = await api.PATCH(`/submissions/${submissionId}`, body);
  return { data };
};

export const postSendSubmissionEmail = async (submissionId, body = {}) => {
  const { data } = await api.POST(
    `/submissions/${submissionId}/sendSubmissionEmail`,
    body
  );

  return { data };
};
