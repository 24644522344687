import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

export const DataGridLicenceKey =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-062162}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Axiom_Law}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Axiom}_only_for_{9}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Axiom}_need_to_be_licensed___{Axiom}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_June_2025}____[v3]_[01]_MTc1MDQ2MDQwMDAwMA==8b9882093004a8b6ff0b98ddf5716a24';

const LicenseContext = createContext<boolean>(false);

export const DataGridLicenseContextProvider: FC<PropsWithChildren> = ({
  children,
}) => <LicenseContext.Provider value>{children}</LicenseContext.Provider>;

export const useDataGridLicenseContext = () => useContext(LicenseContext);
