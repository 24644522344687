import React, { FC } from 'react';
import { CondensedHeader, CondensedLarge, Flashy } from '@axiom/ui';

import { AuthApi } from '../../api/auth';
import { EnvUtil } from '../../utils/env-util';

import { useSessionExpiryContext } from './SessionExpiryContext';
import { SessionExpirySignIn } from './SessionExpiryStyles';
import { convertSeconds } from './session-expiry-util';

export const SessionExpiryToast: FC = () => {
  const expiresInSeconds = useSessionExpiryContext();

  if (expiresInSeconds > 0) {
    const formattedTimeLeft = convertSeconds(expiresInSeconds);

    return (
      <>
        <CondensedHeader>Your session is expiring soon</CondensedHeader>
        <CondensedLarge>
          Current session expires in <Flashy bold>{formattedTimeLeft}</Flashy>
        </CondensedLarge>
        <CondensedLarge>
          Save your work and click here to re-authenticate.{' '}
          <SessionExpirySignIn onClick={() => AuthApi.refreshToken()}>
            Sign in
          </SessionExpirySignIn>
        </CondensedLarge>
      </>
    );
  }

  return (
    <>
      <CondensedHeader>Your session has expired</CondensedHeader>
      <CondensedLarge>
        Use the following link to re-authenticate.{' '}
        <a href={EnvUtil.myAppsUrl} target="_blank" rel="noreferrer">
          Sign in
        </a>
      </CondensedLarge>
    </>
  );
};
