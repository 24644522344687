import { Api } from '@axiom/ui';
import { PracticeArea } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('practice area');

export const getPracticeAreas = async () => {
  const { data } = await api.GET('/practiceAreas');
  return { data };
};

export default {
  getPracticeAreas,
};

class PracticeAreaApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readPracticeAreas() {
    return super.read<{ data: Array<PracticeArea> }>({
      endpoint: '/practiceAreas',
      method: 'GET',
    });
  }
}

export const PracticeAreaApi = new PracticeAreaApiClass();
