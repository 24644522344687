import APIHelper from './APIHelper';

const api = new APIHelper('banner message');

export const getBannerMessages = async appName => {
  const { data } = await api.GET(`/bannerMessages/${appName}`);
  return { data };
};

export default {
  getBannerMessages,
};
