/* eslint-disable max-classes-per-file */
import { GenericDto } from './generic-dto';

const MetaDto = class MetaDto extends GenericDto {
  constructor(body) {
    super(body, ['page', 'search', 'sort']);
  }
};

const FiltersRequestDto = class FiltersRequestDto extends GenericDto {
  constructor(body) {
    super(body, [
      'axiomEmployeeType',
      'barredLocations',
      'compensation.end',
      'compensation.includeEmptyValues',
      'compensation.start',
      'employeeType',
      'experiences.tags',
      'homeOfficeId',
      'id',
      'industries',
      'integrationId',
      'isOpenToAdHoc.includeEmptyValues',
      'isOpenToAdHoc.value',
      'isOpenToCommute.includeEmptyValues',
      'isOpenToCommute.value',
      'isOpenToFullTime.includeEmptyValues',
      'isOpenToFullTime.value',
      'isOpenToPartTime.includeEmptyValues',
      'isOpenToPartTime.value',
      'isOpenToRemote.includeEmptyValues',
      'isOpenToRemote.value',
      'languages',
      'occupationType',
      'ownerUserId',
      'practiceAreaId',
      'profileStatus',
      'soonestEngagementEndDate.end',
      'soonestEngagementEndDate.start',
      'tags',
      'weeklyAvailability.end',
      'weeklyAvailability.start',
      'yearsOfExperience.end',
      'yearsOfExperience.includeEmptyValues',
      'yearsOfExperience.start',
    ]);
  }
};

export const CandidateFiltersRequestDto = class CandidateFiltersRequestDto {
  constructor(body) {
    this.data = {
      filters: {
        ...new FiltersRequestDto(body.filters).export(),
      },
      ...new MetaDto(body).export(),
    };
  }

  export() {
    return this.data;
  }
};
