import { string, number, arrayOf, shape } from 'prop-types';

import { talentShape } from './talent';

export const positionShape = shape({
  baselineBillRate: number,
  baselineCalculatedPrice: number,
  baselineCost: number,
  baselineHourlyRate: number,
  baselineListCost: number,
  baselineListPrice: number,
  baselineMargin: number,
  candidates: arrayOf(talentShape),
  clientFullWorkingDay: number,
  demandDriver: string,
  description: string,
  durationTerms: string,
  endDate: string,
  id: string,
  legalNonLegal: string,
  monthlyExpectedQty: number,
  monthlyRate: number,
  name: string,
  reservedType: string,
  role: string,
  rsvdBillingUnitsPerDay: string,
  startDate: string,
  talentExperienceYears: string,
  talentType: string,
  targetMargin: string,
  unitsPerWeek: string,
  utilization: number,
  worksite: string,
});
