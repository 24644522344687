import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const ALIListItem = styled.li`
  background: ${getThemeProp('appBackground')};
  border: 1px solid ${getThemeProp('colorPrimary')};
  margin: 0 0 0.75em;
  position: relative;
`;

export const ALIContent = styled.div`
  display: flex;
  align-items: center;
  height: 3.5em;
  padding-left: 3em;
  padding-right: 0.5em;
  position: relative;
  text-align: left;
  width: 100%;

  svg {
    position: absolute;
    left: -40px;
    top: -4px;
    height: 4.5em;
  }
`;

export const ALIAuthor = styled.span`
  span {
    &:not(:empty)::before {
      content: ', ';
    }
  }
`;

export const ALIDate = styled.time`
  flex-grow: 1;
  text-align: right;
`;
