import React from 'react';
import { shape, exact, oneOf, bool } from 'prop-types';
import { Label, Text, Tooltip } from '@axiom/ui';

import { talentShape } from '../../models/talent';
import { DateUtil } from '../../utils/date-util';

import {
  TalentProfileStatusWrapper,
  TPSProfileUpdate,
  TPSProfileName,
} from './TalentProfileStatusStyles';

export const REASON_FOR_NO_EDIT = {
  NO_PERMISSION: 'NO_PERMISSION',
  CAN_NOT_CHANGE: 'CAN_NOT_CHANGE',
};

const TalentProfileStatus = ({ usersById, talent, editStatus }) => {
  const {
    profileStatus,
    profileStatusLastUpdatedBy,
    profileStatusUpdatedAt,
    profileStatusUpdatedByUser,
  } = talent;

  const tooltipMessage = () => {
    let data = '';

    if (!editStatus.canEdit) {
      if (editStatus.reason === REASON_FOR_NO_EDIT.NO_PERMISSION) {
        data = 'Reach out to Talent Owner to update';
      } else if (editStatus.reason === REASON_FOR_NO_EDIT.CAN_NOT_CHANGE) {
        data = 'Profile Status cannot be changed from an automated status';
      }
    }

    return data;
  };

  const statusDisplay = () => {
    const { canEdit } = editStatus;
    const children = canEdit ? (
      'Profile Status'
    ) : (
      <Tooltip
        anchor={<span>Profile Status</span>}
        name="profile status tooltip"
      >
        <span>{tooltipMessage()}</span>
      </Tooltip>
    );

    return (
      <>
        <Label>{children}</Label>
        <Text name="PROFILE_STATUS">{profileStatus}</Text>
      </>
    );
  };

  const getEditorName = () => {
    if (profileStatusLastUpdatedBy) {
      return (
        profileStatusUpdatedByUser?.fullName ||
        usersById[profileStatusLastUpdatedBy]?.fullName ||
        null
      );
    }

    return 'Profile Default';
  };

  return (
    <TalentProfileStatusWrapper>
      {statusDisplay()}
      <TPSProfileUpdate
        size="small"
        className="font-style-italic"
        name="PROFILE_STATUS_UPDATED_AT"
        data-value={profileStatusUpdatedAt}
      >
        Last Edited {DateUtil.displayTimestamp(profileStatusUpdatedAt)}
      </TPSProfileUpdate>
      <TPSProfileName
        size="small"
        className="font-style-italic"
        name="PROFILE_STATUS_UPDATED_BY"
      >
        {getEditorName()}
      </TPSProfileName>
    </TalentProfileStatusWrapper>
  );
};

TalentProfileStatus.defaultProps = {
  talent: {},
  editStatus: {
    canEdit: false,
    reason: '',
  },
};

TalentProfileStatus.propTypes = {
  talent: talentShape,
  usersById: shape({}).isRequired,
  editStatus: exact({
    canEdit: bool.isRequired,
    reason: oneOf([
      REASON_FOR_NO_EDIT.NO_PERMISSION,
      REASON_FOR_NO_EDIT.CAN_NOT_CHANGE,
    ]),
  }),
};

export default TalentProfileStatus;
