import { window } from '../../utils/global';

export function byId(columns) {
  return columns.reduce((c, i) => {
    c[i.id] = i;
    return c;
  }, {});
}

export function setColumnsPreferences({ columns = [], localStorageKey }) {
  const localStorageItem =
    JSON.parse(window.localStorage.getItem(localStorageKey) || null) || {};
  localStorageItem.columns = columns.map(({ id, width, orderIndex }) => ({
    id,
    width,
    orderIndex,
  }));
  window.localStorage.setItem(
    localStorageKey,
    JSON.stringify(localStorageItem)
  );
}

export function columnsWithPreferences({ _columns = [], localStorageKey }) {
  const columnsById = byId(_columns);
  const columnsPreferences =
    (JSON.parse(window.localStorage.getItem(localStorageKey) || null) || {})
      .columns || [];

  const defaultProps = {
    width: 'auto',
    resizable: true,
    reorderable: true,
  };

  const prefsMatchColumns =
    columnsPreferences.length === _columns.length &&
    columnsPreferences.every(c => !!columnsById[c.id]);

  if (!prefsMatchColumns) {
    return _columns.map((_column, orderIndex) => ({
      orderIndex,
      ...defaultProps,
      ..._column,
    }));
  }

  return columnsPreferences.map((columnPreference, orderIndex) => ({
    orderIndex,
    ...defaultProps,
    ...columnsById[columnPreference.id],
    ...columnPreference,
  }));
}
