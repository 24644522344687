// =====================
// APP-LEVEL EVENT TYPES
// =====================

export const APP_LOADING = 'APP_LOADING';
export const APP_ERROR = 'APP_ERROR';
export const APP_REQUEST_INITIAL_DATA = 'APP_REQUEST_INITIAL_DATA';
export const APP_RECEIVE_INITIAL_DATA = 'APP_RECEIVE_INITIAL_DATA';
export const APP_SIDE_BAR_COLLAPSE = 'APP_SIDE_BAR_COLLAPSE';
export const APP_SIDE_BAR_EXPAND = 'APP_SIDE_BAR_EXPAND';
export const APP_SIDE_BAR_ACTIVE = 'APP_SIDE_BAR_ACTIVE';
export const APP_SIDE_BAR_INACTIVE = 'APP_SIDE_BAR_INACTIVE';
export const APP_SIDE_DRAWER_ACTIVE = 'APP_SIDE_DRAWER_ACTIVE';
export const APP_SIDE_DRAWER_INACTIVE = 'APP_SIDE_DRAWER_INACTIVE';
export const APP_ADD_ACTIVE_RECORD = 'APP_ADD_ACTIVE_RECORD';
export const API_ERROR_RECEIVED = 'API_ERROR_RECEIVED';

// APP-LEVEL STATE CONSTANTS
export const OPPORTUNITY_RECORD_TYPE = 'opportunity';
export const TALENT_RECORD_TYPE = 'talent';
export const RELATED_RECORD_TYPE = 'related';

// ===========
// EVENT TYPES
// ===========

// Opportunities
export const OPPORTUNITIES_ERROR = 'OPPORTUNITIES_ERROR';
export const OPPORTUNITIES_SAVE = 'OPPORTUNITIES_SAVE';
export const OPPORTUNITIES_SAVE_SUCCESS = 'OPPORTUNITIES_SAVE_SUCCESS';
export const OPPORTUNITIES_SAVE_ERROR = 'OPPORTUNITIES_SAVE_ERROR';
export const GET_DECORATED_OPPORTUNITY = 'GET_DECORATED_OPPORTUNITY';
export const DECORATED_OPPORTUNITY_LOADED = 'DECORATED_OPPORTUNITY_LOADED';
export const DECORATED_OPPORTUNITY_ERROR = 'DECORATED_OPPORTUNITY_ERROR';
export const RELATED_OPPORTUNITIES_LOAD = 'RELATED_OPPORTUNITIES_LOAD';
export const RELATED_OPPORTUNITIES_LOADED = 'RELATED_OPPORTUNITIES_LOADED';
export const RELATED_OPPORTUNITIES_ERROR = 'RELATED_OPPORTUNITIES_ERROR';
export const OPPORTUNITY_PARENT_SAVE = 'OPPORTUNITY_PARENT_SAVE';

// User
export const USER_OPPORTUNITIES_LOAD = 'USER_OPPORTUNITIES_LOAD';
export const USER_OPPORTUNITIES_LOADED = 'USER_OPPORTUNITIES_LOADED';
export const USER_OPPORTUNITIES_LOAD_ERROR = 'USER_OPPORTUNITIES_LOAD_ERROR';

export const USER_TALENT_LOADED = 'USER_TALENT_LOADED';
export const USER_TALENT_LOAD_ERROR = 'USER_TALENT_LOAD_ERROR';
export const USER_TALENT_ACTIVITY_CREATED = 'USER_TALENT_ACTIVITY_CREATED';
export const USER_TALENT_ACTIVITY_UPDATED = 'USER_TALENT_ACTIVITY_UPDATED';

// Users
export const USERS_LOAD = 'USERS_LOAD';
export const USERS_LOADED = 'USERS_LOADED';
export const USERS_LOAD_ERROR = 'USERS_LOAD_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const USER_GROUPS_LOAD = 'USER_GROUPS_LOAD';
export const USER_GROUPS_LOADED = 'USER_GROUPS_LOADED';
export const USER_GROUPS_ERROR = 'USER_GROUPS_ERROR';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_GROUPS_ERROR = 'SET_USER_GROUPS_ERROR';
export const SET_USER_GROUPS_SUCCESS = 'SET_USER_GROUPS_SUCCESS';

// Talent
export const TALENT_LOADED = 'TALENT_LOADED';
export const TALENT_ERROR = 'TALENT_ERROR';
export const TALENT_SAVE_SUCCESS = 'TALENT_SAVE_SUCCESS';
export const TALENT_SAVE_ERROR = 'TALENT_SAVE_ERROR';
export const SINGLE_TALENT_LOAD = 'SINGLE_TALENT_LOAD';
export const SINGLE_TALENT_LOADED = 'SINGLE_TALENT_LOADED';
export const UPDATE_TALENT = 'UPDATE_TALENT';

export const TALENT_OPPORTUNITIES_SAVE = 'TALENT_OPPORTUNITIES_SAVE';

export const TALENT_OPPORTUNITIES_EDIT = 'TALENT_OPPORTUNITIES_EDIT';
export const TALENT_OPPORTUNITIES_EDIT_SUCCESS =
  'TALENT_OPPORTUNITIES_EDIT_SUCCESS';
export const TALENT_OPPORTUNITIES_EDIT_ERROR =
  'TALENT_OPPORTUNITIES_EDIT_ERROR';

export const GET_DECORATED_TALENT = 'GET_DECORATED_TALENT';
export const DECORATED_TALENT_LOADED = 'DECORATED_TALENT_LOADED';
export const DECORATED_TALENT_ERROR = 'DECORATED_TALENT_ERROR';

// Searches
export const SEARCHES_SAVE = 'SEARCHES_SAVE';
export const SEARCHES_SAVED = 'SEARCHES_SAVED';
export const SEARCH_SAVE_ERROR = 'SEARCHES_SAVE_ERROR';

// Activities

export const OPPORTUNITY_COLLABORATORS_LOAD = 'OPPORTUNITY_COLLABORATORS_LOAD';
export const OPPORTUNITY_COLLABORATORS_LOADED =
  'OPPORTUNITY_COLLABORATORS_LOADED';
export const OPPORTUNITY_COLLABORATORS_LOAD_ERROR =
  'OPPORTUNITY_COLLABORATORS_LOAD_ERROR';

export const CANDIDATE_COLLABORATORS_LOAD = 'CANDIDATE_COLLABORATORS_LOAD';
export const CANDIDATE_COLLABORATORS_LOADED = 'CANDIDATE_COLLABORATORS_LOADED';
export const CANDIDATE_COLLABORATORS_LOAD_ERROR =
  'CANDIDATE_COLLABORATORS_LOAD_ERROR';

export const OPPORTUNITY_LANGUAGES_LOAD = 'OPPORTUNITY_LANGUAGES_LOAD';
export const OPPORTUNITY_LANGUAGES_LOADED = 'OPPORTUNITY_LANGUAGES_LOADED';
export const OPPORTUNITY_LANGUAGES_LOAD_ERROR =
  'OPPORTUNITY_LANGUAGES_LOAD_ERROR';

// Candidate Education
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const DELETE_EDUCATION_ERROR = 'DELETE_EDUCATION_ERROR';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';

export const UPDATE_EDUCATION = 'UPDATE_EDUCATION';

// Opportunity Candidates
export const OPPORTUNITY_CANDIDATES_SAVE = 'OPPORTUNITY_CANDIDATES_SAVE';
export const OPPORTUNITY_CANDIDATES_SAVE_SUCCESS =
  'OPPORTUNITY_CANDIDATES_SAVE_SUCCESS';
export const OPPORTUNITY_CANDIDATES_SAVE_ERROR =
  'OPPORTUNITY_CANDIDATES_SAVE_ERROR';

export const OPPORTUNITY_CANDIDATES_EDIT = 'OPPORTUNITY_CANDIDATES_EDIT';
export const OPPORTUNITY_CANDIDATES_EDIT_SUCCESS =
  'OPPORTUNITY_CANDIDATES_EDIT_SUCCESS';
export const OPPORTUNITY_CANDIDATES_EDIT_ERROR =
  'OPPORTUNITY_CANDIDATES_EDIT_ERROR';

export const OPPORTUNITY_CANDIDATES_LOAD = 'OPPORTUNITY_CANDIDATES_LOAD';
export const OPPORTUNITY_CANDIDATES_LOAD_SUCCESS =
  'OPPORTUNITY_CANDIDATES_LOAD_SUCCESS';
export const OPPORTUNITY_CANDIDATES_LOAD_ERROR =
  'OPPORTUNITY_CANDIDATES_LOAD_ERROR';

// Companies
export const COMPANIES_LOAD = 'COMPANIES_LOAD';
export const COMPANIES_LOADED = 'COMPANIES_LOADED';
export const COMPANIES_LOAD_ERROR = 'COMPANIES_LOAD_ERROR';
export const COMPANY_LOAD = 'COMPANY_LOAD';
export const COMPANY_LOADED = 'COMPANY_LOADED';
export const COMPANY_LOAD_ERROR = 'COMPANY_LOAD_ERROR';
export const COMPANY_CREATE = 'COMPANY_SAVE';
export const COMPANY_CREATED = 'COMPANY_SAVED';
export const COMPANY_CREATE_ERROR = 'COMPANY_SAVE_ERROR';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';

// Barred Locations
export const BARRED_LOCATIONS_LOADED = 'BARRED_LOCATIONS_LOADED';
export const BARRED_LOCATIONS_LOAD_ERROR = 'BARRED_LOCATIONS_LOAD_ERROR';

// Groups
export const GROUPS_LOAD = 'GROUPS_LOAD';
export const GROUPS_LOAD_ERROR = 'GROUPS_LOAD_ERROR';
export const GROUPS_LOADED = 'GROUPS_LOADED';
export const GROUP_CREATE = 'GROUP_CREATE';
export const GROUP_CREATE_ERROR = 'GROUP_CREATE_ERROR';
export const GROUP_CREATED = 'GROUP_CREATED';
export const GROUP_DELETE = 'GROUP_DELETE';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';
export const GROUP_DELETED = 'GROUP_DELETED';
export const GROUP_UPDATE = 'GROUP_UPDATE';
export const GROUP_UPDATE_ERROR = 'GROUP_UPDATE_ERROR';
export const GROUP_UPDATED = 'GROUP_UPDATED';
export const GROUP_USER_ADD = 'GROUP_USER_ADD';
export const GROUP_USER_ADD_ERROR = 'GROUP_USER_ADD_ERROR';
export const GROUP_USER_ADDED = 'GROUP_USER_ADDED';
export const GROUP_USER_REMOVE = 'GROUP_USER_REMOVE';
export const GROUP_USER_REMOVE_ERROR = 'GROUP_USER_REMOVE_ERROR';
export const GROUP_USER_REMOVED = 'GROUP_USER_REMOVED';
export const GROUP_ADMIN_SELECT_GROUP = 'GROUP_ADMIN_SELECT_GROUP';
export const GROUP_ADMIN_SELECT_USER = 'GROUP_ADMIN_SELECT_USER';
export const GROUP_ADMIN_RESET_SELECTION = 'GROUP_ADMIN_RESET_SELECTION';

// Languages
export const LANGUAGES_LOADED = 'LANGUAGES_LOADED';
export const LANGUAGES_LOAD_ERROR = 'LANGUAGES_LOAD_ERROR';

// Home Offices
export const HOME_OFFICE_LOADED = 'HOME_OFFICE_LOADED';
export const HOME_OFFICE_LOAD_ERROR = 'HOME_OFFICE_LOAD_ERROR';
export const HOME_OFFICES_LOADED = 'HOME_OFFICES_LOADED';
export const HOME_OFFICES_LOAD_ERROR = 'HOME_OFFICES_LOAD_ERROR';

// Admin
export const USER_MGMT_CHANGE_SELECTED = 'USER_MGMT_CHANGE_SELECTED';
export const USER_MGMT_RESET_SELECTED = 'USER_MGMT_RESET_SELECTED';

// Downloads
export const DOWNLOAD_USER_OPPORTUNITY_LIST = 'DOWNLOAD_USER_OPPORTUNITY_LIST';
export const DOWNLOAD_USER_TALENT_LIST = 'DOWNLOAD_USER_TALENT_LIST';
export const DOWNLOAD_ERROR = 'DOWNLOAD_ERROR';

// Banner Messages
export const BANNER_MESSAGES_LOAD = 'BANNER_MESSAGES_LOAD';
export const BANNER_MESSAGES_LOADED = 'BANNER_MESSAGES_LOADED';
export const BANNER_MESSAGES_LOAD_ERROR = 'BANNER_MESSAGES_LOAD_ERROR';

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_POLL = 'GET_NOTIFICATIONS_POLL';
export const GET_NOTIFICATIONS_POLL_SUCCESS = 'GET_NOTIFICATIONS_POLL_SUCCESS';
export const GET_NOTIFICATIONS_POLL_ERROR = 'GET_NOTIFICATIONS_POLL_ERROR';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';
export const UPDATE_ALL_NOTIFICATIONS = 'UPDATE_ALL_NOTIFICATIONS';
export const UPDATE_ALL_NOTIFICATIONS_SUCCESS =
  'UPDATE_ALL_NOTIFICATIONS_SUCCESS';
export const UPDATE_ALL_NOTIFICATIONS_ERROR = 'UPDATE_ALL_NOTIFICATIONS_ERROR';

// ==========
// FORM NAMES
// ==========

export const ADD_EDUCATION = 'addEducation';
export const ADMIN_ADD_GROUP = 'adminAddGroup';
export const ADMIN_ADD_SUBGROUP = 'adminAddSubGroup';
export const ADMIN_EDIT_GROUP = 'adminEditGroup';
export const EDIT_EDUCATION = 'editEducation';
export const OPPORTUNITY_CLOSED_WON = 'opportunityClosedWon';
export const OPPORTUNITY_EDIT = 'editOpportunity';
export const SAVED_SEARCH_ENDPOINT = 'saveSearch';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SEARCHES_SUBMIT = 'SEARCHES_SUBMIT';
export const TALENT_EDIT = 'editTalent';
export const WORKSPACE = 'workspace';
