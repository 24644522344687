import { stringify } from 'qs';
import requestLib from 'superagent';
import { CookieTokenRefresher } from '@axiom/auth';
import isPlainObject from 'lodash/isPlainObject';
import { CookieUtil } from '@axiom/ui';

import { FormData } from '../utils/global';
import { EnvUtil } from '../utils/env-util';
import { PreloadedAppErrorsStore } from '../stores/preloaded-app-errors-store';

import { BANNER_MESSAGE_API_NAME, NOTIFICATIONS } from './constants';
import {
  APIError,
  BadRequestError,
  AuthorizationError,
  AuthenticationError,
} from './errors';

// API calls that shouldn't reset the inactivity timeout.
const API_CALLS_TO_SKIP = [BANNER_MESSAGE_API_NAME, NOTIFICATIONS];

let cookieRefresher;
if (process.browser) {
  cookieRefresher = new CookieTokenRefresher({
    cookieDomain: () => `${EnvUtil.cookieDomain}`,
  });
}

async function request(name, endpoint, method, body, headers = {}) {
  try {
    if (process.browser && !API_CALLS_TO_SKIP.includes(name)) {
      cookieRefresher.resetTimeout();
    }

    const formattedEndpoint = endpoint.startsWith('https')
      ? endpoint
      : `${EnvUtil.clientApiBase}${endpoint}`;

    const requestObj = requestLib[method.toLowerCase()](formattedEndpoint)
      .set({
        ...(body instanceof FormData
          ? {}
          : { 'Content-Type': 'application/json' }),
        ...headers,
      })
      .withCredentials();

    const response = await requestObj.send(body);
    return response.body;
  } catch (e) {
    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      headers,
      response,
    };

    let error;

    switch (response.status) {
      case 400:
        error = new BadRequestError(obj);
        break;
      case 403:
        error = new AuthenticationError(obj);
        break;
      case 401:
        CookieUtil.clearUserAndReload(EnvUtil.cookieDomain);
        throw new AuthorizationError(obj);
      default:
        error = new APIError(obj);
    }

    PreloadedAppErrorsStore.show(
      error.applicationError && error.applicationError.userErrorText
        ? error.applicationError.userErrorText
        : 'A network error has occurred. Please refresh your browser.'
    );
    throw error;
  }
}

/**
 * For the Java API, objects need to be an encoded JSON string. Here, we extract and
 * encode each object and append it to the existing query string
 * @param {*} query The query string containing the potential object(s) to be
 * encoded
 */
export const encodeApiObjects = query => {
  const serializedObjects = [];
  const updatedQuery = {};

  for (const property in query) {
    if (query[property] && typeof query[property] === 'object') {
      serializedObjects.push(
        [property, encodeURIComponent(JSON.stringify(query[property]))].join(
          '='
        )
      );
    } else {
      updatedQuery[property] = query[property];
    }
  }

  return [stringify(updatedQuery), ...serializedObjects].join('&');
};

export const get = (name, endpoint, body, encodeFilters = false) =>
  request(
    name,
    `${endpoint}/?${encodeFilters ? encodeApiObjects(body) : stringify(body)}`,
    'GET'
  );

export const post = (name, endpoint, body) =>
  request(name, endpoint, 'POST', JSON.stringify(body));

export const put = (name, endpoint, body) =>
  request(name, endpoint, 'PUT', JSON.stringify(body));

export const patch = (name, endpoint, body) => {
  // See if body is blank object and just resolve peacefully
  try {
    if (isPlainObject(body) && Object.keys(body).length === 0) {
      return Promise.resolve(body);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return request(name, endpoint, 'PATCH', JSON.stringify(body));
};

export const httpDelete = (name, endpoint) => request(name, endpoint, 'DELETE');

export const multiPost = (name, endpoint, body) => {
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    const prop = body[key];
    if (Array.isArray(prop)) {
      prop.forEach(val => formData.append(`${key}[]`, val));
    } else {
      formData.append(key, prop);
    }
  });
  return request(name, endpoint, 'POST', formData);
};

export const multiPatch = (name, endpoint, body) => {
  const formData = new FormData();

  Object.keys(body).forEach(key => {
    const prop = body[key];
    if (Array.isArray(prop)) {
      if (prop.length) {
        prop.forEach(val => formData.append(`${key}[]`, val));
      } else {
        formData.set(`${key}[]`, '');
      }
    } else {
      formData.append(key, prop);
    }
  });

  /**
   * If we patch and nothing has changed, just resolve
   */
  if ([...formData.keys()].length === 0) {
    return Promise.resolve(body);
  }
  return request(name, endpoint, 'PATCH', formData);
};

// NOTE: Right now this just curries API names, but in the future could be
// extended to allow APIs to pass other custom data/callbacks
export default class APIHelper {
  constructor(resourceName) {
    this.name = resourceName;
  }

  GET(...args) {
    return get(this.name, ...args);
  }

  POST(...args) {
    return post(this.name, ...args);
  }

  PUT(...args) {
    return put(this.name, ...args);
  }

  PATCH(...args) {
    return patch(this.name, ...args);
  }

  DELETE(...args) {
    return httpDelete(this.name, ...args);
  }

  MULTIPOST(...args) {
    return multiPost(this.name, ...args);
  }

  MULTIPATCH(...args) {
    return multiPatch(this.name, ...args);
  }
}
