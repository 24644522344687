import { shape, string, bool } from 'prop-types';
import { OpportunitiesConst, Opportunity } from '@axiom/const';

import { getOpportunitiesByUserId } from '../api/userOpportunities';
import {
  ALL,
  ACCOUNT_MANAGER_OWNER_OR_SALES_LEAD,
  CLOSED_LOST,
  CLOSED_WON,
  COLLABORATOR,
  OWNER,
  TEAM,
} from '../api/constants';
import AbstractTableStore from '../classes/abstract-table-store';
import { opportunityShape } from '../models/opportunities';

const { StageCodes } = OpportunitiesConst;
const { STAGE_CODE_MAP } = Opportunity;

export const UserOpportunitiesStoreMetaConst = {
  assigneeType: {
    all: ALL,
    accountManagerOwnerOrSalesLead: ACCOUNT_MANAGER_OWNER_OR_SALES_LEAD,
    owner: OWNER,
    collaborator: COLLABORATOR,
    team: TEAM,
  },
  sort: {
    opportunityName: 'jobName',
    accountName: 'accountName',
    closeDate: 'salesCloseDate',
    stage: 'stageCode',
    positions: 'positions',
    teLead: 'ownerUser',
    salesLeadId: 'salesLead',
  },
  isFulfillmentActive: {
    all: 'all',
    active: 'true',
    inactive: 'false',
  },
};

class UserOpportunitiesStoreClass extends AbstractTableStore {
  getDefaultFilters() {
    return {
      assigneeType:
        UserOpportunitiesStoreMetaConst.assigneeType
          .accountManagerOwnerOrSalesLead,
      isFulfillmentActive:
        UserOpportunitiesStoreMetaConst.isFulfillmentActive.active,
    };
  }

  loadByConfig(userId, state) {
    const filters = this.getFiltersFromState(state);

    if (
      this.shouldUpdateQueryParams(
        filters,
        UserOpportunitiesStoreMetaConst.sort.stage
      )
    ) {
      this.overrideFilters(filters, true);
    } else {
      this._sendRequest({
        meta: {
          sort: this.getSort(),
          searchConfig: {
            filters: this.getFilters(),
          },
          userId,
        },
      });
    }
  }

  formatMetaForRequest(meta) {
    const query = {};

    if (meta.sort) {
      query.sort = meta.sort;
    }

    query.filters = {};
    query.stageCode = StageCodes.filter(code => {
      return ![
        STAGE_CODE_MAP[CLOSED_WON],
        STAGE_CODE_MAP[CLOSED_LOST],
      ].includes(code);
    });

    if (meta.searchConfig?.filters.assigneeType) {
      query.assigneeType = meta.searchConfig.filters.assigneeType;
    }

    if (
      meta.searchConfig?.filters.isFulfillmentActive &&
      meta.searchConfig.filters.isFulfillmentActive !== 'all'
    ) {
      query.isFulfillmentActive = meta.searchConfig.filters.isFulfillmentActive;
    }

    if (meta.userId) {
      query.userId = meta.userId;
    }

    query.page = meta.serverMeta.currentPage || 1;

    return query;
  }

  getResults(query) {
    return getOpportunitiesByUserId(query).then(payload => {
      return {
        data: payload.data,
        meta: payload.meta,
      };
    });
  }

  getSort() {
    return this.getFilters().sort || UserOpportunitiesStoreMetaConst.sort.stage;
  }
}

export const UserOpportunitiesStore = new UserOpportunitiesStoreClass({
  filterShape: shape({
    assigneeType: string,
    isFulfillmentActive: bool,
  }),
  dataShape: opportunityShape,
});
