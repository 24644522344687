import {
  OPPORTUNITIES_ERROR,
  OPPORTUNITIES_SAVE_SUCCESS,
  OPPORTUNITY_CANDIDATES_LOAD_SUCCESS,
  OPPORTUNITY_CANDIDATES_SAVE,
  OPPORTUNITY_CANDIDATES_SAVE_SUCCESS,
  OPPORTUNITY_CANDIDATES_SAVE_ERROR,
  OPPORTUNITY_CANDIDATES_EDIT,
  OPPORTUNITY_CANDIDATES_EDIT_SUCCESS,
  OPPORTUNITY_CANDIDATES_EDIT_ERROR,
  GET_DECORATED_OPPORTUNITY,
  RELATED_OPPORTUNITIES_LOADED,
  RELATED_OPPORTUNITIES_ERROR,
} from '../constants';

export const getDecoratedOpportunity = opportunityId => ({
  type: GET_DECORATED_OPPORTUNITY,
  payload: { opportunityId },
});

export const opportunitiesError = payload => ({
  type: OPPORTUNITIES_ERROR,
  error: true,
  payload,
});

export const opportunitySaveSuccess = payload => ({
  type: OPPORTUNITIES_SAVE_SUCCESS,
  payload,
});

export const opportunityCandidatesLoadSuccess = payload => ({
  type: OPPORTUNITY_CANDIDATES_LOAD_SUCCESS,
  payload,
});

export const opportunityCandidatesSave = payload => ({
  type: OPPORTUNITY_CANDIDATES_SAVE,
  payload,
});

export const opportunityCandidatesSaveSuccess = payload => ({
  type: OPPORTUNITY_CANDIDATES_SAVE_SUCCESS,
  payload,
});

export const opportunityCandidatesSaveError = payload => ({
  type: OPPORTUNITY_CANDIDATES_SAVE_ERROR,
  error: true,
  payload,
});

export const opportunityCandidatesEdit = payload => ({
  type: OPPORTUNITY_CANDIDATES_EDIT,
  payload,
});

export const opportunityCandidatesEditSuccess = payload => ({
  type: OPPORTUNITY_CANDIDATES_EDIT_SUCCESS,
  payload,
});

export const opportunityCandidatesEditError = payload => ({
  type: OPPORTUNITY_CANDIDATES_EDIT_ERROR,
  error: true,
  payload,
});

export const relatedOpportunitiesLoaded = payload => ({
  type: RELATED_OPPORTUNITIES_LOADED,
  payload,
});

export const relatedOpportunitiesError = payload => ({
  type: RELATED_OPPORTUNITIES_ERROR,
  error: true,
  payload,
});
