import styled from 'styled-components';

export const CheckboxWithLabelWrapper = styled.div`
  cursor: pointer;
  vertical-align: middle;
  margin-left: 1rem;
  display: inline-flex;
  align-items: center;
`;

export const CWLLabel = styled.label`
  margin-left: 1rem;
`;
