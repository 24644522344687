export const EducationUtils = {
  sortDisplay: (degrees = []) =>
    degrees.sort((a, b) => {
      if (a?.isPrimary) return -1;
      if (b?.isPrimary) return 1;
      if (a?.yearAwarded === b?.yearAwarded) {
        return a?.institution?.localeCompare(b?.institution);
      }
      return a?.yearAwarded > b?.yearAwarded ? -1 : 1;
    }),
};
