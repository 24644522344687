import { SubmissionExperience } from '@axiom/validation';

export class SubmissionExperienceModel {
  props: SubmissionExperience = {};

  constructor(props: SubmissionExperience = {}) {
    this.props = props;
  }

  calculatedDescription() {
    return (
      this.props.submissionDescription ||
      this.props.description ||
      ''
    ).trim();
  }
}
