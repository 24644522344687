import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Label } from '@axiom/ui';

import { FFError, isErroring } from '../FFError/FFError';
import { formatDataTestId } from '../../utils/dataTest';
import MultiSelect from '../MultiSelect/MultiSelect';
import { FlexDiv } from '../../themes/components';
import { DropdownOptionsShape } from '../../models/dropdown-options';

import { FFMSErrorWrapper, FFMSWrapper } from './FFMultiSelectStyles';

const FFMultiSelect = ({
  input,
  disabled,
  label,
  meta,
  className,
  options,
  textField,
  dataItemKey,
  filterable,
}) => (
  <FFMSWrapper
    className={className}
    disabled={disabled}
    data-test={formatDataTestId(
      `${input.name || label || 'multi_select'}_wrapper`
    )}
  >
    <FlexDiv>{label && <Label htmlFor={input.name}>{label}</Label>}</FlexDiv>
    <MultiSelect
      name={input.name}
      value={input.value}
      disabled={disabled}
      onChange={values => {
        input.onChange(values);
      }}
      onBlur={values => input.onBlur(values)}
      valid={isErroring(meta) ? false : null}
      className={className}
      options={options}
      dataItemKey={dataItemKey}
      textField={textField}
      filterable={filterable}
    />
    <FlexDiv>
      <FFMSErrorWrapper>
        <FFError finalFormElementMeta={meta} />
      </FFMSErrorWrapper>
    </FlexDiv>
  </FFMSWrapper>
);

FFMultiSelect.propTypes = {
  options: DropdownOptionsShape,
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  disabled: bool,
  label: string,
  className: string,
  dataItemKey: string,
  textField: string,
  filterable: bool,
};

FFMultiSelect.defaultProps = {
  options: MultiSelect.defaultProps.data,
  disabled: false,
  label: null,
  className: null,
  dataItemKey: MultiSelect.defaultProps.dataItemKey,
  textField: MultiSelect.defaultProps.textField,
  filterable: true,
};

export default FFMultiSelect;
