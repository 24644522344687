import React, { useCallback, useState } from 'react';
import { z } from 'zod';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Form,
  Input,
  Button,
  Gutter,
} from '@axiom/ui';
import {
  Experience,
  Opportunity,
  OpportunityPosition,
} from '@axiom/validation';

import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';

import { ConvertToAxiomExperienceConfirmation } from './ConvertToAxiomExperienceConfirmation';

const urlInputFormSchema = z.object({
  url: z.string(),
});
export const ConvertToAxiomExperience: React.FC<{
  onClose: () => void;
  experience: Experience;
}> = ({ experience, onClose = () => {} }) => {
  const [opportunity, setOpportunity] = useState<Opportunity>(null);
  const [positions, setPositions] = useState<OpportunityPosition[]>(null);
  const resetAndClose = useCallback(() => {
    if (opportunity) {
      OpportunityLegacyApi.clearOpportunity(opportunity.id);
      OpportunityLegacyApi.clearOpportunityPositions(opportunity.id);
    }
    onClose();
  }, [onClose, opportunity]);
  return positions ? (
    <ConvertToAxiomExperienceConfirmation
      onClose={resetAndClose}
      experience={experience}
      opportunity={opportunity}
      positions={positions}
    />
  ) : (
    <Form
      schema={urlInputFormSchema}
      onSubmit={async (
        formData: z.infer<typeof urlInputFormSchema>,
        actions
      ) => {
        const errorMessage =
          'The URL you entered does not have an opportunity associated with it.';
        const opportunityId =
          /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i.exec(
            formData.url
          )?.[0] ?? null;
        if (formData.url && !opportunityId) {
          actions.setFieldError('url', errorMessage);
          return;
        }
        const opportunityResponse = await OpportunityLegacyApi.readOpportunity(
          opportunityId
        ).toPromise();
        if (!opportunityResponse) {
          actions.setFieldError('url', errorMessage);
          OpportunityLegacyApi.clearOpportunity(opportunityId);
          OpportunityLegacyApi.clearOpportunityPositions(opportunityId);
          return;
        }
        setOpportunity(opportunityResponse.data);
        const positionsResponse =
          await OpportunityLegacyApi.readOpportunityPositions(
            opportunityId
          ).toPromise();
        setPositions(positionsResponse.data);
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="convert-experience-input-url">
            <ModalHeader onClose={resetAndClose}>
              Convert to Axiom Engagement
            </ModalHeader>
            <ModalSection>
              <Gutter horizontal="8px" vertical="8px">
                <Input
                  description="Copy and paste the Axiom Platform Opportunity URL from browser associated with this talent’s past experience. (Example: https://envoy.apps.axiomlaw.com/opportunities/...)"
                  label="Opportunity URL"
                  name="url"
                />
              </Gutter>
            </ModalSection>
            <ModalFooter>
              <Button
                name="convert-experience-input-url-cancel"
                onClick={resetAndClose}
                variation="outline"
              >
                Cancel
              </Button>
              <Button
                name="convert-experience-input-url-next"
                onClick={fireSubmit}
              >
                Next
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
