import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import {
  getCandidateWhiteboard,
  putCandidateWhiteboard,
} from '../api/talentWhiteboard';
import { DateUtil } from '../utils/date-util';

const TalentAvailabilityNotesClassValidation = AjvUtil.compileSchema({
  properties: {
    availabilityNotes: {
      type: 'string',
    },
  },
});

class FormTalentAvailabilityNotesStoreClass extends FormStore {
  load(candidate) {
    this.setState(
      getCandidateWhiteboard(candidate.id).then(({ data }) => {
        const updatedAt = data?.updatedAt
          ? DateUtil.displayDateTimestamp(data.updatedAt)
          : null;

        return {
          // id and calculatedDisplayName are here for the reloads via form submit
          id: candidate.id,
          calculatedDisplayName: candidate.calculatedDisplayName,
          availabilityNotes: data?.body || null,
          updatedAt,
          updatedByUser: data?.updatedByUser || null,
        };
      })
    );
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      TalentAvailabilityNotesClassValidation,
      formData
    );
  }

  selectData(state) {
    return this.select(state)?.data || {};
  }

  submit(changedFields, formData) {
    /**
     * Only send when availabilityNotes have a value
     * empty string is a valid value
     * so we watch for undefined
     * */
    if (changedFields?.availabilityNotes !== undefined) {
      this.clearState(
        putCandidateWhiteboard(formData.id, {
          body: changedFields.availabilityNotes,
        })
      ).then(() => this.load(formData));
    }
  }
}

export const FormTalentAvailabilityNotesStore =
  new FormTalentAvailabilityNotesStoreClass();
