import { SUBMIT_FORM } from '../constants';

export const submitForm = (form, endpoint) => ({
  type: SUBMIT_FORM,
  payload: { endpoint },
  meta: {
    form,
  },
});

export default { submitForm };
