import { CandidatesConst } from '@axiom/const';

const {
  ProfileStatuses: {
    Active,
    Alum,
    AlumDNR,
    PendingAlum,
    PendingAlumDNR,
    Beach,
    PendingBeach,
    InDiligence,
    Waitlist,
    PendingActive,
    Certifying,
    Idle,
    NewLead,
    Reservoir,
    Rejected,
  },
} = CandidatesConst;

const ALUM_COLORS = {
  background: '#8C814A',
  border: '#665E36',
  color: '#FFF',
};
const PENDING_ALUM_COLORS = {
  background: '#534A8C',
  border: '#2E294E',
  color: '#FFF',
};
const ACTIVE_COLORS = {
  background: '#274C80',
  border: '#001D4C',
  color: '#FFF',
};
const BEACH_COLORS = {
  color: '#fff',
  background: '#A82528',
  border: '#A82528',
};
const REJECTED_COLORS = {
  background: '#2c3841',
  border: '#111519',
  color: '#FFF',
};
const CERTIFYING_COLORS = {
  background: '#2c7584',
  border: `#17444B`,
  color: '#FFF',
};
const IDLE_COLORS = {
  background: '#5b8160',
  border: `#37503A`,
  color: '#FFF',
};

export const CANDIDATE_STATUS_STYLES = {
  [Active]: ACTIVE_COLORS,
  [Alum]: ALUM_COLORS,
  [AlumDNR]: REJECTED_COLORS,
  [PendingAlum]: PENDING_ALUM_COLORS,
  [PendingAlumDNR]: REJECTED_COLORS,
  [Beach]: BEACH_COLORS,
  [Certifying]: CERTIFYING_COLORS,
  [Idle]: IDLE_COLORS,
  [InDiligence]: CERTIFYING_COLORS,
  [NewLead]: IDLE_COLORS,
  [PendingActive]: ACTIVE_COLORS,
  [PendingBeach]: BEACH_COLORS,
  [Rejected]: REJECTED_COLORS,
  [Reservoir]: IDLE_COLORS,
  [Waitlist]: CERTIFYING_COLORS,
};
