import { Opportunity } from '@axiom/validation';
import moment from 'moment';

export const TimeToSubmissionCopyUtil = (opportunity: Opportunity): string => {
  if (
    !opportunity.firstCandidateSubmittedAt &&
    opportunity.firstCandidateSelectedOrFurtherAt
  ) {
    return '';
  }
  if (opportunity.timeToSubmission.hours < 0) {
    return '';
  }
  if (opportunity.timeToSubmission.hours === 1) {
    return '1 HR';
  }
  if (
    opportunity.timeToSubmission.hours === 0 &&
    moment(opportunity.qualifiedAt) >
      moment(opportunity.firstCandidateSubmittedAt)
  ) {
    return '';
  }
  if (
    opportunity.timeToSubmission.hours >= 0 &&
    opportunity.timeToSubmission.hours < 24
  ) {
    return `${opportunity.timeToSubmission.hours} HRS`;
  }
  if (
    opportunity.timeToSubmission.hours >= 24 &&
    opportunity.timeToSubmission.hours < 48
  ) {
    return '1 DAY';
  }
  return `${Math.floor(opportunity.timeToSubmission.hours / 24)} DAYS`;
};
