import React, { useState, useEffect } from 'react';
import { string, number, func, bool, oneOfType } from 'prop-types';
import { Label, DataTest } from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';
import { DropdownOptionsShape } from '../../models/dropdown-options';

import { LabelWrapper, KendoAutoCompleteWrapper } from './AutoCompleteStyles';

const OptionComponent = (li, itemProps) => {
  const itemChildren = (
    <LabelWrapper data-value={itemProps.dataItem.value}>
      {li.props.children}
    </LabelWrapper>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const AutoComplete = ({
  options,
  id,
  name,
  valid,
  value: initialValue,
  label,
  disabled,
  type,
  onFilterChange,
  onChange,
  placeholder,
  className,
  textField,
  listNoDataRender,
}) => {
  const [value, setValue] = useState(placeholder);

  const handlerFilterChange = event => {
    setValue(event.target.value);
    onFilterChange(event);
  };

  const handleClose = event => {
    const selectedLabel = event.target.value;
    const selectedValue = options.find(
      ({ label: optionLabel }) => optionLabel === selectedLabel
    );
    if (selectedValue) {
      onChange(selectedValue);
    } else {
      setValue(placeholder);
    }
  };

  useEffect(() => {
    setValue(placeholder);
  }, [placeholder]);

  return (
    <DataTest value={id || name}>
      {label && (
        <Label invalid={!valid} htmlFor={id || name}>
          {label}
        </Label>
      )}
      <div
        disabled={disabled}
        // invalid
        data-test={formatDataTestId('autocomplete')}
        data-value={initialValue}
        id={id}
      >
        <KendoAutoCompleteWrapper
          type={type}
          name={name}
          onFilterChange={onFilterChange}
          placeholder={placeholder}
          valid={valid}
          className={className}
          data={options}
          defaultItem={{ label: placeholder }}
          disabled={disabled}
          itemRender={OptionComponent}
          onChange={handlerFilterChange}
          onClose={handleClose}
          textField={textField}
          value={value}
          popupSettings={{ animate: false }}
          listNoDataRender={listNoDataRender}
        />
      </div>
    </DataTest>
  );
};

AutoComplete.defaultProps = {
  className: null,
  options: [],
  disabled: false,
  id: null,
  label: null,
  name: undefined,
  onChange: () => {},
  onFilterChange: () => {},
  placeholder: '',
  textField: 'label',
  valid: true,
  value: undefined,
  type: undefined,
  listNoDataRender: undefined,
};

AutoComplete.propTypes = {
  className: string,
  onFilterChange: func,
  options: DropdownOptionsShape,
  disabled: bool,
  id: string,
  label: string,
  name: string,
  placeholder: oneOfType([string, number]),
  onChange: func,
  textField: string,
  valid: bool,
  value: string,
  type: string,
  listNoDataRender: func,
};

export default AutoComplete;
