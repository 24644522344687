import { Candidate } from '@axiom/const';

export const TALENT_FIELD_ACTIVITY_LOG = Candidate.ACTIVITY_LOG;
export const TALENT_FIELD_ADDRESS_1 = Candidate.ADDRESS_1;
export const TALENT_FIELD_ADDRESS_2 = Candidate.ADDRESS_2;
export const TALENT_FIELD_ADDRESS_CITY = Candidate.ADDRESS_CITY;
export const TALENT_FIELD_ADDRESS_COUNTRY = Candidate.ADDRESS_COUNTRY;
export const TALENT_FIELD_ADDRESS_COUNTRY_CODE = Candidate.ADDRESS_COUNTRY_CODE;
export const TALENT_FIELD_ADDRESS_DISPLAY_NAME = Candidate.ADDRESS_DISPLAY_NAME;
export const TALENT_FIELD_ADDRESS_STATE = Candidate.ADDRESS_STATE;
export const TALENT_FIELD_ADDRESS_ZIP = Candidate.ADDRESS_ZIP;
export const TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE =
  Candidate.ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE;
export const TALENT_FIELD_AVAILABILITY = Candidate.AVAILABILITY;
export const TALENT_FIELD_AVAILABILITY_NOTES = Candidate.AVAILABILITY_NOTES;
export const TALENT_FIELD_AXIOM_EMPLOYEE_TYPE = Candidate.AXIOM_EMPLOYEE_TYPE;
export const TALENT_FIELD_BARRED_LOCATIONS = Candidate.BARRED_LOCATIONS;
export const TALENT_FIELD_BULLHORN_ID = Candidate.BULLHORN_ID;
export const TALENT_FIELD_CALCULATED_DESIRED_WEEKLY_HOURS =
  Candidate.CALCULATED_DESIRED_WEEKLY_HOURS;
export const TALENT_FIELD_CANDIDACY_ENGAGEMENTS =
  Candidate.CANDIDACY_ENGAGEMENTS;
export const TALENT_FIELD_CANDIDACY_LIST = Candidate.CANDIDACY_LIST;
export const TALENT_FIELD_COLLABORATOR_ID = Candidate.COLLABORATOR_ID;
export const TALENT_FIELD_COLLABORATORS = Candidate.COLLABORATORS;
export const TALENT_FIELD_COMPENSATION = Candidate.COMPENSATION;
export const TALENT_FIELD_CREATED_AT = Candidate.CREATED_AT;
export const TALENT_FIELD_CURRENCY = Candidate.CURRENCY;
export const TALENT_FIELD_DAYS_BEACHED = Candidate.DAYS_BEACHED;
export const TALENT_FIELD_DAYS_DESIRED_REMOTE = Candidate.DAYS_DESIRED_REMOTE;
export const TALENT_FIELD_DAYS_REQUIRED_REMOTE = Candidate.DAYS_REQUIRED_REMOTE;
export const TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE =
  Candidate.DAYS_SINCE_STATUS_CHANGE;
export const TALENT_FIELD_DAYS_WILLING_TO_COMMUTE =
  Candidate.DAYS_WILLING_TO_COMMUTE;
export const TALENT_FIELD_EMPLOYEE_TYPE = Candidate.EMPLOYEE_TYPE;
export const TALENT_FIELD_EXPERIENCES = Candidate.EXPERIENCES;
export const TALENT_FIELD_FIRST_NAME = Candidate.FIRST_NAME;
export const TALENT_FIELD_FURTHEST_CANDIDACY_STATUS =
  Candidate.FURTHEST_CANDIDACY_STATUS;
export const TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT =
  Candidate.FURTHEST_CANDIDACY_STATUS_UPDATED_AT;
export const TALENT_FIELD_HOME_OFFICE_ID = Candidate.HOME_OFFICE_ID;
export const TALENT_FIELD_HOME_PHONE = Candidate.HOME_PHONE;
export const TALENT_FIELD_HOURLY_COMPENSATION = Candidate.HOURLY_COMPENSATION;
export const TALENT_FIELD_ID = Candidate.ID;
export const TALENT_FIELD_IS_OPEN_TO_ADHOC = Candidate.IS_OPEN_TO_ADHOC;
export const TALENT_FIELD_IS_OPEN_TO_COMMUTE = Candidate.IS_OPEN_TO_COMMUTE;
export const TALENT_FIELD_IS_OPEN_TO_FULL_TIME = Candidate.IS_OPEN_TO_FULL_TIME;
export const TALENT_FIELD_IS_OPEN_TO_PART_TIME = Candidate.IS_OPEN_TO_PART_TIME;
export const TALENT_FIELD_IS_OPEN_TO_REMOTE = Candidate.IS_OPEN_TO_REMOTE;
export const TALENT_FIELD_LANGUAGE_CEFR = Candidate.LANGUAGE_CEFR;
export const TALENT_FIELD_LANGUAGES = Candidate.LANGUAGES;
export const TALENT_FIELD_LAST_NAME = Candidate.LAST_NAME;
export const TALENT_FIELD_LAST_UPDATED_BY = Candidate.LAST_UPDATED_BY;
export const TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.LATEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE =
  Candidate.LATEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_MATTER_ID = Candidate.MATTER_ID;
export const TALENT_FIELD_MAX_MINUTES_COMMUTE_ONE_WAY =
  Candidate.MAX_MINUTES_COMMUTE_ONE_WAY;
export const TALENT_FIELD_MIDDLE_NAME = Candidate.MIDDLE_NAME;
export const TALENT_FIELD_MOBILE_PHONE = Candidate.MOBILE_PHONE;
export const TALENT_FIELD_NICK_NAME = Candidate.NICK_NAME;
export const TALENT_FIELD_NOTICE_PERIOD = Candidate.NOTICE_PERIOD;
export const TALENT_FIELD_OCCUPATION_TYPE = Candidate.OCCUPATION_TYPE;
export const TALENT_FIELD_OWNER_FULL_NAME = Candidate.OWNER_FULL_NAME;
export const TALENT_FIELD_OWNER_LAST_NAME = Candidate.OWNER_LAST_NAME;
export const TALENT_FIELD_OWNER_USER_ID = Candidate.OWNER_USER_ID;
export const TALENT_FIELD_PART_TIME_HOURS_MONDAY =
  Candidate.PART_TIME_HOURS_MONDAY;
export const TALENT_FIELD_PART_TIME_HOURS_TUESDAY =
  Candidate.PART_TIME_HOURS_TUESDAY;
export const TALENT_FIELD_PART_TIME_HOURS_WEDNESDAY =
  Candidate.PART_TIME_HOURS_WEDNESDAY;
export const TALENT_FIELD_PART_TIME_HOURS_THURSDAY =
  Candidate.PART_TIME_HOURS_THURSDAY;
export const TALENT_FIELD_PART_TIME_HOURS_FRIDAY =
  Candidate.PART_TIME_HOURS_FRIDAY;
export const TALENT_FIELD_PERSONAL_EMAIL = Candidate.PERSONAL_EMAIL;
export const TALENT_FIELD_PROFILE_STATUS = Candidate.PROFILE_STATUS;
export const TALENT_FIELD_PROFILE_STATUS_UPDATED_AT =
  Candidate.PROFILE_STATUS_UPDATED_AT;
export const TALENT_FIELD_RESUME = Candidate.RESUME;
export const TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.SOONEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_TAGS = Candidate.TAGS;
export const TALENT_FIELD_UPDATED_AT = Candidate.UPDATED_AT;
export const TALENT_FIELD_WEEKLY_AVAILABILITY = Candidate.WEEKLY_AVAILABILITY;
export const TALENT_FIELD_WORK_EMAIL = Candidate.WORK_EMAIL;
export const TALENT_FIELD_YEARS_OF_EXPERIENCE = Candidate.YEARS_OF_EXPERIENCE;

export const talentFieldLabels = {
  [TALENT_FIELD_ACTIVITY_LOG]: 'Activity Log',
  [TALENT_FIELD_ADDRESS_1]: 'Address Line 1',
  [TALENT_FIELD_ADDRESS_2]: 'Apt., Ste., etc.',
  [TALENT_FIELD_ADDRESS_CITY]: 'City',
  [TALENT_FIELD_ADDRESS_COUNTRY]: 'Country',
  [TALENT_FIELD_ADDRESS_COUNTRY_CODE]: 'Country Code',
  [TALENT_FIELD_ADDRESS_DISPLAY_NAME]: 'Address Display Name',
  [TALENT_FIELD_ADDRESS_STATE]: 'State',
  [TALENT_FIELD_ADDRESS_ZIP]: 'ZIP',
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME]:
    'Account Name',
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE]: 'Roll Off Date',
  [TALENT_FIELD_AVAILABILITY]: 'Current Availability',
  [TALENT_FIELD_AVAILABILITY_NOTES]: 'Availability Notes',
  [TALENT_FIELD_BARRED_LOCATIONS]: 'Bar Admission',
  [TALENT_FIELD_COLLABORATORS]: 'Talent Collaborators',
  [TALENT_FIELD_COMPENSATION]: 'Annual Salary',
  [TALENT_FIELD_DAYS_BEACHED]: 'Days Beached',
  [TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE]: 'Days At Status',
  [TALENT_FIELD_EMPLOYEE_TYPE]: 'Employee Type',
  [TALENT_FIELD_EXPERIENCES]: 'Employee Type',
  [TALENT_FIELD_FIRST_NAME]: 'First Name',
  [TALENT_FIELD_FURTHEST_CANDIDACY_STATUS]: 'Furthest Candidacy',
  [TALENT_FIELD_HOME_OFFICE_ID]: 'Home Office',
  [TALENT_FIELD_HOME_PHONE]: 'Home Phone',
  [TALENT_FIELD_HOURLY_COMPENSATION]: 'Hourly Salary',
  [TALENT_FIELD_LANGUAGE_CEFR]: 'CEFR Level',
  [TALENT_FIELD_LANGUAGES]: 'Languages',
  [TALENT_FIELD_LAST_NAME]: 'Last Name',
  [TALENT_FIELD_MIDDLE_NAME]: 'Middle Name',
  [TALENT_FIELD_MOBILE_PHONE]: 'Cell Phone',
  [TALENT_FIELD_NICK_NAME]: 'Nickname',
  [TALENT_FIELD_NOTICE_PERIOD]: 'Notice Period',
  [TALENT_FIELD_OCCUPATION_TYPE]: 'Opportunity Profile',
  [TALENT_FIELD_OWNER_USER_ID]: 'Talent Owner',
  [TALENT_FIELD_PERSONAL_EMAIL]: 'Personal Email',
  [TALENT_FIELD_PROFILE_STATUS]: 'Employment Status',
  [TALENT_FIELD_RESUME]: 'Resume',
  [TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME]: 'Account Name',
  [TALENT_FIELD_TAGS]: 'Tags',
  [TALENT_FIELD_WEEKLY_AVAILABILITY]: 'Current Availability',
  [TALENT_FIELD_WORK_EMAIL]: 'Axiom Email',
  [TALENT_FIELD_YEARS_OF_EXPERIENCE]: 'Years of Experience',
  address: 'Address',
  daysInDiligence: 'Days In Diligence',
  displayFirstName: 'Display First Name',
  displayLastName: 'Display Last Name',
  language: 'Language',
  languageProficiency: 'Language Proficiency',
  languageSkill: 'Language Skill',
  name: 'Talent Name',
  practiceArea: 'Practice Area',
  practiceAreaId: 'Practice Area',
};
