import { createSelector } from 'reselect';
import fGet from 'lodash/fp/get';

import {
  OPPORTUNITY_TYPE_NEW_DEAL,
  OPPORTUNITY_TYPES_WITH_CHILDREN,
  OPPORTUNITY_TYPES_WITH_PARENT,
} from '../../models/opportunities';

export const makeOpportunitiesByIdMap = () =>
  createSelector(
    fGet('opportunities.byId'),
    fGet('opportunities.related'),
    (opportunitiesById, relatedById) =>
      Object.keys(opportunitiesById).reduce((map, opportunityId) => {
        const opportunity = { ...opportunitiesById[opportunityId] };
        const { engagementType, id, parentId } = opportunity;

        // The filtering in this next block exists because the backend allows
        // any type of opportunity to add any other opportunity as its parent, regardless of
        // either opportunity's type, and does not remove relationships when opportunity types
        // change so that the relationship no longer makes sense. So, we just
        // filter out what is shown on the frontend and hope for the best.
        let parent = null;
        let relatedParentId = null;

        if (OPPORTUNITY_TYPES_WITH_CHILDREN[engagementType]) {
          // is parent, related = children
          relatedParentId = id;
        } else if (OPPORTUNITY_TYPES_WITH_PARENT[engagementType] && parentId) {
          // has parent, related = siblings
          parent = opportunitiesById[parentId];
          relatedParentId = parentId;
        }

        if (parent && parent.engagementType === OPPORTUNITY_TYPE_NEW_DEAL) {
          opportunity.parent = parent;
        } else {
          opportunity.parent = null;
        }

        const related = relatedParentId && relatedById[relatedParentId];
        if (related) {
          opportunity.related = related
            .map(relatedId => opportunitiesById[relatedId])
            .filter(
              rel =>
                rel &&
                rel.id !== id &&
                rel.parentId === relatedParentId &&
                rel.engagementType &&
                OPPORTUNITY_TYPES_WITH_PARENT[rel.engagementType]
            );
        } else {
          opportunity.related = [];
        }

        map[opportunityId] = opportunity;
        return map;
      }, {})
  );
