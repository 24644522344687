const removeAccents = str =>
  str
    .replace(/ä|æ|ǽ/g, 'ae')
    .replace(/ö|œ/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/Ä/g, 'Ae')
    .replace(/Ü/g, 'Ue')
    .replace(/Ö/g, 'Oe')
    .replace(/À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ|Α|Ά|Ả|Ạ|Ầ|Ẫ|Ẩ|Ậ|Ằ|Ắ|Ẵ|Ẳ|Ặ|А/g, 'a')
    .replace(/à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª|α|ά|ả|ạ|ầ|ấ|ẫ|ẩ|ậ|ằ|ắ|ẵ|ẳ|ặ|а/g, 'a')
    .replace(/Б/g, 'B')
    .replace(/б/g, 'b')
    .replace(/Ç|Ć|Ĉ|Ċ|Č/g, 'C')
    .replace(/ç|ć|ĉ|ċ|č/g, 'c')
    .replace(/Д/g, 'D')
    .replace(/д/g, 'd')
    .replace(/Ð|Ď|Đ|Δ/g, 'Dj')
    .replace(/ð|ď|đ|δ/g, 'dj')
    .replace(/È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě|Ε|Έ|Ẽ|Ẻ|Ẹ|Ề|Ế|Ễ|Ể|Ệ|Е|Э/g, 'E')
    .replace(/è|é|ê|ë|ē|ĕ|ė|ę|ě|έ|ε|ẽ|ẻ|ẹ|ề|ế|ễ|ể|ệ|е|э/g, 'e')
    .replace(/Ф/g, 'F')
    .replace(/ф/g, 'f')
    .replace(/Ĝ|Ğ|Ġ|Ģ|Γ|Г|Ґ/g, 'G')
    .replace(/ĝ|ğ|ġ|ģ|γ|г|ґ/g, 'g')
    .replace(/Ĥ|Ħ/g, 'H')
    .replace(/ĥ|ħ/g, 'h')
    .replace(/Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ|Η|Ή|Ί|Ι|Ϊ|Ỉ|Ị|И|Ы/g, 'I')
    .replace(/ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı|η|ή|ί|ι|ϊ|ỉ|ị|и|ы|ї/g, 'i')
    .replace(/Ĵ/g, 'J')
    .replace(/ĵ/g, 'j')
    .replace(/Ķ|Κ|К/g, 'K')
    .replace(/ķ|κ|к/g, 'k')
    .replace(/Ĺ|Ļ|Ľ|Ŀ|Ł|Λ|Л/g, 'L')
    .replace(/ĺ|ļ|ľ|ŀ|ł|λ|л/g, 'l')
    .replace(/М/g, 'M')
    .replace(/м/g, 'm')
    .replace(/Ñ|Ń|Ņ|Ň|Ν|Н/g, 'N')
    .replace(/ñ|ń|ņ|ň|ŉ|ν|н/g, 'n')
    .replace(/Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ|Ο|Ό|Ω|Ώ|Ỏ|Ọ|Ồ|Ố|Ỗ|Ổ|Ộ|Ờ|Ớ|Ỡ|Ở|Ợ|О/g, 'O')
    .replace(/ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º|ο|ό|ω|ώ|ỏ|ọ|ồ|ố|ỗ|ổ|ộ|ờ|ớ|ỡ|ở|ợ|о/g, 'o')
    .replace(/П/g, 'P')
    .replace(/п/g, 'p')
    .replace(/Ŕ|Ŗ|Ř|Ρ|Р/g, 'R')
    .replace(/ŕ|ŗ|ř|ρ|р/g, 'r')
    .replace(/Ś|Ŝ|Ş|Ș|Š|Σ|С/g, 'S')
    .replace(/ś|ŝ|ş|ș|š|ſ|σ|ς|с/g, 's')
    .replace(/Ț|Ţ|Ť|Ŧ|τ|Т/g, 'T')
    .replace(/ț|ţ|ť|ŧ|т/g, 't')
    .replace(/Þ|þ/g, 'th')
    .replace(/Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ|Ũ|Ủ|Ụ|Ừ|Ứ|Ữ|Ử|Ự|У/g, 'U')
    .replace(/ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ|υ|ύ|ϋ|ủ|ụ|ừ|ứ|ữ|ử|ự|у/g, 'u')
    .replace(/Ƴ|Ɏ|Ỵ|Ẏ|Ӳ|Ӯ|Ў|Ý|Ÿ|Ŷ|Υ|Ύ|Ϋ|Ỳ|Ỹ|Ỷ|Ỵ|Й/g, 'Y')
    .replace(/ẙ|ʏ|ƴ|ɏ|ỵ|ẏ|ӳ|ӯ|ў|ý|ÿ|ŷ|ỳ|ỹ|ỷ|ỵ|й/g, 'y')
    .replace(/В/g, 'V')
    .replace(/в/g, 'v')
    .replace(/Ŵ/g, 'W')
    .replace(/ŵ/g, 'w')
    .replace(/Ź|Ż|Ž|Ζ|З/g, 'Z')
    .replace(/ź|ż|ž|ζ|з/g, 'z')
    .replace(/Æ|Ǽ/g, 'AE')
    .replace(/ß/g, 'ss')
    .replace(/ẞ/g, 'SS')
    .replace(/Ĳ/g, 'IJ')
    .replace(/ĳ/g, 'ij')
    .replace(/Œ/g, 'OE')
    .replace(/ƒ/g, 'f')
    .replace(/ξ/g, 'ks')
    .replace(/π/g, 'p')
    .replace(/β/g, 'v')
    .replace(/μ/g, 'm')
    .replace(/ψ/g, 'ps')
    .replace(/Ё/g, 'Yo')
    .replace(/ё/g, 'yo')
    .replace(/Є/g, 'Ye')
    .replace(/є/g, 'ye')
    .replace(/Ї/g, 'Yi')
    .replace(/Ж/g, 'Zh')
    .replace(/ж/g, 'zh')
    .replace(/Х/g, 'Kh')
    .replace(/х/g, 'kh')
    .replace(/Ц/g, 'Ts')
    .replace(/ц/g, 'ts')
    .replace(/Ч/g, 'Ch')
    .replace(/ч/g, 'ch')
    .replace(/Ш/g, 'Sh')
    .replace(/ш/g, 'sh')
    .replace(/Щ/g, 'Shch')
    .replace(/щ/g, 'shch')
    .replace(/Ю/g, 'Yu')
    .replace(/ю/g, 'yu')
    .replace(/Я/g, 'Ya')
    .replace(/я/g, 'ya')
    .replace(/Ъ|ъ|Ь|ь/g, '');

const formatStringForUrl = str =>
  str
    // convert invalid characters into blank characters
    .replace(/[^A-Za-z0-9\s-]/g, '')
    // convert multiple spaces and hyphens into one space
    .replace(/[\s-]+/g, ' ')
    // convert spaces to hyphens
    .replace(/\s/g, '-')
    .toLowerCase()
    .trim();

const formatPracticeAreaName = practiceArea => {
  const formatExclusions = ['legal-support', 'commercial-contract-law'];
  const { name: practiceAreaName } = practiceArea;
  const formattedPracticeArea = formatStringForUrl(practiceAreaName);

  // Do not add law to the end of these practice areas
  if (formatExclusions.includes(formattedPracticeArea)) {
    return formattedPracticeArea;
  }

  return `${formattedPracticeArea}-law`;
};

export const generateCandidateSlug = (candidate = {}, practiceAreas) => {
  const {
    calculatedDisplayName = null,
    practiceArea = [],
    practiceAreaId,
    publicRefId,
  } = candidate;
  const paId = practiceArea?.id || practiceAreaId;
  const firstTokenOfName = (calculatedDisplayName ?? '').trim().split(' ')[0];
  const practiceAreaName = [];

  let practiceAreaMatch = practiceAreas.find(pa => pa.id === paId);

  /* Note: Practice areas without a type are filtered out in the PreloadedPracticeArea store
   * but may be currently assigned to candidates.
   */
  if (practiceAreaMatch) {
    if (practiceAreaMatch.parentId) {
      practiceAreaMatch = practiceAreas.find(
        pa => pa.id === practiceAreaMatch.parentId
      );
    }

    practiceAreaName.push(formatPracticeAreaName(practiceAreaMatch));
  }

  return [
    formatStringForUrl(removeAccents(firstTokenOfName)),
    ...practiceAreaName,
    publicRefId,
  ].join('-');
};
