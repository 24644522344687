import get from 'lodash/get';

import {
  BarredLocationsOptionsShape,
  BarredLocationByIdShape,
  BarredLocationsShape,
} from '../models/barred-locations';
import GenericStore from '../classes/generic-store';
import { getBarredLocations } from '../api/barred-locations';

class PreloadedBarredLocationsClass extends GenericStore {
  load() {
    return this.setState(
      getBarredLocations().then(response => ({
        options: response.data,
        byId: response.data.reduce((data, item) => {
          if (!data[item.id]) {
            data[item.id] = item;
          }
          return data;
        }, {}),
      }))
    );
  }

  getOptionsShape() {
    return BarredLocationsOptionsShape;
  }

  getByIdShape() {
    return BarredLocationByIdShape;
  }

  getDataShape() {
    return BarredLocationsShape;
  }

  selectData(state) {
    return get(this.select(state), 'data', { options: [], byId: {} });
  }
}

export const PreloadedBarredLocationsStore =
  new PreloadedBarredLocationsClass();
