import React, { FC, useState } from 'react';
import { Opportunity, OpportunityCandidate, Position } from '@axiom/validation';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardSection,
  CondensedMedium,
  ContextMenu,
  ContextMenuItem,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Layout,
  SmallHeader,
  ToastUtil,
  CondensedHeader,
  CondensedLarge,
  Gutter,
} from '@axiom/ui';

import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import {
  FormSchema,
  GetMoreMatchesModal,
} from '../GetMoreMatchesModal/GetMoreMatchesModal';
import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { AiDuplicatesMessage } from '../AiDuplicatesMessage/AiDuplicatesMessage';

import { OpportunityTalentParentPositionEmpty } from './OpportunityTalentParentPositionEmpty';
import { noMatchesErrorText } from './OpportunityTalentAiMatchControl';
import { OpportunityTalentParentPositionList } from './OpportunityTalentParentPositionList';

interface OpportunityTalentParentPositionProps {
  name: string;
  title?: string;
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
  isFilled?: boolean;
  isTalentPool?: boolean;
  positions?: Array<Position>;
}

export const OpportunityTalentParentPosition: FC<
  OpportunityTalentParentPositionProps
> = ({
  name,
  title,
  opportunity,
  candidates,
  isFilled,
  isTalentPool,
  positions,
}) => {
  const [hasGottenNoRecommendations, setHasGottenNoRecommendations] =
    useState(false);
  const [showGetMoreMatchesModal, setShowGetMoreMatchesModal] = useState(false);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(
    opportunity.hasBeenAIMatched || hasGottenNoRecommendations
  );
  const [savedFilters, setSavedFilters] = useState<FormSchema>({});
  const addTalentOnClick = () => {
    if (isTalentPool) {
      AppFindCandidateForOppModalStore.beginAddingToOpportunity(
        [opportunity.id],
        opportunity.id
      );
    } else {
      AppFindCandidateForOppModalStore.beginAddingToPosition(
        opportunity.id,
        positions,
        name
      );
    }
  };

  const renderButton = () => {
    return (
      <Button
        name={
          isTalentPool
            ? 'ADD_TALENT_TO_TALENT_POOL_BUTTON'
            : 'ADD_TALENT_TO_POSITION_BUTTON'
        }
        onClick={!isTalentPool ? addTalentOnClick : null}
        icon={isTalentPool ? 'arrow-down' : null}
        iconPosition="right"
        pattern="secondary"
      >
        Add Talent
      </Button>
    );
  };

  const renderContextMenu = () => {
    return (
      <ContextMenu
        anchor={renderButton()}
        name="ADD_TALENT_CONTEXT_MENU"
        direction="right"
      >
        <ContextMenuItem
          onClick={async () => {
            addTalentOnClick();
          }}
        >
          Search for talent
        </ContextMenuItem>
        <ContextMenuItem
          name="GET_AI_MATCHES_CONTEXT_MENU_ITEM"
          disabled={!opportunity.isAiMatchable}
          onClick={async () => {
            if (shouldTriggerModal) {
              setShowGetMoreMatchesModal(true);
            } else {
              // we're sending the default parameters (first button click)
              const response =
                await OpportunityCandidateMlRecommendationApi.createOpportunityCandidateMlRecommendations(
                  { opportunityId: opportunity.id },
                  {}
                );
              setShouldTriggerModal(true);
              // response is null if there is a known server error
              if (response !== null) {
                await OpportunityStore.load(opportunity.id);
                const { data: { candidateIds, opportunityCandidates } = {} } =
                  response;
                if (!candidateIds?.length) {
                  ToastUtil.add({
                    dismissible: true,
                    name: `NOMATCHES`,
                    type: 'error',
                    children: (
                      <>
                        <CondensedHeader>Error</CondensedHeader>
                        <CondensedLarge>{noMatchesErrorText}</CondensedLarge>
                      </>
                    ),
                  });
                  setHasGottenNoRecommendations(true);
                  setShouldTriggerModal(false);
                }

                // Pop a toast if talent was already on the opp before the AI match
                const dupes = opportunityCandidates?.filter(
                  ({ duplicate }) => duplicate
                );
                if (dupes?.length) {
                  ToastUtil.add({
                    dismissible: true,
                    name: `DUPLICATECAND`,
                    type: 'critical',
                    children: <AiDuplicatesMessage duplicates={dupes} />,
                  });
                }
              }
            }
          }}
        >
          {shouldTriggerModal ? 'Get more matches' : 'Get AI matches'}
        </ContextMenuItem>
      </ContextMenu>
    );
  };

  return (
    <>
      <Card name={name}>
        <CardHeader>
          <Grid columns={2}>
            <GridRow>
              <GridColumn>
                <Layout
                  direction="horizontal"
                  horizontalGutter="8px"
                  position="middle"
                >
                  <Badge background="gray">{candidates.length}</Badge>
                  <SmallHeader maxLines={1}>{title}</SmallHeader>
                  {isFilled && <Badge background="green">Filled</Badge>}
                </Layout>
              </GridColumn>
              <GridColumn>
                {opportunity.isFulfillmentActive && (
                  <Layout
                    direction="horizontal"
                    horizontalGutter="8px"
                    position="right middle"
                  >
                    {isTalentPool ? renderContextMenu() : renderButton()}
                  </Layout>
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        </CardHeader>

        <CardSection>
          {opportunity.aiUnmatchableMessage &&
            isTalentPool &&
            !opportunity.isAiMatchable && (
              <Gutter bottom="16px">
                <CondensedMedium name="AI_UNMATCHABLE_MESSAGE">
                  <Flashy bold>Note:</Flashy> {opportunity.aiUnmatchableMessage}
                </CondensedMedium>
              </Gutter>
            )}
          {candidates.length === 0 ? (
            <OpportunityTalentParentPositionEmpty isTalentPool={isTalentPool} />
          ) : (
            <OpportunityTalentParentPositionList
              opportunity={opportunity}
              candidates={candidates}
              isTalentPool={isTalentPool}
            />
          )}
        </CardSection>
      </Card>
      {showGetMoreMatchesModal && (
        <GetMoreMatchesModal
          opportunity={opportunity}
          onRequestSuccess={({ filters }) => {
            setSavedFilters(filters);
          }}
          onClose={() => setShowGetMoreMatchesModal(false)}
          savedFilters={savedFilters}
        />
      )}
    </>
  );
};
