import React from 'react';

import ManageContactsList from '../ManageContactsList/ManageContactsList';
import ManageContactForm from '../ManageContactForm/ManageContactForm';

import {
  ManageContactsWrapper,
  ManageContactsLeft,
  ManageContactsRight,
} from './ManageContactsViewStyles';

const ManageContactsView = () => {
  return (
    <ManageContactsWrapper data-test="MANAGE_CONTACTS">
      <ManageContactsLeft>
        <ManageContactsList />
      </ManageContactsLeft>
      <ManageContactsRight>
        <ManageContactForm />
      </ManageContactsRight>
    </ManageContactsWrapper>
  );
};

export default ManageContactsView;
