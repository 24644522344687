import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import {
  BOX_SHADOW_RULE,
  HEADER_HEIGHT,
  PANEL_WIDTH,
} from '../../themes/constants';

export const DrawerTab = styled.div`
  position: absolute;
  left: -60px;
  top: ${HEADER_HEIGHT}px;
  z-index: 2;
  height: 70px;
  width: 60px;
  padding: 23px 18px;
  background: white;
  border-radius: 5px 0 0 5px;
  left: -60px;
  top: 15px;
  ${BOX_SHADOW_RULE};
`;

export const StyledSideDrawer = styled.section`
  background-color: white;
  color: ${getThemeProp('primaryText')};
  flex: 0 1 auto;
  width: ${({ expanded }) => (expanded ? `${PANEL_WIDTH}px` : 0)};
  position: relative;
  transition: 300ms width ease-out, 0ms padding ease-out;
  ${BOX_SHADOW_RULE};
  height: 100%;
  overflow: hidden;
  z-index: 3;
  display: flex;
  flex-direction: column;
  & > * {
    display: ${({ expanded }) => (expanded ? 'auto' : 'none')};
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: ${({ expanded }) => (expanded ? 100 : 0)}ms opacity linear;
    transition-delay: ${({ expanded }) => (expanded ? 300 : 0)}ms;
    width: ${({ expanded }) => (expanded ? 'auto' : '15px')};
  }
`;
