import PropTypes from 'prop-types';

export const TAG_MODEL_PROPERTIES = ['id', 'name', 'type'];

export const TagShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['tag']),
});

export const TagDataShape = PropTypes.shape({
  data: TagShape,
});
