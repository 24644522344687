import styled, { css } from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const LawSchoolManagementFormRow = styled.div`
  display: flex;
  margin: 0 32px;

  ${props =>
    props.divider &&
    css`
      border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
    `};

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const LawSchoolManagementFormColumn = styled.div`
  min-width: 40%;
  padding: 24px 24px 0;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-child(2) {
    min-width: 60%;
    padding-right: 0;
  }
`;

export const LawSchoolManagementFormFormHeader = styled.div`
  background: ${getThemeProp('appBackground')};
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  margin: 0;
  padding: 24px 32px;
`;
