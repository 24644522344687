import GenericStore from '../classes/generic-store';
import { getOpportunityCandidates } from '../api/opportunities-legacy-api';

class OpportunityCandidatesStoreClass extends GenericStore {
  load(opportunityId, includeMlRecommendations = false) {
    return this.setState(
      getOpportunityCandidates(opportunityId, includeMlRecommendations)
    );
  }
}

export const OpportunityCandidatesStore = new OpportunityCandidatesStoreClass();
