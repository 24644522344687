import React, { Component } from 'react';
// TODO: deprecate redux-form
import { formPropTypes, reduxForm } from 'redux-form';
import { Education as EDUCATION } from '@axiom/const';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Badge,
  Layout,
} from '@axiom/ui';

import {
  deleteEducation,
  updateEducation,
} from '../../redux/actions/education';
import UnorderedList from '../UnorderedList/UnorderedList';
import { educationShape } from '../../models/education';
import { formatDataTestId } from '../../utils/dataTest';
import { FormEducationItemStore } from '../../stores/form-education-item-store';

import {
  EducationItemWrapper,
  EICard,
  EICardBody,
  EICardRow,
  EICardRowLeft,
  EICardRowRight,
  EICardRowRightMenu,
} from './EducationItemStyles';

class EducationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmingDelete: false,
    };
  }

  cancelDelete = () => {
    this.setState({ isConfirmingDelete: false });
  };

  confirmDelete = () => {
    const { dispatch, education } = this.props;
    dispatch(deleteEducation(education.id));
  };

  requestDelete = () => {
    this.setState({ isConfirmingDelete: true });
  };

  setPrimary = () => {
    const { dispatch, education } = this.props;
    if (education.isPrimary) return;
    dispatch(
      updateEducation({
        educationId: education.id,
        isPrimary: true,
      })
    );
  };

  startEditing = () => {
    FormEducationItemStore.load(
      this.props.education.candidateId,
      this.props.education
    );
  };

  render() {
    const { education } = this.props;
    if (!education || !education.id) return null;

    const { isConfirmingDelete } = this.state;

    return (
      <EducationItemWrapper data-test={formatDataTestId('education_item')}>
        <EICard>
          <EICardBody>
            <EICardRow>
              <EICardRowLeft>
                <UnorderedList
                  title="Institution"
                  values={education.institution}
                />
              </EICardRowLeft>
              <EICardRowRight>
                <Layout direction="vertical" verticalGutter="4px">
                  {education.isPrimary && (
                    <div>
                      <Badge background="yellow">Primary</Badge>
                    </div>
                  )}
                  <UnorderedList title="Date" values={education.yearAwarded} />
                </Layout>
                <EICardRowRightMenu>
                  <ContextMenu direction="left">
                    {!education.isPrimary && (
                      <ContextMenuItem
                        onClick={() => {
                          this.setPrimary();
                        }}
                      >
                        Set Primary
                      </ContextMenuItem>
                    )}
                    <ContextMenuItem
                      onClick={() => {
                        this.startEditing();
                      }}
                    >
                      Edit
                    </ContextMenuItem>
                    {!education.isPrimary && (
                      <ContextMenuItem
                        onClick={() => {
                          this.setState({ isConfirmingDelete: true });
                        }}
                      >
                        Delete
                      </ContextMenuItem>
                    )}
                  </ContextMenu>
                </EICardRowRightMenu>
              </EICardRowRight>
            </EICardRow>
          </EICardBody>
          <EICardRow>
            <EICardRowLeft>
              <UnorderedList title="Degree" values={education.degree} />
            </EICardRowLeft>
            <EICardRowRight>
              {education.areaOfStudy && (
                <UnorderedList
                  title="Area Of Study"
                  values={education.areaOfStudy.name}
                />
              )}
              {education.degree === EDUCATION.DEGREES.STATE_EXAM && (
                <UnorderedList
                  title="Score"
                  values={education.stateExamScore}
                />
              )}
            </EICardRowRight>
          </EICardRow>
        </EICard>

        {isConfirmingDelete ? (
          <Modal size="confirm">
            <ModalHeader onClose={this.cancelDelete}>
              Delete Education
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                Are you sure you want to delete "{education.degree}" from "
                {education.institution}"?
              </Paragraph>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={this.cancelDelete}>
                Cancel
              </Button>
              <Button onClick={this.confirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </EducationItemWrapper>
    );
  }
}

EducationItem.propTypes = {
  ...formPropTypes,
  education: educationShape.isRequired,
};

export default reduxForm({})(EducationItem);
