/* eslint-disable import/prefer-default-export */
import keyBy from 'lodash/keyBy';

const proficiencies = {
  basic: 'Conversational',
  proficient: 'Business Proficient',
  fluent: 'Native or Bilingual',
};

const skills = {
  speaking: 'Speaking',
  reading: 'Reading',
  writing: 'Writing',
};

export function opportunityLanguagesTexts({
  opportunityLanguages = [],
  languages,
}) {
  // nameProficiencies[name + proficiency] = [
  //   { name, proficiency, skill },
  //   { name, proficiency, skill },
  // ];

  const nameProficiencies = opportunityLanguages.reduce(
    (profs, opportunityLanguage) => {
      const { label } = languages.find(
        lang => lang.value === opportunityLanguage.id
      );
      const proficiency =
        proficiencies[
          opportunityLanguage.requiredLanguageProficiency.toLowerCase()
        ];
      const { requiredLanguageSkill } = opportunityLanguage;
      const skill = requiredLanguageSkill
        ? skills[requiredLanguageSkill.toLowerCase()]
        : '';

      if (!profs[label + proficiency]) profs[label + proficiency] = [];
      if (!profs[label + proficiency].some(n => n.skill === skill)) {
        profs[label + proficiency].push({
          name: label,
          proficiency,
          skill,
        });
      }
      profs[label + proficiency].sort(({ skill: a }, { skill: b }) => {
        if (!a) return 1;
        if (!b) return -1;
        return a < b ? -1 : 1;
      });

      return profs;
    },
    {}
  );

  return Object.values(nameProficiencies)
    .map(nameProficiency =>
      nameProficiency.reduce((language, { name, proficiency, skill }, i) => {
        if (skill) {
          return (
            language +
            (i === 0 ? `${name} | ${proficiency} | ${skill}` : `, ${skill}`)
          );
        }
        return language + (i === 0 ? `${name} | ${proficiency}` : '');
      }, '')
    )
    .sort();
}

export const opportunityEditLanguagesTexts = ({
  opportunityLanguages = [],
  languages,
}) => {
  const oppLanguages = opportunityLanguages.map(opportunityLanguage => {
    const { id, requiredLanguageProficiency, requiredLanguageSkill } =
      opportunityLanguage;
    const uniqueKey = id + requiredLanguageProficiency + requiredLanguageSkill;
    const language = [];

    language.push(languages.find(lang => lang.value === id).label);
    language.push(
      proficiencies[
        opportunityLanguage.requiredLanguageProficiency.toLowerCase()
      ]
    );

    if (requiredLanguageSkill) {
      language.push(skills[requiredLanguageSkill.toLowerCase()]);
    }

    return { id, language: language.join('; '), uniqueKey };
  });

  return keyBy(oppLanguages, 'uniqueKey');
};
