import React from 'react';
import {
  ApiError,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';
import { Candidate, Opportunity, Position } from '@axiom/validation';

import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-api-legacy';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { TalentWarningModalStore } from '../../stores/talent-warning-modal-store';

export const OpportunityTalentReassignPositionModal = ({
  onClose = () => {},
  isMlRecommendation,
  opportunityId,
  candidateId,
  positionId,
}: {
  onClose: () => void;
  isMlRecommendation: boolean;
  opportunityId: Opportunity['id'];
  candidateId: Candidate['id'];
  positionId: Position['id'];
}) => {
  return (
    <Modal name="REASSIGN_POSITION_MODAL">
      <ModalHeader
        background="blue"
        name="REASSIGN_POSITION_MODAL_HEADER"
        onClose={onClose}
      >
        Reassign Position
      </ModalHeader>
      <ModalSection>
        <Paragraph>
          Are you sure you would like to reassign this talent to another
          position? Doing so will impact their current rate, status and
          submission tracker in Axiom for Talent?
        </Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button
          onClick={onClose}
          name="REASSIGN_POSITION_MODAL_CANCEL_BUTTON"
          variation="outline"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              await (isMlRecommendation
                ? OpportunityLegacyApi.createOpportunityCandidates(
                    opportunityId,
                    { candidateIds: [candidateId], positionId }
                  )
                : OpportunityLegacyApi.updateOpportunityCandidate(
                    opportunityId,
                    candidateId,
                    { positionId }
                  ));
              await OpportunityLegacyApi.updateBurdendedCost(
                opportunityId,
                candidateId
              );
              TalentWarningModalStore.onTalentOpportunityUpdate(candidateId);
            } catch (e) {
              PreloadedAppErrorsStore.show(
                ((e as ApiError).getErrors() as { message: string })?.message
              );
            }
            await Promise.all([
              OpportunityLegacyApi.refreshOpportunityCandidates(opportunityId),
              OpportunityLegacyApi.refreshOpportunityPositions(opportunityId),
              CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
                candidateId
              ),
            ]);

            onClose();
          }}
          name="REASSIGN_POSITION_MODAL_SAVE_BUTTON"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
