import styled from 'styled-components';

import UnorderedList from '../UnorderedList/UnorderedList';

export const OPHAccountName = styled(UnorderedList)`
  width: 260px;
`;

export const OPHCell = styled(UnorderedList)`
  flex: 1;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;

export const OPHFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 0 0;
  padding: 0 1.2rem;
`;
