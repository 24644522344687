import React, { FC } from 'react';
import { CondensedHeader, CondensedLarge } from '@axiom/ui';

export const SessionRefreshedToast: FC = () => (
  <>
    <CondensedHeader>Your session has been refreshed</CondensedHeader>
    <CondensedLarge>
      Your session has been refreshed, no further action is required.
    </CondensedLarge>
  </>
);
