import { HOME_OFFICE_LOADED, HOME_OFFICE_LOAD_ERROR } from '../constants';

export const INITIAL_STATE = {
  byTalentId: {},
  error: undefined,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case HOME_OFFICE_LOADED: {
      return {
        ...state,
        byTalentId: {
          ...state.byTalentId,
          [payload.talentID]: payload.data,
        },
      };
    }
    case HOME_OFFICE_LOAD_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default reducer;
