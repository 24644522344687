import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { func, string } from 'prop-types';
import {
  Button,
  FluidButtonLayout,
  Gutter,
  IconButton,
  Tab,
  Tabs,
} from '@axiom/ui';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormOpportunityProfileNextStepsDescriptionStore } from '../../stores/form-opportunity-profile-next-steps-description-store';
import EmptyDescription from '../EmptyDescription/EmptyDescription';
import { formatDataTestId } from '../../utils/dataTest';
import { FFTextArea } from '../FFTextArea/FFTextArea';
import { FormCell, FormRow } from '../../styled/form';
import { OpportunityStore } from '../../stores/opportunity-store';
// eslint-disable-next-line boundaries/element-types
import { StyledFFTextArea } from '../FFTextArea/FFTextAreaStyles';

const getPlaceholderComponent = label => {
  const text = label === 'Next Steps' ? `${label}s` : label;
  return (
    <EmptyDescription
      header={`No ${text}`}
      body={`Click the edit icon to enter ${label.toLowerCase()}.`}
    />
  );
};

const getForm = ({ data, name, label, toggleEditButton }) => (
  <FormStateTreatment
    name="opportunity_description"
    formStore={FormOpportunityProfileNextStepsDescriptionStore}
    renderNonLoadedView={() => (
      <StyledFFTextArea
        disabled
        value={data}
        data-test={formatDataTestId('TEXTAREA_DISABLED')}
      />
    )}
    renderLoadedView={({ fireSubmit, fireCancel }) => {
      const cancel = () => {
        toggleEditButton(true);
        fireCancel();
      };
      return (
        <>
          <FormRow style={{ marginBottom: 0 }}>
            <FormCell width="full">
              <Field
                name={name}
                component={FFTextArea}
                label={label}
                placeholderComponent={getPlaceholderComponent(label)}
                showCharsRemainingLabel={name === 'nextStep'}
                maxLength={name === 'nextStep' ? 255 : null}
              />
            </FormCell>
          </FormRow>
          <Gutter bottom="16px" />
          <FluidButtonLayout>
            <Button
              onClick={cancel}
              className="edit-action cancel"
              name="CANCEL"
              variation="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={fireSubmit}
              className="edit-action save"
              name="SAVE"
            >
              Save
            </Button>
          </FluidButtonLayout>
        </>
      );
    }}
  />
);

getForm.propTypes = {
  data: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  toggleEditButton: func.isRequired,
};

export const OpportunityProfileNextStepsDescription = ({ opportunity }) => {
  const [showEditButton, toggleShowEditButton] = useState(true);

  const changeTab = () => {
    if (!showEditButton) {
      toggleShowEditButton(true);
    }
    FormOpportunityProfileNextStepsDescriptionStore.clearState();
  };

  useEffect(() => {
    if (!showEditButton) {
      toggleShowEditButton(true);
    }
  }, [opportunity]);

  return (
    <Gutter bottom="24px" name="next steps description">
      <Tabs
        fluid
        scrollable
        appendToRight={
          <span>
            {showEditButton && (
              <IconButton
                pattern="secondary"
                variation="minimal"
                icon="pencil"
                onClick={() => {
                  toggleShowEditButton(false);
                  FormOpportunityProfileNextStepsDescriptionStore.load(
                    opportunity
                  );
                }}
                name="EDIT_NEXTSTEPS_DESCRIPTION_BUTTON"
              />
            )}
          </span>
        }
      >
        <Tab label="Next Steps" name="Next Steps" onClick={changeTab}>
          <Gutter top="8px">
            {getForm({
              data: opportunity.nextStep || '',
              name: 'nextStep',
              label: 'Next Step',
              toggleEditButton: toggleShowEditButton,
            })}
          </Gutter>
        </Tab>
        <Tab
          label="Opportunity Description"
          name="Opportunity Description"
          onClick={changeTab}
        >
          <Gutter top="8px">
            {getForm({
              data: opportunity.description || '',
              name: 'description',
              label: 'Description',
              toggleEditButton: toggleShowEditButton,
            })}
          </Gutter>
        </Tab>
      </Tabs>
    </Gutter>
  );
};

OpportunityProfileNextStepsDescription.propTypes = {
  opportunity: OpportunityStore.getDataShape().isRequired,
};

export default OpportunityProfileNextStepsDescription;
