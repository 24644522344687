import { Api } from '@axiom/ui';
import { Taxonomy } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class TaxonomyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readTaxonomy() {
    return super.read<{ data: Taxonomy }>({
      endpoint: `/taxonomy`,
      method: 'GET',
    });
  }
}

export const TaxonomyApi = new TaxonomyApiClass();
