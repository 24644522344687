import {
  DOWNLOAD_USER_OPPORTUNITY_LIST,
  DOWNLOAD_USER_TALENT_LIST,
  DOWNLOAD_ERROR,
} from '../constants';

export const downloadUserOpportunitiesAsCsv = selectedIds => ({
  type: DOWNLOAD_USER_OPPORTUNITY_LIST,
  payload: { selectedIds },
});

export const downloadUserTalentAsCsv = selectedIds => ({
  type: DOWNLOAD_USER_TALENT_LIST,
  payload: { selectedIds },
});

export const downloadError = payload => ({
  type: DOWNLOAD_ERROR,
  payload,
  error: true,
});
