/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape } from 'prop-types';

import DatePicker from '../DatePicker/DatePicker';
import { FFError, isErroring } from '../FFError/FFError';

const FFDatePicker = ({ input, meta, ...rest }) => (
  <>
    <DatePicker
      invalid={isErroring(meta)}
      date={input.value}
      onChange={v => input.onChange(v === '' ? null : v)}
      {...rest}
    />
    <div>
      <FFError finalFormElementMeta={meta} />
    </div>
  </>
);

FFDatePicker.defaultProps = {};
FFDatePicker.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
};

export default FFDatePicker;
