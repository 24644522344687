import React, { Component } from 'react';
import { arrayOf, string } from 'prop-types';
import {
  EducationPlaceholderIcon,
  EducationUtils,
  Header4,
  Gutter,
  IconButton,
} from '@axiom/ui';

import EducationForm from '../EducationForm/EducationForm';
import EducationItem from '../EducationItem/EducationItem';
import NoContentPlaceholder from '../NoContentPlaceholder/NoContentPlaceholder';
import { LogHeader, LogHeaderRight, LogItems } from '../../styled/logs';
import { educationShape } from '../../models/education';
import { formatDataTestId } from '../../utils/dataTest';
import { FormEducationItemStore } from '../../stores/form-education-item-store';

class EducationList extends Component {
  openEducationForm = () => {
    FormEducationItemStore.load(this.props.candidateId, {});
  };

  render() {
    const { degrees } = this.props;

    return (
      <Gutter name="EDUCATION_CONTAINER" top="2rem">
        <LogHeader>
          <Header4>Education</Header4>
          <LogHeaderRight>
            <IconButton
              icon="plus"
              pattern="secondary"
              variation="minimal"
              name="ADDEDUCATIONBUTTON"
              onClick={this.openEducationForm}
            />
          </LogHeaderRight>
        </LogHeader>

        <EducationForm />

        {degrees.length > 0 ? (
          <LogItems data-test={formatDataTestId('educations')}>
            {EducationUtils.sortDisplay(degrees).map(education => (
              <li key={education.id}>
                <EducationItem
                  education={education}
                  form={education.id}
                  initialValues={{ ...education }}
                />
              </li>
            ))}
          </LogItems>
        ) : (
          <NoContentPlaceholder
            image={<EducationPlaceholderIcon />}
            title="No Education"
            text="Add your education and qualifications."
          />
        )}
      </Gutter>
    );
  }
}

EducationList.defaultProps = {
  degrees: [],
};

EducationList.propTypes = {
  candidateId: string.isRequired,
  degrees: arrayOf(educationShape),
};

export default EducationList;
