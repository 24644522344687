/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape, arrayOf, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import MultiSelect from '../MultiSelect/MultiSelect';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';

const TagsMultiSelect = ({ input, ...other }) => (
  <MultiSelect {...input} {...other} />
);

TagsMultiSelect.propTypes = {
  input: shape({
    value: arrayOf(
      shape({
        label: string,
        value: string,
      })
    ),
    onChange: func,
  }).isRequired,
};

export const TagsDropdownFieldComponent = props => (
  <Field component={TagsMultiSelect} {...props} />
);

export default connect(state => PreloadedTagsStore.selectOptions(state))(
  TagsDropdownFieldComponent
);
