import { GenericDto } from './generic-dto';

export const ActivityDto = class ActivityDto extends GenericDto {
  constructor(body) {
    super(body, [
      'candidateId',
      'opportunityId',
      'lastAuthoredBy',
      'description',
      'purpose',
      'type',
      'bullhornNoteId',
      'salesforceEventId',
      'createdAt',
      'updatedAt',
      'emailAddresses',
      'document',
      'items',
      'file',
      'scheduledDate',
    ]);
  }
};
