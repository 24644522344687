import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 600px;
`;

export const maxHeightDivStyle = {
  height: '100%',
};

export const DynamicScrollingItem = styled.div`
  flex: 0 1 auto;
  overflow: auto;
  max-height: 30em;
  position: relative;
`;

export const TagLabel = styled.div`
  display: inline-block;
  flex: 1 1 auto;
`;

export const TagList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const TagListItem = styled.li`
  display: flex;
  position: relative;
  cursor: pointer;
  line-height: 50px;
  padding-left: 5px;

  ${props =>
    props.selected &&
    `
      background: ${GENERAL_STYLES_MAP.DROPDOWN.background};
      color: ${GENERAL_STYLES_MAP.DROPDOWN.color};
    `};

  button {
    height: 100%;
  }
`;
