import { pickBy, identity } from 'lodash';
import { OpportunityDto, CandidateOpportunitiesDto, Api } from '@axiom/ui';
import { CandidateOpportunitiesConst, OpportunitiesConst } from '@axiom/const';
import {
  Candidate,
  OpportunityCandidateOrMlRecommended,
  Opportunity,
  OpportunityPosition,
  Position,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('opportunity');

const { OppCandMlRecFilters } = CandidateOpportunitiesConst;

type CreateOpportunityCandidatesType = {
  candidateIds: Candidate['id'][];
  positionId: Position['id'];
};

class OpportunityLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readOpportunityCandidates(
    opportunityId: Opportunity['id'],
    includeMlRecommendations: boolean
  ) {
    return super.read<{ data: OpportunityCandidateOrMlRecommended[] }>({
      encodeQuery: false,
      endpoint: `/opportunities/${opportunityId}/candidates`,
      method: 'GET',
      query: includeMlRecommendations
        ? {
            expand: [OpportunitiesConst.Expansions.mlRecommendationsInfo],
            filter: [OppCandMlRecFilters.notMlAccepted],
          }
        : undefined,
    });
  }

  createOpportunityCandidates(
    opportunityId: Opportunity['id'],
    body: CreateOpportunityCandidatesType
  ) {
    return super.write<{ data: CreateOpportunityCandidatesType }>({
      endpoint: `/opportunities/${opportunityId}/candidates`,
      method: 'POST',
      body,
    });
  }

  updateOpportunityCandidate(
    opportunityId: Opportunity['id'],
    candidateId: Candidate['id'],
    body: OpportunityCandidateOrMlRecommended
  ) {
    return super.write<{ data: OpportunityCandidateOrMlRecommended }>({
      endpoint: `/opportunities/${opportunityId}/candidates/${candidateId}`,
      method: 'PATCH',
      body,
    });
  }

  readOpportunity(opportunityId: Opportunity['id']) {
    return super.read<{ data: Opportunity }>({
      endpoint: `/opportunities/${opportunityId}`,
      method: 'GET',
    });
  }

  clearOpportunity(opportunityId: Opportunity['id']) {
    return super.clear(`/opportunities/${opportunityId}`);
  }

  readOpportunityPositions(opportunityId: Opportunity['id']) {
    return super.read<{ data: OpportunityPosition[] }>({
      endpoint: `/opportunities/${opportunityId}/positions`,
      method: 'GET',
    });
  }

  refreshOpportunityPositions(opportunityId: Opportunity['id']) {
    return super.invalidate(`/opportunities/${opportunityId}/positions`);
  }

  clearOpportunityPositions(opportunityId: Opportunity['id']) {
    return super.clear(`/opportunities/${opportunityId}/positions`);
  }

  refreshOpportunityCandidates(opportunityId: Opportunity['id']) {
    return super.invalidate(`/opportunities/${opportunityId}/candidates`);
  }

  updateOpportunity(opportunityId: Opportunity['id'], body: Opportunity) {
    return super.write<{ data: Opportunity }>({
      endpoint: `/opportunities/${opportunityId}`,
      method: 'PATCH',
      body,
    });
  }

  refreshOpportunity(opportunityId: Opportunity['id']) {
    return super.invalidate(`/opportunities/${opportunityId}`);
  }

  updateBurdendedCost(
    opportunityId: Opportunity['id'],
    candidateId: Candidate['id']
  ) {
    return super.write<{ data: undefined }>({
      endpoint: `/opportunities/${opportunityId}/candidates/${candidateId}/cost`,
      method: 'PUT',
    });
  }
}

export const OpportunityLegacyApi = new OpportunityLegacyApiClass();

export const getOpportunity = async (opportunityId: Opportunity['id']) => {
  const { data: opportunity } = await api.GET(
    `/opportunities/${opportunityId}`
  );
  return opportunity;
};

export const getOpportunities = async (
  filterMeta: Record<string, unknown> & {
    filters?: Record<string, unknown>;
  } = {
    page: 1,
  }
) => {
  // Strips out properties with nulls in them
  filterMeta = pickBy(
    {
      ...filterMeta,
      filters: {
        ...pickBy(filterMeta.filters, identity),
      },
    },
    identity
  );

  const { data, meta } = await api.POST(
    `${EnvUtil.apiEnvoyUrl}/opportunities/search`,
    filterMeta,
    true
  );
  return {
    data,
    meta,
  };
};

export const addCandidatesToPosition = async ({
  opportunityId,
  candidateIds,
  positionId,
}: {
  opportunityId: Opportunity['id'];
  candidateIds: Candidate['id'][];
  positionId: Position['id'];
}) => {
  const { data } = await api.POST(
    `/opportunities/${opportunityId}/candidates`,
    {
      candidateIds,
      positionId,
    }
  );
  return data;
};

export const addCandidatesToOpportunity = async ({
  opportunityId,
  candidateIds,
}: {
  opportunityId: Opportunity['id'];
  candidateIds: Candidate['id'][];
}) => {
  const { data } = await api.POST(
    `/opportunities/${opportunityId}/candidates`,
    {
      candidateIds,
    }
  );
  return data;
};

export const getOpportunityCandidates = async (
  opportunityId: Opportunity['id'],
  includeMlRecommendations: boolean
) => {
  const { data } = await api.GET(
    `/opportunities/${opportunityId}/candidates`,
    includeMlRecommendations
      ? {
          expand: [OpportunitiesConst.Expansions.mlRecommendationsInfo],
          filter: [OppCandMlRecFilters.notMlAccepted],
        }
      : {}
  );
  return data;
};

export const updateOpportunityCandidate = async ({
  opportunityId,
  candidateId,
  ...props
}: Record<string, unknown> & {
  opportunityId: Opportunity['id'];
  candidateId: Candidate['id'];
}) => {
  const { data } = await api.PATCH(
    `/opportunities/${opportunityId}/candidates/${candidateId}`,
    props
  );
  return data;
};

export const updateCandidateOpportunity = async ({
  opportunityId,
  candidateId,
  ...props
}: Record<string, unknown> & {
  opportunityId: Opportunity['id'];
  candidateId: Candidate['id'];
}) => {
  const requestBody = new CandidateOpportunitiesDto(props);
  const { data } = await api.PATCH(
    `/candidates/${candidateId}/opportunities/${opportunityId}`,
    requestBody.export()
  );
  return data;
};

export const getOpportunityPositions = async (
  opportunityId: Opportunity['id']
) => {
  const { data: positions } = await api.GET(
    `/opportunities/${opportunityId}/positions`
  );
  return positions;
};

export const refreshBurdenedCost = async (
  opportunityId: Opportunity['id'],
  candidateId: Candidate['id']
) => {
  const { data: cost } = await api.PUT(
    `/opportunities/${opportunityId}/candidates/${candidateId}/cost`
  );
  return cost;
};

export const patchOpportunity = async (
  opportunityId: Opportunity['id'],
  body: Record<string, unknown>
) => {
  const requestBody = new OpportunityDto(body);

  const { data } = await api.PATCH(
    `/opportunities/${opportunityId}`,
    requestBody.export()
  );
  return { data };
};

export const getRelatedOpportunities = async (
  opportunityId: Opportunity['id']
) => {
  const { data: related } = await api.GET(
    `/opportunities/${opportunityId}/related`
  );
  return related;
};

export const downloadOpportunityList = async (
  body: Record<string, unknown>
) => {
  const response = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/downloads/opportunities`,
    body,
    true
  );
  return response;
};
