import { CandidateOpportunitiesConst } from '@axiom/const';

import { getRelatedOpportunities } from '../api/opportunities-legacy-api';
import GenericStore from '../classes/generic-store';

const { CandidateStatuses } = CandidateOpportunitiesConst;
export const ENGAGEMENTS_CANDIDATE_STATUS_WHITELIST = [
  CandidateStatuses.Engaged,
  CandidateStatuses.Completed,
];

class OpportunityEngagementsStoreClass extends GenericStore {
  transformEngagements(relatedOpportunities) {
    return relatedOpportunities.map(opportunity => {
      const opportunityCandidates = opportunity.positions.reduce(
        (acc, position) => {
          const positionCandidates = position.candidates.filter(candidate =>
            ENGAGEMENTS_CANDIDATE_STATUS_WHITELIST.includes(
              candidate.candidateStatus
            )
          );

          return positionCandidates.length > 0
            ? [...acc, ...positionCandidates]
            : acc;
        },
        []
      );

      return { ...opportunity, candidates: opportunityCandidates };
    });
  }

  load(opportunityId) {
    return this.setState(
      getRelatedOpportunities(opportunityId).then(relatedOpportunities =>
        this.transformEngagements(relatedOpportunities)
      )
    );
  }
}

export const OpportunityEngagementsStore =
  new OpportunityEngagementsStoreClass();
