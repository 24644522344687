import React from 'react';
import { shape, string, bool } from 'prop-types';
import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { formatDataTestId } from '../../utils/dataTest';

const ErrorWrapper = styled.div`
  color: ${getThemeProp('negativeColor')};
  font-weight: bold;
  display: inline-block;
`;

export const isErroring = finalFormElementMeta =>
  !!(
    (finalFormElementMeta.error || finalFormElementMeta.submitError) &&
    finalFormElementMeta.touched
  );

export const FFError = ({ finalFormElementMeta }) => (
  <ErrorWrapper>
    {(finalFormElementMeta.error || finalFormElementMeta.submitError) &&
      finalFormElementMeta.touched && (
        <span data-test={formatDataTestId('form error')}>
          {finalFormElementMeta.error || finalFormElementMeta.submitError}
        </span>
      )}
  </ErrorWrapper>
);

FFError.propTypes = {
  finalFormElementMeta: shape({
    touched: bool,
    error: string,
    submitError: string,
  }).isRequired,
};
