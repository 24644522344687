import React from 'react';
import { Field } from 'react-final-form';
import { Certification } from '@axiom/const';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';

import { DateUtil } from '../../utils/date-util';
import { toDropdownOptions } from '../../utils/to-dropdown-options';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import { FFInput } from '../FFInput/FFInput';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormCertificationItemStore } from '../../stores/form-certification-item-store';

const {
  QUALIFICATION_AWARDS: { QUALIFICATION, YEAR_OF_CALL, STATE_EXAM },
  FORM_TYPES: { QUALIFICATIONS, CERTIFICATIONS_AND_AWARDS },
} = Certification;

const YEARS_BACK = 80;

const certificationYears = toDropdownOptions(
  DateUtil.getYearOptionsByRange(
    new Date().getFullYear(),
    new Date().getFullYear() - YEARS_BACK
  )
);

const CertificationForm = () => {
  return (
    <FormStateTreatment
      formStore={FormCertificationItemStore}
      renderLoadedView={({ formData, fireSubmit, fireCancel }) => {
        const renderCertificationsAndAwardsFields = () => {
          return (
            <>
              <GridRow gutterBottom="16px">
                <GridColumn width={12}>
                  <Field
                    name="award"
                    component={FFInput}
                    label="Certification / Award Name"
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={12}>
                  <Field
                    name="institution"
                    component={FFInput}
                    label="Awarding Organization"
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={12}>
                  <Field
                    type="number"
                    name="year"
                    component={FFDropdown}
                    label="Year"
                    options={certificationYears}
                  />
                </GridColumn>
              </GridRow>
            </>
          );
        };

        const renderQualificationsFields = () => {
          const qualificationOptions = toDropdownOptions([
            QUALIFICATION,
            STATE_EXAM,
            YEAR_OF_CALL,
          ]);

          return (
            <>
              <GridRow gutterBottom="16px">
                <GridColumn width={12}>
                  <Field
                    name="institution"
                    component={FFInput}
                    label="Institution"
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={6}>
                  <Field
                    name="award"
                    options={qualificationOptions}
                    component={FFDropdown}
                    label="Degree"
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Field
                    type="number"
                    name="year"
                    component={FFDropdown}
                    label="Year"
                    options={certificationYears}
                  />
                </GridColumn>
              </GridRow>
              {formData.award === STATE_EXAM && (
                <GridRow>
                  <GridColumn width={12}>
                    <Field
                      name="stateExamScore"
                      label="Score"
                      component={FFInput}
                      allowNull
                    />
                  </GridColumn>
                </GridRow>
              )}
            </>
          );
        };

        const renderForm = () => {
          return formData.type === QUALIFICATIONS
            ? renderQualificationsFields()
            : renderCertificationsAndAwardsFields();
        };

        return (
          <Modal name="CERTFORM">
            <ModalHeader onClose={fireCancel}>
              {formData.id ? 'Edit' : 'Add'} Qualifications, Certifications, &
              Awards
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow gutterBottom="16px">
                  <GridColumn width={12}>
                    <Field
                      name="type"
                      component={FFDropdown}
                      label="Type"
                      options={toDropdownOptions([
                        QUALIFICATIONS,
                        CERTIFICATIONS_AND_AWARDS,
                      ])}
                    />
                  </GridColumn>
                </GridRow>
                {formData.type && renderForm()}
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCELCERTIFICATIONBUTTON"
                onClick={fireCancel}
                variation="outline"
              >
                Cancel
              </Button>
              <Button
                name="SAVECERTIFICATIONBUTTON"
                onClick={fireSubmit}
                disabled={formData.type === undefined}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};

export default CertificationForm;
