import React, { FC } from 'react';
import { Layout, Paragraph, SmallHeader } from '@axiom/ui';

import EmptyPosition from '../../public/svg/empty-position.svg';

interface OpportunityTalentParentPositionEmptyProps {
  isTalentPool?: boolean;
  isRemovedTalent?: boolean;
}

export const OpportunityTalentParentPositionEmpty: FC<
  OpportunityTalentParentPositionEmptyProps
> = ({ isTalentPool, isRemovedTalent }) => {
  let title = 'No talent added to the position';
  let description =
    "Adding talent to the position is easy - use the 'Add Talent' button or transfer talent from the talent pool.";

  if (isTalentPool) {
    title = 'No talent have been added to the Talent Pool';
    description =
      "Click the 'Add Talent' button to add talent to the pool via searching for talent or AI matches.";
  } else if (isRemovedTalent) {
    title = 'No talent has been removed from this opportunity yet';
    description = '';
  }

  return (
    <Layout direction="horizontal" horizontalGutter="16px">
      <EmptyPosition width={117} height={117} />
      <Layout direction="vertical" verticalGutter="16px">
        <SmallHeader>{title}</SmallHeader>
        <Paragraph>{description}</Paragraph>
      </Layout>
    </Layout>
  );
};
