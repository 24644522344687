import { arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getOpportunitySearchesByUserId } from '../api/searches';

import { OpportunitiesStore } from './opportunities-store';

class UserOpportunityFiltersStoreClass extends GenericStore {
  load(userId) {
    return this.setState(
      getOpportunitySearchesByUserId(userId, this.getType()).then(userFilters =>
        userFilters.map(item => ({
          id: item.id,
          name: item.name,
          filters: item.search,
        }))
      )
    );
  }

  getType() {
    return 'opportunity';
  }

  getDataShape() {
    return arrayOf(OpportunitiesStore.getMetaConfigShape());
  }
}

export const UserOpportunityFiltersStore =
  new UserOpportunityFiltersStoreClass();
