import { Api } from '@axiom/ui';
import { Candidate, CandidateWhiteboard } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readCandidate(candidateId: Candidate['id']) {
    return super.read<{ data: Candidate }>({
      endpoint: `/candidates/${candidateId}`,
      method: 'GET',
    });
  }

  readCandidateNotes(candidateId: CandidateWhiteboard['candidateId']) {
    return super.read<{ data: CandidateWhiteboard }>({
      endpoint: `/candidates/${candidateId}/whiteboard`,
      method: 'GET',
    });
  }

  updateCandidateNotes(body: CandidateWhiteboard) {
    return super.write<{ data: CandidateWhiteboard }>({
      endpoint: `/candidates/${body.candidateId}/whiteboard`,
      body,
      method: 'PUT',
    });
  }

  refreshNotes(candidateId: CandidateWhiteboard['candidateId']) {
    return super.invalidate(`/candidates/${candidateId}/whiteboard`);
  }
}

export const CandidateLegacyApi = new CandidateLegacyApiClass();
