import styled from 'styled-components';

import { getAnyStyleByStatus } from '../../utils/color';

export const StatusIcon = styled.div<{ status: string }>`
  line-height: 3rem;
  padding-left: calc(1rem + 10px);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    height: 3rem;
    width: 8px;
    background: ${({ status }) => getAnyStyleByStatus(status).background};
    border: 1px solid ${({ status }) => getAnyStyleByStatus(status).border};
    position: absolute;
    top: 0;
    left: 0;
  }
`;
