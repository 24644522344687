import { Candidate, User } from '@axiom/validation';
import { AnonBioProfileStatusUtil } from '@axiom/ui';

import { window } from './global';
import { EnvUtil } from './env-util';

const openBio = (id: Candidate['id'], anon = false, download = false) => {
  const url = `${window.location.origin}/talent/${id}${
    download ? '/download' : ''
  }/bio${download ? '/showHighlights' : ''}${anon ? '/anonymous' : ''}`;

  window.open(url, download ? 'download_bio' : '_blank');
};

export const AxiomForTalentUtil = {
  canViewAft: (talent?: Candidate, user?: User) =>
    !!(talent && user && !user.roles.includes('EnvoySales')),
  openAftProfile: (talentId: Candidate['id']) => {
    window.open(`${EnvUtil.portalUrl}/talent/${talentId}`, '_blank');
  },
  openAltSpv: (slug: string) => {
    window.open(`${EnvUtil.clientUrl}/access-legal-talent/${slug}`, '_blank');
  },
  openBio: (candidateId: Candidate['id']) => {
    openBio(candidateId);
  },
  openAnonymousBio: (candidateId: Candidate['id']) => {
    openBio(candidateId, true);
  },
  downloadBio: (candidateId: Candidate['id']) => {
    openBio(candidateId, false, true);
  },
  downloadAnonymousBio: (candidateId: Candidate['id']) => {
    openBio(candidateId, true, true);
  },
  openResume: (talentId: Candidate['id']) => {
    window.open(`${EnvUtil.portalUrl}/bio/${talentId}`, '_blank');
  },
  openAnonymousResume: (talentId: Candidate['id']) => {
    window.open(`${EnvUtil.portalUrl}/anonymous-profile/${talentId}`, '_blank');
  },
  isFullBiosByStatus: (status: Candidate['profileStatus']) => {
    return !AnonBioProfileStatusUtil.statusSet().has(status);
  },
};
