import keyBy from 'lodash/keyBy';

import { LANGUAGES_LOADED } from '../constants';

export const INITIAL_STATE = {
  data: [],
  byId: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LANGUAGES_LOADED:
      return {
        ...state,
        data: payload.data,
        byId: keyBy(payload.data, 'id'),
      };
    default:
      return state;
  }
};

export default reducer;
