import styled, { css } from 'styled-components';

const boxedColumnMixin = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 14px 0;
  height: fit-content;
`;

export const ModalGridRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const CurrentAvailabilityColumn = styled.div`
  ${boxedColumnMixin};
  max-width: 200px;
  border-radius: 8px;

  .last-edited {
    font-style: italic;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .expected-change {
    margin-top: 1rem;

    &__date {
      margin: 0;
    }
  }
`;

export const DesiredHoursColumn = styled.div`
  ${boxedColumnMixin};
  max-width: 120px;
  position: relative;
  margin: 0 38px 0 46px;
  &:after {
    content: '-';
    display: block;
    position: absolute;
    right: -25px;
    top: 21px;
    color: #5c6471;
    font-size: 2.5rem;
  }
  &:before {
    content: '=';
    display: block;
    position: absolute;
    left: -34px;
    top: 25px;
    color: #5c6471;
    font-size: 2.5rem;
  }

  .desired-hours {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    align-items: center;
  }
`;

export const EngagementListColumn = styled.div`
  ${boxedColumnMixin};
  margin-right: 16px;
  max-width: 820px;
  padding: 0 24px;
  overflow: hidden;
`;

export const EngagementTable = styled.table`
  width: 100%;
  table-layout: fixed;
  overflow: scroll;

  tbody {
    display: block;
    overflow: auto;
    height: 480px;
    width: 100%;
  }
  thead tr {
    display: block;
    padding: 0;
  }
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    display: block;
    padding: 13px 0 20px 0;
  }

  tbody tr {
    height: 80px;
  }
  th {
    font-size: 0.875rem;
    font-weight: bold;
    padding: 13px 0 9px 0;
    position: sticky;
  }
  td,
  th {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-start;
    padding-right: 10px;
  }
  td {
    &.hours {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  td > div {
    &.data {
      display: block;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
  }
  .utilization-column {
    width: 25%;
  }
  .active-engagements-column {
    width: 25%;
  }
  .opportunity-name-column {
    width: 25%;
  }
  .roll-off-date-column {
    width: 20%;
    padding-left: 10px;
    height: 100%;
    .with-icon {
      height: auto;
    }
  }
  .opp-link-column {
    width: 5%;
    padding-right: 0;
  }
`;

export const RollOffIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 8px;
  }
`;
