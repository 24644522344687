import styled from 'styled-components';
import { Label } from '@axiom/ui';

import Input from '../Input/Input';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
// eslint-disable-next-line boundaries/element-types
import { DivEditorWrapper } from '../RichTextEditor/RichTextEditorStyles';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

const calcSize = (value = 4) => 100 / value;

export const OpportunityProfilePositionsTabsWrapper = styled.div``;

export const OpportunityProfilePositionWrapper = styled.div`
  background: #fff;
  border: 1px solid #000;
  margin-bottom: ${({ extraMargin }) => (extraMargin ? '14rem' : '1rem')};
  position: relative;
`;

export const OPPUnderlay = styled.div`
  ${({ shouldBlur }) => shouldBlur && 'clip-path: inset(0);'};
  ${({ shouldBlur }) => shouldBlur && 'filter: blur(0.5rem);'};
`;

export const OPPOverlay = styled.div`
  position: absolute;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  border: 0.0625rem solid black;
  background: rgba(255, 255, 255, 0.85);
`;

export const OPPOverlayForm = styled.form`
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
`;

export const OPPOverlayFormInput = styled(Input)`
  margin-bottom: 1rem;
`;

export const OPPHeader = styled.div`
  background: ${GENERAL_STYLES_MAP.PANEL_HEADER.background};
  border: 2px solid ${GENERAL_STYLES_MAP.PANEL_HEADER.border};
  display: flex;
  padding: 0.5rem 0;
`;

export const OPPIcon = styled.div`
  flex: none;
  padding: 0.25rem 0.5rem;
`;

export const OPPHeaderContent = styled.div`
  flex: 0 1 100%;
  overflow: hidden;
  overflow-wrap: break-word;
`;

export const OPPMainContent = styled.div`
  border: 1px solid #000;
  border-top: none;
  padding-top: 1em;
`;

export const OPPRow = styled.div`
  padding: 0 1em 1em;
`;

export const OPPTitles = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OPPTitle = styled(Label)`
  min-width: ${props => calcSize(props.size)}%;
  display: flex;
`;

export const OPPValues = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OPPCell = styled.div`
  float: left;
  width: ${props => calcSize(props.size)}%;
  min-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

export const OPPDisplayWrapper = styled.div`
  transition: height 250ms linear;
`;

export const OPPDivider = styled.hr`
  margin: 0 1rem 1rem;
`;

export const OPPRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OPPRichTextEditor = styled(RichTextEditor)`
  & > ${DivEditorWrapper} {
    height: 10rem;
    cursor: ${({ readOnly }) => (readOnly ? 'auto' : 'text')};
    overflow: auto;
    padding: 0.5rem 1rem;
    border: ${({ readOnly }) =>
      readOnly ? `0.0625rem solid #707070` : `0.0625rem solid black`};

    .editors,
    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      overflow: auto;
      height: auto;
      min-height: initial;
    }
  }
`;
