import React, { FC } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionSection,
  Badge,
  Card,
  CardHeader,
  CardSection,
  Layout,
  SmallHeader,
} from '@axiom/ui';
import { Opportunity, OpportunityCandidate } from '@axiom/validation';

import { OpportunityTalentParentPositionList } from './OpportunityTalentParentPositionList';
import { OpportunityTalentParentPositionEmpty } from './OpportunityTalentParentPositionEmpty';

interface OpportunityTalentParentRemovedTalentProps {
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
}

export const OpportunityTalentParentRemovedTalent: FC<
  OpportunityTalentParentRemovedTalentProps
> = ({ opportunity, candidates }) => {
  return (
    <Accordion name="removed-talent">
      <Card>
        <CardHeader>
          <AccordionHeader>
            <Layout
              direction="horizontal"
              horizontalGutter="8px"
              position="middle"
            >
              <Badge name="removed-talent-count" background="gray">
                {candidates.length}
              </Badge>
              <SmallHeader maxLines={1}>Removed Talent</SmallHeader>
            </Layout>
          </AccordionHeader>
        </CardHeader>
        <AccordionSection>
          <CardSection>
            {candidates.length > 0 ? (
              <OpportunityTalentParentPositionList
                opportunity={opportunity}
                candidates={candidates}
              />
            ) : (
              <OpportunityTalentParentPositionEmpty isRemovedTalent />
            )}
          </CardSection>
        </AccordionSection>
      </Card>
    </Accordion>
  );
};
