import { ENGAGEMENTS_CANDIDATE_STATUS_WHITELIST } from '../stores/opportunity-engagements-store';

export const EngagementsUtils = {
  buildEngagements(opportunity, related) {
    const opportunityEngagementCandidates = opportunity.candidates.filter(
      candidate =>
        ENGAGEMENTS_CANDIDATE_STATUS_WHITELIST.includes(
          candidate.candidateStatus
        )
    );
    const opportunityEngagement = {
      ...opportunity,
      candidates: opportunityEngagementCandidates,
    };
    const relatedEngagements = related.reduce(
      (relatedOpps, opps) => [
        ...relatedOpps,
        {
          ...opps,
          candidates: opps.candidates.map(candidate => ({
            ...candidate,
            position: opps.positions.find(
              position => position.id === candidate.positionId
            ),
          })),
        },
      ],
      []
    );
    return [opportunityEngagement, ...relatedEngagements];
  },
  countEngagements(opportunity, related) {
    if (!opportunity || !related) {
      return 0;
    }

    const opportunityEngagementsLength =
      opportunity && opportunity.candidates
        ? opportunity.candidates.filter(candidate =>
            ENGAGEMENTS_CANDIDATE_STATUS_WHITELIST.includes(
              candidate.candidateStatus
            )
          ).length
        : 0;
    const relatedEngagementsLength = related
      ? related.filter(relatedOpp => relatedOpp.candidates.length > 0).length
      : 0;
    return opportunityEngagementsLength + relatedEngagementsLength;
  },
};
