import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';

// eslint-disable-next-line import/prefer-default-export
export const StyledHorizontalRule = styled.hr`
  background: ${GENERAL_STYLES_MAP.DISABLED.background};
  border: 0;
  height: 1px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;
