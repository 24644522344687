import {
  USER_OPPORTUNITIES_LOAD,
  USER_OPPORTUNITIES_LOADED,
  USER_OPPORTUNITIES_LOAD_ERROR,
} from '../constants';

export const getOpportunitiesByUserId = (id, body) => ({
  type: USER_OPPORTUNITIES_LOAD,
  payload: { id, body },
});

export const opportunitiesByUserIdLoaded = payload => ({
  type: USER_OPPORTUNITIES_LOADED,
  payload,
});

export const opportunitiesByUserIdError = payload => ({
  type: USER_OPPORTUNITIES_LOAD_ERROR,
  payload,
  error: true,
});
