import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const AreasOfStudyManagementFormFormHeader = styled.div`
  background: ${getThemeProp('appBackground')};
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  margin: 0;
  padding: 24px 32px;
`;
