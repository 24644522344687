import GenericStore from '../classes/generic-store';

import { OpportunityCandidatesStore } from './opportunity-candidates-store';

class TalentWarningModalStoreClass extends GenericStore {
  load(warningData) {
    this.setState(warningData);
  }

  onTalentOpportunityUpdate(candidateID) {
    this.dispatch((dispatch, getState) => {
      const opportunityCandidatesState = OpportunityCandidatesStore.select(
        getState()
      );
      const candidate = opportunityCandidatesState.data?.find(
        c => c.id === candidateID
      );

      const shouldWarnContractor =
        candidate.consultant1099UtilizationWarning === true;
      const shouldWarnMinPay = candidate.meetsMinPayRequirements === false;

      if (shouldWarnContractor || shouldWarnMinPay) {
        this.load({ shouldWarnContractor, shouldWarnMinPay });
      }
    });
  }
}

export const TalentWarningModalStore = new TalentWarningModalStoreClass();
