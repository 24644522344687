import { SubmissionCandidate } from '@axiom/validation';

export class SubmissionCandidateModel {
  props: SubmissionCandidate = {};

  constructor(props: SubmissionCandidate = {}) {
    this.props = props;
  }

  calculatedDescription() {
    return (
      this.props.submissionDescription ||
      this.props.publicCandidateSummary ||
      ''
    ).trim();
  }

  calculatedAddress() {
    return [
      this.props?.addressCity,
      this.props?.addressState,
      this.props?.addressCountry,
    ]
      .filter(a => a)
      .join(', ');
  }
}
