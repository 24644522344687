import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { CandidateOpportunityLegacyApi } from '../api/candidate-opportunities-api-legacy';
import {
  OpportunityLegacyApi,
  updateCandidateOpportunity,
} from '../api/opportunities-legacy-api';

import { OpportunityStore } from './opportunity-store';

const EditAutoCoolingModalValidation = AjvUtil.compileSchema({
  required: ['candidateStatusMessage'],
  properties: {
    candidateStatusMessage: {
      type: 'string',
      maxLength: 1000,
      minLength: 1,
    },
  },
});

class FormAutoCoolingModalStoreClass extends FormStore {
  load(form) {
    if (form?.candidateStatusMessages?.length) {
      form.coolingMessage = `Thank you for your interest in this opportunity. While the client enjoyed learning more about you, they have decided not to interview you for this role. Here’s what influenced their decision:\n${form.candidateStatusMessages.map(
        msg => {
          return `\n- ${msg}`;
        }
      )}\n\nWe still think you’re awesome and are already looking for your next opportunity.`;
    }
    this.setState(form);
  }

  submit(changedFields, formData) {
    const data = { ...formData, ...changedFields };
    return this.clearState(
      updateCandidateOpportunity(data).then(async () => {
        OpportunityStore.load(formData.opportunityId);
        await Promise.all([
          OpportunityLegacyApi.refreshOpportunityCandidates(
            formData.opportunityId
          ),
          CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
            formData.candidateId
          ),
        ]);
      })
    );
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      EditAutoCoolingModalValidation,
      data
    );
  }
}

export const FormAutoCoolingModalStore = new FormAutoCoolingModalStoreClass();
