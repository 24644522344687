import {
  BANNER_MESSAGES_LOAD,
  BANNER_MESSAGES_LOADED,
  BANNER_MESSAGES_LOAD_ERROR,
} from '../constants';

export const getBannerMessage = payload => ({
  type: BANNER_MESSAGES_LOAD,
  payload,
});

export const bannerMessagesLoaded = payload => ({
  type: BANNER_MESSAGES_LOADED,
  payload,
});

export const bannerMessagesLoadedError = payload => ({
  type: BANNER_MESSAGES_LOAD_ERROR,
  payload,
  error: true,
});
