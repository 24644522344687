import styled from 'styled-components';

import { Fieldset, FormCell } from '../../styled/form';

export const LanguageForm = styled(Fieldset)`
  ${FormCell}:nth-of-type(4) {
    align-items: center;
    display: flex;
  }
`;

export const LanguageList = styled.ul`
  list-style: none;
  margin: 0 0 2.5em;
  padding: 0;
`;

export const LanguageItem = styled.li`
  align-items: center;
  display: flex;
  margin-bottom: 0.5em;
`;

export const LanguageDescription = styled.span`
  margin-right: 1em;
`;

export const LanguageDisplayItem = styled.span`
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;
