import React from 'react';

import { Modal } from '../element/Modal/Modal';
import { ModalHeader } from '../element/Modal/ModalHeader';
import { ModalSection } from '../element/Modal/ModalSection';
import { ModalFooter } from '../element/Modal/ModalFooter';
import { Button } from '../element/Button/Button';
import { Gutter } from '../layout/Gutter/Gutter';
import { CondensedLarge } from '../content/CondensedLarge/CondensedLarge';

interface DownloadModalProps {
  onClose: () => void;
  onDownload: () => void;
}

export const BiosConfirmModal = ({
  onClose,
  onDownload,
}: DownloadModalProps) => {
  return (
    <Modal name="DOWNLOAD_BIO_CONFIRM_MODAL">
      <ModalHeader name="DOWNLOAD_BIO_CONFIRM_MODAL_HEADER" onClose={onClose}>
        Talent not opted in to proactive shares
      </ModalHeader>
      <ModalSection>
        <CondensedLarge>
          This talent has not opted in to proactive shares. Please acknowledge
          that you understand this talent preference.
        </CondensedLarge>
        <Gutter bottom="8px" />
        <CondensedLarge>
          I understand that this Talent may not want their bio shared with
          Clients proactively.
        </CondensedLarge>
      </ModalSection>
      <ModalFooter>
        <Button
          name="CANCEL_MODAL_BUTTON"
          variation="outline"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button name="CONFIRM_MODAL_BUTTON" onClick={onDownload}>
          Download
        </Button>
      </ModalFooter>
    </Modal>
  );
};
