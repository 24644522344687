import React from 'react';
import {
  Badge,
  EmptyLayout,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  Well,
} from '@axiom/ui';
import { Candidate, OpportunityCandidate } from '@axiom/validation';
import { CandidatesConst } from '@axiom/const';

import EmptyProjectGraphic from '../../public/svg/empty-project.svg';
import {
  TalentOpportunityCard,
  TalentOpportunityCardType,
  talentOpportunityCardTypeNameFormatter,
  TalentOpportunityCardTypeNames,
} from '../TalentOpportunityCard/TalentOpportunityCard';

export const TalentOpportunityContainer = ({
  type,
  candidateOpportunities,
  candidate,
}: {
  type: TalentOpportunityCardType;
  candidateOpportunities: OpportunityCandidate[];
  candidate?: Candidate;
}) => {
  const typeName = talentOpportunityCardTypeNameFormatter(type);

  return (
    <>
      <Well name={`talent-opportunity-container-${typeName}`}>
        <Layout direction="vertical" verticalGutter="16px">
          <Layout
            name={`${typeName}-category-layout`}
            direction="horizontal"
            position="space-between"
          >
            <Layout>
              <LayoutItem position="left">
                <Badge background="gray" name="talentOpportunityCountBadge">
                  {candidateOpportunities.length}
                </Badge>
              </LayoutItem>
              <Gutter horizontal="4px" />
              <LayoutItem position="right">
                <Flashy bold name="container-type">
                  {type}
                </Flashy>
              </LayoutItem>
            </Layout>
            {type === TalentOpportunityCardTypeNames.currentEngagements &&
              candidate?.calculatedEngagedAndReservedHours && (
                <Layout name="ENGAGEMENT_HOURS_COUNT" position="right">
                  <LayoutItem>
                    <Badge
                      name="ENGAGEMENT_HOURS_BADGE"
                      background={
                        candidate.calculatedEngagedAndReservedHours
                          .totalEngagedHours >=
                          CandidatesConst.EngagedHoursWarningLimit ||
                        candidate.calculatedEngagedAndReservedHours
                          .totalReservedHours >=
                          CandidatesConst.ReservedHoursWarningLimit
                          ? 'red'
                          : 'gray'
                      }
                    >
                      {
                        candidate.calculatedEngagedAndReservedHours
                          .totalEngagedHours
                      }{' '}
                      hours (
                      {
                        candidate.calculatedEngagedAndReservedHours
                          .totalReservedHours
                      }{' '}
                      reserved)
                    </Badge>
                  </LayoutItem>
                </Layout>
              )}
          </Layout>

          {candidateOpportunities.map(candidateOpp => (
            <div key={candidateOpp.id}>
              <TalentOpportunityCard
                type={type}
                opportunityCandidate={candidateOpp}
              />
            </div>
          ))}
          {candidateOpportunities.length === 0 && (
            <>
              <EmptyLayout
                name={`talent-opportunity-container-${typeName}-empty-layout`}
                graphic={<EmptyProjectGraphic width="117px" height="117px" />}
              >
                <Layout direction="vertical" verticalGutter="16px">
                  <ParagraphHeader
                    name={`talent-opportunity-container-${typeName}-empty-layout-header`}
                  >
                    No Opportunities
                  </ParagraphHeader>
                  <Paragraph
                    name={`talent-opportunity-container-${typeName}-empty-layout-body`}
                  >
                    No opportunities of this type exist.
                  </Paragraph>
                </Layout>
              </EmptyLayout>
            </>
          )}
        </Layout>
      </Well>
      <Gutter vertical="8px" />
    </>
  );
};
