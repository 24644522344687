import FormStore from '../classes/form-store';
import { patchOpportunity } from '../api/opportunities-legacy-api';

import { OpportunityStore } from './opportunity-store';

class FormOpportunityExcludeFromWorkFeedStoreClass extends FormStore {
  getDataShape() {}

  load(opportunity) {
    return this.setState({
      isExcludedFromFeed: opportunity.isExcludedFromFeed,
      id: opportunity.id,
    });
  }

  validate() {
    return true;
  }

  submit(changedFields, formData) {
    const { id, isExcludedFromFeed } = formData;

    return this.watchState(patchOpportunity(id, { isExcludedFromFeed })).then(
      () => {
        OpportunityStore.load(id);
      }
    );
  }
}

export const FormOpportunityExcludeFromWorkFeedStore =
  new FormOpportunityExcludeFromWorkFeedStoreClass();
