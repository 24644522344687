import {
  BANNER_MESSAGES_LOADED,
  BANNER_MESSAGES_LOAD_ERROR,
} from '../constants';

export const INITIAL_STATE = {
  data: [],
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BANNER_MESSAGES_LOADED:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case BANNER_MESSAGES_LOAD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
