import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { func, oneOf, string } from 'prop-types';
import { EventsConst, CandidateOpportunitiesConst } from '@axiom/const';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  EmailIcon,
  Gutter,
  InPersonIcon,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  OtherActivityIcon,
  Paragraph,
  PhoneIcon,
  SectionHeader,
  Text,
} from '@axiom/ui';

import { TalentActivitiesApi } from '../../api/talentActivities';
import { fullNameByIdSelector } from '../../redux/selectors/users';
import { DateUtil } from '../../utils/date-util';
import { eventShape } from '../../models/event';
import { EventsStore, EventSubjectConst } from '../../stores/events-store';
import { OpportunityCandidatesStore } from '../../stores/opportunity-candidates-store';
import { CandidateStore } from '../../stores/candidate-store';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

import {
  ALIListItem,
  ALIContent,
  ALIAuthor,
  ALIDate,
} from './ActivityLogItemStyles';

const { Types } = EventsConst;

const iconForType = {
  [Types.Call]: PhoneIcon,
  [Types.Email]: EmailIcon,
  [Types.Interview]: InPersonIcon,
  [Types.InPerson]: InPersonIcon,
  // TBD? [Types.Note]: NoteIcon,
  [Types.Video]: PhoneIcon,
};
const AFCActionPurposes = new Set([
  CandidateOpportunitiesConst.AllStatusLabels.ClientPassed,
  CandidateOpportunitiesConst.AllStatusLabels.InterviewRequested,
  CandidateOpportunitiesConst.AllStatusLabels.HireRequested,
  `${CandidateOpportunitiesConst.AllStatusLabels.InterviewRequested} - Zoom not supported`,
  'Interview Needs Attention',
]);

class ActivityLogItem extends Component {
  constructor(config) {
    super(config);
    this.state = {
      AFCActionDialogOpen: false,
      confirmDeleteModal: false,
    };
  }

  onAFCActionDialogOpen = () => {
    this.setState({ AFCActionDialogOpen: true });
  };

  onAFCActionDialogClose = () => {
    this.setState({ AFCActionDialogOpen: false });
  };

  toggleDetailsViewOrOpenPassModal = () => {
    const { event } = this.props;
    if (AFCActionPurposes.has(event.purpose)) {
      this.onAFCActionDialogOpen();
    } else {
      this.props.onEditClick(this.props.event);
    }
  };

  lookupLastAuthoredByName = () => {
    const { event, opportunityCandidatesState, usersById, candidateState } =
      this.props;
    const author =
      usersById[event.lastAuthoredBy] ||
      opportunityCandidatesState?.data?.find(
        state => state.id === event.candidateId
      )?.calculatedDisplayName ||
      candidateState?.data?.calculatedDisplayName;
    return author;
  };

  deleteActivity = async () => {
    this.setState({
      confirmDeleteModal: false,
    });
    await EventsStore.delete(
      this.props.eventSubject,
      this.props.eventSubjectId,
      this.props.event.id
    );

    if (this.props.event.candidateId) {
      await TalentActivitiesApi.refreshActivities(this.props.event.candidateId);
    }
  };

  render() {
    const { event } = this.props;
    const lastAuthoredBy = this.lookupLastAuthoredByName();
    const Icon = iconForType[event.type] || OtherActivityIcon;

    return (
      <>
        <ALIListItem data-test="ACTIVITYLOGITEM">
          <Layout position="middle">
            <LayoutItem fluid>
              <ALIContent
                onClick={this.toggleDetailsViewOrOpenPassModal}
                role="button"
                data-test="CONTENTBUTTON"
              >
                <div data-test="ICON" data-value={event.type}>
                  <Icon />
                </div>
                <Text name="activityLogItemPurpose" bold>
                  {event.purpose}
                </Text>
                <ALIAuthor
                  data-test="ACTIVITYLOGITEMAUTHOR"
                  data-value={event.lastAuthoredBy}
                >
                  <Text size="small" as="span">
                    {lastAuthoredBy}
                  </Text>
                </ALIAuthor>
                <ALIDate data-test="ACTIVITYLOGITEMDATE">
                  <Text size="small">
                    {DateUtil.displayDate(event.scheduledDate)}
                  </Text>
                </ALIDate>
              </ALIContent>
            </LayoutItem>
            <ContextMenu direction="left">
              {!AFCActionPurposes.has(event.purpose) && (
                <ContextMenuItem
                  onClick={() => this.props.onEditClick(this.props.event)}
                >
                  Edit
                </ContextMenuItem>
              )}
              <ContextMenuItem
                onClick={() => {
                  this.setState({
                    confirmDeleteModal: true,
                  });
                }}
              >
                Delete
              </ContextMenuItem>
            </ContextMenu>
          </Layout>
        </ALIListItem>
        {this.state.confirmDeleteModal && (
          <Modal size="confirm">
            <ModalHeader
              onClose={() => this.setState({ confirmDeleteModal: false })}
            >
              Confirm Deletion
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                Are you sure you want to delete this {this.props.event.purpose}{' '}
                activity?
              </Paragraph>
            </ModalSection>
            <ModalFooter>
              <Button
                variation="outline"
                onClick={() => this.setState({ confirmDeleteModal: false })}
              >
                Cancel
              </Button>
              <Button onClick={this.deleteActivity}>Delete Activity</Button>
            </ModalFooter>
          </Modal>
        )}
        {this.state.AFCActionDialogOpen && (
          <Modal size="confirm">
            <ModalHeader onClose={this.onAFCActionDialogClose}>
              Activity
            </ModalHeader>
            <ModalSection>
              <Gutter bottom="24px">
                <SectionHeader>{event.purpose}</SectionHeader>
              </Gutter>
              <RichTextEditor
                name="description"
                id="description"
                initialValue={event.description}
                readOnly
              />
            </ModalSection>
            <ModalFooter>
              <Button onClick={this.onAFCActionDialogClose}>Close</Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

ActivityLogItem.defaultProps = {};

ActivityLogItem.propTypes = {
  event: eventShape.isRequired,
  eventSubjectId: string.isRequired,
  eventSubject: oneOf(Object.values(EventSubjectConst)).isRequired,
  onEditClick: func.isRequired,
  opportunityCandidatesState:
    OpportunityCandidatesStore.getStateShape().isRequired,
  usersById: PropTypes.shape({ id: PropTypes.string }).isRequired,
  candidateState: CandidateStore.getStateShape().isRequired,
};

const mapStateToProps = state => ({
  usersById: fullNameByIdSelector(state),
  opportunityCandidatesState: OpportunityCandidatesStore.select(state),
  candidateState: CandidateStore.select(state),
});

export default connect(mapStateToProps)(ActivityLogItem);
