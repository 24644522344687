import { call, put, takeLatest } from 'redux-saga/effects';

import {
  OPPORTUNITY_CANDIDATES_LOAD,
  OPPORTUNITY_CANDIDATES_LOAD_ERROR,
} from '../constants';
import { addApiError } from '../actions/app';
import { opportunityCandidatesLoadSuccess } from '../actions/opportunities';
import { getCandidatesByJobId as getCandidatesByOpportunityId } from '../../api/opportunity-talent-api';

export function* getOpportunityCandidatesSaga({ payload: opportunityId }) {
  try {
    const candidates = yield call(getCandidatesByOpportunityId, opportunityId);
    yield put(opportunityCandidatesLoadSuccess({ candidates, opportunityId }));
  } catch (e) {
    const { applicationError } = e;
    yield put(addApiError(applicationError));
    yield put({ type: OPPORTUNITY_CANDIDATES_LOAD_ERROR });
  }
}

function* opportunityCandidatesSaga() {
  yield takeLatest(OPPORTUNITY_CANDIDATES_LOAD, getOpportunityCandidatesSaga);
}

export default opportunityCandidatesSaga;
