import React from 'react';
import { Certification } from '@axiom/const';
import { Candidate } from '@axiom/validation';

import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { SectionHeader } from '../../content/SectionHeader/SectionHeader';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

const { QUALIFICATION_AWARDS } = Certification;

export const TalentQualifications = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  const awardTypes = Object.values(QUALIFICATION_AWARDS);
  const qualifications = (candidate?.certifications || []).filter(cert =>
    awardTypes.includes(cert.award)
  );

  return (
    <>
      <SectionHeader name="Qualifications">Qualifications</SectionHeader>
      <Gutter bottom="12px" />
      {qualifications.length === 0 ? (
        <Paragraph name="NO_QUALIFICATIONS">--</Paragraph>
      ) : (
        <Layout direction="vertical" verticalGutter="12px">
          {qualifications.map(({ award, institution, year }) => (
            <div
              data-test="QUALIFICATION_AWARD"
              key={`${award}_${institution}_${year}`}
            >
              <ParagraphHeader name="AWARD_NAME">{award}</ParagraphHeader>

              <span>
                <Paragraph name="INSTITUTION" as="span">
                  {institution}
                </Paragraph>
                ,{' '}
                <Paragraph name="YEAR" as="span">
                  {year}
                </Paragraph>
              </span>
            </div>
          ))}
        </Layout>
      )}
    </>
  );
};
