import { GenericDto } from './generic-dto';

export const OpportunityDto = class OpportunityDto extends GenericDto {
  constructor(body) {
    super(body, [
      'accountId',
      'accountName',
      'accountSalesforceId',
      'amount',
      'billingCurrency',
      'billingEstimatedUnits',
      'businessTeam',
      'clientName',
      'closedLostNotes',
      'competitorType',
      'competitorView',
      'contractedEndDate',
      'cultureSkills',
      'currency',
      'description',
      'engagementType',
      'forecastCategory',
      'fulfillmentStatus',
      'inadequateSupplyCategory',
      'integrationId',
      'isExcludedFromFeed',
      'isRemote',
      'jobName',
      'matterId',
      'nextStep',
      'newSubmissionType',
      'offering',
      'opportunityOwnerSalesforceId',
      'ownerUserId',
      'practiceAreaId',
      'reasonLost',
      'region',
      'renewal',
      'renewalContract',
      'requiredNumResources',
      'salesCloseDate',
      'salesforceAccountId',
      'salesforceContactId',
      'salesforceContractId',
      'salesforceCxLeadId',
      'salesforceId',
      'salesforceQuoteId',
      'salesLeadId',
      'salesNote',
      'stage',
      'startDate',
      'statusUpdatedAt',
      'useCase',
    ]);
  }
};
