import styled from 'styled-components';

export const className = 'filter-input';

export const OFFDateRangeContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 25px;
  justify-content: left;

  & > *:first-child,
  & > *:last-child {
    flex: 1;
  }

  .DateInput {
    width: 100%;
  }
`;

export const OFFMultiSelectContainer = styled.div`
  flex: 0 1 32.5%;
  box-shadow: none;
  margin-bottom: 25px;
`;
