import styled from 'styled-components';

import { GENERAL_STYLES_MAP, SIDEBAR_PADDING } from '../../themes/constants';
import UnorderedList from '../UnorderedList/UnorderedList';

export const DetailContainer = styled.div`
  display: flex;
`;

export const DetailColumn = styled.div`
  border-left: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  flex: 0 1 50%;
  margin: ${SIDEBAR_PADDING}px 0 0 0;
  max-width: 50%;
  padding: 0 ${SIDEBAR_PADDING}px 2rem ${SIDEBAR_PADDING}px;

  &:first-child {
    border-left: 0;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const OPDFRows = styled.div`
  margin: 2rem 0;
`;

export const OPDFFirstRow = styled.div`
  margin: 1.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const OPDFFirstRowRight = styled.div`
  margin-top: -0.125rem;
  display: flex;
  align-items: center;
  position: relative;
`;

export const OPDFHr = styled.hr`
  margin: 2rem 0;
`;

export const OPDFRow = styled.div`
  margin: 1.25rem 0;
  display: flex;
`;

export const OPDFColumn = styled.div`
  flex: 0 0 50%;
  justify-content: space-between;
  display: flex;
  word-wrap: break-word;
  padding-right: 15px;

  & > div {
    width: 100%;
  }
`;

export const OPDFLanguages = styled(UnorderedList)`
  & > ul > li {
    margin-top: 0.5rem;
  }
`;
