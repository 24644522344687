import React from 'react';
import { useApi } from '@axiom/ui';

import DefaultLayout from '../layouts/default';
import { OpportunitiesList } from '../components/OpportunitiesList/OpportunitiesList';
import { OpportunityFilters } from '../components/OpportunityFilters/OpportunityFilters';
import { Page } from '../components/Page/Page';
import { UserSettingsUtil } from '../utils/user-settings-util';
import { UsersApi } from '../api/users-api';

export const OpportunitiesPage = () => {
  const [{ data: user }] = useApi(UsersApi.getSessionUser());
  return (
    <Page>
      <DefaultLayout
        sidebarContent={<OpportunityFilters />}
        showSideDrawer={
          !UserSettingsUtil.shouldShowNewOpportunityTalentView(
            user.userSettings
          )
        }
      >
        <OpportunitiesList />
      </DefaultLayout>
    </Page>
  );
};
