import React, { useState } from 'react';
import {
  Button,
  Banner,
  Checkbox,
  Flashy,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  IconButton,
  ImageCircle,
  Input,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  CandidateProfileUtil,
  Icon,
  useApi,
  DateUtil,
  useOnDependencyUpdate,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';
import {
  Candidate,
  CalendarEventType,
  TimeZone,
  Contact,
  Opportunity,
} from '@axiom/validation';
import { z } from 'zod';

import { CalendarApi } from '../../api/calendar-api';
import { AccountApi } from '../../api/accounts-legacy';

const formSchema = z
  .object({
    contacts: z.array(z.string().uuid()),
    adHocContacts: z.array(
      z
        .object({
          firstName: z.string().min(1).trim(),
          lastName: z.string().min(1).trim(),
          email: z.string().email().trim(),
        })
        .optional()
    ),
    ALL_CONTACTS: z.string().optional(),
    errorSelectAtLeastOne: z.string().optional(),
    errorMissMatchEmailDomain: z.string().optional(),
  })
  .refine(
    ({ contacts, adHocContacts }) => {
      // checking for no client selected or added
      const hasSelected: boolean = contacts.length >= 1;
      const hasAdHoc: boolean = adHocContacts.length >= 1;
      const isPopulated: boolean =
        hasAdHoc ||
        !adHocContacts.find(contact => {
          return Object.values(contact).find(c => !c);
        });
      return hasSelected || (hasAdHoc && isPopulated);
    },
    {
      message: 'At least one contact must be selected or added',
      path: ['errorSelectAtLeastOne'],
    }
  );

export type InterviewContactsType = z.infer<typeof formSchema>;

type SetValueType = (
  values: React.SetStateAction<InterviewContactsType>,
  shouldValidate?: boolean
) => void;

export type TalentInterviewConfirmModalType = {
  opportunity: Opportunity;
  candidate: Candidate;
  onClose: () => void;
  onBackHandler: () => void;
  refreshData: () => void;
  timezone: TimeZone;
  scheduledEvents?: CalendarEventType[];
};

export const TalentInterviewConfirmModal = ({
  onBackHandler,
  opportunity,
  candidate,
  onClose,
  scheduledEvents,
  timezone,
  refreshData,
}: TalentInterviewConfirmModalType) => {
  const [{ data: account }] = useApi(
    AccountApi.readAccount(opportunity.accountId)
  );

  const { contacts } = account;
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [currentEvent, setCurrentEvent] = useState(
    scheduledEvents?.[currentEventIndex] || {}
  );
  useOnDependencyUpdate(() => {
    setCurrentEvent(scheduledEvents[currentEventIndex]);
  }, [currentEventIndex]);

  const [isInterviewTimeUnavailable, setIsInterviewTimeUnavailable] =
    useState(false);

  const initValues: InterviewContactsType = {
    contacts: [],
    adHocContacts: [],
  };

  if (contacts?.[0]?.id) {
    initValues.contacts.push(contacts[0].id);
  }

  const scheduleInterview = async (
    data?: InterviewContactsType
  ): Promise<unknown> => {
    const { title, startTime, endTime, busy } = currentEvent;
    const payload = {
      adhocAttendees: data.adHocContacts,
      contactIds: data.contacts,
      candidateId: candidate.id,
      submissionId: opportunity.submissionId,
      title,
      startTime,
      endTime,
      busy,
    };

    await CalendarApi.createScheduledCalendarInterview(payload);

    if (
      scheduledEvents.length &&
      currentEventIndex < scheduledEvents.length - 1
    ) {
      setCurrentEventIndex(currentEventIndex + 1);
      return false;
    }

    await refreshData();

    return true;
  };

  const handleOnSubmit = async (
    formData: InterviewContactsType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    try {
      const allSaved = await scheduleInterview({ ...initValues, ...formData });
      if (allSaved) {
        onClose();
      }
    } catch (error) {
      const errorObj = error?.getResponse();
      if (errorObj?.text.includes('domain')) {
        setFieldError(
          `errorMissMatchEmailDomain`,
          'For enhanced security, all attendees must have matching email domains from the same organization.'
        );
      }
      const is400StatusCode = errorObj?.statusCode === 400;
      if (is400StatusCode) {
        setIsInterviewTimeUnavailable(true);
      } else {
        throw error;
      }
    }
  };

  const handleOnClickOneMore = (setValues: SetValueType) => {
    setValues(
      (prev: InterviewContactsType) => ({
        ...prev,
        adHocContacts: [
          ...prev.adHocContacts,
          {
            firstName: null,
            lastName: null,
            email: null,
          },
        ],
      }),
      false
    );
  };

  const handleOnClickTrash = (index: number, setValues: SetValueType) => {
    setValues((prev: InterviewContactsType) => {
      const { adHocContacts } = prev;
      adHocContacts.splice(index, 1);
      return { ...prev, adHocContacts };
    }, false);
  };

  const getButtonSubmitButton = (fn: () => void) => {
    if (
      scheduledEvents?.length === 1 ||
      scheduledEvents?.length - 1 === currentEventIndex
    ) {
      return (
        <Button name="CONFIRM" onClick={fn}>
          Schedule Interview
        </Button>
      );
    }

    return (
      <Button name="NEXT_INTERVIEW" onClick={fn}>
        Next
      </Button>
    );
  };

  return (
    <Form
      name="MODAL_FORM"
      schema={formSchema}
      initialValues={initValues}
      onSubmit={handleOnSubmit}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal name="INTERVIEW_PROMPT">
            <ModalHeader name="HEADER" onClose={onClose}>
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader name="HEADER_TEXT">
                    {!scheduledEvents ? 'Request to ' : ''}Interview{' '}
                    {candidate.calculatedFirstName}
                  </SmallHeader>
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow gutterBottom="16px">
                  <GridColumn>
                    {!isInterviewTimeUnavailable ? (
                      <Banner name="INTERVIEW_BANNER">
                        <Flashy bold color="controlSecondary">
                          Interview Date{' '}
                        </Flashy>
                        {DateUtil.displayBannerTimestamp(
                          currentEvent.startTime,
                          currentEvent.endTime,
                          timezone
                        )}{' '}
                        {timezone.abbr} {timezone.name}
                      </Banner>
                    ) : (
                      <Banner
                        name="INTERVIEW_UNAVAILABLE_BANNER"
                        type="error"
                        impact="high"
                      >
                        <Layout position="middle" horizontalGutter="8px">
                          <Paragraph>
                            <Flashy color="textAlert">
                              <Icon name="alert-filled" />
                            </Flashy>
                          </Paragraph>
                          <Paragraph>
                            The selected interview time is no longer available.
                            Please try again.
                          </Paragraph>
                        </Layout>
                      </Banner>
                    )}
                  </GridColumn>
                </GridRow>
                <GridRow gutterBottom="16px">
                  <GridColumn>
                    <FormGroup
                      name="ALL_CONTACTS"
                      label="Who else should we invite to the interview?"
                      description={`Select or add additional attendees below. All contacts selected will receive an invitation to join ${candidate.calculatedFirstName}'s interview.`}
                    >
                      {contacts.map((contact: Contact) => (
                        <Gutter key={contact.id} bottom="8px">
                          <Checkbox
                            name="contacts"
                            displayValue={`${contact.fullName} (${contact.email})`}
                            option={contact.id}
                          />
                        </Gutter>
                      ))}
                    </FormGroup>
                  </GridColumn>
                </GridRow>

                {values.adHocContacts.map((_, i) => (
                  <React.Fragment key={`adHocContacts_${i.toString(10)}`}>
                    <GridRow
                      name="ADHOC_ROW"
                      stretched
                      columns={4}
                      gutterBottom="12px"
                    >
                      <GridColumn widescreenWidth={3} smallScreenWidth={12}>
                        <Input
                          name={`adHocContacts.${i}.firstName`}
                          label="First Name"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={3} smallScreenWidth={12}>
                        <Input
                          name={`adHocContacts.${i}.lastName`}
                          label="Last Name"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={5} smallScreenWidth={10}>
                        <Input
                          name={`adHocContacts.${i}.email`}
                          label="Email"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={1} smallScreenWidth={2}>
                        <Gutter top="32px">
                          <IconButton
                            name="REMOVE_CONTACT"
                            icon="trash"
                            pattern="secondary"
                            variation="minimal"
                            onClick={() => handleOnClickTrash(i, setValues)}
                          />
                        </Gutter>
                      </GridColumn>
                    </GridRow>
                    <GridRow gutterBottom="0">
                      <GridColumn width={12}>
                        {/* for displaying custom error */}
                        <FormGroup name="errorMissMatchEmailDomain" />
                      </GridColumn>
                    </GridRow>
                  </React.Fragment>
                ))}

                <GridRow gutterBottom="12px">
                  <GridColumn width={12}>
                    {/* for displaying custom error */}
                    <FormGroup name="errorSelectAtLeastOne" />
                  </GridColumn>
                </GridRow>

                <GridRow>
                  <GridColumn>
                    <Button
                      pattern="secondary"
                      variation="minimal"
                      icon="plus"
                      iconPosition="right"
                      name="ADD_NEW_BUTTON"
                      onClick={() => handleOnClickOneMore(setValues)}
                    >
                      Add New
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter stackableOn="mobile">
              {!isInterviewTimeUnavailable ? (
                <>
                  <Button name="CANCEL" variation="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  {getButtonSubmitButton(fireSubmit)}
                </>
              ) : (
                <Button name="BACK" variation="outline" onClick={onBackHandler}>
                  Back
                </Button>
              )}
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
