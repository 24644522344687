import React from 'react';
import { z } from 'zod';
import {
  Form,
  FormGroup,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  Flashy,
  Checkbox,
  CondensedMedium,
  Banner,
  Layout,
  Modal,
  Button,
  ModalHeader,
  ModalSection,
  ModalFooter,
} from '@axiom/ui';
import { ContactsOpportunitiesConst } from '@axiom/const';
import {
  Submission,
  Opportunity,
  ContactsOpportunity,
} from '@axiom/validation';

import { getSFDCLinkForClose } from '../../utils/salesforce-utils';

interface SendSubmissionEmailModalProps {
  contactsOpportunities?: Submission['contactsOpportunities'];
  salesforceId: Opportunity['salesforceId'];
  onClose: () => void;
  onEmailSubmit: (
    id: Submission['submissionId'],
    contactOpps: Array<ContactsOpportunity['contactId']>
  ) => void;
  submissionId: Submission['submissionId'];
}

export const SendSubmissionEmailModal = ({
  salesforceId,
  onClose,
  onEmailSubmit,
  contactsOpportunities,
  submissionId,
}: SendSubmissionEmailModalProps) => {
  const sendSubmissionEmailFormSchema = z.object({
    contactOppIds: z.array(z.string()).min(1, {
      message: contactsOpportunities?.length
        ? 'You must select at least one checkbox'
        : 'No Contacts to Submit',
    }),
  });
  const initialValues = {
    contactOppIds: contactsOpportunities?.map(
      contactOpp => contactOpp.contactId
    ),
  };
  return (
    <Form
      name="submission-email-form"
      schema={sendSubmissionEmailFormSchema}
      onSubmit={changedData => {
        const contactIds = Array.isArray(changedData?.contactOppIds)
          ? changedData.contactOppIds
          : initialValues.contactOppIds;
        return onEmailSubmit(submissionId, contactIds);
      }}
      initialValues={initialValues}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="contact-modal">
            <ModalHeader onClose={onClose}>Send Submission Email</ModalHeader>
            <ModalSection>
              {contactsOpportunities?.length === 0 ? (
                <Banner type="error" impact="high" name="no-contacts-banner">
                  There are no contacts associated with this submission
                </Banner>
              ) : (
                <>
                  <CondensedHeader name="send-title">
                    Who do you want to send it to?
                  </CondensedHeader>
                  <Gutter bottom="24px" />
                </>
              )}

              <FormGroup name="contactOppIds">
                <Layout direction="vertical" verticalGutter="16px">
                  {contactsOpportunities?.map(contactOpp => {
                    return (
                      <Checkbox
                        key={contactOpp.contactId}
                        option={contactOpp.contactId}
                        name="contactOppIds"
                        displayValue={
                          <CondensedMedium name="checkbox-display-value">
                            <Flashy
                              bold
                            >{`${contactOpp.contact.firstName} ${contactOpp.contact.lastName},`}</Flashy>{' '}
                            {
                              ContactsOpportunitiesConst.Roles[
                                contactOpp.role as keyof typeof ContactsOpportunitiesConst.Roles
                              ]
                            }{' '}
                            ({contactOpp.contact.email})
                          </CondensedMedium>
                        }
                      />
                    );
                  })}
                </Layout>
              </FormGroup>
              <Gutter bottom="24px" />
              <CondensedLarge>
                To add additional clients to receive the submission email,{' '}
                <a
                  data-test="salesforceOppLink"
                  href={getSFDCLinkForClose(salesforceId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  add them as a Contact Role
                </a>{' '}
                for this opportunity in SalesForce. This may take a few minutes
                to occur. Close the Manage Submission modal and check back
                later.
              </CondensedLarge>
            </ModalSection>
            <ModalFooter>
              <Button name="closeButton" variation="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button name="submitButton" onClick={fireSubmit}>
                Send
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
