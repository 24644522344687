import styled from 'styled-components';

import { getThemeProp } from '../../themes';

export const KendoGridContainer = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
`;

export const NoResults = styled.div`
  color: ${getThemeProp('colorSecondary')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const KendoOverrideStyles = styled.div`
  height: 100%;
  position: relative;

  .k-grid {
    border-color: rgba(33, 37, 41, 0.125);
    color: #292b2c;
    background-color: #ffffff;
  }

  td,
  .k-grid-content,
  .k-grid-header-locked,
  .k-grid-content-locked {
    border-color: rgba(33, 37, 41, 0.125);
  }

  tr:not(.k-alt) {
    background-color: rgb(245 245 245);
  }

  tr.k-alt {
    color: #292b2c;
    background-color: rgb(238 239 238);
  }

  tbody tr:hover,
  tbody tr.k-state-hover {
    color: #292b2c;
    background-color: rgba(41, 43, 44, 0.065);
  }

  th.k-state-selected,
  td.k-state-selected,
  tr.k-state-selected > td {
    color: #292b2c;
    background-color: rgba(255, 90, 45, 0.25);
  }

  td.k-state-focused,
  th.k-state-focused {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
  }

  & > div.k-grid {
    height: 100%;
    border: none;
  }

  .k-grid-header-wrap {
    border-right: 1px solid transparent;
  }

  .k-grid-container {
    background: #f5f5f5;
  }

  & > div.k-grid table.k-grid-table {
    z-index: 0;
    border-bottom: solid 1px #cccccc;
  }

  .k-grid-content tr:last-child td,
  .k-grid-content-locked tr:last-child td {
    border-bottom-width: 1px !important;
  }

  .k-grid-header th.k-grid-header-sticky,
  .k-grid-header .k-grid-header-sticky.k-sorted {
    border-left: solid 1px #e0e0e0;
  }

  .k-master-row .k-grid-content-sticky {
    border-bottom: solid 1px #e0e0e0;
  }

  .k-master-row:hover,
  .k-master-row:hover .k-grid-content-sticky {
    background-color: ${({ theme }) => theme.neutrals.neutral20};
  }

  thead th {
    padding: 5px 10px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text.default} !important;
    background-color: rgb(246, 246, 246);

    &:last-child {
      margin: 0;
      border-right: none;
      padding-right: 0;
      padding-left: 0;
    }

    &.k-grid-header-sticky {
      background-color: rgb(246, 246, 246);
      position: sticky;
    }
  }

  tbody td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    padding: 0 10px;
    border-bottom: solid 1px #e0e0e0;
    border-right: none;
    border-left: none;

    &.k-grid-content-sticky {
      background-color: rgb(246, 246, 246);
      position: sticky;
    }
  }

  tbody td.kendo-grid-column-locked {
    padding: 0;
    border-left: solid 1px #e0e0e0;
  }

  tbody td.no-padding {
    border-left: solid 1px #cccccc;
  }

  .k-state-selected {
    background-color: transparent;
  }

  .k-pager-numbers li .k-link.k-state-selected {
    background-color: #333333;
  }

  a {
    color: #333333;
  }

  .k-link {
    color: #333333;
  }

  .k-link:hover {
    color: #ffffff;
  }

  th > a.k-link:hover {
    color: #333333;
  }

  .k-link.k-state-selected {
    font-weight: bold;
    color: #ffffff;
  }

  th a {
    color: #666666;
  }

  td a {
    color: #666666;
  }

  .k-checkbox-label::before,
  .k-checkbox-label::after,
  .k-radio-label::before,
  .k-radio-label::after {
    left: 6px;
  }
`;
