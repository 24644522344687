import React, { useState } from 'react';
import { shape } from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  Tooltip,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import ManageContactFormContextMenu from '../ManageContactFormContextMenu/ManageContactFormContextMenu';
import { AppFindAccountForContactModalStore } from '../../stores/app-find-account-for-contact-modal-store';
import { ContactStore } from '../../stores/contact-store';
import { DateUtil } from '../../utils/date-util';
import { generateBenchUrl } from '../../utils/link-util';

import { ManageContactFormEmailPref } from './ManageContactFormEmailPref';
import { ManageContactFormPricing } from './ManageContactFormPricing';

const ManageContactFormComponent = ({ contactState }) => {
  const [confirmAccountId, setConfirmAccountId] = useState(null);

  return (
    <StoreStateTreatment
      storeState={contactState}
      name="MANAGE_CONTACT_FORM"
      renderLoadedView={({ data }) => {
        const user = data?.user || {};
        const {
          accounts,
          email,
          firstName,
          id: contactId,
          lastName,
          salesforceId,
          benchNotificationOptOut,
          availabilityNotificationOptOut,
          canViewPricing,
        } = data;
        let userStatus = 'Not Applicable';
        if (user.isActive !== undefined) {
          userStatus = user.isActive ? 'Active' : 'Inactive';
        }
        return (
          <>
            <Layout
              background="panelGray"
              borderBottom="1px solid contentBreak"
              position="stretch middle"
            >
              <LayoutItem fluid verticalGutter="16px" leftGutter="24px">
                <SectionHeader name="ACCOUNT_NAME">
                  {`${firstName} ${lastName}`}
                </SectionHeader>
              </LayoutItem>
              <LayoutItem verticalGutter="24px" rightGutter="8px">
                <ManageContactFormContextMenu
                  contactId={contactId}
                  isActive={user.isActive}
                  salesforceId={salesforceId}
                  userId={user.id}
                />
              </LayoutItem>
            </Layout>
            <Gutter top="16px" left="24px" right="16px">
              <Grid columns={2}>
                <GridRow gutterBottom="24px">
                  <GridColumn width={5}>
                    <ParagraphHeader>User Status</ParagraphHeader>
                  </GridColumn>
                  <GridColumn data-test="IS_ACTIVE" width={7}>
                    {userStatus}
                    {!user.isActive && (
                      <Tooltip>
                        User cannot access Axiom for Clients or any associated
                        accounts.
                      </Tooltip>
                    )}
                  </GridColumn>
                </GridRow>
                <GridRow gutterBottom="24px">
                  <GridColumn width={5}>
                    <ParagraphHeader>Last Sign-In Date</ParagraphHeader>
                  </GridColumn>
                  <GridColumn data-test="LAST_SIGN_IN_DATE" width={7}>
                    {DateUtil.displayDate(user.lastLoginAt) || 'Never'}
                  </GridColumn>
                </GridRow>
                {user.contactId && (
                  <ManageContactFormPricing
                    contactId={user.contactId}
                    canViewPricing={canViewPricing}
                  />
                )}
                <GridRow gutterBottom="24px">
                  <GridColumn width={5}>
                    <ParagraphHeader>User Email</ParagraphHeader>
                  </GridColumn>
                  <GridColumn data-test="CONTACT_EMAIL" width={7}>
                    {email}
                  </GridColumn>
                </GridRow>
                {user.contactId && (
                  <ManageContactFormEmailPref
                    contactId={user.contactId}
                    benchNotificationOptOut={!benchNotificationOptOut}
                    availabilityNotificationOptOut={
                      !availabilityNotificationOptOut
                    }
                  />
                )}
              </Grid>
            </Gutter>
            <Layout
              borderTop="1px solid contentBreak"
              position="stretch middle"
            >
              <LayoutItem fluid verticalGutter="16px" leftGutter="24px">
                <ParagraphHeader name="Associated Accounts">
                  Associated Accounts
                </ParagraphHeader>
              </LayoutItem>
              <LayoutItem verticalGutter="16px" rightGutter="16px">
                <IconButton
                  // falsy check causes bug TT-1652
                  disabled={user.isActive === false}
                  icon="plus"
                  pattern="secondary"
                  variation="minimal"
                  title="Add associated account"
                  onClick={() =>
                    AppFindAccountForContactModalStore.beginAddingToContact(
                      [contactId],
                      contactId
                    )
                  }
                />
              </LayoutItem>
            </Layout>
            <Gutter left="24px" right="16px">
              <Grid columns={3}>
                {accounts.map(({ calculatedName, id: accountId }) => (
                  <GridRow gutterBottom="16px">
                    <GridColumn data-test="ACCOUNT_CALCULATED_NAME" width={10}>
                      {`${calculatedName}`}
                    </GridColumn>
                    <GridColumn width={1}>
                      <IconButton
                        icon="popout"
                        pattern="secondary"
                        variation="minimal"
                        toTab={generateBenchUrl(accountId)}
                        title={`Open "Client Bench" in new tab`}
                        className="open-bench-tooltip"
                      />
                    </GridColumn>
                    <GridColumn width={1}>
                      <IconButton
                        icon="trash"
                        pattern="secondary"
                        variation="minimal"
                        title={`Remove associated account ${calculatedName}`}
                        onClick={() => setConfirmAccountId(accountId)}
                      />
                    </GridColumn>
                  </GridRow>
                ))}
              </Grid>
            </Gutter>
            {confirmAccountId && (
              <Modal size="confirm">
                <ModalHeader onClose={() => setConfirmAccountId(null)}>
                  Remove Account
                </ModalHeader>
                <ModalSection>
                  <Paragraph>
                    Are you sure you would like to remove this account from this
                    contact?
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    variation="outline"
                    onClick={() => setConfirmAccountId(null)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (confirmAccountId) {
                        ContactStore.removeContactFromAccount(
                          contactId,
                          confirmAccountId
                        );
                        setConfirmAccountId(null);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </>
        );
      }}
      renderNonLoadedView={() => {
        return (
          <Grid>
            <GridRow>
              <GridColumn textAlign="center">Select a user.</GridColumn>
            </GridRow>
          </Grid>
        );
      }}
    />
  );
};

ManageContactFormComponent.propTypes = {
  contactState: shape(ContactStore.getDataShape).isRequired,
};

export default connect(
  state => ({
    contactState: ContactStore.select(state),
  }),
  {}
)(ManageContactFormComponent);
