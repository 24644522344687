import React from 'react';
import { string } from 'prop-types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { AvatarIcon } from '@axiom/ui';

import { AftIconWrapper } from './AxiomForTalentIndicatorStyles';

const AxiomForTalentIndicator = ({ className, position, title }) => (
  <Tooltip position={position} anchorElement="target" openDelay={0}>
    <AftIconWrapper className={className} title={title}>
      <AvatarIcon alt={title} />
    </AftIconWrapper>
  </Tooltip>
);

AxiomForTalentIndicator.defaultProps = {
  className: '',
  position: 'top',
  title: 'Visible in Axiom For Talent',
};

AxiomForTalentIndicator.propTypes = {
  className: string,
  position: string,
  title: string,
};

export default AxiomForTalentIndicator;
