import { loadLightPalette } from '@axiom/ui';

import { GENERAL_STYLES_MAP } from './constants';

const lightPalette = loadLightPalette();
const spacing = {
  ...lightPalette.spacing,
  desktopMaxWidth: '100%',
};
export default {
  spacing: {
    ...lightPalette.spacing,
  },
  main: {
    appBackground: '#F5F5F5',
    axiomOrange: GENERAL_STYLES_MAP.AXIOM.background,
    buttonBorder: '#6d6d6d',
    colorPrimary: '#2B2B2B',
    colorSecondary: '#707070',
    contentBackground: '#FFFFFF',
    contracting: '#C7D66D',
    darkBlue: '#001E49',
    disabledColor: GENERAL_STYLES_MAP.DISABLED.background,
    info: '#477e96',
    interviewing: '#B1B89C',
    negativeColor: '#800000',
    positiveColor: '#C7D66D',
    primaryText: '#000000',
    qualified: '#4D7C8A',
    ready: '#92B5A5',
    textMuted: '#6c757d',
    unqualified: '#93A3B1',
    warning: '#D0021B',
    sectionBorder: '#CFCFD4',
    ...lightPalette,
    spacing,
  },
  dark: {
    primaryText: '#ffffff',
  },
};

export const getThemeProp =
  property =>
  ({ theme }) =>
    theme[property];
