import APIHelper from './APIHelper';

const api = new APIHelper('language');

export const getLanguagesByOpportunityId = async opportunityId => {
  const response = await api.GET(`/opportunities/${opportunityId}/languages`);
  return { data: response.data };
};

export const putLanguagesByOpportunityId = async (opportunityId, languages) => {
  const languagesForServer = languages.map(language => {
    const languageForServer = { ...language };

    languageForServer.languageId = language.id;
    delete languageForServer.id;
    delete languageForServer.candidateId;
    delete languageForServer.createdAt;
    delete languageForServer.updatedAt;
    delete languageForServer.name;

    return languageForServer;
  });

  const { data } = await api.PUT(
    `/opportunities/${opportunityId}/languages`,
    languagesForServer
  );

  return { data };
};

export default {
  getLanguagesByOpportunityId,
  putLanguagesByOpportunityId,
};
