import styled from 'styled-components';
import { AutoComplete as KendoAutoComplete } from '@progress/kendo-react-dropdowns';

export const LabelWrapper = styled.div`
  padding: 0.5em 0;
`;

export const KendoAutoCompleteWrapper = styled(KendoAutoComplete)`
  width: 100%;

  & input.k-input {
    padding-left: 0;
  }
`;
