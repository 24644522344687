import { shape, string, arrayOf } from 'prop-types';

export const eventShape = shape({
  id: string,
  candidateId: string,
  opportunityId: string,
  description: string,
  purpose: string,
  type: string,
  createdAt: string,
  updatedAt: string,
  attachmentName: string,
  emailAddresses: arrayOf(string),
  bullhornNoteId: string,
  salesforceEventId: string,
  lastUpdatedBy: string,
  attachmentKey: string,
  scheduledDate: string,
  lastAuthoredBy: string,
});
