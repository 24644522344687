import styled from 'styled-components';
import { Text } from '@axiom/ui';

import { FFDropdown } from '../FFDropdown/FFDropdown';

export const TalentProfileStatusWrapper = styled.section`
  flex: 0 0 auto;
  max-width: 247px;
`;

export const TPSDropdownWrapper = styled(FFDropdown)`
  margin-bottom: 0;

  div {
    margin-bottom: 0;
  }
`;

export const TPSProfileUpdate = styled(Text)`
  width: 234px;
  word-break: break-all;
`;

export const TPSProfileName = styled(Text)`
  max-width: 234px;
  word-break: break-all;
`;
