import APIHelper from './APIHelper';

const api = new APIHelper('position');

export const PositionsApi = {
  patchPosition(positionId, body) {
    return api.PATCH(`/positions/${positionId}`, body);
  },
  putPositionTags(positionId, body) {
    return api.PUT(`/positions/${positionId}/tags`, body);
  },
  deletePosition(positionId) {
    return api.DELETE(`/positions/${positionId}`);
  },
};
