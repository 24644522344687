import { DECORATED_OPPORTUNITY_LOADED } from '../constants';

export const INITIAL_STATE = {
  byOpportunityId: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DECORATED_OPPORTUNITY_LOADED:
      return {
        ...state,
        byOpportunityId: {
          ...state.byOpportunityId,
          [payload.opportunityId]: payload.languages.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
