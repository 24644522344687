import React, { Component } from 'react';
import { connect } from 'react-redux';

import TagManagementForm from '../TagManagementForm/TagManagementForm';
import { TagDataShape } from '../../models/tag';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';
import { FormTagsManagementStore } from '../../stores/form-tag-management-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';

import { Root, List, Form } from './GroupSettingsModalStyles';
import TagManagementList from './TagManagementList';

export class TagManagementViewComponent extends Component {
  componentDidMount() {
    FormTagsManagementStore.clearState();
  }

  handleSelectedChange = tag => {
    FormTagsManagementStore.load(tag);
  };

  handleDelete = tagId => {
    PreloadedTagsStore.delete(tagId);
  };

  render() {
    const { selected, tags, tagsState } = this.props;

    return (
      <StoreStateTreatment
        allowOverflow
        storeState={tagsState}
        renderLoadedView={() => (
          <Root>
            <List>
              <TagManagementList
                tags={tags}
                selected={selected.data}
                onSelectedChange={this.handleSelectedChange}
                onConfirmTagDeletion={this.handleDelete}
              />
            </List>
            <Form>
              <TagManagementForm />
            </Form>
          </Root>
        )}
      />
    );
  }
}

TagManagementViewComponent.defaultProps = {
  selected: null,
};

TagManagementViewComponent.propTypes = {
  selected: TagDataShape,
  tags: PreloadedTagsStore.getDataShape().isRequired,
  tagsState: PreloadedTagsStore.getStateShape().isRequired,
};

export default connect(state => ({
  selected: FormTagsManagementStore.select(state),
  tags: PreloadedTagsStore.selectData(state),
  tagsState: PreloadedTagsStore.select(state),
}))(TagManagementViewComponent);
