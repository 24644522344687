import styled from 'styled-components';

export const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 65px 25px 0;
  width: ${props => props.widthOverride || '100%'};
`;

export const FilterDropdownWrapper = styled.div`
  flex: 0 0 20%;
  padding-right: 25px;

  & > div,
  & > div > div {
    margin-bottom: 0;
  }
`;

export const FilterTextWrapper = styled.div`
  flex: 1 0 auto;
  margin-top: 1rem;
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: flex-end;

  button {
    margin-left: 12px;
  }
`;
