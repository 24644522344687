import styled from 'styled-components';

export const ProgressMeter = styled.div`
  height: 0.5em;
  width: 280px;
  background: #e9eeee;
  border: 1px solid #373d49;
  border-radius: 0.5em;
  overflow: hidden;
`;

export const ProgressIndicator = styled.span`
  display: inline-block;
  height: 0.5em;
  background: #515966;
`;
