import { shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { CandidateUtil } from '../utils/candidate-util';

const getNum = val => (val || val === 0 ? Number(val) : undefined);

class PreloadedCandidateListFiltersClass extends GenericStore {
  load() {
    const filters = { ...CandidateUtil.getCandidatesListFilters() };

    if (filters.yearsOfExperience) {
      filters.yearsOfExperience = {
        start: getNum(filters.yearsOfExperience?.start),
        end: getNum(filters.yearsOfExperience?.end),
      };
    }

    if (filters.weeklyAvailability) {
      filters.weeklyAvailability = {
        start: getNum(filters.weeklyAvailability?.start),
        end: getNum(filters.weeklyAvailability?.end),
      };
    }

    if (filters.compensation) {
      filters.compensation = {
        start: getNum(filters.compensation?.start),
        end: getNum(filters.compensation?.end),
      };
    }

    if (filters.daysToSoonestEngagementEnd) {
      filters.daysToSoonestEngagementEnd = getNum(
        filters.daysToSoonestEngagementEnd
      );
    }

    return this.setState({
      ...filters,
    });
  }

  setState(data) {
    const newData = { ...data };
    delete newData.rollOffDateDropdown;
    delete newData.rollOffDatePicker;

    if (
      !Number.isInteger(newData.yearsOfExperience?.start) &&
      !Number.isInteger(newData.yearsOfExperience?.end)
    ) {
      newData.yearsOfExperience = {};
    }

    if (
      !Number.isInteger(newData.weeklyAvailability?.start) &&
      !Number.isInteger(newData.weeklyAvailability?.end)
    ) {
      newData.weeklyAvailability = {};
    }

    if (
      !Number.isInteger(newData.compensation?.start) &&
      !Number.isInteger(newData.compensation?.end)
    ) {
      newData.compensation = {};
    }

    return super.setState(newData);
  }

  getDataShape() {
    return shape({});
  }

  select(state) {
    return super.select(state).data || null;
  }
}

export const PreloadedCandidateListFilters =
  new PreloadedCandidateListFiltersClass();
