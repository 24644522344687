/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import debounce from 'lodash/debounce';

import { window } from '../../utils/global';
import { formatDataTestId } from '../../utils/dataTest';

import {
  byId,
  setColumnsPreferences,
  columnsWithPreferences,
} from './KendoGridHelpers';
import {
  KendoOverrideStyles,
  KendoGridContainer,
  NoResults,
} from './KendoGridStyles';

class KendoGrid extends React.Component {
  constructor(props) {
    super(props);
    const { columns: _columns, localStorageKey } = props;
    const columns = columnsWithPreferences({ _columns, localStorageKey });
    if (localStorageKey) setColumnsPreferences({ columns, localStorageKey });
    this.state = { columns, maxContainerWidth: 0 };
    this.containerRef = React.createRef();
    this.kendoGridRef = React.createRef();
  }

  componentDidMount() {
    this.setColumnWidths();
    this.checkScrollLoadEntries();
    window.addEventListener('resize', this.checkScrollLoadEntries);

    // no support for resizeObserver in IE:
    // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#Browser_compatibility
    this.resizeObserver = window.ResizeObserver
      ? new window.ResizeObserver(() => {
          this.handleContainerResize();
        })
      : null;

    if (this.resizeObserver) {
      this.resizeObserver.observe(this.containerRef.current);
    }
  }

  componentDidUpdate() {
    this.checkScrollLoadEntries();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScrollLoadEntries);
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.containerRef.current);
    }
  }

  onColumnReorder = ({ columns: kendoColumns }) => {
    const columnsById = byId(this.state.columns);
    const columns = kendoColumns
      .slice()
      .map(c => {
        if (c.id === 'Actions') {
          return { ...c, orderIndex: 100 };
        }
        return c;
      })
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(({ id, orderIndex }) => ({
        ...columnsById[id],
        orderIndex,
      }));

    const { localStorageKey } = this.props;
    if (localStorageKey) setColumnsPreferences({ columns, localStorageKey });

    this.setState({ columns });
  };

  onColumnResize = ({ end, index, newWidth }) => {
    if (end) {
      this.setState(state => {
        const columns = state.columns.slice();
        columns[index] = {
          ...columns[index],
          width: newWidth,
        };

        const { localStorageKey } = this.props;
        if (localStorageKey) {
          setColumnsPreferences({ columns, localStorageKey });
        }

        return { columns };
      });
    }
  };

  onScroll = () => {
    this.checkScrollLoadEntries();
  };

  // like a window resize, many resize events are fired when the container's size changes
  handleContainerResize = debounce(this.setColumnWidths, 250, {
    trailing: true,
  });

  setColumnWidths() {
    if (this.containerRef.current) {
      const gridWidth = this.containerRef.current.getBoundingClientRect().width;
      if (gridWidth > this.state.maxContainerWidth) {
        this.setState(() => {
          const [totalReservedWidth, numberOfAutos] = this.props.columns.reduce(
            ([totalWidth, totalAutos], column) => {
              if (!column.width || column.width === 'auto') {
                return [totalWidth, totalAutos + 1];
              }
              return [totalWidth + column.width, totalAutos];
            },
            [0, 0]
          );
          const defaultWidth = (gridWidth - totalReservedWidth) / numberOfAutos;

          const columns = this.props.columns.map(column => {
            if (!column.width || column.width === 'auto') {
              return {
                ...column,
                width: Math.max(defaultWidth, 250),
              };
            }
            return column;
          });

          return { columns, maxContainerWidth: gridWidth };
        });
      }
    }
  }

  renderGridColumnCell = props => {
    const column = this.state.columns[props.columnIndex];
    const extendedProps = { onRowClick: this.props.onRowClick, ...props };
    const tdElement =
      typeof column.cellTd === 'function'
        ? column.cellTd(extendedProps)
        : column.cellTd || <td />;
    const tdElementKey =
      typeof column.cellKey === 'function'
        ? column.cellKey(extendedProps.dataItem)
        : `${extendedProps.dataItem.id}-${column.id}`;
    const tdElementClassName =
      [
        extendedProps.className, // from kendo
        tdElement.props.className, // from parent
        column.locked ? 'kendo-grid-column-locked' : '', // kendo default border style
      ]
        .join(' ')
        .trim() || null;
    const tdElementStyle = {
      ...extendedProps.style,
      ...tdElement.props.style,
    };
    const tdContent = function () {
      if (column.property) {
        if (typeof column.property === 'function') {
          return column.property(extendedProps);
        }
        return extendedProps.dataItem[column.property];
      }
      if (column.cell) {
        if (typeof column.cell === 'function') {
          return column.cell(extendedProps);
        }
        return column.cell;
      }
      return null;
    };
    return React.cloneElement(
      tdElement,
      {
        key: tdElementKey,
        className: tdElementClassName,
        style: tdElementStyle,
      },
      tdContent()
    );
  };

  checkScrollLoadEntries = () => {
    if (this.kendoGridRef.current) {
      const { totalResults, data } = this.props;
      const c = this.kendoGridRef.current.vs.container;
      const { scrollTop, scrollHeight, clientHeight } = c;

      // scroll bar needs to be sufficient on initial load to give a user a sense of scrollability
      // and to ensure there's not case where data can't be fetched due to lack of ability to scroll
      const notEnoughScrollBar = scrollHeight - clientHeight < 800;

      const scrolledPct = scrollTop / (scrollHeight - clientHeight);
      // almost done if we're 3/4 of the way scrolled
      const almostDoneVerticalScrolling = scrolledPct > 0.75;

      if (
        (notEnoughScrollBar || almostDoneVerticalScrolling) &&
        totalResults > data.length
      ) {
        this.props.loadMoreEntries();
      }
    }
  };

  render() {
    const {
      columns,
      data,
      totalResults,
      loadMoreEntries,
      onRowClick,
      height,
      ...props
    } = this.props;

    return (
      <KendoGridContainer
        data-test={formatDataTestId('jobsListKendoGrid')}
        {...props}
        ref={this.containerRef}
      >
        {!data || data.length === 0 ? (
          <NoResults>No results. Please broaden your search criteria</NoResults>
        ) : (
          <KendoOverrideStyles>
            <Grid
              ref={this.kendoGridRef}
              data={data}
              style={{ height }}
              data-test={formatDataTestId('dataGrid')}
              reorderable
              resizable
              onColumnReorder={this.onColumnReorder}
              onColumnResize={this.onColumnResize}
              onScroll={this.onScroll}
              onRowClick={onRowClick}
            >
              {this.state.columns.map(column => (
                <GridColumn
                  key={`kendo-grid-column-${column.id}`}
                  orderIndex={column.orderIndex}
                  id={column.id}
                  locked={column.locked}
                  width={column.width}
                  minResizableWidth={column.minResizableWidth}
                  resizable={column.resizable}
                  reorderable={column.reorderable}
                  headerCell={column.header}
                  cell={this.renderGridColumnCell}
                />
              ))}
            </Grid>
          </KendoOverrideStyles>
        )}
      </KendoGridContainer>
    );
  }
}

KendoGrid.defaultProps = {
  columns: [],
  localStorageKey: null,
  data: [],
  totalResults: 0,
  loadMoreEntries: () => {},
  onRowClick: () => {},
  height: null,
};

KendoGrid.propTypes = {
  // Surpressing because kendo grid is going away
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object),
  localStorageKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  totalResults: PropTypes.number,
  loadMoreEntries: PropTypes.func,
  onRowClick: PropTypes.func,
  height: PropTypes.string,
};

export default KendoGrid;
