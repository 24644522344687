import styled from 'styled-components';

export const FFRadioLeftToRightWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FFErrorWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;
