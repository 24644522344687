import { shape, string, oneOf } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';

import { AjvUtil } from '../utils/ajv-util';
import { updateCandidateOpportunity } from '../api/opportunities-legacy-api';
import GenericStore from '../classes/generic-store';

import { OpportunityStore } from './opportunity-store';

const { CandidateStatuses } = CandidateOpportunitiesConst;

const EditAutoWarmingModalValidation = AjvUtil.compileSchema({
  required: ['candidateStatusMessage'],
  properties: {
    candidateStatusMessage: {
      type: 'string',
      maxLength: 1000,
      minLength: 1,
    },
  },
});

class EditAutoWarmingModalStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      opportunityId: string,
      candidateId: string,
      candidateStatus: oneOf([CandidateStatuses.Warmed]),
      candidateStatusMessage: string,
    });
  }

  load(form) {
    this.setState(form);
  }

  save(formData) {
    return this.clearState(
      updateCandidateOpportunity(formData).then(() => {
        OpportunityStore.load(formData.opportunityId);
      })
    );
  }

  close() {
    this.clearState();
  }

  cancel() {
    this.clearState();
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      EditAutoWarmingModalValidation,
      data
    );
  }
}

export const EditAutoWarmingModalStore = new EditAutoWarmingModalStoreClass();
