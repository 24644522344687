import { USER_TALENT_LOADED, USER_TALENT_LOAD_ERROR } from '../constants';

const INITIAL_STATE = {
  byUserId: {},
  loading: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USER_TALENT_LOADED: {
      const withActivities = payload.data.filter(talent => talent.events);
      const activitiesById =
        withActivities.length === 0
          ? state.activitiesById
          : {
              ...state.activitiesById,
              ...withActivities.reduce((obj, talent) => {
                obj[talent.id] = talent.events;
                return obj;
              }, {}),
            };

      return {
        ...state,
        activitiesById,
        byUserId: {
          ...state.byUserId,
          [payload.id]: payload.data.map(talent => talent.id),
        },
        loading: false,
      };
    }
    case USER_TALENT_LOAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
