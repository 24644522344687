import React from 'react';
import { func, bool, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Button,
  DataTest,
  FluidButtonLayout,
  Gutter,
  Label,
  Text,
} from '@axiom/ui';
import isEqual from 'lodash/fp/isEqual';
import { PracticeAreaConst } from '@axiom/const';

import MultiSelect from '../MultiSelect/MultiSelect';
import UserMultiSelect from '../UserMultiSelect/UserMultiSelect';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SearchInput from '../SearchInput/SearchInput';
import DatePicker from '../DatePicker/DatePicker';
import SkillsDropdown from '../SkillsDropdown/SkillsDropdown';
import { FFInput } from '../FFInput/FFInput';
import { EditSavedOpportunityFilterStore } from '../../stores/edit-saved-opportunity-filter-store';
import { OpportunitiesStore } from '../../stores/opportunities-store';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { formatDataTestId } from '../../utils/dataTest';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { getTreeTrunk } from '../../utils/dropdown-tree-utils';
import {
  containsInvalidCharacters,
  invalidCharactersErrorMessage,
} from '../../utils/search';

import {
  opportunityStageSelectItems,
  opportunityBusinessTeamSelectItems,
} from './OpportunityFiltersFormConstants';
import {
  OFFDateRangeContainer,
  OFFMultiSelectContainer,
} from './OpportunityFiltersFormStyles';

const defaultFilters = OpportunitiesStore.getDefaultFilters();

const OpportunityFiltersForm = ({
  savedFiltersState,
  filters,
  handleFilterChange,
  handleSaveFilters,
  isEditable,
  practiceAreas,
}) => (
  <DataTest value="OpportunityFiltersForm">
    <SearchInput
      aria-label="Search Opportunities"
      name="search"
      value={filters.search || ''}
      onChange={e => {
        const invalidCharacters = containsInvalidCharacters(e.target.value);
        if (invalidCharacters) {
          PreloadedAppErrorsStore.show(invalidCharactersErrorMessage);
          return false;
        }
        return handleFilterChange({
          search: e.target.value,
        });
      }}
      disabled={!isEditable}
      data-test={formatDataTestId('searchBar')}
    />
    <Label>Opportunity Stage</Label>
    <OFFMultiSelectContainer>
      <MultiSelect
        className="gtm-filters-filters-filters.stageCode"
        options={opportunityStageSelectItems}
        isDisabled={!isEditable}
        name="stageCode"
        onChange={selectedOptions => {
          handleFilterChange({
            stageCode: selectedOptions,
          });
        }}
        placeholder="Show All"
        value={filters.stageCode}
      />
    </OFFMultiSelectContainer>
    <Label>Business Team</Label>
    <OFFMultiSelectContainer>
      <MultiSelect
        className="gtm-filters-filters.businessTeam"
        options={opportunityBusinessTeamSelectItems}
        disabled={!isEditable}
        name="businessTeam"
        onChange={selectedOptions => {
          handleFilterChange({
            businessTeam: selectedOptions,
          });
        }}
        placeholder="Show All"
        value={filters.businessTeam}
      />
    </OFFMultiSelectContainer>
    <Label>TS Lead</Label>
    <OFFMultiSelectContainer>
      <UserMultiSelect
        disabled={!isEditable}
        name="ownerUserId"
        onChange={selectedUsers => {
          handleFilterChange({
            ownerUserId: selectedUsers,
          });
        }}
        placeholder="Show All"
        value={filters.ownerUserId}
      />
    </OFFMultiSelectContainer>

    <Label>Sales Owner</Label>
    <OFFMultiSelectContainer>
      <UserMultiSelect
        disabled={!isEditable}
        name="salesLeadId"
        onChange={selectedUsers => {
          handleFilterChange({
            salesLeadId: selectedUsers,
          });
        }}
        placeholder="Show All"
        value={filters.salesLeadId}
      />
    </OFFMultiSelectContainer>

    <Label>Practice Area</Label>
    <OFFMultiSelectContainer>
      <MultiSelect
        className="gtm-filters-filters.practiceAreaId"
        options={getTreeTrunk(
          practiceAreas.filter(
            pa => pa.type && pa.name !== PracticeAreaConst.PracticeAreas.Unknown
          ),
          true
        )}
        disabled={!isEditable}
        name="practiceAreaId"
        onChange={selectedPracticeAreas => {
          handleFilterChange({
            practiceAreaId: selectedPracticeAreas,
          });
        }}
        placeholder="Show All"
        value={filters.practiceAreaId}
      />
    </OFFMultiSelectContainer>
    <Label>Close Date Range</Label>
    <OFFDateRangeContainer>
      <DataTest value="CloseDateStart" style={{ flex: 1 }} inline>
        <DatePicker
          disableParentScrolling={false}
          name="salesCloseDate"
          id="CloseDateRange1"
          openDirection="up"
          readOnly
          showClearDate
          placeholder="Any Date"
          date={filters.salesCloseDateStart}
          disabled={!isEditable}
          onChange={salesCloseDateStart => {
            handleFilterChange({ salesCloseDateStart });
          }}
        />
      </DataTest>
      <Gutter as="span" horizontal="1rem">
        <Text size="small">to</Text>
      </Gutter>
      <DataTest value="CloseDateEnd" style={{ flex: 1 }} inline>
        <DatePicker
          disableParentScrolling={false}
          name="salesCloseDate"
          id="CloseDateRange2"
          openDirection="up"
          readOnly
          showClearDate
          placeholder="Any Date"
          anchorDirection="right"
          date={filters.salesCloseDateEnd}
          disabled={!isEditable}
          onChange={salesCloseDateEnd => {
            handleFilterChange({ salesCloseDateEnd });
          }}
        />
      </DataTest>
    </OFFDateRangeContainer>
    <SkillsDropdown
      name="positionTags"
      label="Skills"
      placeholder="Show All"
      selectedValues={filters.skills}
      disabled={!isEditable}
      onChangeAction={skills =>
        handleFilterChange({
          skills: skills.map(item => item.value),
        })
      }
      className="gtm-filters-skills"
    />
    {isEditable && (
      <StoreStateTreatment
        storeState={savedFiltersState}
        renderNonLoadedView={() => (
          <Form
            onSubmit={data => {
              handleSaveFilters(data);
            }}
            initialValues={filters}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Field
                  name="name"
                  domID="save-name"
                  component={FFInput}
                  data-test={formatDataTestId('opportunityEditClientName')}
                  validate={value =>
                    value ? undefined : 'Please specify a name before saving'
                  }
                  label="Search Name"
                />
                <FluidButtonLayout>
                  <Button
                    name="RESETFILTERSBUTTON"
                    disabled={isEqual(filters, defaultFilters)}
                    onClick={() => OpportunitiesStore.resetFilters()}
                    variation="outline"
                  >
                    Reset Filters
                  </Button>
                  <Button
                    name="SAVESEARCHBUTTON"
                    onClick={handleSubmit}
                    className="gtm-saved-search"
                  >
                    Save Search
                  </Button>
                </FluidButtonLayout>
              </form>
            )}
          />
        )}
      />
    )}
  </DataTest>
);

OpportunityFiltersForm.defaultProps = {
  handleFilterChange: () => {},
};

OpportunityFiltersForm.propTypes = {
  filters: shape({}).isRequired,
  handleFilterChange: func,
  isEditable: bool.isRequired,
  handleSaveFilters: func.isRequired,
  savedFiltersState: EditSavedOpportunityFilterStore.getStateShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
};

export default connect(state => ({
  savedFiltersState: EditSavedOpportunityFilterStore.select(state),
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
}))(OpportunityFiltersForm);
