import GenericStore from '../classes/generic-store';
import { saveSearch } from '../api/searches';

import { OpportunitiesStore } from './opportunities-store';
import { UserOpportunityFiltersStore } from './user-opportunity-filters-store';

class EditSavedFiltersStoreClass extends GenericStore {
  save(userId, filterConfig) {
    const sendFilters = {
      type: UserOpportunityFiltersStore.getType(),
      id: filterConfig.id,
      name: filterConfig.name,
      search: filterConfig.filters,
    };

    return this.clearState(
      saveSearch(userId, sendFilters).then(data => {
        UserOpportunityFiltersStore.load(userId);
        OpportunitiesStore.loadByConfig({
          meta: {
            searchConfig: {
              ...filterConfig,
              id: data.id,
            },
          },
        });
      })
    );
  }

  getDataShape() {
    return OpportunitiesStore.getMetaConfigShape();
  }
}

export const EditSavedOpportunityFilterStore = new EditSavedFiltersStoreClass();
