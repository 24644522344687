import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AppFindAccountForContactModalStore } from '../../stores/app-find-account-for-contact-modal-store';
import TypeaheadModal from '../TypeaheadModal/TypeaheadModal';

import { StyledListButton } from './AppFindAccountForContactModalStyles';

const AppFindAccountForContactModal = ({ findAccountState }) => {
  const [confirmAccountId, setConfirmAccountId] = useState(null);

  const onSelect = (storeData, accountId) => {
    if (confirmAccountId) {
      setConfirmAccountId(null);
      AppFindAccountForContactModalStore.submit(storeData, accountId);
    } else {
      setConfirmAccountId(accountId);
    }
  };

  const onCancel = () => {
    setConfirmAccountId(null);
    AppFindAccountForContactModalStore.clearState();
  };

  return (
    <StoreStateTreatment
      isModalState
      showInitialLoader={false}
      showRefetchLoader={false}
      name="FIND_CONTACT_FOR_ACCOUNT_STATE"
      storeState={findAccountState}
      renderLoadedView={({ data }) => {
        return (
          <>
            <TypeaheadModal
              storeState={findAccountState}
              onSearch={(searchTerm, page) =>
                AppFindAccountForContactModalStore.findAccounts(searchTerm, {
                  ...data,
                  page,
                  results: page === 1 ? [] : data.results,
                })
              }
              onCancel={onCancel}
              totalResultsCount={data.totalResultsCount}
              itemRender={account => (
                <StyledListButton
                  key={data.id}
                  type="button"
                  onClick={() => {
                    onSelect(data, account.id);
                  }}
                >
                  {account.calculatedName}
                </StyledListButton>
              )}
              title="Search Accounts"
              searchTerm={data.searchTerm}
              results={data.results}
              page={data.page}
            />
            {confirmAccountId && (
              <Modal size="confirm">
                <ModalHeader onClose={onCancel}>Assign Account</ModalHeader>
                <ModalSection>
                  <Paragraph>
                    Are you sure you would like to assign this account to this
                    contact?
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button variation="outline" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button onClick={() => onSelect(data, confirmAccountId)}>
                    Assign
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </>
        );
      }}
    />
  );
};

AppFindAccountForContactModal.propTypes = {
  findAccountState:
    AppFindAccountForContactModalStore.getStateShape().isRequired,
};

export default connect(state => ({
  findAccountState: AppFindAccountForContactModalStore.select(state),
}))(AppFindAccountForContactModal);
