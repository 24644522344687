import styled from 'styled-components';
import { loadLightPalette } from '@axiom/ui';

import { UnstyledButton } from '../../styled';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

const PALETTE = loadLightPalette();
const LINE_HEIGHT = 'line-height: 3rem;';
const gradientStartValue = 'rgba(0, 0, 0, 0.2)';
const userControlsSizing = 'width: 28px; height: 15px; padding 0 8px';

export const DTContainer = styled.div`
  background: transparent;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  padding: 5px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid ${PALETTE.neutrals.malignant30};
`;

export const DTSelectedSearchContainer = styled.div`
  width: 100%;
  min-width: 0;
  flex: 1 1 auto;
`;

export const DTDeleteAndOpen = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
`;

export const DTDeleteAllSelected = styled(UnstyledButton)`
  ${userControlsSizing};
  cursor: pointer;
  flex: 0 1 auto;
`;

export const DTDropdownCarrot = styled(UnstyledButton)`
  ${userControlsSizing};
  flex: 0 1 auto;
`;

export const DTDropdownContainer = styled.div`
  background: #fff;
  ${props =>
    props.visible
      ? `border: 1px solid #999; display: block;`
      : `border: none; display: none;`};
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 50;
  flex: 1;
  width: 100%;
  max-height: 14rem;
  overflow: auto;
`;

export const DTSearchContainer = styled.div`
  height: 1.5rem;
  margin: 0 5px 0 0;
  box-sizing: content-box;
  position: relative;
`;

export const DTInputPlaceholder = styled.div`
  font-size: inherit;
  white-space: nowrap;
  line-height: 1.5rem;
  color: ${PALETTE.neutrals.malignant40};
`;

export const DTSearchInput = styled.input`
  top: 0;
  left: 0;
  z-index: 5;
  background: transparent;
  border: none;
  box-sizing: content-box;
  height: 1.5rem;
  width: 1rem;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: inherit;
  color: inherit;
`;

export const DTHiddenContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  box-sizing: content-box;
  white-space: pre;
  font-size: inherit;
`;

export const DTShowSelectedContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

export const DTSelectedItemContainer = styled.div`
  cursor: pointer;
  border-radius: 7px;
  background: ${PALETTE.colors.axiomOrange};
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;
  padding: 0 5px;
  min-width: 0;
`;

export const DTSelectedItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  margin-right: 5px;
  color: ${GENERAL_STYLES_MAP.SKILLS.color};
  font-weight: ${GENERAL_STYLES_MAP.SKILLS.weight};
  font-size: 0.875rem;
`;

export const DTItemDelete = styled.div`
  border-radius: 50%;
  background-color: #fff;
  height: 14px;
  width: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  display: flex;
  color: ${PALETTE.colors.axiomOrange};
  justify-content: center;
  align-items: center;

  svg path {
    /* Better match the icon font used by Kendo Multiselect */
    stroke: ${PALETTE.colors.axiomOrange};
    stroke-width: 2px;
  }
`;

export const DTTreeContainer = styled.ul`
  background: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  ul {
    border: none;
    overflow: hidden;
  }
`;

export const DTNoOptions = styled.p`
  color: #999;
  text-align: center;
`;

export const DTCategoryLabelContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 1.5rem 0 1rem;
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.SKILLS.border};
`;

export const DTCategoryLabel = styled.div`
  width: 100%;
  flex: 1;
  line-height: 1.7;
`;

export const DTExpandCollapsContainer = styled(UnstyledButton)`
  cursor: pointer;
  flex: 0 1 auto;
  ${LINE_HEIGHT};
  padding: 0 0 0 1rem;
  align-items: center;
  display: flex;
`;

export const DTExpandCollapsCarrot = styled.span`
  display: inline-block;
  height: 0;
  width: 0;
  ${props => {
    if (props.expand) {
      return `
        border-top: 6px solid #000;
        border-right: 6px solid transparent;
        border-bottom: none;
        border-left: 6px solid transparent;
      `;
    }

    return `
      border-top: 6px solid transparent;
      border-right: none;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #000;
    `;
  }};
`;

export const DTTreeItemContainer = styled.li`
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  ${LINE_HEIGHT};
  position: relative;
  z-index: 1;
`;

export const DTTreeItem = styled.div`
  margin: 0;
  padding: 0 2rem 0 2.8125rem;
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.SKILLS.border};
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  background-color: ${GENERAL_STYLES_MAP.SKILLS.secondaryBackground};
`;

export const DTCheckbox = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  flex: none;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const DTTreeText = styled.div`
  line-height: 1.7;
  padding: 1rem 0;
`;

export const DTTreeGradiant = styled.li`
  position: absolute;
  ${props => props.direction}: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  padding: 0;
  background: transparent;
  background: -moz-linear-gradient(
    ${props => props.direction},
    ${gradientStartValue} 0%,
    transparent 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    ${props => props.direction},
    ${gradientStartValue} 0%,
    transparent 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    from ${props => props.direction},
    ${gradientStartValue} 0%,
    transparent 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;
