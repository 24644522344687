// eslint-disable-next-line max-classes-per-file
export const createDeveloperErrorString = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras = {},
}) => [
  `[ ${apiName} ${HTTPMethod} ] API ${HTTPErrorCode} ENCOUNTERED. Response was ${JSON.stringify(
    response
  )}`,
  extras,
];

export const convertAPINameToUserFriendlyText = ({
  apiName,
  method,
  errorCode,
  body,
}) => {
  const verbs = {
    GET: 'getting',
    PUT: 'updating',
    POST: 'creating',
    PATCH: 'updating',
    DELETE: 'deleting',
  };

  let errorMsg = `There was an error with ${
    verbs[method] || ''
  } ${apiName.toLowerCase()}. `;

  if (body) {
    if (body.message) {
      errorMsg += body.message;
    }
    if (body.errors && Array.isArray(body.errors)) {
      errorMsg += body.errors
        .reduce((ary, e) => {
          if (e.message) {
            ary.push(e.message);
          }
          return ary;
        }, [])
        .join('. ');
    }
  }

  errorMsg = errorMsg.trim();
  if (!errorMsg.endsWith('.')) {
    errorMsg += '.';
  }

  return {
    errorMessage: errorMsg,
    errorCode,
  };
};

export const createAPIErrorObject = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras,
  errors,
  body,
}) => ({
  id: `${Date.now()}.${apiName}.${JSON.stringify(response)}.${JSON.stringify(
    extras
  )}`,
  userErrorText: convertAPINameToUserFriendlyText({
    apiName,
    method: HTTPMethod,
    errorCode: HTTPErrorCode,
    body,
  }),
  developerErrorText: createDeveloperErrorString({
    HTTPErrorCode,
    HTTPMethod,
    apiName,
    response,
    extras,
  }),
  apiName,
  response,
  errors,
});

export class ClientError extends Error {
  constructor(errorObject) {
    super('Client Error');
    this.applicationError = errorObject;
  }

  getReduxFormErrors = () => ({
    ...this.applicationError,
  });
}

export const HIDDEN_FIELD_ERROR_CODES = [
  'extra_field',
  'requires_at_least_one',
];

export const fieldErrorFormatters = {
  enum_mismatch: ({ expectedValues = [] }) =>
    `Must be one of (${expectedValues.join(', ')}).`,
  extra_field: ({ field }) =>
    `Field "${field}" is unknown or cannot be edited.`,
  missing_required: () => `This field is required.`,
  requires_at_least_one: () => 'At least one field must be filled in.',
  too_long: ({ maxLength }) => `Can't be longer than ${maxLength} characters.`,
  too_short: ({ minLength }) =>
    `Can't be shorter than ${minLength} characters.`,
  wrong_format: ({ expectedFormat = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedFormat) ? 'n' : ''} ${expectedFormat}.`,
  wrong_type: ({ expectedType = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedType) ? 'n' : ''} ${expectedType}.`,
};

export const formatFieldError = e => {
  if (typeof e === 'string') return e;
  const formatter = fieldErrorFormatters[e.code];
  if (formatter) return formatter(e);
  return e.message || JSON.stringify(e);
};

export class BadRequestError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Bad Request Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      body,
      errors: body?.errors ? body.errors : [{ message: body.message }], // munch new shape to old shape
      extras: { endpoint, headers, body },
      response,
    });
    this.applicationError = errorObj;
  }

  getReduxFormErrors = () => {
    const { errors } = this.applicationError;
    const formErrors = {};
    try {
      let apiErrors = 0;
      errors.forEach(e => {
        let { field } = e;
        if (!field || HIDDEN_FIELD_ERROR_CODES.includes(e.code)) {
          apiErrors += 1;
          field = `__error${apiErrors}`;
        }
        formErrors[field] = formatFieldError(e);
      });
    } catch (e) {
      console.error('Error parsing API error', e); // eslint-disable-line no-console
    }
    return formErrors;
  };

  getStoreErrors() {
    const { errors, response } = this.applicationError;
    try {
      return {
        statusCode: response.statusCode,
        errors: errors.map(e => (e.message ? e.message : e)),
      };
    } catch (e) {
      return errors;
    }
  }
}

export class APIError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('API Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthorizationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authorization Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthenticationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authentication Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}
