import React from 'react';
import { string } from 'prop-types';
import { WarningIcon } from '@axiom/ui';

import { ErrorBlurbWrapper } from './ErrorBlurbStyles';

export const ErrorBlurb = ({ errorMessage }) => (
  <ErrorBlurbWrapper>
    <WarningIcon />
    <span>{errorMessage}</span>
  </ErrorBlurbWrapper>
);

ErrorBlurb.propTypes = {
  errorMessage: string,
};

ErrorBlurb.defaultProps = {
  errorMessage: 'An error occurred. Please refresh.',
};
