import {
  APP_LOADING,
  APP_REQUEST_INITIAL_DATA,
  APP_SIDE_BAR_COLLAPSE,
  APP_SIDE_BAR_EXPAND,
  APP_SIDE_BAR_ACTIVE,
  APP_SIDE_BAR_INACTIVE,
  APP_SIDE_DRAWER_ACTIVE,
  APP_SIDE_DRAWER_INACTIVE,
  API_ERROR_RECEIVED,
} from '../constants';

export const appLoading = payload => ({
  type: APP_LOADING,
  payload,
});

export const getInitialAppData = payload => ({
  type: APP_REQUEST_INITIAL_DATA,
  payload,
});

export const closeSideBar = () => ({
  type: APP_SIDE_BAR_COLLAPSE,
});

export const openSideBar = () => ({
  type: APP_SIDE_BAR_EXPAND,
});

export const activateSideBar = () => ({
  type: APP_SIDE_BAR_ACTIVE,
});

export const deactivateSideBar = () => ({
  type: APP_SIDE_BAR_INACTIVE,
});

export const activateSideDrawer = () => ({
  type: APP_SIDE_DRAWER_ACTIVE,
});

export const deactivateSideDrawer = () => ({
  type: APP_SIDE_DRAWER_INACTIVE,
});

export const addApiError = payload => ({
  type: API_ERROR_RECEIVED,
  payload,
});
