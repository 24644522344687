import styled from 'styled-components';

export const FloatRight = styled.span`
  float: right;
`;

export const FloatLeft = styled.span`
  float: left;
`;

export const InlineRow = styled.span`
  display: inline-flex;
  align-items: center;
`;
export const InlineCol = styled.span`
  margin-right: 0.7rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

export const Col = styled.div`
  flex: 1;
`;

export const InputGroup = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const InputGroupPrepend = styled.div`
  flex-shrink: 1;
`;
export const InputGroupAppend = styled.div`
  flex-shrink: 1;
`;
export const InputGroupInput = styled.div`
  flex-grow: 1;
`;

export const AlignRight = styled.div`
  text-align: right;
`;

export const FlexDiv = styled.div`
  display: flex;
`;
