import styled, { css } from 'styled-components';
import { Label } from '@axiom/ui';

import AxiomForTalentIndicator from '../AxiomForTalentIndicator/AxiomForTalentIndicator';
import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const StyledInput = styled.input`
  height: 2.25rem;
  width: 100%;
  border: 0.0625rem solid ${getThemeProp('colorSecondary')};
  background: ${getThemeProp('contentBackground')};
  padding: 0 0.375rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${getThemeProp('primaryText')};

  &:focus {
    border-color: ${GENERAL_STYLES_MAP.AXIOM.background};
  }
`;

export const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 0.5rem;
`;

export const StyledWrapper = styled.div`
  ${props =>
    (props.isDisabled &&
      css`
        ${StyledInput} {
          background: ${getThemeProp('appBackground')};
          color: ${GENERAL_STYLES_MAP.DISABLED.color};
        }
        ${StyledLabel} {
          color: ${getThemeProp('colorSecondary')};
        }
      `) ||
    (props.isInvalid &&
      css`
        ${StyledInput} {
          border-color: ${getThemeProp('negativeColor')};
        }
        ${StyledLabel} {
          color: ${getThemeProp('negativeColor')};
          font-weight: bold;
        }
      `)};
`;

export const StyledAftIcon = styled(AxiomForTalentIndicator)`
  margin-left: 4px;
`;
