import {
  SEARCHES_SAVE,
  SEARCHES_SAVED,
  SEARCH_SAVE_ERROR,
  SEARCHES_SUBMIT,
} from '../constants';

export const saveSearch = () => ({
  type: SEARCHES_SAVE,
});

export const saveSearchSuccess = ({ searches, type }) => ({
  type: SEARCHES_SAVED,
  payload: { searches, type },
});

export const saveSearchError = payload => ({
  type: SEARCH_SAVE_ERROR,
  error: true,
  payload,
});

export const searchesSubmit = ({
  form,
  shouldResetPageCount = true,
  shouldDebounce = false,
}) => ({
  type: SEARCHES_SUBMIT,
  payload: { form, shouldResetPageCount, shouldDebounce },
});
