import { SEARCHES_SAVE, SEARCHES_SAVED, SEARCH_SAVE_ERROR } from '../constants';

export const INITIAL_STATE = {
  loading: false,
  error: undefined,
  data: [],
  byId: {},
  saving: false,
  jobs: [],
  talent: [],
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SEARCHES_SAVE:
      return {
        ...state,
        saving: true,
        error: undefined,
      };

    case SEARCH_SAVE_ERROR:
      return {
        ...state,
        saving: false,
        error: payload,
      };

    case SEARCHES_SAVED:
      return {
        ...state,
        saving: false,
        error: undefined,
        byId: {
          ...state.byId,
          [payload.searches.data.id]: payload.searches.data,
        },
      };

    default:
      return state;
  }
};

export default reducer;
