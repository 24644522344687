import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SPINNER_LENGTH } from '../../themes/constants';

const Spinner = ({ size }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 50 50"
    style={{
      enableBackground: 'new 0 0 50 50',
    }}
    xmlSpace="preserve"
  >
    <path
      fill="#000"
      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

Spinner.defaultProps = {
  size: SPINNER_LENGTH,
};

Spinner.propTypes = {
  size: PropTypes.number,
};

export const SpinnerWrapper = styled.div`
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  & > svg {
    top: calc(50% - ${SPINNER_LENGTH / 2}px);
    left: calc(50% - ${SPINNER_LENGTH / 2}px);
    position: relative;
  }
`;

export const CenteredSpinner = () => (
  <SpinnerWrapper>
    <Spinner />
  </SpinnerWrapper>
);

export default Spinner;
