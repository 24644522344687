import { AjvUtil } from '../utils/ajv-util';
import FormStore from '../classes/form-store';
import { UserShape } from '../models/user';
import { setUserGroups, updateUser } from '../redux/actions/users';

import { PreloadedGroupsStore } from './preloaded-groups-store';
import { PreloadedUsersStore } from './preloaded-users-store';

const UserValidation = AjvUtil.compileSchema({
  required: ['isActive', 'roles'],
  properties: {
    isActive: {
      type: 'boolean',
    },
    roles: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
  },
});

class FormUserManagementStoreClass extends FormStore {
  load({ email, firstName, id, isActive, isSSO, lastName, roles, team }) {
    return this.setState({
      email,
      firstName,
      id,
      isActive,
      isSSO,
      lastName,
      roles,
      team,
    });
  }

  getDataShape() {
    return UserShape;
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(UserValidation, formData);
  }

  submit(_formData, form) {
    // TODO: migrate logic from redux sagas
    this.dispatch(updateUser(form));
    this.dispatch(setUserGroups(form));

    setTimeout(() => {
      PreloadedGroupsStore.load();
      PreloadedUsersStore.load();
    }, 500);
  }
}

export const FormUserManagementStore = new FormUserManagementStoreClass();
