/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useState } from 'react';
import { func } from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import union from 'lodash/union';
import {
  Button,
  DataTest,
  Gutter,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  SmallHeader,
  Text,
} from '@axiom/ui';
import { EventsConst } from '@axiom/const';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import {
  AppEventItemModalStore,
  eventTypeOptions,
} from '../../stores/app-event-item-modal-store';
import { formatDataTestId } from '../../utils/dataTest';
import { DateUtil } from '../../utils/date-util';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import FFDatePicker from '../FFDatePicker/FFDatePicker';
import FFMultiSelect from '../FFMultiSelect/FFMultiSelect';
import { FFRichTextEditor } from '../FFRichTextEditor/FFRichTextEditor';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { DropdownOptionsShape } from '../../models/dropdown-options';
import { selectifyObject } from '../../utils/selectify';
import { LabelWrapper } from '../../styled';

import {
  UpperContentWrapper,
  LowerContentWrapper,
  AttachFileWrapper,
  RichTextEditorWrapper,
} from './AppEventItemModalStyles';
import AppEventItemModalCheckbox from './AppEventItemModalCheckbox';

export const eventPurposeOptionsAdd = selectifyObject(
  Object.values(EventsConst.UserPurposes).sort()
);

export const eventPurposeOptionsEdit = selectifyObject({
  ...Object.keys(EventsConst.UserPurposes).reduce((acc, key) => {
    acc[EventsConst.UserPurposes[key]] = EventsConst.UserPurposes[key];
    return acc;
  }, {}),
  ...Object.keys(EventsConst.AutomatedPurposes).reduce((acc, key) => {
    acc[
      EventsConst.AutomatedPurposes[key]
    ] = `Automated - ${EventsConst.AutomatedPurposes[key]}`;
    return acc;
  }, {}),
});

const AppEventItemModal = ({ userOptions, onClose }) => {
  const [confirmData, setConfirmData] = useState(null);
  const inputFileRef = useRef(null);

  const handleSave = (changedData, formData) => {
    if (formData.id) {
      setConfirmData({ changedData, formData });
    } else {
      AppEventItemModalStore.submit(changedData, formData);
      onClose();
    }
  };

  const handleCheckboxChange = (
    value,
    form,
    { collaborateEmails, emailAddresses }
  ) => {
    const emailsWithoutCollaborators = filter(
      emailAddresses,
      email => !collaborateEmails.includes(email)
    );
    switch (value) {
      case 'checked':
      case 'indeterminate':
        form.change('emailAddresses', emailsWithoutCollaborators);
        break;
      case 'unchecked':
        form.change('emailAddresses', union(emailAddresses, collaborateEmails));
        break;
      default:
        break;
    }
  };

  const getTimestampDisplay = event =>
    event.updatedAt
      ? `Last Edited: ${DateUtil.displayTimestamp(event.updatedAt)}`
      : `Creating for: ${DateUtil.displayTimestamp(new Date())}`;

  const getCheckboxValue = ({ collaborateEmails, emailAddresses }) => {
    if (collaborateEmails.every(email => emailAddresses.includes(email))) {
      return 'checked';
    }
    if (collaborateEmails.some(email => emailAddresses.includes(email))) {
      return 'indeterminate';
    }
    return 'unchecked';
  };

  const getPurposeOptions = (options, selected) => {
    if (selected !== EventsConst.Purposes.Submission) {
      return options.filter(
        option => option.value !== EventsConst.Purposes.Submission
      );
    }

    return options.filter(
      option => option.value === EventsConst.Purposes.Submission
    );
  };

  const buildSubmissionUrl = values => {
    const candidateId = values.candidateId ? `/${values.candidateId}` : '';

    return `/opportunity-detail/${values.opportunityId}/submission${candidateId}`;
  };

  const getFileElement = (
    // eslint-disable-next-line react/prop-types
    { id, attachmentKey, attachmentName, document },
    form
  ) => {
    let formattedName = attachmentName;
    if (attachmentKey) {
      try {
        const [name] = attachmentName.split('/');
        formattedName = name;
      } catch (e) {
        /* eslint-disable no-console */
        console.error("Couldn't get attachment name");
        /* eslint-enable no-console */
      }
    } else if (document) {
      formattedName = document.name;
    }

    return (
      <AttachFileWrapper
        data-test={formatDataTestId('addActivityFormAttachFileWrapper')}
      >
        <Button
          className="gtm-file-upload"
          name="ADDACTIVITYFORMUPLOADFILEBUTTON"
          toTab={attachmentKey && `/api/events/${id}/file`}
          onClick={() => !attachmentKey && inputFileRef.current.click()}
        >
          {formattedName || 'Browse'}
        </Button>
        <input
          id="file-upload"
          ref={inputFileRef}
          type="file"
          onChange={e => {
            form.change('document', e.target.files[0]);
          }}
        />
      </AttachFileWrapper>
    );
  };

  return (
    <FormStateTreatment
      name="ACTIVITY_MODAL_STATE"
      isModalState
      formStore={AppEventItemModalStore}
      submit={(changedData, formData) => {
        handleSave(changedData, formData);
      }}
      renderLoadedView={({ formData, fireSubmit, fireCancel, form }) => (
        <>
          <Modal>
            <ModalHeader onClose={fireCancel}>
              <SmallHeader>
                {`${formData.id ? 'Edit' : 'New'} Activity`}
              </SmallHeader>
            </ModalHeader>
            <ModalSection>
              {formData.opportunityId &&
                formData.purpose === EventsConst.Purposes.Submission && (
                  <DataTest
                    value="submission_closed_message"
                    style={{ marginBottom: '1rem' }}
                  >
                    Opportunity closed.{' '}
                    <a
                      href={buildSubmissionUrl(formData)}
                      data-test={formatDataTestId('view_submission_link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Submission
                    </a>
                  </DataTest>
                )}
              <Gutter bottom="1.5rem">
                <Text name="last edited" className="font-style-italic" subtle>
                  {getTimestampDisplay(formData)}
                </Text>
              </Gutter>
              <UpperContentWrapper
                data-test={formatDataTestId('activityModal')}
              >
                <Layout position="top">
                  <LayoutItem fluid>
                    <Field
                      name="type"
                      component={FFDropdown}
                      label="Activity Type"
                      options={eventTypeOptions}
                    />
                  </LayoutItem>
                  <LayoutItem fluid>
                    <Gutter left="8px">
                      {!formData.id &&
                      formData.purpose ===
                        EventsConst.Purposes.OpportunityInterview ? (
                        <div>
                          <LabelWrapper name="filter_by_profile_status">
                            Topic / Purpose
                          </LabelWrapper>
                          <div>{EventsConst.Purposes.OpportunityInterview}</div>
                        </div>
                      ) : (
                        <Field
                          name="purpose"
                          component={FFDropdown}
                          label="Topic / Purpose"
                          options={getPurposeOptions(
                            formData.id
                              ? eventPurposeOptionsEdit
                              : eventPurposeOptionsAdd,
                            formData.purpose
                          )}
                          isDisabled={
                            formData.purpose === EventsConst.Purposes.Submission
                          }
                        />
                      )}
                    </Gutter>
                  </LayoutItem>
                  <LayoutItem fluid>
                    <Gutter left="8px">
                      <Field
                        label="Activity Date"
                        name="scheduledDate"
                        component={FFDatePicker}
                        anchorDirection="right"
                        data-test={formatDataTestId('SCHEDULED_DATE')}
                      />
                    </Gutter>
                  </LayoutItem>
                </Layout>
              </UpperContentWrapper>
              <LowerContentWrapper>
                <Field
                  name="emailAddresses"
                  label="To"
                  component={FFMultiSelect}
                  options={userOptions}
                />

                {formData.collaborateEmails.length > 0 && (
                  <AppEventItemModalCheckbox
                    onChange={value =>
                      handleCheckboxChange(value, form, formData)
                    }
                    name="includeCollaborators"
                    label="Include Collaborators"
                    className="gtm-include-collaborators"
                    value={getCheckboxValue(formData)}
                  />
                )}

                {getFileElement(formData, form)}

                <Gutter bottom="2rem">
                  <Text subtle className="font-style-italic">
                    Maximum Allowed File Size 15MB
                  </Text>
                </Gutter>
                <RichTextEditorWrapper height="250px" isEnabled>
                  <Field
                    label="Notes"
                    name="description"
                    placeholder="Keep Activity Log comments factual, concise and relevant to assessing the candidate, Talent or engagement.  Never include discriminatory or derogatory comments, admissions against Axiom’s interests or state legal conclusions (e.g., “Axiom is at fault”).  Your comments may be disclosable under data protection legislation and/or in legal proceedings involving Axiom."
                    component={FFRichTextEditor}
                  />
                </RichTextEditorWrapper>
              </LowerContentWrapper>
            </ModalSection>
            <ModalFooter>
              <Button onClick={fireCancel} name="CANCEL" variation="outline">
                Cancel
              </Button>
              <Button onClick={fireSubmit} name="SAVE">
                Save
              </Button>
            </ModalFooter>
          </Modal>
          {confirmData && (
            <Modal size="confirm">
              <ModalHeader onClose={() => setConfirmData(null)}>
                Confirm Change
              </ModalHeader>
              <ModalSection>
                Are you sure you want to save the edits to this activity?
              </ModalSection>
              <ModalFooter name="EDITACTIVITYCONFIRMATIONMODAL">
                <Button
                  onClick={() => setConfirmData(null)}
                  variation="outline"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setConfirmData(null);
                    AppEventItemModalStore.submit(
                      confirmData.changedData,
                      confirmData.formData
                    );
                    onClose();
                  }}
                  name="ADDACTIVITYFORMCONFIRMBUTTON"
                >
                  Confirm
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </>
      )}
    />
  );
};

AppEventItemModal.propTypes = {
  userOptions: DropdownOptionsShape,
  onClose: func,
};
AppEventItemModal.defaultProps = {
  userOptions: [],
  onClose: () => {},
};

export default connect(state => ({
  userOptions: PreloadedUsersStore.selectData(state).map(u => ({
    label: u.fullName,
    value: u.email,
  })),
}))(AppEventItemModal);
