import { string, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import {
  OpportunityLegacyApi,
  patchOpportunity,
} from '../api/opportunities-legacy-api';

import { OpportunityStore } from './opportunity-store';

class EditOpportunityFulfillmentStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      opportunityId: string,
    });
  }

  load(opportunityId) {
    this.setState({ opportunityId });
  }

  save(opportunityId, form) {
    return this.clearState(patchOpportunity(opportunityId, form)).then(() => {
      OpportunityStore.load(opportunityId);
      return OpportunityLegacyApi.refreshOpportunity(opportunityId);
    });
  }
}

export const EditOpportunityFulfillmentStore =
  new EditOpportunityFulfillmentStoreClass();
