import { Opportunity } from '@axiom/const';

export const OPPORTUNITY_FIELD_ACCOUNT_NAME = Opportunity.ACCOUNT_NAME;
export const OPPORTUNITY_FIELD_ACCOUNT_SALESFORCE_ID =
  Opportunity.ACCOUNT_SALESFORCE_ID;
export const OPPORTUNITY_FIELD_AMOUNT = Opportunity.AMOUNT;
export const OPPORTUNITY_FIELD_BILLING_CURRENCY = Opportunity.BILLING_CURRENCY;
export const OPPORTUNITY_FIELD_BILLING_ESTIMATED_UNITS =
  Opportunity.BILLING_ESTIMATED_UNITS;
export const OPPORTUNITY_FIELD_BILLING_NAME = Opportunity.BILLING_NAME;
export const OPPORTUNITY_FIELD_BUSINESS_TEAM = Opportunity.BUSINESS_TEAM;
export const OPPORTUNITY_FIELD_CLOSED_LOST_NOTES =
  Opportunity.CLOSED_LOST_NOTES;
export const OPPORTUNITY_FIELD_COLLABORATOR_ID = Opportunity.COLLABORATOR_ID;
export const OPPORTUNITY_FIELD_COMPETITOR_TYPE = Opportunity.COMPETITOR_TYPE;
export const OPPORTUNITY_FIELD_COMPETITOR_VIEW = Opportunity.COMPETITOR_VIEW;
export const OPPORTUNITY_FIELD_CREATED_AT = Opportunity.CREATED_AT;
export const OPPORTUNITY_FIELD_CULTURE_SKILLS = Opportunity.CULTURE_SKILLS;
export const OPPORTUNITY_FIELD_CURRENCY = Opportunity.CURRENCY;
export const OPPORTUNITY_FIELD_DESCRIPTION = Opportunity.DESCRIPTION;
export const POSITION_FIELD_DESCRIPTION = `POSITION_${Opportunity.DESCRIPTION}`;
export const OPPORTUNITY_FIELD_ENGAGEMENT_TYPE = Opportunity.ENGAGEMENT_TYPE;
export const OPPORTUNITY_FIELD_FIRST_SUBMITTED_CANDIDATE_AT =
  Opportunity.FIRST_SUBMITTED_CANDIDATE_AT;
export const OPPORTUNITY_FIELD_FORECAST_CATEGORY =
  Opportunity.FORECAST_CATEGORY;
export const OPPORTUNITY_FIELD_ID = Opportunity.ID;
export const OPPORTUNITY_FIELD_INADEQUATE_SUPPLY_CATEGORY =
  Opportunity.INADEQUATE_SUPPLY_CATEGORY;
export const OPPORTUNITY_FIELD_IS_REMOTE = Opportunity.IS_REMOTE;
export const OPPORTUNITY_FIELD_LANGUAGES = Opportunity.LANGUAGES;
export const OPPORTUNITY_FIELD_LAST_UPDATED_BY = Opportunity.LAST_UPDATED_BY;
export const OPPORTUNITY_FIELD_MATTER_ID = Opportunity.MATTER_ID;
export const OPPORTUNITY_FIELD_NEXT_STEP = Opportunity.NEXT_STEP;
export const OPPORTUNITY_FIELD_OFFERING = Opportunity.OFFERING;
export const OPPORTUNITY_FIELD_OPPORTUNITY_OWNER_SALESFORCE_ID =
  Opportunity.OPPORTUNITY_OWNER_SALESFORCE_ID;
export const OPPORTUNITY_FIELD_OWNER_USER_ID = Opportunity.OWNER_USER_ID;
export const OPPORTUNITY_FIELD_PRACTICE_AREA_ID = Opportunity.PRACTICE_AREA_ID;
export const OPPORTUNITY_FIELD_QUALIFIED_AT = Opportunity.QUALIFIED_AT;
export const OPPORTUNITY_FIELD_REASON_LOST = Opportunity.REASON_LOST;
export const OPPORTUNITY_FIELD_REGION = Opportunity.REGION;
export const OPPORTUNITY_FIELD_REQUIRED_NUM_RESOURCES =
  Opportunity.REQUIRED_NUM_RESOURCES;
export const OPPORTUNITY_FIELD_SALES_CX_LEAD_ID = Opportunity.SALES_CX_LEAD_ID;
export const OPPORTUNITY_FIELD_SALES_LEAD_ID = Opportunity.SALES_LEAD_ID;
export const OPPORTUNITY_FIELD_SALES_NOTE = Opportunity.SALES_NOTE;
export const OPPORTUNITY_FIELD_SALESFORCE_ACCOUNT_ID =
  Opportunity.SALESFORCE_ACCOUNT_ID;
export const OPPORTUNITY_FIELD_SALESFORCE_ID = Opportunity.SALESFORCE_ID;
export const OPPORTUNITY_FIELD_STAGE = Opportunity.STAGE;
export const OPPORTUNITY_FIELD_STATUS_UPDATED_AT =
  Opportunity.STATUS_UPDATED_AT;
export const OPPORTUNITY_FIELD_UPDATED_AT = Opportunity.UPDATED_AT;
export const OPPORTUNITY_FIELD_USE_CASE = Opportunity.USE_CASE;

// derived field, not part of the actual model
export const OPPORTUNITY_FIELD_STATUS_GROUP = 'statusGroup';

export const opportunityFieldLabels = {
  [OPPORTUNITY_FIELD_ACCOUNT_NAME]: 'Account Name',
  [OPPORTUNITY_FIELD_ACCOUNT_SALESFORCE_ID]: 'Account Salesforce ID',
  [OPPORTUNITY_FIELD_AMOUNT]: 'Amount',
  [OPPORTUNITY_FIELD_BILLING_CURRENCY]: 'Billing Currency',
  [OPPORTUNITY_FIELD_BILLING_ESTIMATED_UNITS]: 'Est. Billing Units',
  [OPPORTUNITY_FIELD_BILLING_NAME]: 'Billing Name',
  [OPPORTUNITY_FIELD_BUSINESS_TEAM]: 'Business Team',
  [OPPORTUNITY_FIELD_CLOSED_LOST_NOTES]: 'Closed Lost Notes',
  [OPPORTUNITY_FIELD_COLLABORATOR_ID]: 'Opportunity Collaborator',
  [OPPORTUNITY_FIELD_COMPETITOR_TYPE]: 'Competitor Type',
  [OPPORTUNITY_FIELD_COMPETITOR_VIEW]: 'Competitor View',
  [OPPORTUNITY_FIELD_CREATED_AT]: 'Created',
  [OPPORTUNITY_FIELD_CULTURE_SKILLS]: 'Culture Skills',
  [OPPORTUNITY_FIELD_CURRENCY]: 'Currency',
  [OPPORTUNITY_FIELD_DESCRIPTION]: 'Description',
  contractedEndDate: 'End Date',
  [OPPORTUNITY_FIELD_ENGAGEMENT_TYPE]: 'Type',
  [OPPORTUNITY_FIELD_FORECAST_CATEGORY]: 'Forecast Category',
  [OPPORTUNITY_FIELD_ID]: 'Opportunity ID',
  [OPPORTUNITY_FIELD_IS_REMOTE]: 'Remote',
  [OPPORTUNITY_FIELD_LANGUAGES]: 'Languages',
  [OPPORTUNITY_FIELD_LAST_UPDATED_BY]: 'Last Updated By',
  [OPPORTUNITY_FIELD_MATTER_ID]: 'Matter ID',
  [OPPORTUNITY_FIELD_NEXT_STEP]: 'Next Steps',
  [OPPORTUNITY_FIELD_OFFERING]: 'Offering',
  [OPPORTUNITY_FIELD_OPPORTUNITY_OWNER_SALESFORCE_ID]:
    'Opportunity Owner Salesforce ID',
  [OPPORTUNITY_FIELD_OWNER_USER_ID]: 'Opportunity Owner',
  [OPPORTUNITY_FIELD_PRACTICE_AREA_ID]: 'Practice Area',
  [OPPORTUNITY_FIELD_REASON_LOST]: 'Reason Lost',
  [OPPORTUNITY_FIELD_REGION]: 'Region',
  [OPPORTUNITY_FIELD_REQUIRED_NUM_RESOURCES]: 'Number of Axiom Resources',
  salesCloseDate: 'Close Date',
  [OPPORTUNITY_FIELD_SALES_CX_LEAD_ID]: 'Salesforce CX Lead',
  [OPPORTUNITY_FIELD_SALES_LEAD_ID]: 'Opportunity Owner',
  [OPPORTUNITY_FIELD_SALES_NOTE]: 'Sales Note',
  [OPPORTUNITY_FIELD_SALESFORCE_ACCOUNT_ID]: 'Salesforce Account ID',
  [OPPORTUNITY_FIELD_SALESFORCE_ID]: 'Salesforce ID',
  [OPPORTUNITY_FIELD_STAGE]: 'Stage',
  startDate: 'Start Date',
  [OPPORTUNITY_FIELD_STATUS_GROUP]: 'Status',
  [OPPORTUNITY_FIELD_STATUS_UPDATED_AT]: 'Status Updated',
  [OPPORTUNITY_FIELD_UPDATED_AT]: 'Updated',
  [OPPORTUNITY_FIELD_USE_CASE]: 'Use Case',
};
