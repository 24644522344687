import { DECORATED_TALENT_LOADED } from '../constants';

import { normalizeOpportunityData } from './opportunities';

export const INITIAL_STATE = {
  byTalentId: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DECORATED_TALENT_LOADED:
      return {
        ...state,
        byTalentId: {
          ...state.byTalentId,
          [payload.talentID]: payload.opportunities.data.map(
            normalizeOpportunityData
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
