import React from 'react';
import { PossibleImageSizes, CountryCodes } from '@axiom/const';
import { SubmissionCandidate } from '@axiom/validation';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { CandidateProfileUtil } from '../../../utils/candidate-profile-util';
import { Layout } from '../../layout/Layout/Layout';
import { ImageCircle } from '../../element/ImageCircle/ImageCircle';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { CondensedMedium } from '../../content/CondensedMedium/CondensedMedium';
import { Gutter } from '../../layout/Gutter/Gutter';
import { CandidateBadges } from '../CandidateBadges/CandidateBadges';

export type ClientEngagementsCandidateHeaderTypes = {
  candidate: SubmissionCandidate;
  isAnonymous?: boolean;
  removeTalentImage?: boolean;
};

export const ClientEngagementsCandidateHeader = ({
  candidate,
  isAnonymous = false,
  removeTalentImage = false,
}: ClientEngagementsCandidateHeaderTypes) => {
  const displayName = () => {
    if (isAnonymous && !!candidate.calculatedFirstName) {
      return candidate.calculatedFirstName;
    } else if (candidate.calculatedDisplayName) {
      return candidate.calculatedDisplayName;
    }

    return 'Talent Name';
  };

  const renderLocationForBios = () => {
    if (candidate?.addressCountry === CountryCodes.US) {
      return [candidate?.addressState, candidate?.addressCountry]
        .filter(a => a)
        .join(', ');
    }
    return candidate?.addressCountry;
  };

  return (
    <Layout position="middle" horizontalGutter="16px" name="CANDIDATE_HEADER">
      {!removeTalentImage && (
        <div>
          <ImageCircle
            name="CANDIDATE_IMAGE"
            imageName={displayName()}
            src={
              isAnonymous
                ? null
                : CandidateProfileUtil.getProfileImageUri(
                    candidate,
                    PossibleImageSizes.W_100
                  )
            }
          />
        </div>
      )}
      <div>
        <SmallHeader name="display-name">{displayName()}</SmallHeader>
        {removeTalentImage && (
          <CondensedMedium name="bio_location">
            {renderLocationForBios()}
          </CondensedMedium>
        )}
        {Number.isInteger(candidate.yearsOfExperience) &&
          candidate.yearsOfExperience >= 0 &&
          !removeTalentImage && (
            <CondensedMedium name="candidate-header">
              {`${new CandidateYearsOfExperienceModel(
                candidate.yearsOfExperience
              ).getYearsOfExperienceRange()} years experience`}
            </CondensedMedium>
          )}
        <Gutter bottom="8px" />
        <CandidateBadges badges={candidate.badges} />
      </div>
    </Layout>
  );
};
