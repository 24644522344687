import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_POLL,
  GET_NOTIFICATIONS_POLL_SUCCESS,
  GET_NOTIFICATIONS_POLL_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_ALL_NOTIFICATIONS,
  UPDATE_ALL_NOTIFICATIONS_SUCCESS,
  UPDATE_ALL_NOTIFICATIONS_ERROR,
} from '../constants';

export const getNotifications = payload => ({
  type: GET_NOTIFICATIONS,
  payload,
});
export const getNotificationsSuccess = payload => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsPoll = payload => ({
  type: GET_NOTIFICATIONS_POLL,
  payload,
});
export const getNotificationsPollSuccess = payload => ({
  type: GET_NOTIFICATIONS_POLL_SUCCESS,
  payload,
});
export const getNotificationsPollError = payload => ({
  type: GET_NOTIFICATIONS_POLL_ERROR,
  payload,
});

export const getNotificationsError = payload => ({
  type: GET_NOTIFICATIONS_ERROR,
  payload,
  error: true,
});

export const putNotificationRead = payload => ({
  type: UPDATE_NOTIFICATION,
  payload,
});

export const putNotificationReadSuccess = payload => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload,
});

export const putNotificationReadError = payload => ({
  type: UPDATE_NOTIFICATION_ERROR,
  payload,
  error: true,
});

export const putAllNotificationRead = payload => ({
  type: UPDATE_ALL_NOTIFICATIONS,
  payload,
});

export const putAllNotificationReadSuccess = payload => ({
  type: UPDATE_ALL_NOTIFICATIONS_SUCCESS,
  payload,
});

export const putAllNotificationReadError = payload => ({
  type: UPDATE_ALL_NOTIFICATIONS_ERROR,
  payload,
  error: true,
});
