import styled, { css } from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

export const StaticItem = styled.div<{ divider?: boolean }>`
  flex: 0 0 auto;

  ${props =>
    props.divider &&
    css`
      border-top: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
      margin-top: 10px;
      padding-top: 10px;
    `};
`;

export const DynamicScrollingItem = styled.div`
  flex: 0 1 auto;
  overflow: auto;
  max-height: 24em;
  position: relative;
  left: -5px;
`;

export const UserList = styled.ul`
  margin: 0 16px 0 3px;
  padding: 0;
`;

export const UserListItem = styled.li<{
  isInactive?: boolean;
  isSelected?: boolean;
}>`
  background: ${({ isInactive, isSelected }) => {
    if (isSelected && isInactive) return GENERAL_STYLES_MAP.DISABLED.background;
    if (isSelected) return GENERAL_STYLES_MAP.DROPDOWN.background;
    return 'transparent';
  }};

  color: ${({ isInactive, isSelected }) => {
    if (isSelected) return getThemeProp('contentBackground');
    if (isInactive) return '#757575';
    return getThemeProp('primaryText');
  }};

  cursor: pointer;
  margin: 0;
  padding-left: 5px;
  line-height: 50px;
`;
