import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';

const maxHeight = '550px';

export const ManageAccountsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  max-height: 100%;
  height: 600px;
`;

export const ManageAccountsLeft = styled.div`
  width: 40%;
  padding: 0 32px 0 0;
  max-height: 100%;
  height: 100%;

  ${MediaQueryUtil.mobileOnly(
    css`
      width: 100%;
      max-height: ${maxHeight};
    `
  )}
`;

export const ManageAccountsRight = styled.div`
  width: 60%;
  background: ${getThemeProp('contentBackground')};
  box-shadow: -3px 1.5px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  overflow-y: auto;

  ${MediaQueryUtil.mobileOnly(
    css`
      width: 100%;
    `
  )}
`;
