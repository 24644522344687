import React from 'react';
import { Button, FluidButtonLayout, Textarea, Form } from '@axiom/ui';
import { z } from 'zod';
import { Candidate, CandidateWhiteboard } from '@axiom/validation';

import { CandidateLegacyApi } from '../../api/candidate-legacy-api';

const formSchema = z.object({
  note: z.string().max(500).nullish(),
});

export const TalentAvailabilityNotesEdit = ({
  candidate,
  setEditingNotes,
  note,
}: {
  candidate: Candidate;
  setEditingNotes: (isEditable: boolean) => void;
  note: CandidateWhiteboard;
}) => {
  return (
    <Form
      name="TALENT_AVAILABILITY_NOTES_FORM"
      initialValues={{
        note: note.body,
      }}
      schema={formSchema}
      onSubmit={async formData => {
        let updatedNote;
        if (formData.note === null) {
          updatedNote = '';
        } else if (formData.note) {
          updatedNote = formData.note;
        } else {
          updatedNote = note.body;
        }
        await CandidateLegacyApi.updateCandidateNotes({
          id: note.id,
          candidateId: candidate.id,
          body: updatedNote,
        });
        await CandidateLegacyApi.refreshNotes(candidate.id);
        setEditingNotes(false);
      }}
    >
      {({ fireSubmit, fireReset }) => (
        <>
          <Textarea name="note" />
          <FluidButtonLayout>
            <Button
              name="cancel"
              pattern="secondary"
              variation="outline"
              onClick={() => {
                fireReset();
                setEditingNotes(false);
              }}
            >
              Cancel
            </Button>
            <Button
              name="save"
              pattern="secondary"
              onClick={() => {
                fireSubmit();
              }}
            >
              Save
            </Button>
          </FluidButtonLayout>
        </>
      )}
    </Form>
  );
};
