import React, { FC } from 'react';
import { AreaOfStudy, AreaOfStudySchema } from '@axiom/validation';
import { AreasOfStudyCategoriesConst } from '@axiom/const';
import {
  Button,
  Dropdown,
  FluidButtonLayout,
  Form,
  Gutter,
  Header3,
  Input,
} from '@axiom/ui';

import { toDropdownOptions } from '../../utils/to-dropdown-options';
import { AreasOfStudyApi } from '../../api/areas-of-study';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';

import { AreasOfStudyManagementFormFormHeader } from './AreasOfStudyManagementFormStyles';

interface AreasOfStudyManagementFormProps {
  areasOfStudy: AreaOfStudy[];
  selected: AreaOfStudy;
  onClose: () => void;
}

export const AreasOfStudyManagementForm: FC<
  AreasOfStudyManagementFormProps
> = ({ selected, areasOfStudy, onClose }) => {
  const formSchema = AreaOfStudySchema.pick({ name: true, category: true });
  const categoryOptions = toDropdownOptions(
    AreasOfStudyCategoriesConst.Categories
  );
  // if an ID is present then we are looking to update an existing Area of Study
  const isEditing = selected.id?.length > 0;

  const onSubmit = async (formValue: AreaOfStudy) => {
    const areaOfStudyValue = { ...selected, ...formValue };

    const areaOfStudyExistsWithName = !!areasOfStudy.find(
      a =>
        a.name.toLowerCase() === areaOfStudyValue.name.toLowerCase() &&
        a.id !== areaOfStudyValue.id
    );

    if (areaOfStudyExistsWithName) {
      PreloadedAppErrorsStore.show(
        `An Area of Study already exists with name "${areaOfStudyValue.name}"`
      );
      return;
    }

    if (isEditing) {
      await AreasOfStudyApi.updateAreaOfStudy(areaOfStudyValue);
    } else {
      await AreasOfStudyApi.createAreaOfStudy(areaOfStudyValue);
    }

    await AreasOfStudyApi.invalidate('/areasOfStudy');
    onClose();
  };

  return (
    <Form initialValues={selected} schema={formSchema} onSubmit={onSubmit}>
      {() => (
        <>
          <AreasOfStudyManagementFormFormHeader data-test="AREA_OF_STUDY_ADD_EDIT_FORM">
            <Header3>{isEditing ? 'Edit' : 'Add'} Area of Study</Header3>
          </AreasOfStudyManagementFormFormHeader>
          <Gutter top="16px" horizontal="32px">
            <Input name="name" label="Name" />
            <Gutter top="16px" />
            <Dropdown
              name="category"
              options={categoryOptions}
              label="Category"
              displayKey="label"
              valueKey="value"
            />
          </Gutter>
          <Gutter top="16px" horizontal="16px">
            <FluidButtonLayout>
              <Button name="CANCEL" onClick={onClose} variation="outline">
                Cancel
              </Button>
              <Button name="SUBMIT" type="submit">
                Save
              </Button>
            </FluidButtonLayout>
          </Gutter>
        </>
      )}
    </Form>
  );
};
