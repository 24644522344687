import { getFormValues, getFormInitialValues } from 'redux-form';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import {
  createEducation,
  deleteEducation,
  updateEducation,
} from '../../api/education';
import {
  deleteEducationFailed,
  deleteEducationSucceeded,
} from '../actions/education';
import { getSingleTalent } from '../actions/talent';
import {
  ADD_EDUCATION,
  DELETE_EDUCATION,
  UPDATE_EDUCATION,
} from '../constants';

export function* createEducationFormGenerator() {
  try {
    const values = yield select(getFormValues(ADD_EDUCATION));
    yield call(createEducation, values.candidateId, values);
    yield put(getSingleTalent(values.candidateId));
  } catch (e) {
    yield put(addApiError(e.applicationError));
  }
}

export function* updateEducationFormGenerator(educationId) {
  try {
    const initialValues = yield select(getFormInitialValues(educationId));
    const values = yield select(getFormValues(educationId));
    const changedValues = pickBy(
      values,
      (val, key) => !isEqual(val, initialValues[key])
    );

    yield call(updateEducation, educationId, changedValues);
    yield put(getSingleTalent(values.candidateId));
  } catch (e) {
    yield put(addApiError(e.applicationError));
  }
}

export function* deleteEducationGenerator({ payload: { educationId } }) {
  try {
    yield call(deleteEducation, educationId);
    yield put(deleteEducationSucceeded(educationId));
  } catch (e) {
    yield put(deleteEducationFailed(educationId));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateEducationGenerator({
  payload: { educationId, ...values },
}) {
  try {
    const { data } = yield call(updateEducation, educationId, values);
    yield put(getSingleTalent(data.candidateId));
  } catch (e) {
    yield put(addApiError(e.applicationError));
  }
}

export default function* educationSaga() {
  yield takeEvery(DELETE_EDUCATION, deleteEducationGenerator);
  yield takeEvery(UPDATE_EDUCATION, updateEducationGenerator);
}
