import React, { useEffect } from 'react';
import { AnonBioProfileStatusUtil, TalentBios, useApi } from '@axiom/ui';
import { User } from '@axiom/validation';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { isEnvoyAdmin, isEnvoyUser } from '@axiom/utils';

import { LegacyTalentApi } from '../../api/talent';
import { PracticeAreasApi } from '../../api/practiceAreas';
import { CandidateApi } from '../../api/candidate/candidate-api';
import { EnvUtil } from '../../utils/env-util';
import { WindowUtil } from '../../utils/window-util';

export const CandidateBios = ({ user }: { user: User }) => {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { candidateId } = urlParams;
  const isAnon = location.pathname.includes('/anonymous');
  const download = location.pathname.includes('/download');
  const hideHighlights = location.pathname.includes('/hideHighlights');

  const [{ data: candidate }, { data: practiceAreas }] = useApi(
    LegacyTalentApi.readCandidate(candidateId),
    PracticeAreasApi.readPracticeAreas()
  );
  const [{ data: groupedExperiences }] = useApi(
    candidate.id &&
      CandidateApi.readCandidateGroupedExperiences(candidate.id, {
        isAnonymous: isAnon,
      })
  );
  const baseUrl = `/talent/${candidate.id}`;

  useEffect(() => {
    if (
      !isAnon &&
      (AnonBioProfileStatusUtil.statusSet().has(candidate.profileStatus) ||
        (!candidate.isProfileShared &&
          !isEnvoyUser(user) &&
          !isEnvoyAdmin(user)))
    ) {
      const href = `${baseUrl}/bio/anonymous`;

      navigate(href, { replace: true });
    }
  }, []);

  useEffect(() => {
    WindowUtil.title(candidate.calculatedDisplayName);
  }, [candidateId]);

  const handleOnNavigate = (hide = false) => {
    const href = `${baseUrl}/download/bio${`/${
      hide ? 'hideHighlights' : 'showHighlights'
    }`}${isAnon ? '/anonymous' : ''}`;

    window.open(href, 'download_bio');
  };

  return (
    <TalentBios
      user={user}
      hideHighlights={hideHighlights}
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      onNavigate={handleOnNavigate}
      isAnonymous={!!isAnon}
      download={!!download}
      clientUrl={EnvUtil.clientUrl}
    />
  );
};
