import { Api } from '@axiom/ui';
import { AreaOfStudy } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AreasOfStudyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAreasOfStudy() {
    return super.read<{ data: AreaOfStudy[] }>({
      endpoint: '/areasOfStudy',
      method: 'GET',
    });
  }

  readAreaOfStudy(id: AreaOfStudy['id']) {
    return super.read<{ data: AreaOfStudy }>({
      endpoint: `/areasOfStudy/${id}`,
      method: 'GET',
    });
  }

  createAreaOfStudy(body: AreaOfStudy) {
    return super.write<AreaOfStudy>({
      endpoint: '/areasOfStudy',
      method: 'POST',
      body,
    });
  }

  updateAreaOfStudy(body: AreaOfStudy) {
    return super.write<AreaOfStudy>({
      endpoint: `/areasOfStudy/${body.id}`,
      method: 'PATCH',
      body,
    });
  }
}

export const AreasOfStudyApi = new AreasOfStudyApiClass();
