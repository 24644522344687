import styled from 'styled-components';

import { GENERAL_STYLES_MAP } from '../../themes/constants';

export const ManageAccountsConfidentialAccount = styled.div`
  display: flex;
  margin: 0 32px;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};

  &:last-child {
    margin-bottom: 32px;
  }

  input {
    border: 0.0625rem solid #707070;
  }
`;

export const ManageAccountsColumn = styled.div`
  min-width: 40%;
  padding: 24px 24px 24px 0;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-child(2) {
    min-width: 60%;
    padding-right: 0;
  }
`;

export const ManageAccountsConfidentialAccountPublicDisplayNameLabel = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 5px;
    text-transform: none;
  }
`;
