import { z } from 'zod';
import { Api } from '@axiom/ui';
import {
  Candidate,
  CandidateOpportunity,
  CandidateOpportunitySchema,
  OpportunityCandidate,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

export type readUserOpportunityResponseType = {
  data: Array<OpportunityCandidate>;
};

const updateCandidateOpportunityBodyType = CandidateOpportunitySchema.pick({
  candidateStatus: true,
  candidateStatusMessage: true,
  rejectionLossCode: true,
  rejectionLossCodeCustomMessage: true,
});

class CandidateOpportunityLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readCandidateOpportunities(id: Candidate['id']) {
    return super.read<readUserOpportunityResponseType>({
      endpoint: `/candidates/${id}/opportunities`,
      method: 'GET',
      query: {
        includeOtherCandidatesStatusesOnPositions: true,
      },
    });
  }

  updateCandidateOpportunityStatus(
    candidateId: CandidateOpportunity['candidateId'],
    opportunityId: CandidateOpportunity['opportunityId'],
    body: z.infer<typeof updateCandidateOpportunityBodyType>
  ) {
    return super.write<{ data: CandidateOpportunity[] }>({
      endpoint: `/candidates/${candidateId}/opportunities/${opportunityId}`,
      method: 'PATCH',
      body,
    });
  }

  refreshCandidateOpportunities(id: Candidate['id']) {
    return super.invalidate(`/candidates/${id}/opportunities`);
  }

  refreshAllCandidateOpportunities() {
    return super.invalidateAll();
  }
}

export const CandidateOpportunityLegacyApi =
  new CandidateOpportunityLegacyApiClass();
