/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape, string, bool, number } from 'prop-types';
import styled from 'styled-components';

import { FFError, isErroring } from '../FFError/FFError';
import Input from '../Input/Input';
import DisabledDisplay from '../DisabledDisplay/DisabledDisplay';

const EnabledDisplay = styled.div``;

export const FFInput = ({
  input,
  meta,
  maxLength,
  placeholder,
  disabled,
  showCharsRemainingLabel,
  ...rest
}) => {
  const value = input.value || '';
  const Wrapper = disabled ? DisabledDisplay : EnabledDisplay;

  return (
    <Wrapper>
      <Input
        type={rest.type || 'text'}
        invalid={isErroring(meta)}
        disabled={disabled}
        {...input}
        {...rest}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        onChange={event => input.onChange(event.target.value)}
        showCharsRemainingLabel={!disabled && showCharsRemainingLabel}
        errorMessageNode={<FFError finalFormElementMeta={meta} /> || null}
      />
    </Wrapper>
  );
};

FFInput.propTypes = {
  input: shape({}).isRequired,
  disabled: bool,
  meta: shape({}).isRequired,
  showCharsRemainingLabel: bool,
  maxLength: number,
  placeholder: string,
  showAftIcon: bool,
};

FFInput.defaultProps = {
  disabled: false,
  showCharsRemainingLabel: Input.defaultProps.showCharsRemainingLabel,
  maxLength: Input.defaultProps.maxLength,
  placeholder: '',
  showAftIcon: false,
};
