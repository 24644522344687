import React from 'react';
import { connect } from 'react-redux';

import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { formatDataTestId } from '../../utils/dataTest';

import { AppErrorsWrapper } from './AppErrorsStyles';
import { AppErrorsItem } from './AppErrorsItem';

export const AppErrorsComponent = ({ errors }) => {
  const dismissError = index => {
    PreloadedAppErrorsStore.setState(
      errors.filter((item, mapI) => mapI !== index)
    );
  };

  if (!errors.length) {
    return null;
  }

  return (
    <AppErrorsWrapper data-test={formatDataTestId('app-errors')}>
      {errors.slice(0, 3).map((error, index) => (
        <AppErrorsItem
          key={error.errorMessage}
          error={error}
          onDismiss={() => dismissError(index)}
        />
      ))}
    </AppErrorsWrapper>
  );
};

AppErrorsComponent.propTypes = {
  errors: PreloadedAppErrorsStore.getDataShape().isRequired,
};

export default connect(state => ({
  errors: PreloadedAppErrorsStore.select(state),
}))(AppErrorsComponent);
