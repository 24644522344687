import { arrayOf, shape, string } from 'prop-types';

import { getActivitiesByJobId } from '../api/jobActivities';
import { getActivitiesByTalentId } from '../api/talentActivities';
import GenericStore from '../classes/generic-store';
import { eventShape } from '../models/event';
import { deleteActivityById } from '../api/activities';

export const EventSubjectConst = {
  Candidate: 'Candidate',
  Opportunity: 'Opportunity',
};

const eventSubjectValues = Object.values(EventSubjectConst);

class EventsStoreClass extends GenericStore {
  ensureValidSubject(eventSubject) {
    if (!eventSubjectValues.includes(eventSubject)) {
      throw new Error(
        `eventSubject must be one of the following EventSubjectConst: ${JSON.stringify(
          eventSubjectValues
        )}`
      );
    }
  }

  load(eventSubject, subjectId) {
    this.ensureValidSubject(eventSubject);

    if (eventSubject === EventSubjectConst.Candidate) {
      return this.setState(
        getActivitiesByTalentId(subjectId).then(results => ({
          eventSubject,
          eventSubjectId: subjectId,
          events: results.data,
        }))
      );
    }
    return this.setState(
      getActivitiesByJobId(subjectId).then(results => ({
        eventSubject,
        eventSubjectId: subjectId,
        events: results.data,
      }))
    );
  }

  delete(eventSubject, eventSubjectId, eventId) {
    return this.watchState(deleteActivityById(eventId)).then(() =>
      this.load(eventSubject, eventSubjectId)
    );
  }

  getDataShape() {
    return shape({
      eventSubjectId: string,
      eventSubject: string, // Via EventSubjectConst up above
      events: arrayOf(eventShape),
    });
  }
}

export const EventsStore = new EventsStoreClass();
