import { node, shape, string } from 'prop-types';

import AbstractTableStore from '../classes/abstract-table-store';
import { getTalentByUserIdWithFilters } from '../api/user-talent-api';
import { isUuid } from '../utils/is-uuid';
import { COLLABORATOR, OWNER, TEAM } from '../api/constants';

import { PreloadedUserStore } from './preloaded-user-store';

export const BenchStoreMetaConst = {
  type: {
    owner: OWNER,
    collaborator: COLLABORATOR,
    team: TEAM,
  },
  sort: {
    talentName: 'lastName',
    profileStatus: 'profileStatus',
    practiceArea: 'practiceAreaId',
    currentAvailability: 'weeklyAvailability',
    furthestCandidacy: 'furthestCandidacyStatus',
    accountName: 'alphabeticallySmallestEngagementAccountName',
    rollOffDate: 'soonestEngagementEndDate',
  },
  profileStatus: {
    all: null,
    certifying: 'Certifying',
    inDiligence: 'In Diligence',
    idle: 'Idle',
    waitlist: 'Waitlist',
    pendingActive: 'Pending Active',
    active: 'Active',
    pendingBeach: 'Pending Beach',
    beach: 'Beach',
    pendingAlum: 'Pending Alum',
    pendingAlumDNR: 'Pending Alum DNR',
  },
};

class BenchClass extends AbstractTableStore {
  getDefaultFilters() {
    return {
      type: BenchStoreMetaConst.type.owner,
      profileStatus: [
        BenchStoreMetaConst.profileStatus.active,
        BenchStoreMetaConst.profileStatus.beach,
        BenchStoreMetaConst.profileStatus.pendingActive,
        BenchStoreMetaConst.profileStatus.pendingBeach,
        BenchStoreMetaConst.profileStatus.waitlist,
      ],
    };
  }

  formatMetaForRequest(meta) {
    const query = {};

    if (meta.sort) {
      query.sort = meta.sort;
    }

    query.filters = {};
    const isOtherTeamMemberSearch =
      meta.searchConfig?.filters.type && isUuid(meta.searchConfig.filters.type);

    if (meta.searchConfig?.filters.type) {
      query.type = isOtherTeamMemberSearch
        ? BenchStoreMetaConst.type.owner
        : meta.searchConfig.filters.type;
    }

    if (meta.searchConfig?.filters.profileStatus) {
      query.filters.profileStatus = meta.searchConfig.filters.profileStatus;
    }

    if (meta.userId) {
      query.ownerUserId = isOtherTeamMemberSearch
        ? meta.searchConfig.filters.type
        : meta.userId;
    }

    query.page = meta.serverMeta.currentPage || 1;

    return query;
  }

  loadByConfig(state) {
    this.dispatch((dispatch, getState) => {
      const filters = this.getFiltersFromState(state);
      const userState = PreloadedUserStore.selectRoot(getState());
      const userId = userState.data.id;

      if (this.shouldUpdateQueryParams(filters)) {
        this.overrideFilters(filters, true);
      } else {
        this._sendRequest({
          meta: {
            sort: this.getSort(),
            searchConfig: {
              filters: this.getFilters(),
            },
            userId,
          },
        });
      }
    });
  }

  getResults(query) {
    return getTalentByUserIdWithFilters(query).then(response => response);
  }

  getSort() {
    return this.getFilters().sort || BenchStoreMetaConst.sort.talentName;
  }
}

export const BenchStore = new BenchClass({
  filterShape: shape({
    type: string,
    profileStatus: string,
  }),
  dataShape: node,
});
