import { arrayOf } from 'prop-types';
import sortBy from 'lodash/sortBy';

import GenericStore from '../classes/generic-store';
import { certificationShape } from '../models/certification';
import { deleteCertification } from '../api/certification';
import { getDecoratedTalent } from '../redux/actions/talent';

class CandidateCertificationsStoreClass extends GenericStore {
  load(certifications) {
    return this.setState(
      sortBy(Array.isArray(certifications) ? certifications : [], [
        'institution',
      ])
    );
  }

  getDataShape() {
    return arrayOf(certificationShape);
  }

  delete(certification) {
    return this.watchState(deleteCertification(certification.id)).then(() => {
      this.dispatch(getDecoratedTalent(certification.candidateId));
    });
  }
}

export const CandidateCertificationsStore =
  new CandidateCertificationsStoreClass();
