import { Api } from '@axiom/ui';
import { Tag } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';
import { TAG_TYPE } from './constants';

const api = new APIHelper('tag');

const callGet = async (type: string) => {
  const { data } = await api.GET(`/tags`, { type });
  return { data };
};

const callUpdate = async (tagId: string, body: Tag) => {
  const { data } = await api.PATCH(`/tags/${tagId}`, body);
  return { data };
};

const callAdd = async (body: Tag) => {
  const { data } = await api.POST(`/tags`, [body]);
  return { data };
};

const callDelete = async (tagId: string) => {
  const { data } = await api.DELETE(`/tags/${tagId}`, { type: TAG_TYPE.TAG });
  return { data };
};

export const getTags = async () => callGet(TAG_TYPE.TAG);
export const getSkills = async () => callGet(TAG_TYPE.SKILL);
export const getIndustries = async () => callGet(TAG_TYPE.INDUSTRY);
export const getLawSchoolRanks = async () => callGet(TAG_TYPE.LAW_SCHOOL_RANK);
export const updateTag = async (tagId: string, body: Tag) =>
  callUpdate(tagId, body);
export const updateSkill = async (tagId: string, body: Tag) => {
  body.type = TAG_TYPE.SKILL;
  return callUpdate(tagId, body);
};
export const addTag = async (body: Tag) => {
  body.type = TAG_TYPE.TAG;
  return callAdd(body);
};
export const addSkill = async (tagId: string, body: Tag) => {
  body.type = TAG_TYPE.SKILL;
  return callAdd(body);
};
export const deleteTag = async (tagId: string) => callDelete(tagId);
export const deleteSkill = async () => callDelete(TAG_TYPE.SKILL);

export const getOpportunitiesByTagId = async (tagId: string) => {
  const { data } = await api.GET(`/tags/${tagId}/opportunities`);
  return { data };
};

export const getCandidatesByTagId = async (tagId: string) => {
  const { data } = await api.GET(`/tags/${tagId}/candidates`);
  return { data };
};

class TagsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readSkills() {
    return super.read<{ data: Array<Tag> }>({
      endpoint: '/tags',
      method: 'GET',
      query: { type: TAG_TYPE.SKILL },
    });
  }

  readTags() {
    return super.read<{ data: Array<Tag> }>({
      endpoint: '/tags',
      method: 'GET',
    });
  }
}

export const TagsApi = new TagsApiClass();

export default {
  getTags,
  getSkills,
  getOpportunitiesByTagId,
  getCandidatesByTagId,
};
