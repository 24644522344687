import React from 'react';

import ManageAccountsList from '../ManageAccountsList/ManageAccountsList';
import { ManageAccountsForm } from '../ManageAccountsForm/ManageAccountsForm';

import {
  ManageAccountsWrapper,
  ManageAccountsLeft,
  ManageAccountsRight,
} from './ManageAccountsViewStyles';

const ManageAccountsView = () => {
  return (
    <ManageAccountsWrapper data-test="MANAGE_ACCOUNTS">
      <ManageAccountsLeft>
        <ManageAccountsList />
      </ManageAccountsLeft>
      <ManageAccountsRight>
        <ManageAccountsForm />
      </ManageAccountsRight>
    </ManageAccountsWrapper>
  );
};

export default ManageAccountsView;
