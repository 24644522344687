import React from 'react';
import { Button, Header3 } from '@axiom/ui';

import { Header } from '../../components/Header/Header';
import BannerContainer from '../../components/Banner/Banner';

import { ErrorBoundaryLayoutWrapper } from './ErrorBoundaryLayoutStyles';

export const ErrorBoundaryLayout = () => {
  return (
    <>
      <Header />
      <BannerContainer />
      <div id="top" />
      <ErrorBoundaryLayoutWrapper>
        <Header3>
          There was a problem displaying this page. We apologize for any
          inconvenience. Please try again, and if the issue continues, reach out
          to your Axiom contact for assistance.
        </Header3>
        <Button to="/">Home</Button>
      </ErrorBoundaryLayoutWrapper>
    </>
  );
};
