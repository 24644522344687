import styled from 'styled-components';
import { CheckmarkIcon } from '@axiom/ui';

export const CheckboxWrapper = styled.div``;

export const CheckmarkIconWrapper = styled(CheckmarkIcon)`
  position: absolute;
  top: -0.375rem;
  width: 1.25rem;
  ${props => props.disabled && 'fill: #adadad'};
`;

export const InputWrapper = styled.input`
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
  display: none;
`;
