import styled from 'styled-components';

export const SavedSearchesItemWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 25px;
`;
export const SavedSearchesItemTitle = styled.div`
  flex: 1;
  padding: 15px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  :after {
    content: '';
    height: 50%;
    width: 1px;
    background: #000;
  }
`;
export const SavedSearchesMenuItem = styled.div`
  font-size: 32px;
  padding: 15px;
  position: relative;
  height: 48px;
  color: ${({ expanded }) => (expanded ? 'white' : 'black')};
  background-color: ${({ expanded }) => (expanded ? 'black' : 'white')};
  :after {
    content: '...';
    position: relative;
    top: -14px;
  }
  :before {
    content: '';
    position: absolute;
    left: 0;
    border-left: 1px solid black;
    height: 45%;
  }
`;

export const SavedSearchesItemFilterContainer = styled.div`
  width: 100%;
  background: white;
  padding: 15px;
  top: 10px;
`;

export const SavedSearchesPlayWrapper = styled.div`
  padding: 15px 25px;
  box-shadow: 10px 0 20px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
`;
