import { Api } from '@axiom/ui';
import { BarredLocation } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

import APIHelper from './APIHelper';

const api = new APIHelper('barred location');

export const getBarredLocations = async () => {
  const { data, _meta } = await api.GET('/barredLocations');
  return {
    data,
    meta: _meta,
  };
};

class BarredLocationApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readBarredLocations() {
    return super.read<{ data: Array<BarredLocation> }>({
      endpoint: '/barredLocations',
      method: 'GET',
    });
  }
}

export const BarredLocationApi = new BarredLocationApiClass();

export default {
  getBarredLocations,
};
