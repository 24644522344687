import styled from 'styled-components';

// eslint-disable-next-line boundaries/element-types
import { DynamicScrollingItem as UMLDynamicScrollingItem } from '../UserManagementList/UserManagementListStyles';

export const LawSchoolsManagementListDynamicScrollingItem = styled(
  UMLDynamicScrollingItem
)`
  max-height: 32em;
  overflow: hidden;
`;

export const LawSchoolsManagementListNewSchoolWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Root as LawSchoolsManagementListRoot,
  StaticItem as LawSchoolsManagementListStaticItem,
  UserList as LSMList,
  UserListItem as LawSchoolsManagementListItem,
} from '../UserManagementList/UserManagementListStyles';
