const stringToFloatFieldNames = new Set([]);

export const parseFloatFormValues = formValues => {
  const fieldsToBeUpdated = [];
  stringToFloatFieldNames.forEach(fieldName => {
    if (formValues[fieldName] && typeof formValues[fieldName] === 'string') {
      fieldsToBeUpdated.push({
        fieldName,
        value: parseFloat(formValues[fieldName]),
      });
    }
  });

  const changedFormValues = {};
  fieldsToBeUpdated.forEach(fieldConfig => {
    changedFormValues[fieldConfig.fieldName] = fieldConfig.value;
  });

  return {
    ...formValues,
    ...changedFormValues,
  };
};

export const parseFormValues = formValues => {
  const formValuesWithFloats = parseFloatFormValues(formValues);

  return formValuesWithFloats;
};
