import fGet from 'lodash/fp/get';
import { getFormValues, hasSubmitSucceeded } from 'redux-form';
import { createSelector } from 'reselect';

import { TALENT_EDIT } from '../constants';
import { TALENT_FIELD_COLLABORATORS } from '../../models/constants/talentFields';
import { TALENT_FORM } from '../../api/constants';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EventsStore } from '../../stores/events-store';
import { CandidateStore } from '../../stores/candidate-store';
import { CandidateOpportunitiesStore } from '../../stores/candidate-opportunities-store';
import { PreloadedLanguagesStore } from '../../stores/preloaded-languages-store';
import { CandidateCollaboratorsStore } from '../../stores/candidate-collaborators-store';

import { deriveTalentFields } from './helpers';

export const makeTalentViewSelector = createSelector(
  (state, props) => props.candidateId,
  getFormValues(TALENT_FORM),
  hasSubmitSucceeded(TALENT_EDIT),
  state => PreloadedLanguagesStore.selectData(state),
  fGet('tags.byId'),
  state => PreloadedUserStore.select(state),
  state => EventsStore.select(state),
  state => CandidateStore.select(state),
  state => CandidateOpportunitiesStore.select(state),
  state => CandidateCollaboratorsStore.select(state),
  // Rest of this is "recordsByID"
  fGet('talent.byId'),
  fGet('talentBarredLocations.byTalentId'),
  fGet('talent.degreesById'),
  fGet('talent.experiencesById'),
  fGet('talentHomeOffices.byTalentId'),
  fGet('talentLanguages.byTalentId'),
  fGet('talentOpportunities.byTalentId'),
  fGet('talentTags.byTalentId'),
  (
    candidateId,
    formValues = {},
    hasSubmitted,
    languagesState,
    tagsById,
    user,
    eventsState,
    candidateState,
    candidateOpportunityState,
    collaboratorsState,
    ...recordsByID
  ) => {
    const [
      talentData,
      barredLocations,
      degrees,
      experiences,
      homeOffice,
      languages,
      opportunities,
      tags,
    ] = recordsByID.map(sourceById => sourceById[candidateId]);

    if (experiences) {
      experiences.forEach(experience => {
        if (experience.tags) {
          experience.tags = experience.tags.map(tagId =>
            typeof tagId === 'string' ? tagsById[tagId] : tagId
          );
        }
      });
    }

    const talent = deriveTalentFields({
      talentData,
      barredLocations,
      [TALENT_FIELD_COLLABORATORS]: collaboratorsState.data || [],
      degrees,
      experiences,
      homeOffice,
      languages,
      opportunities,
      tags,
    });

    return {
      hasSubmitted,
      languagesById: languagesState.byId,
      searchTerm: formValues.search,
      talent,
      user,
      eventsState,
      candidateState,
      candidateOpportunityState,
      collaboratorsState,
    };
  }
);

export default makeTalentViewSelector;
