import { CertificationDto } from '@axiom/ui';

import APIHelper from './APIHelper';

const api = new APIHelper('certification');

export const createCertification = async (candidateId, body) => {
  const requestBody = new CertificationDto(body);
  const { data } = await api.POST(
    `/candidates/${candidateId}/certifications`,
    requestBody.export()
  );
  return { data };
};

export const deleteCertification = async certificationId => {
  const { data } = await api.DELETE(`/certifications/${certificationId}`);
  return { data };
};

export const updateCertification = async (certificationId, body) => {
  const requestBody = new CertificationDto(body);
  const { data } = await api.PATCH(
    `/certifications/${certificationId}`,
    requestBody.export()
  );
  return { data };
};
