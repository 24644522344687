import styled from 'styled-components';

export const SpanOpportunityNameHeader = styled.span`
  padding-left: 1rem;
`;

export const OpportunitiesListSpinnerContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  padding-left: 1rem;
`;
