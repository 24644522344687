import styled from 'styled-components';

// eslint-disable-next-line boundaries/element-types
import { DynamicScrollingItem as UMLDynamicScrollingItem } from '../UserManagementList/UserManagementListStyles';

export const ManageAccountsListDynamicScrollingItem = styled(
  UMLDynamicScrollingItem
)`
  max-height: 32em;
  overflow: hidden;
`;

export {
  Root as ManageAccountsListRoot,
  StaticItem as ManageAccountsListStaticItem,
  UserList as MAList,
  UserListItem as ManageAccountsListItem,
} from '../UserManagementList/UserManagementListStyles';
