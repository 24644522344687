/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, bool, func, oneOfType, oneOf } from 'prop-types';

import { formatDataTestId } from '../../utils/dataTest';

import {
  RadioButtonWrapper,
  RadioLabel,
  RadioInput,
} from './RadioButtonStyles';

const RadioButton = ({
  checked,
  className,
  domID,
  label,
  name,
  onChange,
  value,
  disabled,
  labelSide,
  ...rest
}) => (
  <RadioButtonWrapper className={className} $labelSide={labelSide}>
    <RadioInput
      data-test={formatDataTestId(`${name}_${value}`)}
      checked={checked}
      id={domID}
      name={name}
      onChange={onChange}
      type="radio"
      value={value}
      disabled={disabled}
      {...rest}
    />
    <RadioLabel htmlFor={domID} isDisabled={disabled}>
      {label}
    </RadioLabel>
  </RadioButtonWrapper>
);

RadioButton.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  label: null,
  labelSide: 'left',
  onChange: () => false,
};

// TODO-SEAN Qualify workflow is giving this error. resolve later
RadioButton.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
  domID: string.isRequired,
  label: string,
  name: string.isRequired,
  onChange: func,
  value: oneOfType([string, bool]).isRequired,
  labelSide: oneOf(['left', 'right']),
};

export default RadioButton;
