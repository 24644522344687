import { JOB_PRACTICE_AREAS, OpportunitiesConst } from '@axiom/const';

import { sortByLabel } from '../../utils/sort';
import selectify from '../../utils/selectify';
import { OPPORTUNITY_STAGE_OPTIONS } from '../../utils/opportunity-utils';

export const opportunityStageFilterName = 'filters.stageCode';
export const opportunityBusinessTeamFilterName = 'filters.businessTeam';

export const opportunityStageSelectItems = selectify({
  groupLabel: 'Opportunity Stage',
  group: opportunityStageFilterName,
  options: OPPORTUNITY_STAGE_OPTIONS,
});
export const opportunityBusinessTeamSelectItems = selectify({
  groupLabel: 'Business Team',
  group: opportunityBusinessTeamFilterName,
  options: OpportunitiesConst.BusinessTeams,
});
export const opportunityPracticeAreaSelectItems = Object.keys(
  JOB_PRACTICE_AREAS
)
  .map(id => ({
    label: JOB_PRACTICE_AREAS[id],
    value: id,
  }))
  .sort(sortByLabel);
