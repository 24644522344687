import styled from 'styled-components';

const ActionItemWrapperBase = styled.div`
  display: flex;
  min-width: 50px;
  border-right-width: 1px;
  border-right-style: solid;
  align-items: center;
  flex: 1;
  overflow: hidden;

  &:last-child {
    border-right: 1px solid transparent;
  }

  & > div,
  button {
    margin: 0 auto;
  }

  ul.k-menu.k-menu-horizontal {
    padding: 0;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const HeaderActionItemWrapper = styled(ActionItemWrapperBase)`
  border-right-color: transparent;
`;
export const CellActionItemWrapper = styled(ActionItemWrapperBase)`
  border-right-color: #e0e0e0;
  text-align: center;
`;
