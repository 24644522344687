import fGet from 'lodash/fp/get';
import { isEmpty, isEqual, omit, pick, pickBy } from 'lodash';
import { getFormInitialValues, getFormValues } from 'redux-form';
import { call, put, takeLatest, select, all } from 'redux-saga/effects';

import {
  CLOSED_WON,
  COLLABORATOR,
  LANGUAGES,
  OWNER,
  TEAM,
} from '../../api/constants';
import {
  getRelatedJobs as getRelatedOpportunities,
  putJob as putOpportunity,
} from '../../api/jobs';
import { putCollaboratorsByOpportunityId } from '../../api/opportunityCollaborators';
import { putLanguagesByOpportunityId } from '../../api/opportunityLanguages';
import {
  postTalentsByOpportunityID,
  updateTalentStatusByOpportunity,
} from '../../api/opportunity-talent-api';
import { patchTalent } from '../../api/talent';
import { COLLABORATORS } from '../../components/OpportunityEditForm/OpportunityEditFormConstants';
import {
  OPPORTUNITY_FIELD_ID,
  OPPORTUNITY_FIELD_STAGE,
} from '../../models/constants/opportunityFields';
import { TALENT_FIELD_OWNER_USER_ID } from '../../models/constants/talentFields';
import opportunityModel from '../../models/opportunities';
import { addApiError } from '../actions/app';
import { submitForm } from '../actions/form';
import {
  getDecoratedOpportunity,
  opportunitiesError,
  opportunityCandidatesSaveSuccess,
  opportunityCandidatesSaveError,
  opportunityCandidatesEditSuccess,
  opportunityCandidatesEditError,
  opportunitySaveSuccess,
  relatedOpportunitiesError,
  relatedOpportunitiesLoaded,
} from '../actions/opportunities';
import { getDecoratedTalent } from '../actions/talent';
import { getOpportunitiesByUserId } from '../actions/userOpportunities';
import {
  OPPORTUNITY_CANDIDATES_EDIT,
  OPPORTUNITY_CANDIDATES_SAVE,
  OPPORTUNITY_CLOSED_WON,
  OPPORTUNITY_PARENT_SAVE,
  RELATED_OPPORTUNITIES_LOAD,
  WORKSPACE,
} from '../constants';
import { groupMembersByUserIdSelector } from '../selectors/groups';

import { parseFormValues } from './helpers';

export function* updateOpportunityGenerator(form) {
  // eslint-disable-next-line no-useless-catch
  try {
    const initialValues = yield select(getFormInitialValues(form));
    const registered = yield select(fGet(`form.${form}.registeredFields`));
    const values = yield select(getFormValues(form));

    const safeValues = pickBy(
      values,
      (val, key) =>
        opportunityModel[key] &&
        // include stage or any/all registered fields if "forced" + "stage"
        ((initialValues.force === true && key === 'stage') ||
          // value is not MISSING DATA
          (val !== 'MISSING DATA' &&
            // value is "dirty"
            !isEqual(val, initialValues[key]) &&
            [...Object.keys(registered)].includes(key)))
    );

    // Hack for release @TODO-leif make this not bad
    if (form === OPPORTUNITY_CLOSED_WON) {
      safeValues[OPPORTUNITY_FIELD_STAGE] = CLOSED_WON;
    }

    const parsedValues = parseFormValues(safeValues);

    const safeValuesWithoutRelatedEntityFields = omit(parsedValues, [
      COLLABORATORS,
      LANGUAGES,
      'candidates',
      'force',
      'ownerUserFullName',
      OPPORTUNITY_FIELD_ID,
    ]);

    const candidates =
      values.candidates &&
      values.candidates.filter((candidate, i) => {
        const owner = candidate[TALENT_FIELD_OWNER_USER_ID];
        const initial = initialValues.candidates[i] || {};
        return owner && owner !== initial[TALENT_FIELD_OWNER_USER_ID];
      });

    const collaborators = parsedValues[COLLABORATORS];
    const languageObjects = parsedValues[LANGUAGES];
    const shouldPatchOpportunity =
      !isEmpty(safeValuesWithoutRelatedEntityFields) || initialValues.force;

    const calls = [
      // Only patch if we have something to actually patch
      ...(shouldPatchOpportunity
        ? [
            call(
              putOpportunity,
              values.id,
              safeValuesWithoutRelatedEntityFields
            ),
          ]
        : []),
      ...(candidates
        ? candidates.map(candidate =>
            call(
              patchTalent,
              candidate.id,
              pick(candidate, [TALENT_FIELD_OWNER_USER_ID])
            )
          )
        : []),
      ...(collaborators
        ? [call(putCollaboratorsByOpportunityId, values.id, collaborators)]
        : []),
      ...(languageObjects
        ? [call(putLanguagesByOpportunityId, values.id, languageObjects)]
        : []),
    ];

    const [patchOpportunity] = yield all(calls);

    if (candidates || collaborators || languageObjects) {
      yield put(getDecoratedOpportunity(values.id));
    }
    if (shouldPatchOpportunity) {
      yield put(opportunitySaveSuccess(patchOpportunity.data));
    }

    // If we're currently on the Workspace view, we want to reissue the
    // API query to get an updated list of opportunities to show there, since
    // the edit we just made may change that list. We check this by
    // seeing if the workspace filter form is currently mounted.
    const workspaceFormState = yield select(fGet(`form.${WORKSPACE}`));
    if (workspaceFormState) {
      yield put(submitForm(WORKSPACE));
    }
  } catch (e) {
    throw e;
  }
}

export function* opportunityCandidatesSaga(action) {
  try {
    const result = yield call(postTalentsByOpportunityID, {
      opportunityId: action.payload.id,
      candidateIds: action.payload.selectedIds,
    });
    yield put(opportunityCandidatesSaveSuccess(result));
    yield put(getDecoratedOpportunity(action.payload.id));
    const activeRecord = yield select(fGet('app.activeRecordId'));
    if (action.payload.selectedIds.includes(activeRecord)) {
      yield put(getDecoratedTalent(activeRecord));
    }
  } catch (e) {
    yield put(opportunityCandidatesSaveError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateCandidateByOpportunitySaga(action) {
  try {
    const { candidateId, opportunityId, ...values } = action.payload;
    const shouldUpdateCandidate = Object.keys(values).length > 0;
    if (shouldUpdateCandidate) {
      const result = yield call(updateTalentStatusByOpportunity, {
        candidateId,
        opportunityId,
        ...values,
      });
      yield put(opportunityCandidatesEditSuccess(result));
    }
    if (shouldUpdateCandidate) {
      yield put(getDecoratedOpportunity(opportunityId));
    }
  } catch (e) {
    yield put(opportunityCandidatesEditError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateOpportunityParentSaga(action) {
  try {
    const { id, parentId } = action.payload;
    const result = yield call(putOpportunity, id, { parentId });
    yield put(opportunitySaveSuccess(result.data));
    yield put(getDecoratedOpportunity(id));
  } catch (e) {
    yield put(addApiError(e.applicationError));
  }
}

export function* updateWorkspaceSaga(form) {
  try {
    const user = yield select(fGet('user'));
    let userId = user.id;
    const { filter, sort } = yield select(getFormValues(form));
    const query = { sort };
    if (filter === TEAM) {
      const groupMembersByUserId = yield select(groupMembersByUserIdSelector);
      const groupMembers = groupMembersByUserId[userId];
      if (groupMembers) {
        yield all(
          groupMembers.map(member =>
            put(getOpportunitiesByUserId(member.id, query))
          )
        );
      }
    } else {
      if (filter === OWNER || filter === COLLABORATOR) {
        query.type = filter;
      } else {
        userId = filter;
      }
      yield put(getOpportunitiesByUserId(userId, query));
    }
  } catch (e) {
    yield put(opportunitiesError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* relatedOpportunitiesSaga(action) {
  try {
    const result = yield call(getRelatedOpportunities, action.payload);
    yield put(relatedOpportunitiesLoaded(result));
  } catch (e) {
    yield put(relatedOpportunitiesError(e));
    yield put(addApiError(e.applicationError));
  }
}

function* opportunitiesSaga() {
  yield takeLatest(OPPORTUNITY_CANDIDATES_SAVE, opportunityCandidatesSaga);
  yield takeLatest(
    OPPORTUNITY_CANDIDATES_EDIT,
    updateCandidateByOpportunitySaga
  );
  yield takeLatest(OPPORTUNITY_PARENT_SAVE, updateOpportunityParentSaga);
  yield takeLatest(RELATED_OPPORTUNITIES_LOAD, relatedOpportunitiesSaga);
}

export default opportunitiesSaga;
