import React from 'react';
import { State } from 'country-state-city';
import { z } from 'zod';
import {
  CandidatesConst,
  MLRecommendationsConst,
  NonConformingOrConformingCountryCodesAbbreviationsType,
} from '@axiom/const';
import {
  Button,
  CondensedLarge,
  Dropdown,
  Form,
  Gutter,
  Input,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Paragraph,
  SmallHeader,
  ToastUtil,
  CondensedHeader,
} from '@axiom/ui';
import { Opportunity } from '@axiom/validation';

import { CandidateUtil } from '../../utils/candidate-util';
import { OpportunityStore } from '../../stores/opportunity-store';
import { AiDuplicatesMessage } from '../AiDuplicatesMessage/AiDuplicatesMessage';
import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';

const { SupportedStateCountryCodeToStateCountryCodeAbbreviations } =
  MLRecommendationsConst;
const { ProfileStatusLists } = CandidatesConst;

const formSchema = z.object({
  size: z.number().int().gte(1).lte(10).nullish(),
  availability: z.number().nonnegative().nullish(),
  compensationEnd: z.number().nonnegative().nullish(),
  addressState: z.array(z.string()).nullish(),
  profileStatus: z.string().array().min(1).nullish(),
});
export type FormSchema = z.infer<typeof formSchema>;

const formatFilterStructure = ({
  size,
  addressState,
  availability,
  compensationEnd,
  profileStatus,
}: FormSchema) => {
  return {
    ...(addressState?.length && { addressState }),
    compensation: {
      start: 0,
      ...(compensationEnd && {
        end: Number(compensationEnd),
      }),
    },
    ...(profileStatus && { profileStatus }),
    ...(size && { size }),
    ...(availability && { weeklyAvailability: { start: availability } }),
  };
};

export const GetMoreMatchesModal = ({
  opportunity,
  onRequestSuccess,
  onClose,
  savedFilters,
}: {
  opportunity: Opportunity;
  onRequestSuccess: ({ filters }: { filters: FormSchema }) => void;
  onClose: () => void;
  savedFilters: FormSchema;
}) => {
  const initialValues: FormSchema = {
    availability:
      (opportunity.requiredBillingHoursPerWeek ?? null) !== null
        ? Math.floor(opportunity.requiredBillingHoursPerWeek / 5) * 5
        : 0,
    addressState: [],
    profileStatus: ProfileStatusLists.allCertifiedTalent.value,
    size: 3,
    ...savedFilters,
  };

  return (
    <Form
      name="GET_MORE_MATCHES_MODAL_FORM"
      schema={formSchema}
      initialValues={initialValues}
      onSubmit={async formData => {
        const completeFormData = { ...initialValues, ...formData };
        const translatedFilters = formatFilterStructure(completeFormData);
        const response =
          await OpportunityCandidateMlRecommendationApi.createOpportunityCandidateMlRecommendations(
            { opportunityId: opportunity.id },
            translatedFilters
          );
        // null response indicates an api error we are aware of
        if (response === null) {
          // close the modal - an error dialog is showing
          onClose();
          return;
        }
        const { data: { candidateIds, opportunityCandidates } = {} } = response;
        if (candidateIds?.length) {
          // Pop a toast if talent was already on the opp before the AI match
          const dupes = opportunityCandidates?.filter(
            ({ duplicate }) => duplicate
          );
          if (dupes?.length) {
            ToastUtil.add({
              dismissible: true,
              name: `DUPLICATECAND`,
              type: 'critical',
              children: <AiDuplicatesMessage duplicates={dupes} />,
            });
          }
          // close the modal - we got matches
          onClose();
        } else {
          ToastUtil.add({
            dismissible: true,
            name: `NOCANDFOUND`,
            type: 'error',
            children: (
              <>
                <CondensedHeader>Error</CondensedHeader>
                <CondensedLarge>
                  We couldn't find any talent that matches your criteria. Let's
                  broaden your search and try again.
                </CondensedLarge>
              </>
            ),
          });
        }
        await OpportunityStore.load(opportunity.id);
        onRequestSuccess({ filters: completeFormData });
      }}
    >
      {({ fireSubmit, values }) => {
        const filters = JSON.stringify(formatFilterStructure(values));

        return (
          <Modal>
            <ModalHeader onClose={onClose}>Get More Matches</ModalHeader>
            <ModalSection>
              <div
                data-test="pendo-analytics-get-more-matches-modal"
                data-pendo-analytics-id="get-more-matches-click"
                data-pendo-analytics-opportunity-id={opportunity?.id}
                data-pendo-analytics-get-more-matches-filters={filters}
              >
                <SmallHeader name="jobName">{opportunity?.jobName}</SmallHeader>
                <Gutter bottom="16px" />
                <Paragraph>
                  Edit the options below to find more AI matches for this
                  opportunity.
                </Paragraph>
                <Gutter bottom="16px" />
                <Dropdown
                  name="size"
                  label="How many matches"
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
                    label: `${i}`,
                    value: i,
                  }))}
                  displayKey="label"
                  valueKey="value"
                />
                <Gutter bottom="16px" />
                <Dropdown
                  name="profileStatus"
                  label="Profile Status"
                  options={
                    CandidateUtil.profileFilterStatusOptions as Array<
                      Record<string, string>
                    >
                  }
                  displayKey="label"
                  valueKey="value"
                />
                <Gutter bottom="16px" />
                <Dropdown
                  name="availability"
                  label="Availability"
                  options={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                    i => ({
                      label: `${i} or more`,
                      value: i,
                    })
                  )}
                  displayKey="label"
                  valueKey="value"
                />
                <Gutter bottom="16px" />
                <Dropdown
                  name="addressState"
                  label="Location"
                  options={State.getStatesOfCountry(
                    // opp.requiredTalentCountryCode === 'UK' ? 'GB' : opp.requiredTalentCountryCode
                    SupportedStateCountryCodeToStateCountryCodeAbbreviations[
                      opportunity.requiredTalentCountryCode as NonConformingOrConformingCountryCodesAbbreviationsType
                    ]
                  ).map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                  displayKey="label"
                  valueKey="value"
                />
                <Gutter bottom="16px" />
                <Input
                  name="compensationEnd"
                  label="Maximum Annual Compensation"
                />
              </div>
            </ModalSection>
            <ModalFooter>
              <Button onClick={onClose} variation="outline">
                Cancel
              </Button>
              <Button
                name="PENDO_GET_MORE_MATCHES_MODAL_SUBMIT_BUTTON"
                onClick={fireSubmit}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
