import React from 'react';

import DefaultLayout from '../layouts/default';
import { Page } from '../components/Page/Page';
import { BenchView } from '../components/BenchView/BenchView';

export const BenchPage = () => {
  return (
    <Page>
      <DefaultLayout>
        <BenchView />
      </DefaultLayout>
    </Page>
  );
};
