import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import {
  BOX_SHADOW_RULE,
  GENERAL_STYLES_MAP,
  HEADER_HEIGHT,
  SIDEBAR_PADDING,
  SIDEBAR_WIDTH,
} from '../../themes/constants';

export const BarTab = styled.div`
  appearance: none;
  border: 0;
  position: absolute;
  height: 70px;
  width: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 23px 18px;
  left: -60px;
  top: ${HEADER_HEIGHT}px;
  background: ${GENERAL_STYLES_MAP.AXIOM.background};
  color: ${GENERAL_STYLES_MAP.AXIOM.color};
  z-index: 3;
  ${BOX_SHADOW_RULE};

  svg {
    height: 100%;
    width: 100%;
  }
`;

const rightPadding = SIDEBAR_PADDING * 2.5;

export const StyledWrapper = styled.div`
  min-height: 100%;
  position: relative;

  #sidebar {
    flex: 0 1 auto;

    &[data-expanded='false'] {
      width: 0;
      padding: 0;

      & > .content {
        display: none;
        opacity: 0;
        transition: 0ms opacity linear;
        transition-delay: 0ms;
        width: 0px;
      }
    }
    &[data-expanded='true'] {
      width: ${SIDEBAR_WIDTH}px;
      padding: 0 ${rightPadding}px ${SIDEBAR_PADDING}px ${SIDEBAR_PADDING}px;

      & > .content {
        display: block;
        opacity: 1;
        transition: 100ms opacity linear;
        transition-delay: 300ms;
      }
    }

    background: ${getThemeProp('appBackground')};
    color: ${getThemeProp('primaryText')};
    transition: 300ms width ease-out, 0ms padding ease-out;
    ${BOX_SHADOW_RULE};
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
  }
`;
