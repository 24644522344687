import { bool, number, oneOf, shape, string } from 'prop-types';
import { Education } from '@axiom/const';

export const EDUCATION_FIELD_CANDIDATE_ID = Education.CANDIDATE_ID;
export const EDUCATION_FIELD_DEGREE = Education.DEGREE;
export const EDUCATION_FIELD_ID = Education.ID;
export const EDUCATION_FIELD_INSTITUTION = Education.INSTITUTION;
export const EDUCATION_FIELD_IS_PRIMARY = Education.IS_PRIMARY || 'isPrimary';
export const EDUCATION_FIELD_STATE_EXAM_SCORE = Education.STATE_EXAM_SCORE;
export const EDUCATION_FIELD_YEAR_AWARDED = Education.YEAR_AWARDED;

export const EDUCATION_DEGREES = Education.DEGREES;
export const EDUCATION_DEGREE_JD = Education.DEGREES.JD;
export const EDUCATION_DEGREE_STATE_EXAM = Education.DEGREES.STATE_EXAM;

export const educationDegreeOptions = Object.values(EDUCATION_DEGREES).map(
  v => ({
    label: v,
    value: v,
  })
);

export const educationFieldLabels = {
  [EDUCATION_FIELD_DEGREE]: 'Degree',
  [EDUCATION_FIELD_INSTITUTION]: 'Institution',
  [EDUCATION_FIELD_STATE_EXAM_SCORE]: 'Score',
  [EDUCATION_FIELD_YEAR_AWARDED]: 'Date',
};

const educationModel = {
  [EDUCATION_FIELD_CANDIDATE_ID]: string,
  [EDUCATION_FIELD_DEGREE]: oneOf(Object.values(Education.DEGREES)),
  [EDUCATION_FIELD_ID]: string,
  [EDUCATION_FIELD_INSTITUTION]: string,
  [EDUCATION_FIELD_IS_PRIMARY]: bool,
  [EDUCATION_FIELD_STATE_EXAM_SCORE]: string,
  [EDUCATION_FIELD_YEAR_AWARDED]: number,
};

export const EDUCATION_MODEL_PROPERTIES = Object.keys(educationModel);

export const EDUCATION_MODEL_REQUIRED_PROPERTIES = [
  EDUCATION_FIELD_CANDIDATE_ID,
  EDUCATION_FIELD_DEGREE,
  EDUCATION_FIELD_INSTITUTION,
  EDUCATION_FIELD_YEAR_AWARDED,
];

export const educationShape = shape(educationModel);

export default educationModel;
