import React from 'react';

import ConvertUserIds from '../components/ConvertUserIds/ConvertUserIds';
import DefaultLayout from '../layouts/default';
import { Page } from '../components/Page/Page';

export const ConvertUserIdsPage = () => (
  <Page>
    <DefaultLayout>
      <ConvertUserIds />
    </DefaultLayout>
  </Page>
);
