import { number, shape, string } from 'prop-types';

export const certificationShape = shape({
  award: string,
  candidateId: string,
  id: string,
  institution: string,
  year: number,
});

export const certificationYearOptions = [];
for (let i = new Date().getFullYear(); i >= 1950; i--) {
  certificationYearOptions.push({
    label: String(i),
    value: i,
  });
}
